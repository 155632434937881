import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import {
  PatientChronicConditionsVo,
  PatientFileGenericInformationVo,
  ProviderConfigurationVo,
  Symptom,
  SymptomConfiguration,
  SymptomQuestion,
} from '.';
import { MerakiAuthService } from './meraki-auth.service';
import { EmailRequest, NexResponse, PathologyReportFirestoreVo, SendEmailResponsePayload } from './meraki-models/meraki-models';
import { HttpClientMeraki, MERAKI_API_GATEWAY, MERAKI_CACHE_LB } from './meraki-nexus.providers';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Timestamp = firebase.firestore.Timestamp;
import { GenericMedicine } from './view-models/generic-medicine-view-model.utils';
import {
  AddressBookEntryVo,
  PatientConfigurationVo,
  ReminderVo,
  RestApiResultOfBoolean,
  RestApiResultOfGuid,
  UpdateAddressBookEntry,
  PatientLifestyleVo,
  PatientSurgicalHistoryVo,
  NotificationStatusView,
  NotificationVo,
  ChronicConditionVo,
  PatientClinicalNotesVo,
  PatientAllergiesVo,
  ConditionFilterVo,
  CommunicationTemplateVo,
  ClinicalMetricVo,
  CalendarEventVo,
  PatientInWaitingRoom,
  PatientEventVo,
  PatientEventVoStatus,
  RestApiResultOfCheckinPatientVisitResult,
  PatientEventsByDateVo,
  PatientEventView,
  PatientVo,
} from './api-client.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfigService } from './config.service';
import {
  convertClinicalMetricToFirestore,
  convertConditionToFirestore,
  convertFirestoreToClinicalMetric,
  convertFirestoreToCondition,
  convertPatientToFirestore,
  fromTimestamp,
} from './meraki-models/meraki-model-util';
import { KahunAppointment } from './view-models/kahun-view-model.utils';
import * as uuidV4 from 'uuid/v4';
import * as guidByString from 'uuid-by-string';
import {
  CalendarEvent,
  CALENDAR_EVENT_TYPE,
  CALENDAR_EVENT_STATUS,
  CalendarEventStatusHistory,
  CLINICAL_CALENDAR_EVENT_STATUS,
  VisitInfo,
} from './meraki-models/calendar.models';
import { Practice } from './meraki-models/practice.models';
import { VISIT_TYPE } from './meraki-models/general.models';
import { PatientEventType } from './state/clinical-encounter/clinical-encounter.model';
import { BaseInvoice, INVOICE_STATUS } from './meraki-models/invoice.models';
import { ACCOUNT_TYPE, Account } from './meraki-models/account.models';
import { AccountMember, IDENTIFICATION_TYPE, PatientAccount, PatientAccountResponse } from './meraki-models/member.models';
import { AdImpression } from './view-models/smartmeds-ads-models';
import { environment } from '@env/environment';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';
import { b64toBlob, blobToFile } from '@app/shared/functions/b64ToBlob';
import { ApplicationInsightsService } from './application-insights.service';

export interface VisitReasonViewModel extends Symptom, SymptomConfiguration {}

export interface VisitReasonQuestionViewModel extends SymptomQuestion {
  Active: boolean;
}

export interface CommonChronicConditionsVo extends ChronicConditionVo {
  CommonForSpecialties: Array<string>;
}

export interface GenericMedicineRequest {
  FsCollection: string;
  BaseGroupName: string;
  CommunityName: string;
  CohortName: string;
  BPN_TPN: string;
  ATC5Code: string;
  DrugFamilyGroup: string;
  NAPPICode7: string;
  PatientInfo: any;
  View: string;
}

export interface VaccinatedStatusVo {
  // Pfizer: Array<string> | null;
  // JohnsonAndJohnson: Array<string> | null;
  // Other: Array<string> | null;
  Notes: string | null;
  VaccineStatus: string | null;
}

const PRACTICE_ROOT = 'ClinicalPractice';
const PROVIDER_ROOT = 'ClinicalProvider';
const ENCOUNTER_ROOT = 'Encounter';

const SMARTMEDS_AD_IMPRESSIONS_ROOT = 'SmartMedsAdImpression';
const KAHUN_PRACTICE = 'KahunPractice';

export enum PathReportFollowuActions {
  InTaskList = 'InTaskList',
  SmsSent = 'SmsSent',
  EmailSent = 'EmailSent',
}

@Injectable({
  providedIn: 'root',
})
export class MerakiClientService {
  private http: HttpClientMeraki;
  private baseUrl: string;
  private loadBalancerUrl: string;

  constructor(
    private httpHandler: HttpBackend, // Bypass Auth interceptor since it overrides with florence auth token.
    @Inject(HttpClientMeraki) http: HttpClientMeraki,
    @Inject(MERAKI_API_GATEWAY) baseUrl: string,
    @Inject(MERAKI_CACHE_LB) cacheLbUrl: string,
    private authService: MerakiAuthService,
    private firestore: AngularFirestore,
    private slicePipe: SlicePipe,
    private configService: ConfigService,
    private appInsightService: ApplicationInsightsService
  ) {
    this.http = http;
    //this.http = new HttpClient(httpHandler);

    this.baseUrl = baseUrl ? baseUrl : '';
    this.loadBalancerUrl = cacheLbUrl;
  }

  updateSpeechToNotes(tenantId: string, encounterId: string, isGood: boolean, updatedNotes: string) {
    // console.log(encounterId);
    // console.log(tenantId);
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId)
      .update({
        Rating: isGood == null ? null : isGood ? 'Good' : 'Not Good',
        UpdatedSpeechToNotesSummary: updatedNotes,
      });

    return from(speechToNotesCollection);
  }

  updateSpeechToNotesNoteVersion(tenantId: string, encounterId: string, noteVersion: number) {
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId)
      .update({ NoteVersion: noteVersion });

    return from(speechToNotesCollection);
  }

  createSpeechToNotesCollection(tenantId: string, encounterId: string, recordingDuration: number, micDevice: string) {
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId);

    return from(
      speechToNotesCollection.set({
        RecordingDuration: recordingDuration,
        MicrophoneDeviceUsed: micDevice,
      })
    );
  }

  listenForTranscription(id: string, practiceTenantId: string): Observable<string | null> {
    return this.firestore
      .collection('ClinicalPractice')
      .doc(practiceTenantId)
      .collection('Encounter')
      .doc(id)
      .collection('SpeechToNotes')
      .doc(id)
      .valueChanges()
      .pipe(
        map(data => (data ? (data['SpeechToNotesSummary'] ? data['UpdatedSpeechToNotesSummary'] : '') : null)),
        tap(res => {}) // Map to transcription field directly
      );
  }

  getRecentCompletedEncounterId(practiceId: string, patientId: string): Observable<any> {
    // First fetch only completed encounters for the patient
    const encounterSnapshot = this.firestore
      .collection('ClinicalPractice')
      .doc(practiceId)
      .collection('Encounter', ref =>
        ref.where('Status', '==', 'Completed').where('PatientEventDetails.Patient.PatientId', '==', patientId)
      )
      .get();

    //use the encounter snapshot to get the most recent completed encounter
    return encounterSnapshot.pipe(
      map(snapshot => {
        if (snapshot.empty) return null;

        // Sort encounters by date descending (most recent first)
        const sortedDocs = snapshot.docs.sort(
          (a, b) => b.data().EncounterLineItems.EncounterHeader.DateOfService - a.data().EncounterLineItems.EncounterHeader.DateOfService
        );

        // Return the EncounterId of the most recent encounter
        return sortedDocs[0].data().EncounterId;
      })
    );
  }

  listenForLetterGeneration(practiceId: string, practiceTenantId: string, patientId: string): Observable<any> {
    return this.getRecentCompletedEncounterId(practiceTenantId, patientId).pipe(
      switchMap(previousEncounterId => {
        if (!previousEncounterId) {
          return of(null);
        }

        return this.firestore
          .collection('Sandbox')
          .doc(practiceTenantId)
          .collection('PatientLetters')
          .doc(practiceTenantId)
          .collection('Encounters')
          .doc(previousEncounterId)
          .valueChanges()
          .pipe(
            take(1),
            map(data => (data ? data['letterHtml'] : null))
          );
      })
    );
  }

  listenForReferralLetterGeneration(practiceId: string, practiceTenantId: string, patientId: string): Observable<any> {
    return this.firestore
      .collection('Sandbox')
      .doc(practiceTenantId)
      .collection('ReferralLetters')
      .doc(patientId)
      .valueChanges()
      .pipe(
        take(1),
        map(data => (data ? data['letterHtml'] : null))
      );
  }

  submitSandboxUserFeedback(feedback: string, rating: string, practiceId: string, patientId: string) {
    return this.getRecentCompletedEncounterId(practiceId, patientId).pipe(
      take(1),
      switchMap(encounterId => {
        const patientLetterCollection = this.firestore
          .collection('Sandbox')
          .doc(practiceId)
          .collection('PatientLetters')
          .doc(practiceId)
          .collection('Encounters')
          .doc(encounterId);

        return patientLetterCollection.update({
          userFeedback: {
            Feedback: feedback,
            Rating: rating,
          },
        });
      })
    );
  }

  getSandboxProjectVotes(projectName: string): Observable<any> {
    return this.firestore
      .collection('Sandbox')
      .doc('project_votes')
      .valueChanges()
      .pipe(map(data => data?.[projectName] || { upvotes: 0, downvotes: 0 }));
  }

  updateSandboxProjectVotes(projectName: string, vote: 'up' | 'down') {
    return this.firestore
      .collection('Sandbox')
      .doc('project_votes')
      .update({
        [projectName]: {
          upvotes: vote === 'up' ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(0),
          downvotes: vote === 'down' ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(0),
        },
      });
  }

  createSandboxSummary(
    bpn: string,
    practiceId: string,
    patientId: string,
    practiceTenantId: string,
    treatingProvider: string
  ): Observable<any> {
    // console.log('bpn', bpn);
    // console.log('practiceId', practiceId);
    // console.log('patientId', patientId);
    // console.log('practiceTenantId', practiceTenantId);

    return this.getRecentCompletedEncounterId(practiceTenantId, patientId).pipe(
      take(1),
      switchMap(encounterId => {
        // console.log('encounterId', encounterId);
        return this.isSandboxSummaryGCSWritePathAvailable(practiceTenantId, patientId, encounterId).pipe(
          take(1),
          switchMap(available => {
            // console.log('available', available);
            if (available == 'doc does not exist') {
              return this.firestore
                .collection('ClinicalPractice')
                .doc(practiceTenantId)
                .collection('PatientClinicalSummary')
                .doc(patientId)
                .collection('SandboxSummary')
                .doc(encounterId)
                .set({
                  BPN: bpn,
                  TreatingProvider: treatingProvider,
                  PracticeId: practiceId,
                  PracticeTenantId: practiceTenantId,
                  PatientUUID: patientId,
                  CompletedEncounterId: encounterId,
                });
            } else if (available == 'doc does exists without GCSWritePath') {
              // Delete and set operations should be done atomically to avoid race conditions
              const docRef = this.firestore
                .collection('ClinicalPractice')
                .doc(practiceTenantId)
                .collection('PatientClinicalSummary')
                .doc(patientId)
                .collection('SandboxSummary')
                .doc(encounterId);

              return docRef.delete().then(() => {
                return docRef.set({
                  BPN: bpn,
                  TreatingProvider: treatingProvider,
                  PracticeId: practiceId,
                  PracticeTenantId: practiceTenantId,
                  PatientUUID: patientId,
                  CompletedEncounterId: encounterId,
                });
              });
            } else {
              return of(null);
            }
          })
        );
      })
    );
  }

  isSandboxSummaryGCSWritePathAvailable(practiceTenantId: string, patientId: string, completedEncounterId: string): Observable<string> {
    return this.firestore
      .collection('ClinicalPractice')
      .doc(practiceTenantId)
      .collection('PatientClinicalSummary')
      .doc(patientId)
      .collection('SandboxSummary')
      .doc(completedEncounterId)
      .get()
      .pipe(
        take(1),
        map(doc => {
          const data = doc.data();
          // console.log('data', data);
          if (doc.exists) {
            if (data?.GCSWritePath) {
              return 'doc exists with GCSWritePath';
            } else {
              return 'doc does exists without GCSWritePath';
            }
          } else {
            return 'doc does not exist';
          }
        })
      );
  }

  isPracticeEnabledForSpeechToNotes(bpn: string, hpcsaNumber: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(`Practice/${bpn}`)
      .get()
      .pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          var isSpeechToNotesEnabled = practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.Enabled == true;
          var isEnabledDoctorsEmpty = practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.EnabledDoctors?.length == 0;

          if (isSpeechToNotesEnabled) {
            return isEnabledDoctorsEmpty
              ? true
              : practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.EnabledDoctors?.includes(hpcsaNumber);
          }
        })
      );
  }

  isPracticeEnabledForSandboxProjects(bpn: string, hpcsaNumber: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(`Practice/${bpn}`)
      .get()
      .pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          var isSandboxEnabled = practice?.Settings?.SandboxSettings?.Enabled == true;
          var isEnabledDoctorsEmpty = practice?.Settings?.SandboxSettings?.EnabledDoctors?.length == 0;

          if (isSandboxEnabled) {
            return isEnabledDoctorsEmpty ? true : practice?.Settings?.SandboxSettings?.EnabledDoctors?.includes(hpcsaNumber);
          }
          return false;
        })
      );
  }

  isPracticeEnabledForPatientAI(bpn: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(`Practice/${bpn}`)
      .get()
      .pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          // support both typo version and expected version
          return (
            practice?.Settings?.ClinicalCoPilotSettings?.PatientHistorySummary?.Enabled == true ||
            practice?.Settings?.ClincialCoPilotSettings?.PatientHistorySummary?.Enabled == true
          );
        })
      );
  }

  getPatientClinicalSummary(practiceTenantId: string, patientId: string): Observable<any> {
    return this.firestore
      .collection(
        `ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary`,
        ref =>
          ref
            .orderBy('CreatedAt', 'desc') // Apply ordering within the query function
            .limit(1) // Limit to the most recent document
      )
      .valueChanges<string>({ idField: 'id' })
      .pipe(
        /* map(querySnapshot => {
          // Extract the first document from the query snapshot
          const doc = querySnapshot.docs[0];
          const summaryDoc: any = doc.data();
          summaryDoc.id = doc.id;
          return doc ? summaryDoc : null;
        }), */
        first(),
        map(doc => doc.length > 0 && doc[0]),
        map(s => s && { ...s, CreatedAt: fromTimestamp(s.CreatedAt) })
      );
  }

  logClinicalSummaryOpenEvent(practiceTenantId: string, patientId: string, docId: string): Observable<any> {
    // Reference to the document to update
    const documentRef = this.firestore
      .collection(`ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary`)
      .doc(docId);

    // Reference to the events collection
    const eventsCollectionRef = this.firestore.collection(
      `ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary/${docId}/Events`
    );

    return from(eventsCollectionRef.add({ openTime: new Date() })).pipe(
      switchMap(docRef => from(documentRef.update({ OpenWindow: false })).pipe(map(() => ({ success: true, eventId: docRef.id })))),
      catchError(error => {
        throw error;
      })
    );
  }

  logClinicalSummaryCloseEvent(
    practiceId: string,
    patientId: string,
    feedback: string,
    thumbState: string,
    docId: string,
    hideSummaryPreference: boolean,
    eventId: string
  ): Observable<any> {
    // Reference to the document to update
    const documentRef = this.firestore
      .collection(`ClinicalPractice/${practiceId}/PatientClinicalSummary/${patientId}/CheckInSummary`)
      .doc(docId);

    // Reference to the specific event document
    const eventDocumentRef = this.firestore
      .collection(`ClinicalPractice/${practiceId}/PatientClinicalSummary/${patientId}/CheckInSummary/${docId}/Events`)
      .doc(eventId);

    return from(documentRef.update({ OpenWindow: false })).pipe(
      switchMap(() => from(eventDocumentRef.update({ feedback, thumbState, closeTime: new Date(), hideSummaryPreference }))),
      map(() => {
        return { success: true };
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  sendEmail(request: EmailRequest): Observable<NexResponse<SendEmailResponsePayload>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<SendEmailResponsePayload>>(`${this.baseUrl}/v1/communication/email/sendEmail`, request, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      )
    );
  }

  requestPatientSummary(provider: ProviderConfigurationVo, patientEvent: PatientEventVo) {
    var request = {
      BPN: provider.PracticeNumber,
      TPN: provider.TreatingPracticeNumber,
      PracticeTenantId: provider.PracticeTenantId,
      PracticeId: provider.PracticeId,
      PatientId: patientEvent.Patient.PatientXRef,
      PatientUUID: patientEvent.Patient.PatientId,
      EventId: patientEvent.PatientEventId,
      UpdatedDate: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return (
      this.firestore
        // .collection('Practice')
        // .doc(provider.PracticeNumber)
        .collection('ClinicalPractice')
        .doc(provider.PracticeTenantId)
        .collection('CalendarEventCheckin')
        .doc(patientEvent.PatientEventId)
        .set(request, { merge: true })
    );

    /* return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http // todo base url via gateway
          //.post<any>(`${this.baseUrl}/clinicialai-create-patient-timeline-summary-http`,
          .post<any>(`https://europe-west1-healthbridge-meraki-dev.cloudfunctions.net/clinicialai-create-patient-timeline-summary-http`,
            request,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Skip-Progress-Bar': 'true', // skip progress bar as call is long and we don't want to show annoying loader
              },
            })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
    ); */
  }

  loadBenefitCheckReport(bpn: string, referenceNum: string): Observable<string> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<NexResponse<any>>(`${this.baseUrl}/v1/calendar/bcpdf/${bpn}/${referenceNum}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
            map(response => response.text),
            map(response => response?.replace('data:application/pdf;base64,', '')),
            map(response => URL.createObjectURL(blobToFile(b64toBlob(response, 'application/pdf;base64'), referenceNum + '.pdf')))
          )
      )
    );
  }

  getBasicCommunicationTemplates() {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc('00000000-0000-0000-0000-000000000000')
      .collection('CommunicationTemplate')
      .valueChanges()
      .pipe(
        map(data => data.map(d => ({ ...d, LastUpdated: d.LastUpdated.toDate() } as CommunicationTemplateVo))),
        first()
      );
  }

  private communicationTemplateCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('CommunicationTemplate');
  }

  removeCommunicationTemplate(practiceTenantId: string, templateId: string) {
    // todo check if we want to permanently delete, no cqrs history to revert that
    return from(this.communicationTemplateCollectionRef(practiceTenantId).doc(templateId).delete());
  }

  updateCommunicationTemplate(template: CommunicationTemplateVo) {
    return from(
      this.communicationTemplateCollectionRef(template.PracticeId)
        .doc(template.TemplateId)
        .set({ ...template, LastUpdated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
    ).pipe(
      map(
        s => ({ Sucess: true } as RestApiResultOfGuid),
        catchError(error => {
          return of({ Sucess: false, ResponseMessage: 'Firestore write error' } as RestApiResultOfGuid);
        })
      )
    );
  }

  getCommunicationTemplates(practiceTenantId: string, templateTypes: string[]) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('CommunicationTemplate', ref => ref.where('TemplateType', 'in', templateTypes))
      .valueChanges()
      .pipe(
        map(data => data.map(d => ({ ...d, LastUpdated: d.LastUpdated?.toDate() || null } as CommunicationTemplateVo))),
        first()
      );
  }

  getAddressBookAll(practiceId: string): Observable<AddressBookEntryVo[]> {
    const ref: AngularFirestoreCollection<AddressBookEntryVo> = this.addressBookCollectionRef(practiceId);
    return ref.valueChanges().pipe(first());
  }

  getAddressBookEntryById(practiceId: string, addressBookEntryId: string): Observable<AddressBookEntryVo> {
    return this.addressBookCollectionRef(practiceId).doc(addressBookEntryId).valueChanges().pipe(first());
  }

  updateAddressBook(
    practiceId: string,
    addressBookEntryId: string,
    addressBookEntry: UpdateAddressBookEntry
  ): Observable<RestApiResultOfGuid> {
    return from(
      this.addressBookCollectionRef(practiceId)
        .doc(addressBookEntryId)
        .set({
          ...addressBookEntry.AddressBookEntry,
        })
    ).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)), // todo
      catchError(error => {
        return of({ Sucess: false, ResponseMessage: 'Firestore write error' } as RestApiResultOfGuid);
      })
    );
  }

  removeAddressBook(practiceId: string, addressBookEntryId: string): Observable<RestApiResultOfGuid> {
    return from(this.addressBookCollectionRef(practiceId).doc(addressBookEntryId).delete()).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)), // todo
      catchError(error => {
        return of({ Sucess: false, ResponseMessage: 'Firestore write error' } as RestApiResultOfGuid);
      })
    );
  }

  getReminders(providerId: string): Observable<ReminderVo[]> {
    const ref: AngularFirestoreCollection<ReminderVo> = this.taskCollectionRef(providerId);
    return ref.valueChanges().pipe(
      map(s =>
        s.map((data: any) => {
          // todo need common approach to convert/use timestamp, for now to not change existing c# model use conversion on fly
          return {
            ...data,
            DueDate: data.DueDate?.toDate(),
            UpdatedDate: data.UpdatedDate?.toDate(),
            CreatedDate: data.CreatedDate?.toDate(),
          };
        })
      ),
      distinctUntilChanged(_.isEqual) // atm after update this fires twice, might be angularfire bug
    );
  }

  updateReminder(providerId: string, reminderId: string, reminder: ReminderVo): Observable<RestApiResultOfGuid> {
    return from(
      this.taskCollectionRef(providerId)
        .doc(reminderId)
        .set({
          ...reminder,
          DueDate: reminder.DueDate && Timestamp.fromDate(reminder.DueDate),
          UpdatedDate: firebase.firestore.FieldValue.serverTimestamp(),
          CreatedDate: reminder.CreatedDate && Timestamp.fromDate(reminder.CreatedDate),
          Description: reminder.Description || '',
        })
    ).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)),
      catchError(() => of({ Sucess: false }))
    );
  }

  deleteReminder(providerId: string, reminderId: string): Observable<RestApiResultOfGuid> {
    return from(this.taskCollectionRef(providerId).doc(reminderId).delete()).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getSmartGenericMedicine(request: GenericMedicineRequest): Observable<GenericMedicine> {
    return this.authService.getFirebaseToken().pipe(
      take(1),
      switchMap(token =>
        this.http.post<GenericMedicine>(
          `${this.baseUrl}${this.configService.config.smartGenericsSettings.Url}`,
          { message: request },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      )
    );
  }

  getTopNotifications(providerId: string): Observable<NotificationStatusView[]> {
    const ref = this.notificationCollectionRef(providerId);
    return ref.valueChanges().pipe(
      map(s =>
        s.map((data: any) => {
          return {
            ...data,
            Date: data.Date?.toDate(),
          } as NotificationStatusView;
        })
      ),
      map(results => results.splice(0, 5)),
      take(1)
    );
  }

  viewNotification(providerId: string, notificationId: string): Observable<RestApiResultOfGuid> {
    const ref = this.notificationCollectionRef(providerId).doc(notificationId).update({
      Status: 'viewed',
    });

    return from(ref).pipe(
      map(() => ({ Sucess: true } as RestApiResultOfGuid)),
      catchError(error => {
        return of({ Sucess: false, ResponseMessage: 'Firestore write error' } as RestApiResultOfGuid);
      })
    );
  }

  createBulkNotification(notifications: NotificationVo[]) {
    const batch = this.firestore.firestore.batch();

    notifications.forEach(notification => {
      const ref = this.notificationCollectionRef(notification.ProviderId).doc(notification.NotificationId).ref;

      const document = {
        ...notification,
        Date: notification.Date && Timestamp.fromDate(notification.Date),
      };

      batch.set(ref, document);
    });

    return batch.commit();
  }

  getProviders(): Observable<ProviderConfigurationVo[]> {
    const ref: AngularFirestoreCollection<ProviderConfigurationVo> = this.providerCollectionRef();

    return ref.valueChanges().pipe(
      take(1),
      map(s =>
        s.map((data: any) => ({
          ...data,
          BillingEndDate: data.BillingEndDate?.toDate(),
          BillingStartDate: data.BillingStartDate?.toDate(),
          LastUpdatedDate: data.LastUpdatedDate?.toDate(),
        }))
      ),
      distinctUntilChanged(_.isEqual) // atm after update this fires twice, might be angularfire bug
    );
  }

  private providerCollectionRef() {
    return this.firestore.collection(PROVIDER_ROOT);
  }

  private notificationCollectionRef(providerId: string) {
    return this.firestore
      .collection(PROVIDER_ROOT)
      .doc(providerId)
      .collection('Notification', ref => ref.orderBy('Date', 'desc'));
  }

  private taskCollectionRef(providerId: string) {
    const reminderRef = this.firestore.collection(PROVIDER_ROOT).doc(providerId).collection('Task');
    return reminderRef;
  }

  private addressBookCollectionRef(practiceTenantId: string) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection<AddressBookEntryVo>('AddressBook', ref => ref.orderBy('Name'));
  }

  dropNullUndefined(d) {
    return Object.entries(d).reduce(
      (acc, [k, v]) =>
        v == null
          ? acc
          : {
              ...acc,
              [k]: _.isPlainObject(v) ? this.dropNullUndefined(v) : _.isArray(v) ? v.map(s => this.dropNullUndefined(s)) : v,
            },
      {}
    );
  }

  getUndeliveredPathologyReports(practiceTenantId: string, dateFrom: Date, dateTo: Date): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', q => {
        const query = q.where('DeliveryStatus', '==', 'Undelivered');

        if (dateFrom && dateTo) {
          return query
            .where('DeliveryDate', '>=', Timestamp.fromDate(dateFrom))
            .where('DeliveryDate', '<=', Timestamp.fromDate(dateTo))
            .orderBy('DeliveryDate', 'desc');
        } else if (dateFrom && !dateTo) {
          return query.where('DeliveryDate', '>=', Timestamp.fromDate(dateFrom)).orderBy('DeliveryDate', 'desc');
        } else if (!dateFrom && dateTo) {
          return query.where('DeliveryDate', '<=', Timestamp.fromDate(dateTo)).orderBy('DeliveryDate', 'desc');
        }
        return query;
      })
      .valueChanges()
      .pipe(
        map((data: any[]) =>
          data.map(item => ({
            ...this.toPathReportObject(item),
            DeliveryDate: item.DeliveryDate?.toDate() || null,
          }))
        )
        // map(data => data.filter(d => d.DeliveryStatus === 'Undelivered') || data)
      );
  }

  getHtmlBodyFromStorage(billingPracticeNo: string, path: string, communicationId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v2/files/practices/${billingPracticeNo}?file=${path}/${communicationId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      ),
      map(s => s.contents)
    );
  }

  updateHtmlToStorage(billingPracticeNo: string, path: string, id: string, content: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<{ success: boolean }>(
            `${this.baseUrl}/v1/files/practices/${billingPracticeNo}?file=${path}/${id}`,
            {
              content,
              contentType: 'text/html',
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      ),
      map(s => ({ Data: content, Sucess: true }))
    );
  }

  getPathologyReportXml(billingPracticeNo: string, pathologyReportId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v1/files/practices/${billingPracticeNo}/PathologyReports/${pathologyReportId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      ),
      map(s => s.contents)
    );
  }

  getPathologyReportPdf(billingPracticeNo: string, pathologyReportId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v2/files/practices/${billingPracticeNo}?file=PathologyReports/${pathologyReportId}.pdf`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      ),
      map(s => s.contents)
    );
  }

  getPathologyReportWithXml(practiceTenantId: string, pathologyReportId: string): Observable<PathologyReportFirestoreVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReportId)
      .valueChanges()
      .pipe(
        map(s => this.toPathReportObject(s)),
        switchMap(pathologyReport =>
          pathologyReport.Source === 'cloud'
            ? this.getPathReportStructuredXml(practiceTenantId, pathologyReport).pipe(
                map(path => ({ ...pathologyReport, PathologyReport: path }))
              )
            : of(pathologyReport)
        ),
        distinctUntilChanged(_.isEqual)
      );
  }

  getPathologyReport(practiceTenantId: string, pathologyReportId: string): Observable<PathologyReportFirestoreVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReportId)
      .valueChanges()
      .pipe(
        map(s => this.toPathReportObject(s)),
        distinctUntilChanged(_.isEqual)
      );
  }

  getPathReportStructuredXml(practiceTenantId: string, pathologyReport: PathologyReportFirestoreVo) {
    if (pathologyReport.XmlReference != null) {
      return this.firestore
        .doc(pathologyReport.XmlReference)
        .collection('xml')
        .doc(pathologyReport.PathologyReportId)
        .valueChanges(first());
    }
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReport.PathologyReportId)
      .collection('xml')
      .doc(pathologyReport.PathologyReportId)
      .valueChanges(first());
  }

  getPathologyReportsByPatientIds(practiceTenantId: string, patientIds: string[]): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', r =>
        r.where('DeliveryStatus', '==', 'Delivered').where('PatientIds', 'array-contains-any', patientIds)
      )
      .valueChanges()
      .pipe(
        // first(),
        map((data: any[]) =>
          data.map(item => ({
            ...this.toPathReportObject(item),
            DeliveryDate: item.DeliveryDate?.toDate() || null,
          }))
        )
      );
  }

  getPathologyReports(
    practiceTenantId: string,
    primaryProviderId: string,
    dateFrom: Date,
    dateTo: Date,
    reportStates: string[],
    followupActions: string[]
  ): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', q => {
        const query = q.where('DeliveryStatus', '==', 'Delivered');
        if (dateFrom && dateTo) {
          return query
            .where('ReceivedDate', '>=', Timestamp.fromDate(dateFrom))
            .where('ReceivedDate', '<=', Timestamp.fromDate(dateTo))
            .orderBy('ReceivedDate', 'desc');
        } else if (dateFrom && !dateTo) {
          return query.where('ReceivedDate', '>=', Timestamp.fromDate(dateFrom)).orderBy('ReceivedDate', 'desc');
        } else if (!dateFrom && dateTo) {
          return query.where('ReceivedDate', '<=', Timestamp.fromDate(dateTo)).orderBy('ReceivedDate', 'desc');
        }
        /*
        // todo need complex index to make it work, for now filter in memory
        if (!!primaryProviderId) {
          return query != null
            ? query.where('PrimaryProviderId', '==', primaryProviderId)
            : q.where('PrimaryProviderId', '==', primaryProviderId);
        } */
        return query;
      })
      .valueChanges()
      .pipe(
        map((data: any[]) => data.map(item => this.toPathReportObject(item))),
        map(data => (reportStates.length > 0 && data.filter(d => reportStates.includes(d.StatusType))) || data),
        map(
          data =>
            (followupActions.length > 0 && data.filter(d => d.FollowupActions?.every(s => !followupActions?.includes(s.Type)) || false)) ||
            data
        )
      );
  }

  private toPathReportObject(item: any): PathologyReportFirestoreVo {
    return {
      ...item,
      ReceivedDate: item.ReceivedDate?.toDate() || null,
      DeliveryDate: item.DeliveryDate?.toDate() || null,
      // handle use case when during merging we didn't find workflow for path report and status is not set
      StatusType: item.StatusType && item.StatusType !== 'Urgent' ? item.StatusType : 'Read',
      FollowupActions: item.FollowupActions?.map(s => ({ ...s, Date: s.Date && s.Date.toDate() })) || [],
      PatientInfoFromReport: item?.PatientInfoFromReport && convertPatientToFirestore(item?.PatientInfoFromReport),
      PatientContactNo: item?.PatientInfoFromReport?.PatientAccountDetails.MedicalAidMainMemberDetails.ContactNo || null,
      PathologyReport: null, // ignore xml info to lightweight object, ideally ignore even before reading from firestore
      ContentTypes: item?.ContentTypes || [],
    };
  }

  linkPathReportToPatient(practiceTenantId: string, primaryProviderId: string, pathologyReportId: string, patientIds: string[]) {
    return from(
      this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PathologyReport').doc(pathologyReportId).update({
        PatientIds: patientIds,
        StatusType: 'Unread',
        DeliveryStatus: 'Delivered',
        DeliveryDate: Timestamp.now(),
        PrimaryProviderId: primaryProviderId,
      })
    ).pipe(map(s => patientIds));
  }

  addFollowuActionForPathReport(practiceTenantId: any, pathologyReport: any, action: { Type: string; Date: Date }) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReport.PathologyReportId)
        .update({ FollowupActions: [...(pathologyReport.FollowupActions || []), action] })
    );
  }

  updatePathologyReportStatus(practiceTenantId: string, pathologyReportId: string, status: string) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReportId)
        .update({ StatusType: status })
    );
  }

  updatePathologyReportStatuses(group: PathologyReportFirestoreVo[], status: string) {
    const batch = this.firestore.firestore.batch();
    group.forEach(s => {
      batch.update(
        this.firestore.firestore.collection(PRACTICE_ROOT).doc(s.PracticeId).collection('PathologyReport').doc(s.PathologyReportId),
        { StatusType: status }
      );
    });
    return from(batch.commit());
  }

  updatePathologyDeliveryStatus(practiceTenantId: string, pathologyReportId: string, status: string) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReportId)
        .update({ DeliveryStatus: status })
    );
  }

  getKahunPatientSummary(practice: string, appointmentXRef: string): Observable<KahunAppointment> {
    if (appointmentXRef) {
      return this.firestore
        .collection(KAHUN_PRACTICE)
        .doc(practice)
        .collection('Appointment')
        .doc(appointmentXRef)
        .valueChanges()
        .pipe(
          map(data => data as KahunAppointment),
          distinctUntilChanged(_.isEqual)
        );
    }
    return of(null);
  }

  isKahunPractice(provider: any) {
    return this.firestore
      .collection('Configuration')
      .doc('KahunPractice')
      .valueChanges()
      .pipe(
        first(),
        map((data: any) => {
          const BPN = provider.PracticeNumber;
          const TPN = provider.TreatingPracticeNumber;
          let isKahunPractice = false;
          if (data?.billingNumbers.hasOwnProperty(BPN)) {
            isKahunPractice = data?.billingNumbers[BPN].length ? data?.billingNumbers[BPN].includes(TPN) : true;
          }
          return isKahunPractice;
        }),
        catchError(e => of(false))
      );
  }

  public saveAdImpressions(impressions: AdImpression[]) {
    try {
      const fsImpressions = impressions.filter(i => !!i.EncounterID);

      const batch = this.firestore.firestore.batch();

      fsImpressions.forEach(impression => {
        batch.set(this.firestore.firestore.collection(`${SMARTMEDS_AD_IMPRESSIONS_ROOT}`).doc(), impression);
      });

      batch.commit();
    } catch (err) {
      console.error(`An error occurred while saving ad impressions. ${err}`);
    }
  }

  public async loadAd(sponsorInfo: any) {
    try {
      const baseUrl = environment.smartGenericsSettings.AdsDisplayUrl;
      const result = await this.http
        .post(
          baseUrl,
          {
            message: {
              SponsorInfo: sponsorInfo,
            },
          },
          {
            withCredentials: false,
            observe: 'body',
          }
        )
        .toPromise();
      return result;
    } catch (err) {
      console.error(err);
    }
    return null;
  }
}
