import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-confirmation-dialog',
  templateUrl: './logout-confirmation-dialog.component.html',
  styleUrls: ['./logout-confirmation-dialog.component.scss'],
})
export class LogoutConfirmationDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
