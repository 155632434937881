import { Component, OnInit, Input, ViewChild, forwardRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CropperComponent } from 'angular-cropperjs';
import { NgxPicaService } from '@digitalascetic/ngx-pica';
import { switchMap, take, tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'shared-image-upload-crop-resize',
  templateUrl: './image-upload-crop-resize.component.html',
  styleUrls: ['./image-upload-crop-resize.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadCropResizeComponent),
      multi: true,
    },
  ],
})
export class ImageUploadCropResizeComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() public imgCropToHeight = 200;
  @Input() public imgCropToWidth = 400;

  croppedUrl = null;

  @Input() public config = {
    // aspectRatio : 16 / 9,
    dragMode: 'move',
    // background : true,
    movable: true,
    // rotatable : true,
    scalable: true,
    zoomable: true,
    viewMode: 0,
    checkImageOrigin: true,
    cropmove: this.cropMoved.bind(this),
    checkCrossOrigin: true,
  };

  @ViewChild('angularCropper') angularCropper: CropperComponent;

  // Our cropped image and the value of our image controller
  public imageUrl: string;

  constructor(private picaService: NgxPicaService) {}

  ngOnInit() {}

  cropMoved() {
    this.croppedUrl = this.angularCropper.cropper
      .getCroppedCanvas({
        fillColor: '#fff',
      })
      .toDataURL('image/jpeg');
  }

  /**
   * Event to be activated when you select an image
   */
  imageUploadEvent(evt: any) {
    if (!evt.target || !evt.target.files || evt.target.files.length !== 1) {
      return;
    }

    const file = evt.target.files[0];
    if (!['image/jpeg', 'image/png', 'image/gif', 'image/jpg'].includes(file.type)) {
      return;
    }
    const fr = new FileReader();
    const img = new Image();

    fr.onload = () => {
      this.imageUrl = fr.result as string;
      // set the cropper size according to the loaded image sizing
      img.onload = () => {
        setTimeout(() => {
          let width = img.width;
          let height = img.height;

          const needsResizing = this.imgCropToHeight < height || this.imgCropToWidth < width;

          if (needsResizing) {
            // try resize the width first
            if (this.imgCropToWidth < width) {
              const ratio = this.imgCropToWidth / width; // get ratio for scaling image
              width = this.imgCropToWidth; // set the new width to the max
              height = Math.floor(height * ratio); // new height
            }

            // then try resize the height (if still needed)
            if (this.imgCropToHeight < height) {
              const ratio = this.imgCropToHeight / height; // get ratio for scaling image
              height = this.imgCropToHeight; // set the new width to the max
              width = Math.floor(width * ratio); // new height
            }

            this.picaService
              .resizeImage(evt.target.files[0], width, height)
              .pipe(
                take(1),
                switchMap(resizedFile => this.picaService.compressImage(resizedFile, 0.06)),
                tap(resizedFile => fr.readAsDataURL(resizedFile)),
                untilDestroyed(this)
              )
              .subscribe();
          } else {
            this.angularCropper.cropper.setData({ x: 0, y: 0, width, height });
            setTimeout(() => this.cropMoved());
          }
        }, 100);
      };

      img.src = this.imageUrl;
    };

    fr.readAsDataURL(file);
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.imageUrl = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnDestroy(): void {}

  done() {
    this.propagateChange(this.croppedUrl);
    this.imageUrl = null;
  }

  cancel() {
    this.imageUrl = null;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
