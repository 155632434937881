import { HttpBackend } from '@angular/common/http';
import { HttpClientMeraki, MERAKI_API_GATEWAY, MERAKI_CACHE_LB } from '../meraki-nexus.providers';
import { MerakiAuthService } from '../meraki-auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Inject, Injectable } from '@angular/core';
import { VisitReasonQuestionViewModel, VisitReasonViewModel } from '../meraki-client.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, take, tap } from 'rxjs/operators';
import {
  BranchProviderInfoVo,
  MedicalInsurerVo,
  ProviderBranchConfigurationVo,
  SpecialityRuleVo,
  SupportingProviderVo,
} from '../api-client.service';
import { Address, Country } from '../meraki-models/general.models';
import { Insurer } from '../meraki-models/insurer.models';
import { PracticeProvider } from '../meraki-models/provider.models';
import { Branch, Practice } from '../meraki-models/practice.models';
import * as guidByString from 'uuid-by-string';
import { PpeCode } from '../meraki-models/meraki-models';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class MerakiLookupService {
  private http: HttpClientMeraki;
  private baseUrl: string;
  private loadBalancerUrl: string;

  constructor(
    private httpHandler: HttpBackend, // Bypass Auth interceptor since it overrides with florence auth token.
    @Inject(HttpClientMeraki) http: HttpClientMeraki,
    @Inject(MERAKI_API_GATEWAY) baseUrl: string,
    @Inject(MERAKI_CACHE_LB) cacheLbUrl: string,
    private authService: MerakiAuthService,
    private firestore: AngularFirestore
  ) {
    this.http = http;
    //this.http = new HttpClient(httpHandler);

    this.baseUrl = baseUrl ? baseUrl : '';
    this.loadBalancerUrl = cacheLbUrl;
  }

  getDefaultConfigurationByType(): Observable<any> {
    return this.firestore
      .collection('Configuration')
      .doc('AppointmentType')
      .get()
      .pipe(
        map(doc => doc.data()),
        first()
      );
  }

  getVisitReasons(): Observable<VisitReasonViewModel[]> {
    return this.firestore
      .collection('ClinicalVisitReason')
      .doc('VisitReasons')
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object.values(data))
      );
  }

  getVisitReasonsByPractice(bpn: string): Observable<VisitReasonViewModel[]> {
    return this.firestore
      .collection(`Practice/${bpn}/VisitReason`)
      .doc('VisitReasons')
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object.values(data || []))
      );
  }

  getVisitReasonQuestions(id: string): Observable<VisitReasonQuestionViewModel[]> {
    return this.firestore
      .collection('ClinicalVisitReason')
      .doc(id)
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object?.values(data || []))
      );
  }

  getCountries(): Observable<Country[]> {
    return this.firestore
      .collection('Countries')
      .valueChanges<string>({ idField: 'Alpha2' })
      .pipe(
        take(1),
        catchError(err => {
          console.error('error', err);
          return of([]);
        })
      );
  }

  getVisitReasonQuestionsByPractice(bpn: string, id: string): Observable<VisitReasonQuestionViewModel[]> {
    return this.firestore
      .collection(`Practice/${bpn}/VisitReason`)
      .doc(id)
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object?.values(data || [])),
        tap(questions => console.log({ questions }))
      );
  }
  getAllMedicalInsurers() {
    return this.firestore
      .collection('Configuration')
      .doc('SchemeLookup')
      .valueChanges()
      .pipe(
        first(),
        map((item: any) => item.MedicalInsurers),
        map((insurers: { [key: string]: any }) =>
          Object.keys(insurers)
            .reduce((result, insurerCode) => {
              result.push({ ...insurers[insurerCode], Code: insurerCode, Id: insurerCode } as Insurer);
              return result;
            }, [])
            .sort((i1, i2) => i1?.Name?.localeCompare(i2.Name))
        ),
        map((items: Insurer[]) =>
          items.map(
            item =>
              ({
                Name: item.Name,
                Email: item.Email,
                AccountNo: item.Code,
                Id: item.Code,
                InsurerXRef: item.Id,
                Phone1: item.Phone,
              } as MedicalInsurerVo)
          )
        )
      );
  }

  getAllMedicalAids() {
    return (
      this.firestore
        .collection('SchemePlanOption')
        //    .valueChanges<string>({ idField: 'Id' })
        .valueChanges()
        .pipe(take(1))
    );
  }

  getSupportingProviders(bpn: string): Observable<SupportingProviderVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Provider')
      .valueChanges()
      .pipe(
        first(),
        map((providers: PracticeProvider[]) =>
          providers
            // todo check how to get assisting/referring provider
            // .filter(p => p.Type == ProviderType.EXTERNAL)
            .map(
              p =>
                ({
                  Name: p.Name,
                  FriendlyName: `${p.Title ?? ''} ${p.Name} ${p.Surname}`.trim(),
                  HpcsaNumber: p.HPCSANumber,
                  Surname: p.Surname,
                  Title: p.Title,
                  TreatingPracticeNumber: p.TreatingPracticeNumber,
                  Type: p.Type, // todo check what types exist
                  SupportingProviderXRef: p.HPCSANumber,
                } as SupportingProviderVo)
            )
        )
      );
  }

  addressToString(address: Address): string {
    return `${address.Line1}\n${address.Line2}\n${address.Line3}\n${address.Code}`;
  }

  getPracticeBranches(bpn: string): Observable<ProviderBranchConfigurationVo[]> {
    return forkJoin([
      this.firestore.collection('Practice').doc(bpn).valueChanges<string>({ idField: 'Id' }).pipe(first()),
      this.firestore.collection('Practice').doc(bpn).collection('Provider').valueChanges<string>({ idField: 'Id' }).pipe(first()),
    ]).pipe(
      map(([practice, providers]: [Practice, PracticeProvider[]]) =>
        // TODO: depend on what data forge gonna include in provider object we might need to use branch info (addresses mainly)
        !practice.IsMultiBranch
          ? []
          : practice.Branches.map(
              (b: Branch) =>
                ({
                  BranchName: b.Name,
                  BranchId: guidByString(b.Name, 5),
                  BranchXRef: b.Name,
                  IsMainBranch: b.IsMainBranch,
                  Enabled: b.Active,
                  PhoneNumber: b.ContactDetails.OfficeNo,
                  PhysicalAddress: this.addressToString(b.PhysicalAddress),
                  PostalAddress: this.addressToString(b.PostalAddress),
                  ProviderInfos: providers
                    ?.flatMap(d =>
                      d.Common?.AssignedBranches.filter(provider => provider.BranchName == b.Name).map(
                        s =>
                          ({
                            IsDefaultBranch: s.IsDefault,
                            DispensingLicenseNumber: s.DispensingLicenceNo,
                            IsDispensing: s.IsDispensing,
                            ProviderXRef: d.Id,
                          } as BranchProviderInfoVo)
                      )
                    )
                    .filter(s => !!s),
                } as ProviderBranchConfigurationVo)
            )
      )
    );
  }

  getSpecialityRule(practiceId: string, code: string): Observable<SpecialityRuleVo> {
    return this.firestore.collection('SpecialityRule').doc(code).valueChanges().pipe(first());
  }

  getPpeCodes(optionCode: string, specialityCode: string): Observable<PpeCode> {
    return this.firestore
      .collection('PPECode')
      .doc(`${optionCode}_${specialityCode.replace(/^0+/, '')}`)
      .valueChanges()
      .pipe(first());
  }

  getFeatureAddOnConfig(id: string): Observable<any> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<any>(`${this.baseUrl}/v1/clinical/config/getFeatureAddOnConfiguration?id=${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            map(data => ({
              ...data.config,
              DateNow: new Date(data.config.DateNow),
              BeginDate: new Timestamp(data.config.BeginDate._seconds, data.config.BeginDate._nanoseconds).toDate(),
              EndDate: new Timestamp(data.config.EndDate._seconds, data.config.EndDate._nanoseconds).toDate(),
            }))
          )
      )
    );
  }
}
