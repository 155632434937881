import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true,
    },
  ],
})
export class CheckboxListComponent implements ControlValueAccessor {
  @Input() values: string[] = [];
  @Input() label: string = null;

  onChange: any = () => {};
  onTouch: any = () => {};

  _model: string[] = [];

  get model() {
    return this._model;
  }

  get modelValue() {
    return this._model.join(', ');
  }

  writeValue(value: string): void {
    if (!!value) {
      this._model = value
        .replace(/, /g, ',')
        .split(',')
        .filter(f => !!f);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  set(value: []) {
    this._model = value;
    this.onChange(this.modelValue);
  }

  private addOrRemove(value: string) {
    if (this.contains(value)) {
      this.remove(value);
    } else {
      this.add(value);
    }
  }

  private contains(value: string): boolean {
    if (this._model instanceof Array) {
      return this._model.includes(value);
    } else if (!!this._model) {
      return this._model === value;
    }
    return false;
  }

  private add(value: string) {
    if (!this.contains(value)) {
      if (this._model instanceof Array) {
        this._model = [...this._model, value];
      } else {
        this._model = [value];
      }
      this.onChange(this.modelValue);
    }
  }

  private remove(value: string) {
    this._model = this._model.filter(s => s !== value);
    this.onChange(this.modelValue);
  }

  toggleCheck(value: string) {
    this.addOrRemove(value);
  }
  isChecked(value: string) {
    return this.contains(value);
  }
}
