import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

/*
usage:

<button sharedThrottleClick (throttleClick)="doSomething()">Do Something</button>
<button sharedThrottleClick (throttleClick)="doSomething()" [throttleDelay]="250">Do Something</button>

Conditionally disable the throttle (not to be confused with button disabling)
<button [sharedThrottleClick]="enabledThrottleClick$ | async" (throttleClick)="doSomething()">Do Something</button>

*/
@Directive({
  selector: '[sharedThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() sharedThrottleClick = true;
  @Input() throttleDelay = 500;
  // @Input() disableButton = false; // todo provide capability to disable the button through [disabled] attribute
  @Output() throttleClick = new EventEmitter();
  // @HostBinding('attr.disabled') disabled: boolean; // todo provide capability to disable the button through [disabled] attribute

  private clicks = new Subject();

  constructor() {}

  ngOnInit() {
    this.clicks
      .pipe(
        // todo provide capability to disable the button through [disabled] attribute
        throttleTime(this.sharedThrottleClick ? this.throttleDelay : 0),
        // todo provide capability to disable the button through [disabled] attribute
        untilDestroyed(this)
      )
      .subscribe(e => this.throttleClick.emit(e));
  }

  ngOnDestroy() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
