import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export enum Role {
  Doctor = 'doctor',
  FrontOfficeUser = 'front-office-user',
  Receptionist = 'receptionist',
  PracticeAdministrator = 'practice-admin',
  Nurse = 'nurse',
  SystemAdmin = 'system-admin',
}

export interface UserRole {
  Role: Role;
  Scope: string;
}

export interface AuthState {
  authenticated: boolean;
  userId?: string;
  superAdmin?: boolean;
  token?: string;
  username?: string;
  fullName?: string;
  roles?: Array<UserRole>;
  currentTenantId?: string;
  fsRefreshToken?: string;
}

export function createInitialState(): AuthState {
  return {
    authenticated: false,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
