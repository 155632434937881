import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, NavigationService } from '@app/core/services';
import { take, tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  template: '',
})
export class ResolveHomeComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService, private navService: NavigationService) {
    if (!this.authService.authenticated) {
      this.navService.navigateByUrl('/auth/login');
    } else {
      this.authService.currentTenantId$.pipe(take(1), untilDestroyed(this)).subscribe({
        next: tenantId => {
          if (tenantId) {
            this.navService.navigate([tenantId, 'home']);
          } else {
            console.error('Could not determine route');
            this.navService.navigate(['error']);
          }
        },
      });
    }
  }

  ngOnInit() {}
  ngOnDestroy() {}
}
