import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService, NavigationService } from '../services';
import { RoleManagerService } from '../services/role-manager.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private nav: NavigationService,
    private authService: AuthService,
    private roleManagerService: RoleManagerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.authenticatedLoaded$.pipe(
      take(1),
      map(auth => auth || this.router.createUrlTree(['/auth/login'], { queryParams: { returnUrl: state.url } }))
    );
  }
}
