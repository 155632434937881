import { trigger, style, transition, animate, query, stagger } from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(
      ':leave',
      [
        animate('0.5s', style({ opacity: 0 })),
        // stagger(50, [
        // ])
      ],
      { optional: true }
    ),
    query(':enter', [style({ opacity: 0 }), stagger(50, [animate('0.5s', style({ opacity: 1 }))])], { optional: true }),
  ]),
]);
