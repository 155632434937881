import { Injectable, Inject, Optional } from '@angular/core';
import { TenantsStore } from './state/admin/tenants.store';
import { TenantsQuery } from './state/admin/tenants.query';
import {
  TenantClient,
  TenantVo,
  BillingClient,
  ProviderConfigurationVo,
  AccountClient,
  UserVo,
  ChangeProviderConfigurationOption,
  API_BASE_URL,
} from './api-client.service';
import { timeout, catchError, tap, take, switchMap, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProvidersStore } from './state/admin/providers.store';
import { ProvidersQuery } from './state/admin/providers.query';
import { UsersStore } from './state/admin/users.store';
import { UsersQuery } from './state/admin/users.query';
import { ProviderOptionEnabledForHBClinical, ProviderOptionEnabledForHBClinicalLite } from './reference-data.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { MerakiClientService } from './meraki-client.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public providers$ = this.providersQuery.selectAll();
  public tenants$ = this.tenantsQuery.selectAll();
  public users$ = this.usersQuery.selectAll();

  public specialities$ = new BehaviorSubject<any[]>([]);

  constructor(
    private tenantClient: TenantClient,
    private billingClient: BillingClient,
    private accountClient: AccountClient,

    private tenantsStore: TenantsStore,
    private tenantsQuery: TenantsQuery,

    private providersStore: ProvidersStore,
    private providersQuery: ProvidersQuery,

    private usersStore: UsersStore,
    private usersQuery: UsersQuery,

    private authService: AuthService,
    private merakiService: MerakiClientService,
    @Inject(HttpClient) private http: HttpClient,
    @Optional() @Inject(API_BASE_URL) private baseUrl?: string
  ) {}

  getUsers() {
    const request = this.accountClient.getListOfAllUsers();
    return request.pipe(
      tap(() => this.usersStore.setLoading()),
      catchError(() => {
        return of(null);
      }),
      tap((users: UserVo[]) => {
        if (users) {
          this.usersStore.set(users);
        }
      })
    );
  }

  getTenants() {
    const request = this.tenantClient.getTenantList();
    return request.pipe(
      tap(() => this.tenantsStore.setLoading()),
      catchError(() => {
        return of(null);
      }),
      tap((tenants: TenantVo[]) => {
        if (tenants) {
          this.tenantsStore.set(tenants);
        }
      })
    );
  }

  getProviders() {
    const request = this.merakiService.getProviders();

    return request.pipe(
      tap(() => this.providersStore.setLoading()),
      catchError(() => {
        return of(null);
      }),
      tap((providers: ProviderConfigurationVo[]) => {
        if (providers) {
          this.providersStore.set(providers);
        }
      })
    );
  }

  getSpecialities() {
    return this.billingClient.getSpecialityRules().pipe(tap(specialities => this.specialities$.next(specialities)));
  }

  searchProviders(lookup: string) {
    return this.http.get<ProviderConfigurationVo[]>(`${this.baseUrl}/api/v1/billing/providers/${lookup}?$top=100&$skip=0`);
  }

  enableProviderForHBClinical(providerId) {
    return this.authService.auth$.pipe(
      take(1),
      switchMap(auth => {
        const configurationOption: ChangeProviderConfigurationOption = {
          ProviderId: providerId,
          OptionName: ProviderOptionEnabledForHBClinical,
          OptionValue: 'true',
          CorrelationIds: null,
          OriginatedSystem: `Healthbridge Clinical ${auth.username} (${auth.userId})`,
        };

        return this.billingClient.setProviderConfigurationOption(providerId, configurationOption);
      })
    );
  }

  updateHBClinicalContract(providerId, optionName, enabled: string) {
    return this.authService.auth$.pipe(
      take(1),
      switchMap(auth => {
        const configurationOption: ChangeProviderConfigurationOption = {
          ProviderId: providerId,
          OptionName: optionName,
          OptionValue: enabled,
          CorrelationIds: null,
          OriginatedSystem: `Healthbridge Clinical ${auth.username} (${auth.userId})`,
        };

        return this.billingClient.setProviderConfigurationOption(providerId, configurationOption);
      })
    );
  }

  resetStore() {
    this.providersStore.reset();
    this.tenantsStore.reset();
    this.usersStore.reset();
  }
}
