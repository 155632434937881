import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService, ProviderService } from '@app/core/services';
import { AuthService } from '@app/core/services/auth.service';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { PatientsService } from '@app/core/services/patients.service';
import { ActivePatient } from '@app/core/services/state/ui-state/ui-state.store';
import { UiStateService } from '@app/core/services/ui-state.service';
import { PatientVo } from 'api-clinician-app';
import * as _ from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mapTo, startWith, switchMap, take, tap } from 'rxjs/operators';
import { LogoutConfirmationDialogComponent } from '../logout-confirmation-dialog/logout-confirmation-dialog.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DialogService } from '@app/shared/components/nora-component/nora-services/nora-dialog.service';
import {
  ConfirmDialogAction,
  ConfirmDialogComponent,
  ConfirmDialogModel,
  DialogResponse,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { AudioRecorderService } from '@app/modules/sidebar/speech-to-notes/services/audio-recorder.service';
import { GenerateFunctionsService } from '@app/modules/sidebar/sandbox/services/generate-functions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // animations: [slideInOutAnimation]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  searchControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredPatients$: Observable<PatientVo[]>;

  params$ = this.route.paramMap.pipe(
    map(m => ({ tenantId: m.get('tenantId'), patientId: m.get('patientId') })),
    distinctUntilChanged()
  );

  authenticated$ = this.authService.authenticated$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  superAdmin$ = this.authService.superAdmin$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  currentTenantId$ = this.authService.currentTenantId$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  displayProgressBar$ = this.uiStateService.displayProgressBar$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  activePatients$: Observable<ActivePatient[]> = this.uiStateService.activePatients$.pipe(
    distinctUntilChanged(_.isEqual),
    untilDestroyed(this)
  );
  showSearch = false;

  patients$ = this.patientsService.patients$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  currentProvider$ = this.providerService.provider$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));

  constructor(
    private fb: FormBuilder,
    private navService: NavigationService,
    private authService: AuthService,
    private patientsService: PatientsService,
    private providerService: ProviderService,
    private uiStateService: UiStateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private clinicalEncounterService: ClinicalEncounterService,
    private audioRecorderService: AudioRecorderService
  ) {}

  ngOnInit() {
    this.filteredPatients$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => value.toLowerCase()),
      distinctUntilChanged(),
      debounceTime(250),
      switchMap(value => this.searchPatients(value)),
      untilDestroyed(this)
    );
  }

  inProgressEncounter(tenantId, patientId) {
    return this.clinicalEncounterService.getPatientInProgressEncounterId(patientId) != null;
  }

  searchPatients(searchStr: string) {
    if (searchStr.length >= 3) {
      return this.currentProvider$.pipe(
        take(1),
        switchMap(provider => this.patientsService.searchPatients(provider.PracticeId, searchStr))
      );
    } else {
      return of([] as PatientVo[]);
    }
  }

  ngOnDestroy(): void {}

  clearSearch() {
    this.searchControl.setValue('');
    this.searchInput.nativeElement.blur();
  }

  focusSearchInput() {
    this.searchInput.nativeElement.focus();
  }

  selectPatient(patientId: string) {
    this.currentTenantId$
      .pipe(
        take(1),
        tap(tenantId => {
          if (patientId) {
            this.navService.navigate([tenantId, 'patient', patientId]);
          } else {
            this.navService.navigate([tenantId, 'home']);
          }
          this.clearSearch();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  autocompleteDisplay(patient: PatientVo) {
    if (patient && typeof patient === 'object') {
      return `${patient.PatientDetails.FirstName} ${patient.PatientDetails.Surname}`;
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.logout();
        this.navService.navigate(['/auth/login']);
      }
    });
  }

  removeActivePatient(tenantId: string, patientId: string) {
    this.navService
      .navigate([tenantId, 'home'])
      .then(() => this.router.url.includes('home') && this.uiStateService.removeActivePatient(patientId));
  }

  addToWaitingRoom(patientId: string, tenantId: string) {
    this.providerService.waitingRoom$
      .pipe(
        take(1),
        map(waitingRoomPatients =>
          waitingRoomPatients.find(
            waitingRoomPatient => waitingRoomPatient.PatientId === patientId && waitingRoomPatient.PatientEvent.PracticeId === tenantId
          )
        ),
        switchMap(waitingRoomPatient =>
          !!waitingRoomPatient
            ? of(waitingRoomPatient).pipe(mapTo(false))
            : this.patientsService.loadPatientInfo(tenantId, patientId).pipe(
                take(1),
                switchMap(patient => this.providerService.addPatientToWaitingRoom(tenantId, patient).pipe(debounceTime(1000), mapTo(true)))
              )
        ),
        tap(added =>
          added
            ? this.snackbar.open('Patient added to waiting  room', 'OK', { duration: 5000 })
            : this.snackbar.open('Patient already in the waiting room', 'OK', { duration: 5000 })
        ),
        tap(() => this.autocomplete.closePanel()),
        untilDestroyed(this)
      )
      .subscribe();
  }
}

function notNullAndLowercaseIncludes(searchStr: string, pField: string) {
  return searchStr && pField && pField.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase());
}
