import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ReferenceDataState, ReferenceDataStore } from './reference-data.store';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataQuery extends Query<ReferenceDataState> {
  medicalAids$ = this.select(store => store.medicalAids);
  // healthIDSSupportedSchemes$ = this.select(store => store.healthIDSSupportedSchemes);
  medicalAids = this.getValue().medicalAids;
  // healthIDSSupportedSchemes = this.getValue().healthIDSSupportedSchemes;

  constructor(protected store: ReferenceDataStore) {
    super(store);
  }
}
