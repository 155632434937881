import {
  Component,
  Inject,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap, map, filter } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { environment } from '@env/environment';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'shared-algolia-search-box',
  templateUrl: './algolia-search-box.component.html',
  styleUrls: ['./algolia-search-box.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlgoliaSearchBoxComponent extends BaseWidget implements OnInit, OnDestroy {
  @Input() minCharsLength = 2;
  @Input() placeholder: string = null;
  @Input() showGroupedToggle = false;
  @Input() autocomplete: any;

  _groupedResults: boolean; // tslint:disable-line: variable-name
  @Input()
  set groupedResults(val: boolean) {
    this.groupedResultsChange.emit(val);
    this._groupedResults = val;
  }
  get groupedResults() {
    return this._groupedResults;
  }
  @Output() groupedResultsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showGroups: EventEmitter<boolean> = new EventEmitter();
  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild('searchString') searchString: ElementRef<HTMLInputElement>;
  @ViewChild('searchString') searchString2: MatInput;

  form = this.fb.group({
    searchString: null,
    groupedResults: true,
  });

  private timerId = null;
  state: {
    query: string;
    refine: (str: any) => any;
  };

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent,
    private fb: FormBuilder
  ) {
    super('AlgoliaSearchBoxComponent');
  }

  public ngOnInit() {
    this.createWidget(connectSearchBox, {});
    super.ngOnInit();

    if (!!this.searchString) {
      this.searchString.nativeElement.focus();
    }

    if (!!this.searchString2) {
      this.searchString2.focus();
    }

    this.form
      .get('searchString')
      .valueChanges.pipe(
        debounceTime(environment.algoliaSettings.defaultDebounce),
        filter(searchStr => typeof searchStr === 'string'),
        map(searchStr => searchStr as string),
        map(searchStr => searchStr.trim()),
        distinctUntilChanged(),
        filter(s => s.length >= this.minCharsLength || s.length === 0),
        untilDestroyed(this),
        tap(searchstr => this.searchTextChanged.emit(searchstr)) // notify parent
      )
      .subscribe({
        next: searchStr => {
          this.state.refine(searchStr);
        },
      });

    this.form
      .get('groupedResults')
      .valueChanges.pipe(
        debounceTime(environment.algoliaSettings.defaultDebounce),
        map(groupedResults => groupedResults === true),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe({
        next: groupedResults => (this.groupedResults = groupedResults), // this.showGroups.emit(groupedResults)
      });
  }

  toggleSpecificity() {}

  clearQuery() {
    this.form.patchValue({ searchString: '' });
  }

  focus() {
    this.searchString.nativeElement.focus();
  }

  ngOnDestroy() {}
}
