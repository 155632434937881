import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent implements OnInit {
  pageOffset = 0;

  constructor(private scroll: ViewportScroller) {}

  ngOnInit(): void {}

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.pageOffset = window.pageYOffset;
  }
}
