import { EntityState, StoreConfig, EntityStore, MultiActiveState, ID, EntityUIStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import {
  PatientVo,
  PatientDashboardVo,
  PatientAllergiesVo,
  PatientDocumentVo,
  PatientLifestyleVo,
  PatientChronicConditionsVo,
  PatientClinicalNotesVo,
  PatientSurgicalHistoryVo,
} from 'api-clinician-app';
import {
  ClinicalMetricVo,
  MedicationPrescriptionLine,
  MedicalCertificate,
  PatientFileGenericInformationVo,
  PatientConfigurationVo,
  MedicineInteractionVo,
} from '../../api-client.service';
import { PatientFinancialsViewModel } from '../../view-models/patient-financials-view-model';
import * as moment from 'moment';
import { VaccinatedStatusVo } from '../../meraki-client.service';

export interface PatientStateModel {
  PatientId: ID;
  PatientXRef: string;
  //AccountXRefs: string[];
  details: PatientVo;
  dashboard: PatientDashboardVo;
  members: PatientVo[];
  avatar: PatientDocumentVo;
  historicalMetrics: ClinicalMetricVo[];
  activeMetrics: ClinicalMetricVo[];
  patientGenericInformation: PatientFileGenericInformationVo[];
  financials: PatientFinancialsViewModel;
  conditions: PatientChronicConditionsVo;
  prescriptionLines: MedicationPrescriptionLine[];
  allergies: PatientAllergiesVo;
  documents: PatientDocumentVo[];
  lifestyle: PatientLifestyleVo;
  surgicalHistory: PatientSurgicalHistoryVo;
  patientClinicalNotes: PatientClinicalNotesVo;
  patientConfiguration: PatientConfigurationVo;
  medicineInteractions: MedicineInteractionVo[];
  lastUpdated: Date;
  lastChecked: Date;
  isDirty: false;
  isIncomplete: boolean;
}

export interface PatientStateUIModel {
  medications: { id: string; medication: MedicationPrescriptionLine }[];
  prevSickNote: { medicalCertificate: MedicalCertificate; htmlBody: string };
  healthIdVisited: moment.Moment;
  vaccinatedStatusSnapshot: VaccinatedStatusVo;
}

export interface PatientsState extends EntityState<PatientStateModel>, MultiActiveState {}
export interface PatientsUIState extends EntityState<PatientStateUIModel> {}

const initialState = {
  active: [],
};

export enum ConfigurationKey {
  PatientScreenings = 'PatientScreenings',
  CustomAlerts = 'CustomAlerts',
  DeletedEncounterEntries = 'DeletedEncounterEntries',
  VaccinatedStatus = 'VaccinatedStatus',
  Comorbidities = 'Comorbidities',
}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'patients', idKey: 'PatientId', resettable: true })
export class PatientsStore extends EntityStore<PatientsState, PatientStateModel> {
  ui: EntityUIStore<PatientsUIState>;

  constructor() {
    super(initialState);

    this.createUIStore().setInitialEntityState({
      healthIdVisited: null,
      medications: [],
      prevSickNote: null,
    });
  }
}
