import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
  ClinicalClient,
  Status2,
  API_BASE_URL,
  PathologyReportWorkflowVo,
  ProviderCommunicationEmailVo,
  AttachmentVo,
  ClinicalPatientClient,
  MarkPathologyReportAsRead,
  PatientVo,
  Status3,
  RestApiResultOfBoolean,
  ClinicalNewClient,
  PatientDashboardHistoryEventDto,
} from './api-client.service';
import { MerakiClientService, PathReportFollowuActions } from './meraki-client.service';
import * as _ from 'lodash';
import { PathologyReportFirestoreVo } from './meraki-models/meraki-models';
import { PathCentreFilter } from './state/provider/provider.store';

@Injectable({
  providedIn: 'root',
})
export class PathologyService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private clinicalClient: ClinicalClient,
    private clinicalNewClient: ClinicalNewClient,
    private clinicalPatientClient: ClinicalPatientClient,
    private merakiClientService: MerakiClientService,
    @Inject(API_BASE_URL) baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getReportId(practiceId: string, workflowId: string) {
    return this.clinicalClient.getPathologyReportWorkflow(practiceId, workflowId);
  }

  getPathReport(practiceTenantId: string, pathReportId: string) {
    return this.merakiClientService.getPathologyReport(practiceTenantId, pathReportId);
  }

  getPathReportWithHtml(practiceId: string, practiceTenantId: string, billingNo: string, pathReportId: string) {
    return this.merakiClientService.getPathologyReport(practiceTenantId, pathReportId).pipe(
      switchMap(report => this.getPathReportXml(billingNo, pathReportId).pipe(map(xml => ({ report, xml })))),
      switchMap(({ report, xml }) => this.xmlToHtml(practiceId, xml).pipe(map(html => ({ ...report, OriginalMessage: html }))))
    );
  }

  getPathReportXml(billingNo: string, reportId: string) {
    return this.merakiClientService.getPathologyReportXml(billingNo, reportId);
  }

  getPathReportPdf(billingNo: string, reportId: string) {
    return this.merakiClientService.getPathologyReportPdf(billingNo, reportId);
  }

  xmlToHtml(practiceId: string, xml: string) {
    return this.clinicalNewClient.pathXmlToHtml(practiceId, xml).pipe(map(html => html.Data));
  }

  getPathReportHtml(practiceId: string, reportId: string): Observable<string> {
    return this.http.get(`${this.baseUrl}/api/v1/${practiceId}/clinical/pathologyreports/${reportId}/html`, { responseType: 'text' });
  }

  getPathReportPatient(doctorId: string, reportId: string): Observable<PatientVo> {
    return this.clinicalClient.getPathologyReportPatient(doctorId, reportId);
  }

  getReportsByStatus(practiceId: string, status: Status2) {
    return this.clinicalClient.getPathologyReportWorkflowsAll(practiceId, status);
  }

  getUndeliveredReports(practiceId: string) {
    return this.clinicalClient.getUndeliveredPathologyReports(practiceId);
  }

  getUndeliveredReportsByDateRange(practiceId: string, pageNumber: number = 1, pageSize: number = 10) {
    const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const toDate = moment().add(1, 'd').format('YYYY-MM-DD');

    return this.clinicalClient.getUndeliveredPathologyReportsByDateRange(practiceId, fromDate, toDate, pageSize, pageNumber);
  }

  redeliveryReport(tenantId: string, pathologyReportId: string, patientIds: string[] = []) {
    return this.clinicalClient.redeliverPathResult(tenantId, pathologyReportId, { MatchedPatientIds: patientIds });
  }

  ignoreReport(tenantId: string, pathologyReportId: string) {
    return this.clinicalClient.ignorePathResult(tenantId, pathologyReportId);
  }

  getReportCount(practiceId: string, status: Status3) {
    return this.clinicalClient.getPathologyReportWorkflowsCount(practiceId, status);
  }

  getReportsUnactionedForToday(practiceId: string) {
    const fromDate = moment(new Date()).startOf('day');
    const toDate = moment(new Date()).endOf('day');
    // todo can be improved by displaying only unactioned reports for doctor ('my patients')
    return this.filterPathologyResults(practiceId, null, { FromDate: fromDate, ToDate: toDate, UnreadState: true } as PathCentreFilter);
  }

  getReportsForCurrentWeekByStatus(tenantId: string, status: string) {
    const fromDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
    const toDate = moment().add(1, 'd').format('YYYY-MM-DD');

    return this.getPathologyReportWorkflowsBasedOnDatesAndStatus(tenantId, status, fromDate, toDate);
  }

  filterUndeliveredPathologyResults(practiceTenantId: string, filter: any): Observable<PathologyReportFirestoreVo[]> {
    let dateFrom = null;
    let dateTo = null;
    if (filter.DateRange === 'LastWeek') {
      dateFrom = moment().subtract(7, 'd').toDate();
      dateTo = moment().add(1, 'd').toDate();
    } else if (filter.DateRange === 'LastMonth') {
      dateFrom = moment().subtract(1, 'months').toDate();
      dateTo = moment().add(1, 'd').toDate();
    } else if (filter.DateRange === 'Custom') {
      dateFrom = filter.FromDate?.startOf('day').toDate() || null;
      dateTo = filter.ToDate?.endOf('day').toDate() || null;
    }
    if (!dateFrom && !dateTo) {
      return of();
    }
    return this.merakiClientService.getUndeliveredPathologyReports(practiceTenantId, dateFrom, dateTo);
  }

  searchPathologyResults(practiceTenantId: string, patientIds: string[]): Observable<PathologyReportFirestoreVo[]> {
    return this.merakiClientService.getPathologyReportsByPatientIds(practiceTenantId, patientIds);
  }

  filterPathologyResults(
    practiceTenantId: string,
    primaryProviderId: string,
    filter: PathCentreFilter
  ): Observable<PathologyReportFirestoreVo[]> {
    let dateFrom = filter.FromDate?.startOf('day').toDate() || null;
    let dateTo = filter.ToDate?.endOf('day').toDate() || null;
    if (filter.DateRange === 'LastWeek') {
      dateFrom = moment().subtract(7, 'd').toDate();
      dateTo = moment().add(1, 'd').toDate();
    } else if (filter.DateRange === 'LastMonth') {
      dateFrom = moment().subtract(1, 'months').toDate();
      dateTo = moment().add(1, 'd').toDate();
    }

    if (!dateFrom && !dateTo) {
      return of();
    }

    const reportStates = [filter.ReadState && 'Read', filter.UnreadState && 'Unread'].filter(s => s);
    const followupActions = [
      filter.InTaskListState && PathReportFollowuActions.InTaskList,
      filter.SmsSentState && PathReportFollowuActions.SmsSent,
      filter.EmailSentState && PathReportFollowuActions.EmailSent,
    ].filter(s => s);

    // atm local search implemented based on assumption that we still need to use date range from the tab,
    // so as result it become possible to load all path reports for that date range and just do filtering in memory

    return this.merakiClientService
      .getPathologyReports(practiceTenantId, primaryProviderId, dateFrom, dateTo, reportStates, followupActions)
      .pipe(map(result => result.filter(s => (!!primaryProviderId ? s.PrimaryProviderId === primaryProviderId : true))));
  }

  linkPathReportToPatient(practiceTenantId, primaryProviderId, pathologyReportId, patientIds: string[]) {
    return this.merakiClientService.linkPathReportToPatient(practiceTenantId, primaryProviderId, pathologyReportId, patientIds).pipe(
      // add path item on timeline(s) as we linked report
      first(),
      switchMap(() => this.merakiClientService.getPathologyReportWithXml(practiceTenantId, pathologyReportId).pipe(first())),
      map(report => [
        ...patientIds.map(p => ({
          patientId: p,
          entries: this.buildTimelineEntriesForPath(report, pathologyReportId, p),
        })),
      ]),
      catchError(err => {
        console.error('error on insert', err);
        return of(null);
      }),
      switchMap(items =>
        forkJoin([...items.map(item => this.clinicalNewClient.addPatientTimelineEntries(primaryProviderId, item.patientId, item.entries))])
      )
    );
  }

  buildTimelineEntriesForPath(
    report: PathologyReportFirestoreVo,
    pathologyReportId: string,
    patientId: string
  ): PatientDashboardHistoryEventDto[] {
    return [
      ...report.PathologyReport.Patient.Requisition?.map(requisition => ({
        PathologyReportId: pathologyReportId,
        PatientId: patientId,
        ParentReferenceId: pathologyReportId,
        Type: 'PathologyTest',
        Code: requisition.orderNo,
        ParentDescription: report.PathologyReport.Sender.name,
        Description: requisition.Orders?.map(i => i.code).join(',') || '',
        Narrative: 'Ordered: ' + requisition.Orders?.map(i => i.abbreviatedName || i.name).join(',') || '',
        DateTime: report.ReceivedDate,
      })),
    ];
  }

  getReportsForCurrentMonthByStatus(tenantId: string, status: string, pageNumber: number = 1, pageSize: number = 10) {
    const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const toDate = moment().add(1, 'd').format('YYYY-MM-DD');

    const statusDic: { [key: string]: any } = {
      Open: 0,
      Read: 1,
      Urgent: 2,
      Hidden: 3,
    };

    return this.clinicalClient.getPathologyReportWorkflowsBasedOnDatesAndStatusPaged(
      tenantId,
      fromDate,
      toDate,
      statusDic[status],
      pageSize,
      pageNumber
    );
  }

  getReportsLinkedToTenant(practiceId: string) {
    return this.clinicalClient.getPathologyReportWorkflowsForProvider(practiceId);
  }

  getReportsLinkedToTenantByDateRange(practiceId: string, pageNumber: number = 1, pageSize: number = 10) {
    const fromDate = moment().subtract(1, 'months').toDate();
    const toDate = moment().add(1, 'd').toDate();

    return this.clinicalClient.getPathologyReportWorkflowsForProvider(practiceId).pipe(
      map(data => data.filter(f => new Date(f.ReceivedDate) >= fromDate && new Date(f.ReceivedDate) <= toDate)),
      map(data => data.slice(0, pageSize))
    );
  }

  getReportsBySearch(practiceId: string, query: string) {
    return this.clinicalClient.getPathologyReport(practiceId, query);
  }

  download(html: string) {
    return this.clinicalClient.saveHtmlToPdf(html);
  }

  downloadReport(practiceId: string, accessToken: string, html: string) {
    return this.clinicalClient.saveHtmlToPdf(html).pipe(
      map(data => {
        const url = `${this.baseUrl}/api/v1/document?url=${data.Data}&access_token=${accessToken}&practiceId=${practiceId}`;
        return { fullUrl: url, documentUrl: data.Data };
      })
    );
  }

  getLinkedPatients(practiceId: string, reportId: string) {
    return this.clinicalClient.getLinkedPatientDashboards(practiceId, reportId);
  }

  markPathologyReportAsRead(comand: MarkPathologyReportAsRead) {
    return this.clinicalClient.markPathologyReportAsRead(comand.PracticeId, comand.PathologyReportWorkflowId, comand);
  }

  updatePathologyStatus(practiceTenantId, pathologyReportId, status: 'Read' | 'Unread') {
    return this.merakiClientService.updatePathologyReportStatus(practiceTenantId, pathologyReportId, status);
  }

  updatePathReportStatuses(group: PathologyReportFirestoreVo[], status: 'Read' | 'Unread') {
    return this.merakiClientService.updatePathologyReportStatuses(group, status);
  }

  updatePathologyDeliveryStatus(practiceTenantId, pathologyReportId, status: 'Undelivered' | 'Delivered' | 'Ignored') {
    return this.merakiClientService.updatePathologyDeliveryStatus(practiceTenantId, pathologyReportId, status);
  }

  addFollowuActionForPathReport(practiceTenantId, pathologyReport, action: { Type: string; Date: Date }) {
    return this.merakiClientService.addFollowuActionForPathReport(practiceTenantId, pathologyReport, action);
  }

  markPathologyReportsAsRead(practiceid: string, workflowIds: string[]): Observable<RestApiResultOfBoolean> {
    return this.clinicalClient.markPathologyReportsAsRead(practiceid, workflowIds);
  }

  emailReport(report: PathologyReportWorkflowVo, email: ProviderCommunicationEmailVo) {
    const newAttachment: AttachmentVo = {
      Title: 'Pathology results',
      Type: 'Pathology results',
      HtmlBody: report.OriginalMessage,
    };

    email.Attachments = [newAttachment];

    return this.clinicalPatientClient.sendCommunication(report.PracticeId, report.PatientIds[0], email);
  }

  private getStatus(status: string): Status2 {
    return status === 'Open'
      ? Status2._0 // UNREAD
      : status === 'Undelivered'
      ? Status2._1 // undelivered
      : status === 'Urgent'
      ? Status2._2 // FOLLOW-UP
      : Status2._0;
  }

  private getPathologyReportWorkflowsBasedOnDatesAndStatus(
    practiceId: string,
    status: string,
    fromDate: string,
    toDate: string
  ): Observable<PathologyReportWorkflowVo[]> {
    const url =
      this.baseUrl + `/api/v1/${practiceId}/clinical/pathologyreportworkflows/fromdate/${fromDate}/todate/${toDate}/status/${status}`;

    return this.http.get(url).pipe(map(response => response as PathologyReportWorkflowVo[]));
  }
}
