import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as uuid from 'uuid';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { ProviderService, UserFeedbackVo } from '@app/core/services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface UserFeedbackUploadFile {
  id: string;
  base64Data: string;
  mimeType: string;
  fileName: string;
  fileSize: string;
}

@Component({
  selector: 'app-user-feedback-dialog',
  templateUrl: './user-feedback-dialog.component.html',
  styleUrls: ['./user-feedback-dialog.component.scss'],
})
export class UserFeedbackDialogComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  readonly feedbackTypes: string[] = ['Idea or suggestion', 'Comment', 'Bug', 'Other'];
  readonly titleSubmit: string = 'Do you have an idea or suggestion? Have you found a bug? Send us your feedback:';
  readonly titleDone: string = 'Your input is valuable in the development of this product';

  uploadFiles$ = new BehaviorSubject<UserFeedbackUploadFile[]>(null);
  userFeedbackSent$ = new BehaviorSubject<boolean>(false);
  tenantId$ = new BehaviorSubject<string>('');

  form: FormGroup = this.fb.group({
    feedbackType: ['', Validators.required],
    feedbackBody: ['', Validators.required],
    attachments: '',
  });

  editorConfig: any = {
    base_url: '/tinymce',
    suffix: '.min',
    menubar: false,
    toolbar: false,
    browser_spellcheck: true,
    contextmenu: false,
    statusbar: false,
    visual: false,
    height: 240,
    valid_children: '+body[style],+body,+head',
    extended_valid_elements: 'body,head',
    elementpath: false,
    branding: false,
    content_css_cors: true,
    content_style: 'body { font-family:Century Gothic,Helvetica,Arial,sans-serif; font-size:12pt; }',
  };

  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UserFeedbackDialogComponent>,
    private fb: FormBuilder,
    private appInsightsService: ApplicationInsightsService,
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: UserFeedbackDialogModel
  ) {
    this.tenantId$.next(data.tenantId);
  }

  ngOnInit(): void {
    this.form.patchValue({
      feedbackType: this.feedbackTypes[0],
    });
  }

  ngOnDestroy(): void {}

  addAttachmentClick(): void {
    this.fileUpload.nativeElement.click();
  }

  fileUploadEvent($event): void {
    if (!$event.target || !$event.target.files || $event.target.files.length !== 1) {
      return;
    }
    const file = $event.target.files[0];
    const fr = new FileReader();
    fr.onload = () => {
      const uploadData = fr.result as string;

      this.tenantId$
        .pipe(
          withLatestFrom(this.uploadFiles$),
          tap(([, uploadFiles]) => {
            const newUploadFiles = uploadFiles || [];
            newUploadFiles.push({
              id: uuid(),
              base64Data: uploadData,
              fileName: file.name,
              fileSize: Math.round(file.size / 1024) + 'KB',
              mimeType: file.type,
            });
            this.uploadFiles$.next(newUploadFiles);
          })
        )
        .subscribe();
    };
    fr.readAsDataURL(file);
  }

  removeFile(file: UserFeedbackUploadFile): void {
    this.tenantId$
      .pipe(
        withLatestFrom(this.uploadFiles$),
        tap(([, uploadFiles]) => {
          const fileIndex = uploadFiles.findIndex(f => f.id === file.id);
          if (fileIndex >= 0) {
            uploadFiles.splice(fileIndex);
          }
          this.uploadFiles$.next(uploadFiles);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  sendUserFeedback(form, sendButton) {
    sendButton.disabled = true;
    this.tenantId$
      .pipe(
        withLatestFrom(this.uploadFiles$),
        switchMap(([tenantId, uploadFiles]) => {
          const userFeedback: UserFeedbackVo = {
            FeedbackType: this.form.controls.feedbackType.value,
            Body: this.form.controls.feedbackBody.value,
            Attachments: (uploadFiles || []).map(uFile => {
              return { DocumentDataBase64: uFile.base64Data, MimeType: uFile.mimeType, FileName: uFile.fileName };
            }),
          };
          return this.providerService.sendUserFeedback(tenantId, userFeedback);
        }),
        withLatestFrom(this.tenantId$),
        catchError(() => of(null)),
        tap(([result, tenantId]) => {
          if (!result || !result.Sucess) {
            this.snackBar.open('Failed to send your feedback.', 'OK');
          } else {
            this.userFeedbackSent$.next(result?.Sucess);
          }

          this.appInsightsService.trackEvent(AppInsightTags.USER_FEEDBACK, { tenantId });
        })
      )
      .subscribe();
  }
}

export interface UserFeedbackDialogModel {
  tenantId: string;
}
