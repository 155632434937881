import { DrugItem, MedicationPrescriptionLine, Duration, MedicineInfo, EncounterLineItemVo } from 'api-clinician-app';
import { MedicineViewModel, MedicineFamilyViewModel, DosageViewModel } from './medicine-view-model.model';
import * as _ from 'lodash';
import * as numberWords from 'number-words';
import { trace } from 'console';

export function getMedicineFamilyViewModelsFromSmartTemplates(drugItems: DrugItem[]): MedicineFamilyViewModel[] {
  const items = getMedicineFamilyViewModelsFromMedicineViewModels(
    _.chain(drugItems.flatMap(drugItem => getMedicineViewModelFromSmartTemplates(drugItem))).value()
  );

  return items;
}

function getMedicineFamily(medicineInfo: MedicineInfo) {
  if (medicineInfo.hbDrugFamilyGroup && medicineInfo.hbDrugFamilyGroup.length > 0) {
    return medicineInfo.hbDrugFamilyGroup;
  }

  return medicineInfo.NAPPICode;
}

function getPackInfo(consumableItem: MedicineInfo, nappiCode: string = null) {
  if (!consumableItem) {
    return null;
  } else if (!consumableItem.PackInfo) {
    return null;
  } else {
    const pack = consumableItem.PackInfo.find(p => !!nappiCode && p.NAPPIPack === nappiCode.slice(-3)) || consumableItem.PackInfo[0];
    return {
      PackSize: pack.PackSize,
      NAPPICode10: `${+pack.NAPPICode10}`,
      NAPPIPack: pack.NAPPIPack,
    };
  }
}

export function medicineLineItemFromAlgoliaResult({
  algoliaMedicine: algoliaMedicine,
  NAPPIPack,
  packSize,
}: {
  algoliaMedicine: MedicineInfo;
  NAPPIPack: string;
  packSize: number;
}): EncounterLineItemVo {
  return {
    LineType: 'Medicine',
    Amount: 0,
    ChargeCode: '0197',
    PriceOverride: false,
    DosageType: algoliaMedicine?.hbDrugApplicationForm,
    NAPPICode: algoliaMedicine?.NAPPICode + NAPPIPack,
    ChargeQuan: packSize || 1,
    ChargeDesc: getMedicineDescriptionFromMedicineInformation(algoliaMedicine),
    DosageUnit: 0,
    PeriodType: '',
    PeriodUnit: 0,
    DurationType: '',
    DurationUnit: null,
    ChronicIndicator: false,
    FrequencyUnit: null,
    Parameters: {
      AdditionalInstructionSelections: '',
      DosageDescription: '',
      InformationText: '',
      RouteOfAdministration: null,
    },
  } as EncounterLineItemVo;
}

export function getMedicineFamilyViewModelsFromMedicineViewModels(medicineViewModels: MedicineViewModel[]) {
  return _.chain(medicineViewModels)
    .groupBy(item => [
      item.ProductUsageCategory || '',
      getMedicineFamily(item.MedicineInfo) || '',
      item.MedicineInfo?.hbDrugFamilyName || '',
    ])
    .map(
      (value, key) =>
        ({
          // important keys to lookup info
          CategoryCode: value[0].CategoryCode,
          SubCategoryCode: value[0].SubCategoryCode,
          Token: value[0].Token,

          // Family information
          hbDrugFamilyGroup: getMedicineFamily(value[0].MedicineInfo),
          hbDrugFamilyName: key,

          // other information for displaying/filtering...
          Flags: getAllUniqueFlags(value), // value[0].MedicineInfo.Flags || '',
          ProductUsageCategory: value[0].ProductUsageCategory,

          Frequency: _.maxBy(value, val => val.Frequency).Frequency,
          Duration: value[0].Duration,
          Medicines: _.sortBy(value, i => i.MedicineInfo.ProductStrength),
        } as MedicineFamilyViewModel)
    )
    .orderBy(family => Math.abs(1 - family.Frequency))
    .value();
}

export function getAllUniqueFlags(medicineViewModels: MedicineViewModel[]) {
  const flags = medicineViewModels.flatMap(vm => vm.MedicineInfo.Flags.split(','));
  return _.uniq(flags).join(',');
}

export function getDosageViewModelsFromDrugItem(drugItem: DrugItem) {
  return drugItem.Dosage.map(
    dosage =>
      ({
        ...dosage,
        RouteOfAdministration: drugItem.RouteOfAdministration,
        AdditionalInstructionSelections: [],
      } as DosageViewModel)
  );
}

export function getMedicineViewModelsFromSmartTemplates(drugItems: DrugItem[]): MedicineViewModel[] {
  return drugItems.map(drugItem => getMedicineViewModelFromSmartTemplates(drugItem));
}

export function getMedicineViewModelFromSmartTemplates(drugItem: DrugItem): MedicineViewModel {
  // const flags = drugItem.MedicineInfo.Flags.toLowerCase().split(',');

  // if (patientChronicRegisteredNappis && patientChronicRegisteredNappis.some(nappi => drugItem.NAPPICode.startsWith(nappi))) {
  //   flags.push('registered');
  // }

  const vm: MedicineViewModel = {
    // important keys to lookup info
    CategoryCode: drugItem.MedicineInfo.ATC2Code,
    SubCategoryCode: drugItem.MedicineInfo.ATC3Code,
    Token: drugItem.Token,
    NAPPICode: drugItem.MedicineInfo.NAPPICode,

    // information the doctor can change himself

    SelectedDosage: null,
    SelectedDuration: null,

    // machine learning information

    Dosage: getDosageViewModelsFromDrugItem(drugItem),
    Duration: drugItem.Duration,
    Frequency: drugItem.Rank, // for ranking
    ProductUsageCategory: drugItem.ProductUsageCategory,

    // contained in MedicineInfo

    MedicineInfo: drugItem.MedicineInfo,
  };

  return vm;
}

export function getMedicineViewModelFromPrescriptionLine(
  prescriptionLine: MedicationPrescriptionLine,
  medicineInfo: MedicineInfo
): MedicineViewModel {
  const line: MedicineViewModel = {
    // important keys to lookup info
    CategoryCode: (prescriptionLine.Parameters || {}).CategoryCode,
    SubCategoryCode: (prescriptionLine.Parameters || {}).SubCategoryCode,
    Token: (prescriptionLine.Parameters || {}).Token,
    NAPPICode: prescriptionLine.NappiCode,

    // information the doctor can change himself
    SelectedDosage: !!prescriptionLine.DosageUnits
      ? null
      : {
          DosageUnits: prescriptionLine.DosageUnits,
          DosageType: prescriptionLine.DosageType,

          FrequencyUnits: prescriptionLine.FrequencyUnits,
          PeriodUnits: prescriptionLine.PeriodUnit,
          PeriodType: prescriptionLine.PeriodType,

          AdditionalInstructionSelections: (
            (prescriptionLine.Parameters && prescriptionLine.Parameters.AdditionalInstructionSelections) ||
            ''
          ).split(','),
          InformationText: (prescriptionLine.Parameters && prescriptionLine.Parameters.InformationText) || '',
          AdditionalInstructionFull: prescriptionLine.DispensingInstructions || '',

          RouteOfAdministration:
            (prescriptionLine && prescriptionLine.Parameters && prescriptionLine.Parameters.RouteOfAdministration) || '',
          Repeat: prescriptionLine.Repeat || 0,
          DosageText: null,
        },
    SelectedDuration:
      (!!prescriptionLine.DurationUnit && {
        DurationUnits: prescriptionLine.DurationUnit,
        DurationType: prescriptionLine.DurationType,
      }) ||
      null,

    // machine learning information

    Dosage: [],
    Duration: [],
    Frequency: 0,
    ProductUsageCategory: '',

    // contained in MedicineInfo

    MedicineInfo: medicineInfo,
  };
  return line;
}

export function getDurationFromPrescriptionLine(prescriptionLine: MedicationPrescriptionLine): Duration {
  if (!prescriptionLine) {
    return null;
  }

  return {
    DurationUnits: prescriptionLine.DurationUnit,
    DurationType: prescriptionLine.DurationType,
    Frequency: 99,
  };
}

export function getDosageViewModelFromPrescriptionLine(prescriptionLine: MedicationPrescriptionLine): DosageViewModel {
  if (!prescriptionLine) {
    return null;
  }

  return {
    DosageUnits: prescriptionLine.DosageUnits,
    DosageType: prescriptionLine.DosageType,

    FrequencyUnits: prescriptionLine.FrequencyUnits,
    PeriodType: prescriptionLine.PeriodType,
    PeriodUnits: prescriptionLine.PeriodUnit,

    RouteOfAdministration: (prescriptionLine.Parameters && prescriptionLine.Parameters.RouteOfAdministration) || '',
    Repeat: prescriptionLine.Repeat,
    AdditionalInstructionSelections: (
      (prescriptionLine.Parameters && prescriptionLine.Parameters.AdditionalInstructionSelections) ||
      ''
    ).split(','),
    AdditionalInstructionFull: (prescriptionLine.Parameters && prescriptionLine.Parameters.AdditionalInstructionText) || '',
    InformationText: (prescriptionLine.Parameters && prescriptionLine.Parameters.InformationText) || '',

    DosageText: '',
    Rank: 99,
  };
}

export function getPrescriptionLineFromDosageViewModel(
  dosageViewModel: DosageViewModel,
  duration: Duration
): Partial<MedicationPrescriptionLine> {
  if (!dosageViewModel) {
    return null;
  }
  const additionalInstructionSelection = getAdditionalInstructionAsString(dosageViewModel?.AdditionalInstructionSelections);
  return {
    DosageUnits: dosageViewModel.DosageUnits,
    DosageType: dosageViewModel.DosageType,

    FrequencyUnits: dosageViewModel.FrequencyUnits,
    PeriodType: dosageViewModel.PeriodType,
    PeriodUnit: dosageViewModel.PeriodUnits,

    DurationUnit: (duration && duration.DurationUnits) || null,
    DurationType: (duration && duration.DurationType) || null,

    Repeat: dosageViewModel.Repeat,
    AdministrationInstructions: null,
    DispensingInstructions: `${additionalInstructionSelection} ${dosageViewModel.InformationText}`.trimStart(),

    Parameters: {
      AdditionalInstructionSelections: additionalInstructionSelection,
      InformationText: dosageViewModel.InformationText || '',
      RouteOfAdministration: dosageViewModel.RouteOfAdministration || null,
    },
  };
}

export function getPrescriptionLineFromMedicineViewModel(medicineViewModel: MedicineViewModel): Partial<MedicationPrescriptionLine> {
  const prescriptionLine: MedicationPrescriptionLine = {
    Quantity: 1,
    MedicationDescription: getMedicineDescriptionFromMedicineInformation(medicineViewModel.MedicineInfo),
    Description: getMedicineDescriptionFromMedicineInformation(medicineViewModel.MedicineInfo),
    NappiCode: medicineViewModel.NAPPICode, // can change!
    Parameters: {
      CategoryCode: medicineViewModel.CategoryCode,
      SubCategoryCode: medicineViewModel.SubCategoryCode,
      Token: medicineViewModel.Token || `D-0197-${medicineViewModel.SubCategoryCode}`,
    },
  } as MedicationPrescriptionLine;

  return prescriptionLine;
}

export function mergePrescriptionLineData(...prescriptionLines: Partial<MedicationPrescriptionLine>[]) {
  return prescriptionLines.reduce(_.merge);
}

export function getMedicineDescriptionFromMedicineInformation(medicineInfo: MedicineInfo) {
  let description = medicineInfo.ProductName;
  if (
    medicineInfo.ProductStrength !== null &&
    medicineInfo.ProductStrength !== undefined &&
    medicineInfo.ProductStrength !== '0' &&
    medicineInfo.ProductStrength.length > 0
  ) {
    description = `${description} (${medicineInfo.ProductStrength}${medicineInfo.Units})`;
  }
  if (medicineInfo.hbProductUsageCategory) {
    description = `${description} - ${medicineInfo.hbProductUsageCategory}`;
  }
  return description;
}

export function getAdditionalInstructionAsArray(value: string): string[] {
  return value?.split(', ') || [];
}

export function getAdditionalInstructionAsString(list: string[]): string {
  return list?.filter(s => !!s).join(', ') || '';
}

export const getFriendlyDosageDescription = (
  dosageUnit: number,
  dosageType: string,
  frequencyUnits: number,
  periodUnit: number,
  periodType: string,
  durationUnit: number,
  durationType: string,
  route: string
) => {
  const isDecimal = dosageUnit % 1 !== 0;
  const dosageUnitText = formatDosageUnit(dosageUnit, dosageType, !isDecimal);

  const period = formatPeriod(periodUnit, periodType);

  const frequency = formatFrequency(frequencyUnits);

  const duration = formatDuration(durationUnit, durationType);

  const dosageResult = [dosageUnitText, route, `${frequency} ${period}`, duration]
    .filter(s => !!s)
    .map(s => s.trim())
    .filter(s => s !== '')
    .join(', ');

  return dosageResult;
};

export const getDosageDescription = (
  dosageUnit: number,
  dosageType: string,
  frequencyUnits: number,
  periodUnit: number,
  periodType: string,
  durationUnit: number,
  durationType: string
) => {
  const dosageUnitText = formatDosageUnit(dosageUnit, dosageType, false);

  const period = formatPeriod(periodUnit, periodType, false);

  const frequency = formatFrequency(frequencyUnits, false);

  const duration = formatDuration(durationUnit, durationType, false);

  const dosageResult = [dosageUnitText, `${frequency} ${period}`, duration]
    .map(s => s.trimEnd())
    .filter(s => s !== '')
    .join(', ');

  return dosageResult;
};

export const getFriendlyMedicationDescription = (MedicationDescription: string) => {
  const unitMapping: { [key: string]: string } = {
    mg: 'milligrams',
    g: 'grams',
    ml: 'milliliters',
  };

  return MedicationDescription.replace(/\((\d+)(mg|g|ml)\)/gi, (match, number, unit) => {
    const numberInWords = numberWords.convert(parseInt(number));
    const unitInWords = unitMapping[unit.toLowerCase()] || unit;
    return `${numberInWords} ${unitInWords} (${number}${unit})`;
  });
};

function formatDosageUnit(dosageUnit: number, dosageType: string, numbersAsWords: boolean = true) {
  if (!dosageUnit) {
    return '';
  }

  const decimalDosageArr = dosageUnit.toString().split('.')[1];
  const decimalDosage = decimalDosageArr ? `.${numberWords.convert(decimalDosageArr)}` : '';
  let friendlyDosage = numbersAsWords ? `${numberWords.convert(dosageUnit)}${decimalDosage}(${dosageUnit})` : dosageUnit;

  if (dosageUnit === 0.25) {
    friendlyDosage = 'quarter (1/4)';
  } else if (dosageUnit === 0.5) {
    friendlyDosage = 'half (1/2)';
  }

  return `${friendlyDosage} ${dosageType}`;
}

function formatPeriod(periodUnit: number, periodType: string, numbersAsWords: boolean = true) {
  if (!periodUnit) {
    return '';
  }
  if (periodUnit === 1 && periodType === 'day') {
    return 'daily';
  }
  if (periodUnit === 1 && periodType === 'week') {
    return 'weekly';
  }
  if (periodUnit === 1 && periodType === 'month') {
    return 'monthly';
  }
  const friendlyPeriodUnit = numbersAsWords ? `${numberWords.convert(periodUnit)} (${periodUnit})` : periodUnit;
  return `every ${friendlyPeriodUnit} ${periodType}`;
}

function formatFrequency(frequencyUnits: number, numbersAsWords: boolean = true) {
  if (!frequencyUnits) {
    return '';
  }
  const friendlyFrequency = numbersAsWords ? `${numberWords.convert(frequencyUnits)} (${frequencyUnits})` : frequencyUnits;
  return frequencyUnits > 1 ? `${friendlyFrequency} times` : 'once';
}

function formatDuration(durationUnit: number, durationType: string, numbersAsWords: boolean = true) {
  if (!durationUnit) {
    return '';
  }
  const friendlyDurationUnit = numbersAsWords ? `${numberWords.convert(durationUnit)} (${durationUnit})` : durationUnit;
  return `for ${friendlyDurationUnit} ${durationType || ''}`;
}

export function enrichMedicineInfoFlags(medicineInfo: MedicineInfo, spoCodes: string[], registeredNappis: string[]) {
  const flags = ((medicineInfo || {}).Flags || '').split(',');

  const hasFormulary =
    medicineInfo &&
    medicineInfo.FormularyChronic &&
    medicineInfo.FormularyChronic.SPO_Code &&
    medicineInfo.FormularyChronic.SPO_Code.some(spo => spoCodes && spoCodes.includes(spo.Code));
  if (hasFormulary) {
    flags.push('Formulary');
  }
  if (medicineInfo && registeredNappis.includes(medicineInfo.NAPPICode)) {
    flags.push('Registered');
  }

  return {
    ...medicineInfo,
    FormularyChronic: null, // suppress the actual codes going through
    Flags: flags.filter(flag => !!flag).join(','),
  };
}

export function enrichMedicineViewModelInfoFlags(medicineViewModel: MedicineViewModel, spoCodes: string[], registeredNappis: string[]) {
  const vm = _.cloneDeep(medicineViewModel);
  return {
    ...vm,
    MedicineInfo: enrichMedicineInfoFlags(vm.MedicineInfo, spoCodes, registeredNappis),
  } as MedicineViewModel;
}

export function enrichMedicineViewModelsInfoFlags(medicineViewModels: MedicineViewModel[], spoCodes: string[], registeredNappis: string[]) {
  return medicineViewModels.map(medicineViewModel => enrichMedicineViewModelInfoFlags(medicineViewModel, spoCodes, registeredNappis));
}

export function convertMedicineToPrescriptionLine(lineItem: EncounterLineItemVo) {
  const prescription = {
    NappiCode: lineItem.NappiCode,
    MedicationDescription: lineItem.ChargeDesc,
    ChronicIndicator: lineItem.ChronicIndicator,
    Quantity: lineItem.ChargeQuan,

    DosageType: lineItem.DosageType,
    DosageUnits: lineItem.DosageUnit,

    DurationType: lineItem.DurationType,
    DurationUnit: lineItem.DurationUnit,

    PeriodType: lineItem.PeriodType,
    PeriodUnit: lineItem.PeriodUnit,
    FrequencyUnits: lineItem.FrequencyUnit,
    Repeat: lineItem.Repeat,
    Diagnosis: lineItem.Diagnosis,

    Parameters: lineItem.Parameters,
  } as MedicationPrescriptionLine;
  return prescription;
}

export const dosageStaticForms = ['inhaler', 'dose', 'accuhaler', 'turbuhaler'];

export const dosageQuantityForms = ['tablet', 'capsule', 'capsules', 'tablets', 'dose', 'sachet', 'unit', 'units', 'dissolved tablet'];

export function getDosageQuantity(dosage: DosageViewModel, durationType: string, durationUnits: number, ProductUsageCategory?: string) {
  if (!dosageQuantityForms.includes(dosage.DosageType) || !durationType || !durationUnits) {
    return null;
  }

  // Make all inhalers, result in quantity of 1
  if (dosageStaticForms.includes(ProductUsageCategory?.toLowerCase())) {
    return 1;
  }

  // adjust all numbers to day measurement to do easier calculations
  let period = dosage.PeriodUnits;
  switch (dosage.PeriodType) {
    case 'hour':
      period = dosage.PeriodUnits / 24;
      break;
    case 'week':
      period = dosage.PeriodUnits * 7;
      break;
    case 'month':
      period = dosage.PeriodUnits * 30;
      break;
  }

  let duration = durationUnits;
  switch (durationType) {
    case 'hours':
      duration = durationUnits / 24;
    case 'weeks':
      duration = durationUnits * 7;
      break;
    case 'months':
      duration = durationUnits * 30;
      break;
  }

  return Math.ceil(((dosage.DosageUnits || 1) * (dosage.FrequencyUnits || 1) * (duration || 1)) / (period || 1));
}
