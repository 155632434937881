import { Injectable, Inject, InjectionToken } from '@angular/core';
import { UiStateStore } from './state/ui-state/ui-state.store';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { environment } from '@env/environment';
// import * as localForage from 'localforage';

// export interface IConfig {
//   apiUrl: string;
//   applicationInsightsKey: string;
//   clientId: string;
//   googleCaptchaSiteKey: string;
//   oneSignalAppId: string;
//   hmr: boolean;
//   algoliaSettings: {
//     apiKey: string;
//     appId: string;
//     medicineIndexName: string;
//     diagnosisIndexName: string;
//   };
//   settings?: { [key: string]: boolean };
//   offlineSync: {
//     enabled: boolean;
//     delayBeforeStart: number;
//     delayBetweenPatients: number;
//     delayBetweenChecks: number;
//   };
//   localStorage: {
//     type: 'LOCALSTORAGE' | 'WEBSQL' | 'INDEXEDDB';
//     version: number;
//   };
// }

// export const ConfigToken = new InjectionToken<IConfig>('IEnvironment');

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  get config() {
    // return this.query.getValue().config;
    return environment;
  }

  constructor() // private store: UiStateStore,
  // private query: UiStateQuery,
  // @Inject(ConfigToken) config: IConfig
  {
    // this.store.update({ config });
  }
}
