import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { navigation } from '../../navigation';
import { NavigationItem, UiColor } from '../../reference-data.service';

export enum SidebarLocations {
  CALENDAR = 'calendar',
  EXECUTIVE_SUMMARY = 'executive-summary',
  ENCOUNTER_SUMMARY = 'encounter-summary',
  QUICK_NOTES = 'quick-notes',
  TASKS = 'tasks',
  WAITING_ROOM = 'waiting-room',
  PATHOLOGY = 'pathology',
  HIDDEN = 'hidden',
  NOTIFICATIONS = 'notifications',
  USER_FEEDBACK = 'user-feedback',
  NORA_CONSULT_SUMMARY = 'nora-consult-summary',
  ILAB_SANDBOX = 'ilab-sandbox',
}

export enum BrowserTypes {
  Web = 'web',
  Tablet = 'tablet',
  Mobile = 'mobile',
}

export enum BrowserSizeType {
  SMALL = 1024,
  MEDIUM = 1380,
  LARGE = 1600,
  EXTRA_LARGE = 9999,
}

export interface UiState {
  displayProgressBar: boolean;
  displayProgressSpinner: boolean;
  pageVisible: boolean;
  leftPanelOpened: boolean;
  lastPatientUpdated: Date;
  online: boolean;
  currentPatientId: string;
  currentPatientTabs: ActivePatient[];
  browserType: BrowserTypes;
  sidebarLocation: SidebarLocations;
  browserWidth: number;
  browserHeight: number;
  practiceProviders: PracticeProviderStateUI[];
  navigation: NavigationItem[];
  consultTargetTab?: number;
  guidedTour: GuidedTour;
  selectedPathologyCentreTab: number;
}

export interface ActivePatient {
  patientId: string;
  patientName: string;
}

export interface PracticeProviderStateUI {
  providerId: string;
  hpcsaNo: string;
  doctorName: string;
  color: UiColor;
  selectedInCalendar: boolean;
  selectedInWaitingRoom: boolean;
}

export interface GuidedTour {
  id: string;
  status: 'inprogress' | 'paused' | 'completed';
  activeTab?: number;
}

export function createInitialState(): UiState {
  return {
    displayProgressBar: false,
    displayProgressSpinner: false,
    pageVisible: true,
    leftPanelOpened: true,
    lastPatientUpdated: null,
    online: true,
    currentPatientId: null,
    currentPatientTabs: [],
    browserType: getBrowserType(),
    sidebarLocation: SidebarLocations.HIDDEN,
    browserWidth: window.innerWidth,
    browserHeight: window.innerHeight,
    practiceProviders: [],
    consultTargetTab: null,
    guidedTour: null,
    navigation,
    selectedPathologyCentreTab: 0,
  };
}

export function getBrowserType() {
  const currentWidth = window.innerWidth;
  const currentHeight = window.innerHeight;
  const isMobile = currentWidth < BrowserSizeType.SMALL;
  const isTablet = currentWidth < BrowserSizeType.MEDIUM;
  if (isMobile) {
    return BrowserTypes.Mobile;
  }
  if (isTablet) {
    return BrowserTypes.Tablet;
  }
  return BrowserTypes.Web;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ui-state', resettable: true })
export class UiStateStore extends Store<UiState> {
  constructor() {
    super(createInitialState());
  }
}
