import { StorageType } from '@env/IEnvironment';
import { switchMap, tap, catchError, throttleTime, map } from 'rxjs/operators';
import { from, of, asyncScheduler, Observable } from 'rxjs';
import { persistState } from '@datorama/akita';
import { environment } from '@env/environment';

// Developer: Jonathan Kretzmer
// Based almost entirely on: NPM idb-keyval
// Project: https://github.com/jakearchibald/idb-keyval
// File: https://github.com/jakearchibald/idb-keyval/blob/master/idb-keyval.ts
// Changes:
// - Made Observable friendly for easier use
// - Added database version parameter, and added onupgradeneeded method to clear database on upgrade
// - Fused methods into class for easier use
export class IdbKeyValStore {
  private readonly dbp: Promise<IDBDatabase>;

  constructor(private dbName = 'keyval-store', private readonly storeName = 'keyval', private readonly version = 1) {
    this.dbp = new Promise((resolve, reject) => {
      const openreq = indexedDB.open(this.dbName, this.version);
      openreq.onerror = () => reject(openreq.error);
      openreq.onsuccess = () => resolve(openreq.result);

      // First time setup: create an empty object store
      openreq.onupgradeneeded = (ev: IDBVersionChangeEvent) => {
        if (ev.oldVersion > 0) {
          openreq.result.deleteObjectStore(this.storeName);
        }
        openreq.result.createObjectStore(this.storeName, { autoIncrement: true });
      };
    });
  }

  private _withIDBStore(type: IDBTransactionMode, callback: (store: IDBObjectStore) => void): Promise<void> {
    return this.dbp.then(
      db =>
        new Promise<void>((resolve, reject) => {
          const transaction = db.transaction(this.storeName, type);
          transaction.oncomplete = () => resolve();
          transaction.onabort = transaction.onerror = () => reject(transaction.error);
          callback(transaction.objectStore(this.storeName));
        })
    );
  }

  private pSet<Type>(key: IDBValidKey, value: any): Promise<Type> {
    let req: IDBRequest;
    return this._withIDBStore('readwrite', s => {
      req = s.put(value, key);
    }).then(() => req.result);
  }

  private pGet<Type>(key: IDBValidKey): Promise<Type> {
    let req: IDBRequest;
    return this._withIDBStore('readonly', s => {
      req = s.get(key);
    }).then(() => req.result);
  }
  private pDel(key: IDBValidKey): Promise<boolean> {
    return this._withIDBStore('readwrite', s => {
      s.delete(key);
    })
      .then(() => true)
      .catch(() => false);
  }

  private pClear(): Promise<boolean> {
    return this._withIDBStore('readwrite', s => {
      s.clear();
    })
      .then(() => true)
      .catch(() => false);
  }

  private pKeys(): Promise<IDBValidKey[]> {
    const keys: IDBValidKey[] = [];

    return this._withIDBStore('readonly', s => {
      // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
      // And openKeyCursor isn't supported by Safari.
      (s.openKeyCursor || s.openCursor).call(s).onsuccess = function () {
        if (!this.result) {
          return;
        }
        keys.push(this.result.key);
        this.result.continue();
      };
    }).then(() => keys);
  }

  setItem<Type>(key: IDBValidKey, value: Type): Observable<Type> {
    return from(this.pSet<Type>(key, value)).pipe(tap({ error: error => console.error(error) }));
  }

  getItem<Type>(key: IDBValidKey): Observable<Type> {
    return from(this.pGet<Type>(key));
  }

  delItem(key: IDBValidKey): Observable<boolean> {
    return from(this.pDel(key));
  }

  clear(): void {
    from(this.pClear()).subscribe();
  }

  keys(): Observable<IDBValidKey[]> {
    return from(this.pKeys());
  }
}

export function setupStorage(): Observable<boolean> {
  return upgradeLocalstorageToIndexedDB$().pipe(
    catchError(err => {
      console.error(`Error upgrading LocalStorageToIndexDB [${err}]`);
      return of(null);
    }),
    switchMap(() => registerPersistedStorage$()), // request persistent storage
    tap(() => setupAkitaState()),
    map(() => true)
  );
}

function hasPersistentStorage() {
  return navigator.storage && 'persist' in navigator.storage;
}

function registerPersistedStorage$() {
  if (!hasPersistentStorage()) {
    return of(null);
  }

  const startObs$ = 'persisted' in navigator.storage ? from(navigator.storage.persisted()) : of(false);

  return startObs$.pipe(
    switchMap(persistent => {
      if (persistent) {
        console.log('[Storage]', 'Persistent storage already granted');
        return of(persistent);
      } else {
        return from(navigator.storage.persist()).pipe(
          tap(granted => {
            if (granted) {
              console.log('[Storage]', 'Persistent storage request granted');
            } else {
              console.warn('[Storage]', 'Persistent storage request denied, storage may be cleared automatically by the browser');
            }
          })
        );
      }
    })
  );
}

export function setupAkitaState() {
  const storageType = environment.localStorage?.type;
  const version = environment.localStorage?.version || 1;
  const statesToInclude = ['ui-state', 'auth', 'provider', 'clinical-encounters', 'UI/clinical-encounters', 'encounter-letters'];
  const productionStatesToExclude = ['tenants', 'users', 'network-status'];

  console.log('[Storage]', `Using [${storageType}] for UI storage`);

  if (storageType === StorageType.IndexedDB && indexDBSupported()) {
    const indexedDBStore = new IdbKeyValStore('healthbridge-clinical', 'state', version);

    persistState({
      key: 'app-state',
      include: statesToInclude,
      storage: indexedDBStore,
      // Throttle updates to save every 10 seconds that we receive updates with leading to ensure we don't lose data but also don't
      // introduce adverse performance issues either.  This felt like the best balance between the two.
      preStorageUpdateOperator: () => throttleTime(environment.production ? 10000 : 10, asyncScheduler, { leading: true, trailing: true }),
      persistOnDestroy: false,
    });
  } else {
    // minimal storage implementation to remove localForage and nesting it comes with
    persistState({
      key: 'app-state',
      include: statesToInclude,
    });
  }
}

function upgradeLocalstorageToIndexedDB$() {
  const storageType = environment.localStorage?.type;
  const version = environment.localStorage?.version || 1;

  // only upgrade from LocalStorage to IndexedDB
  if (storageType !== StorageType.IndexedDB) {
    return of(null);
  }

  const existingLocalStorage = localStorage.getItem('app-state');
  // if there's nothing in LocalStorage, stop the upgrade
  if (!existingLocalStorage) {
    return of(null);
  }

  // const version = environment.localStorage?.version || 1;
  const indexedDBStore = new IdbKeyValStore('healthbridge-clinical', 'state', version);
  // IndexedDB call is an asynchronous, wrap in observables
  return indexedDBStore.getItem('app-state').pipe(
    switchMap(indexedDBStorage => {
      if (!indexedDBStorage) {
        // no existing indexedDB, let's take LocalStorage and copy it over
        console.warn('[Storage]', 'Upgrading LocalStorage state to IndexedDB...');
        const storageObj = JSON.parse(existingLocalStorage);

        // IndexedDB set call is also asynchronous, wrap it
        return from(indexedDBStore.setItem('app-state', storageObj)).pipe(
          tap(() => console.log('[Storage]', 'Successfully upgraded LocalStorage state to IndexedDB.')),
          catchError(err => of(null).pipe(tap(() => console.error(`Error upgrading LocalStorage state to IndexedDB. [${err}]`))))
        );
      }
      return of(null);
    }),
    tap({ next: () => localStorage.clear() }), // clear localStorage if successful so we don't reimport very stale data later
    catchError(() => of(null)) // if there's a problem just ignore and skip over it
  );
}

function indexDBSupported() {
  return 'indexedDB' in window;
}
