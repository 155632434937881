import { Injectable, OnDestroy, Inject } from '@angular/core';
import {
  UiStateStore,
  ActivePatient,
  SidebarLocations,
  BrowserTypes,
  BrowserSizeType,
  getBrowserType,
  PracticeProviderStateUI,
  GuidedTour,
} from './state/ui-state/ui-state.store';
import { AuthService } from '@app/core/services/auth.service';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { Subject, Observable, timer, Subscription, of, fromEvent, BehaviorSubject, combineLatest } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  map,
  distinctUntilChanged,
  takeWhile,
  shareReplay,
  tap,
  startWith,
  take,
  withLatestFrom,
  filter,
  debounceTime,
  finalize,
} from 'rxjs/operators';
import * as moment from 'moment';
import { action, arrayUpsert, arrayRemove, arrayUpdate } from '@datorama/akita';
import * as _ from 'lodash';
import { NavigationService } from './navigation.service';
import { SectionEnum } from './state/clinical-encounter/clinical-encounter.store';
import { ClinicalEncounterService } from './clinical-encounter.service';
import { ProviderService } from './provider.service';
import { WINDOW } from './window.provider';
import { Router, NavigationEnd } from '@angular/router';
import { NavigationItem } from './reference-data.service';
import { CONSULTATION_NAVIGATION } from './navigation';

export const RESIZE_ON_WIDTH = false;

@Injectable({
  providedIn: 'root',
})
export class UiStateService implements OnDestroy {
  static currentDate$ = of(moment().utc().startOf('day'));

  private _desktopOverride$ = new BehaviorSubject(false);
  desktopOverride$ = this._desktopOverride$.asObservable().pipe(distinctUntilChanged());

  actualBrowserType$ = this.query.select(store => store.browserType);
  browserType$ = combineLatest([this.actualBrowserType$, this.desktopOverride$]).pipe(
    map(([browserType, desktopOverride]) => {
      // override the browser type
      return browserType === BrowserTypes.Mobile && desktopOverride ? BrowserTypes.Tablet : browserType;
    })
  );

  isMobile$ = this.browserType$.pipe(
    map(view => view === BrowserTypes.Mobile),
    untilDestroyed(this)
  );

  activePatients$ = this.query.currentPatientTabs$;
  currentTenantId$ = this.authService.currentTenantId$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this, 'ngOnDestroy'));

  practiceProvidersStateUI$ = this.query.practiceProviders$;

  private timerSubscription: Subscription;
  private timer: Observable<boolean>;

  currentPatientId$ = this.query.select(entity => entity.currentPatientId);
  displayProgressBar$ = this.query.displayProgressBar$;
  displayProgressSpinner$ = this.query.displayProgressSpinner$;
  displayProgressSpinnerLoaded$ = this.query.displayProgressSpinnerLoaded$;
  leftPanelOpened$ = this.query.leftPanelOpened$;
  private totalRequests = 0;
  private timeUntilDisplayProgressBar = 2; // time in sec since when show progress bar
  hideConsultTabs = false;

  isEncounterSectionCollapsed$ = this.providerService.IsEncounterSectionCollapsed$;
  /* PAGE VISIBILITY UPDATE */
  visibilityChanged$ = fromEvent(document, 'visibilitychange').pipe(
    map(event => document.visibilityState === 'visible'),
    startWith(true),
    distinctUntilChanged(),
    shareReplay(1),
    untilDestroyed(this, 'ngOnDestroy')
  );

  sidebar$ = this.query.select(state => state.sidebarLocation).pipe(distinctUntilChanged(), untilDestroyed(this, 'ngOnDestroy'));

  browserWidth$ = this.query.select(state => state.browserWidth).pipe(distinctUntilChanged(), untilDestroyed(this, 'ngOnDestroy'));
  browserHeight$ = this.query.select(state => state.browserHeight).pipe(distinctUntilChanged(), untilDestroyed(this, 'ngOnDestroy'));

  isPortraitMode$ = combineLatest([this.browserWidth$, this.browserHeight$]).pipe(
    distinctUntilChanged(),
    map(([width, height]) => height > width)
  );

  sidebarFlyout$ = combineLatest([this.sidebar$, this.browserWidth$]).pipe(
    map(([currentSidebar, width]) => currentSidebar !== SidebarLocations.HIDDEN && width <= BrowserSizeType.MEDIUM),
    distinctUntilChanged()
  );

  leftMenuFlyout$ = this.browserWidth$.pipe(
    map(width => width < BrowserSizeType.LARGE),
    distinctUntilChanged()
  );

  navigationItems$ = this.query
    .select(state => state.navigation)
    .pipe(
      map(s => s.filter(d => d.id !== CONSULTATION_NAVIGATION.CONSULT)), // todo can be removed later on
      distinctUntilChanged(),
      untilDestroyed(this, 'ngOnDestroy')
    );

  activeGuidedTour$ = this.query.select(state => state.guidedTour);
  selectedPathologyCentreTab$ = this.query.select(state => state.selectedPathologyCentreTab);

  constructor(
    private store: UiStateStore,
    private query: UiStateQuery,
    private authService: AuthService,
    private navService: NavigationService,
    private providerService: ProviderService,
    private clinicalEncounterService: ClinicalEncounterService,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) {
    this.resetProgressBar();
    this.setupVisibilityWatcher();
    this.setupResolutionWatchers();
    this.setupDefaultSidebar();
    this.setupAutocloseSidebarWatcher();
    this.setupNavigationItemsWatchers();
    this.desktopOverride$
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.currentTenantId$),
        tap(([state, tenantId]) => this.navService.navigate([tenantId, 'home'])),
        untilDestroyed(this, 'ngOnDestroy')
      )
      .subscribe();
  }

  private setupDefaultSidebar() {
    // if initial width is over threshold, and we don't already have a sidebar showing go to the waiting room automatically
    combineLatest([this.sidebar$, this.browserWidth$])
      .pipe(
        take(1), // only once, on initial load
        tap(
          ([sidebar, width]) =>
            sidebar === SidebarLocations.HIDDEN && width >= BrowserSizeType.MEDIUM && this.navigateSidebar(SidebarLocations.WAITING_ROOM)
        ),
        untilDestroyed(this, 'ngOnDestroy')
      )
      .subscribe();
  }

  private setupVisibilityWatcher() {
    this.visibilityChanged$.pipe(debounceTime(150), untilDestroyed(this, 'ngOnDestroy')).subscribe(isVisible => {
      this.store.update({ pageVisible: isVisible });
    });
  }

  private setupAutocloseSidebarWatcher() {
    // watch for navigation events
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.sidebar$, this.sidebarFlyout$),
        tap(([event, sidebar, flyout]) => flyout && sidebar !== SidebarLocations.HIDDEN && this.navigateSidebar(SidebarLocations.HIDDEN))
      )
      .subscribe();
  }

  private setupResolutionWatchers() {
    this.store.update({
      browserHeight: this.window.innerHeight,
      browserWidth: this.window.innerWidth,
      browserType: getBrowserType(),
    });
    fromEvent(this.window, 'resize')
      .pipe(
        debounceTime(50),
        map(() => ({
          browserHeight: this.window.innerHeight,
          browserWidth: this.window.innerWidth,
          browserType: getBrowserType(),
        })),
        distinctUntilChanged(),
        tap(({ browserWidth, browserHeight, browserType }) =>
          this.store.update({
            browserHeight,
            browserWidth,
            browserType,
          })
        ),
        untilDestroyed(this, 'ngOnDestroy')
      )
      .subscribe();
  }

  private setupNavigationItemsWatchers() {
    combineLatest([
      this.providerService.IsConsultReasonForVisitEnabled$,
      this.providerService.IsEnabledForSymptom$,
      this.providerService.IsEnabledForExamination$,
      this.providerService.IsEnabledForPlan$,
      this.providerService.IsEnabledForMedicines$,
    ])
      .pipe(
        withLatestFrom(this.query.select(state => state.navigation)),
        map(([settings, items]) => this.updateNavigationItems(items, settings)),
        tap(navigation => this.store.update({ navigation })),
        distinctUntilChanged(),
        untilDestroyed(this, 'ngOnDestroy')
      )
      .subscribe();
  }

  private updateNavigationItems(items: NavigationItem[], settings): NavigationItem[] {
    return items.map(m => ({
      ...m,
      hidden: this.setNavigationItemVisibility(m.id, settings),
      children: m.children?.length > 0 ? this.updateNavigationItems(m.children, settings) : [],
    }));
  }

  private setNavigationItemVisibility(id, settings) {
    const [isConsultReasonForVisitEnabled, IsEnabledForSymptom, IsEnabledForExamination, IsEnabledForPlan, isEnabledMedicines] = settings;

    switch (id) {
      case 'reason-visit':
        return !isConsultReasonForVisitEnabled;
      case 'symptoms':
        return !IsEnabledForSymptom;
      case 'examination':
        return !IsEnabledForExamination;
      case 'medicines':
        return !isEnabledMedicines;
      case 'plan':
        return !IsEnabledForPlan;
      default:
        return false;
    }
  }

  public setupDefaultSelectedProvider() {
    this.currentTenantId$
      .pipe(
        distinctUntilChanged(_.isEqual),
        untilDestroyed(this),
        withLatestFrom(this.providerService.practiceProviders$, this.practiceProvidersStateUI$),
        tap(
          ([currentProviderId, providers, uiState]) =>
            !uiState.some(s => s.providerId === currentProviderId) && this.store.update({ practiceProviders: [] })
        ),
        filter(([, , uiState]) => uiState?.length <= 0),
        tap(([currentProviderId, providers]) => {
          const provider = providers.find(f => f.PracticeId === currentProviderId);

          if (provider) {
            const state = {
              providerId: provider.PracticeId,
              hpcsaNo: provider.HPCSANumber,
              doctorName: provider.TreatingDoctorName,
              color: provider.Color,
              selectedInCalendar: true,
              selectedInWaitingRoom: true,
            } as PracticeProviderStateUI;

            this.updateProviderUiState(state);
          }
        })
      )
      .subscribe();
  }

  removeActivePatientMethod(patientId: string) {
    this.activePatients$.pipe(take(1), untilDestroyed(this, 'ngOnDestroy')).subscribe(patients => {
      const indexOfPatient = _.findIndex(patients, p => p.patientId === patientId);
      const newPatientPosition = indexOfPatient > 0 ? indexOfPatient - 1 : indexOfPatient + 1;
      this.removeActivePatient(patientId);
      this.selectPatient(patients.length === 1 ? null : patients[newPatientPosition].patientId);
    });
  }

  selectPatient(patientId: string) {
    this.currentTenantId$.pipe(take(1)).subscribe(tenantId => {
      if (patientId) {
        this.navService.navigate([tenantId, 'patient', patientId]);
      } else {
        this.navService.navigate([tenantId, 'home']);
      }
    });
  }

  sectionExpanded$(practiceId: string, patientId: string, section: SectionEnum): Observable<boolean> {
    return this.clinicalEncounterService.encounterInProgressUiState$(practiceId, patientId).pipe(
      withLatestFrom(this.isEncounterSectionCollapsed$),
      map(([state, isEncounterSectionCollapsed]) =>
        state && state.expansionSections ? state.expansionSections.find(x => x.section === section).expanded : isEncounterSectionCollapsed
      )
    );
  }

  showProgressBar() {
    this.totalRequests++;
    this.startTimer();
  }

  changeCurrentPatientId(patientId: string) {
    const currentPatientId = this.query.getValue().currentPatientId;
    if (currentPatientId !== patientId) {
      this.store.update({ currentPatientId: patientId });
    }
  }

  hideProgressBar() {
    this.totalRequests--;
    if (this.totalRequests <= 0) {
      this.resetProgressBar();
    }
  }

  hideProgressSpinner() {
    this.store.update({ displayProgressSpinner: false });
  }

  showProgressSpinner() {
    this.store.update({ displayProgressSpinner: true });
  }

  resetProgressBar() {
    this.stopTimer();
    this.totalRequests = 0;
    this.displayProgressSpinnerLoaded$
      .pipe(
        take(1),
        tap(loaded => this.store.update({ displayProgressSpinner: false }))
      )
      .subscribe();
  }

  setGuidedTour(tour: GuidedTour) {
    this.store.update({ guidedTour: tour });
  }

  setConsultTargetTab(index?: number) {
    this.store.update(entity => ({ guidedTour: { ...entity.guidedTour, activeTab: index } }));
  }

  private startTimer() {
    if (!this.timerSubscription) {
      this.timer = timer(1000, 1000).pipe(
        untilDestroyed(this, 'ngOnDestroy'),
        takeWhile(n => n < 60), // fall-back to prevent going too long because of errors
        map(n => n >= this.timeUntilDisplayProgressBar),
        distinctUntilChanged(),
        // fall-back to prevent going too long because of errors
        finalize(() => {
          this.store.update({ displayProgressBar: false });
        })
      );

      this.timerSubscription = this.timer.subscribe({
        next: n => {
          this.store.update({ displayProgressBar: n });
        },
        complete: () => {
          this.store.update({ displayProgressBar: false }); // fall-back to prevent going too long because of errors
        },
      });
    }
  }

  private stopTimer() {
    // this.store.update({ displayProgressBar: false });
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  @action('addActivePatient')
  public addActivePatient(patient: ActivePatient) {
    this.store.update(store => ({
      currentPatientTabs: arrayUpsert(store.currentPatientTabs || [], patient.patientId, patient, 'patientId'),
    }));
  }

  @action('removeActivePatient')
  public removeActivePatient(patientId: string) {
    this.store.update(store => ({
      currentPatientTabs: arrayRemove(store.currentPatientTabs || [], patientId, 'patientId'),
    }));
  }

  @action('clearActivePatients')
  public clearActivePatients() {
    this.store.update(store => ({ currentPatientTabs: [] }));
  }

  public navigateSidebar(sidebarLocation: SidebarLocations) {
    this.store.update({ sidebarLocation });
  }

  updateProviderUiState(provider: PracticeProviderStateUI) {
    this.store.update(store => ({
      practiceProviders: arrayUpsert(store.practiceProviders || [], provider.providerId, provider, 'providerId'),
    }));
  }

  updateProvidersUiState(providers: PracticeProviderStateUI[]) {
    this.store.update({
      practiceProviders: providers,
    });
  }

  setDesktopOverride(desktopOverride: boolean) {
    this._desktopOverride$.next(desktopOverride);
  }

  setPathologyCentreTab(selectedPathologyCentreTab: number) {
    this.store.update({
      selectedPathologyCentreTab,
    });
  }

  ngOnDestroy(): void {}
}
