import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { MERAKI_API_GATEWAY } from './meraki-nexus.providers';

@Injectable({
  providedIn: 'root',
})
export class MerakiAuthService {
  constructor(@Inject(MERAKI_API_GATEWAY) private baseUrl: string, private http: HttpClient, private fireAuth: AngularFireAuth) {}

  getAuthToken(token): Observable<{ success: boolean; data: any }> {
    return this.http.get<{ success: boolean; data: any }>(`${this.baseUrl}/v1/authentication/token/customClinicalToken?token=${token}`);
  }

  signIntoFirebase(token: string): Observable<any> {
    return this.getAuthToken(token).pipe(
      switchMap(response => (response.success ? this.fireAuth.signInWithCustomToken(response.data) : of({ success: false })))
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.fireAuth.authState.pipe(
      take(1),
      map(user => (user ? true : false))
    );
  }

  getFirebaseToken(): Observable<string> {
    return from(this.getFirebaseUserToken());
  }

  private async getFirebaseUserToken() {
    try {
      const user = await this.fireAuth.currentUser;
      if (!user) {
        throw Error('No Firebase user returned.');
      }

      // Auto refreshes token if expired.
      const token = await user.getIdToken();
      return token;
    } catch (err) {
      // don't throw error, as it can kill whole observable, impact will be higher than just failed call
      return null;
      //throw Error(`Could not get Firebase user token. ${err}`);
    }
  }
}
