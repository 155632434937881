import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { DynamicFormService } from '../dynamic-form.service';
import { FormGroup } from '@angular/forms';
import { slideInOutAnimation, listAnimation } from '@app/shared/animations';
import * as _ from 'lodash';
import { QuestionBase } from 'api-clinician-app';
import { QuestionViewModel } from '@app/core/services/view-models/question-view-model.model';
import { convertToQuestionViewModels } from '@app/core/services/view-models/question-view-model.utils';

@Component({
  selector: 'shared-dynamic-form-builder',
  templateUrl: './dynamic-form-builder.component.html',
  styleUrls: ['./dynamic-form-builder.component.scss'],
  animations: [slideInOutAnimation, listAnimation],
})
export class DynamicFormBuilderComponent implements OnInit, OnDestroy {
  @Input() questions: QuestionBase[] = [];
  @Input() form: FormGroup;

  questionGroup = null;

  constructor(private dynamicFormService: DynamicFormService) {}

  ngOnInit() {
    this.buildQuestions();

    this.questionGroup = _.chain(convertToQuestionViewModels({ questions: this.questions }))
      .groupBy(g => g.Category)
      .map((value, key) => ({ Questions: this.groupQuestionsByKey(value), Category: key !== 'undefined' ? key : '' }))
      .value();
  }

  groupQuestionsByKey(questions: QuestionViewModel[]) {
    return _.chain(questions)
      .orderBy(qb => qb.Order)
      .groupBy(g => {
        const keys = g.Questions && g.Questions ? `${g.QuestionKey}`.split('-') : g.QuestionKey;
        return Array.isArray(keys) ? keys[keys.length - 1] : keys;
      })
      .map((value, key) => ({
        Questions: value,
        Subcategory: value && value.length > 1 ? key : '',
        Order:
          value.reduce((acc: number, i) => {
            if (!acc || acc > i.Order) {
              return i.Order;
            }
            return acc;
          }, null) || 0,
      }))
      .value()
      .sort((a, b) => a.Order - b.Order);
  }

  buildQuestions() {
    const questions = this.questions.sort(questionOrder);
    this.dynamicFormService.addControlsToFormGroup(this.form, questions);
  }

  categoryAnswersVisible(category) {
    const dependencies = category.Questions.map(m => m.Questions.filter(f => f.Dependency)).flat();

    if (dependencies && dependencies.length > 0) {
      const ishidden = !dependencies.filter(
        f =>
          f.Dependency &&
          f.Dependency.DependencyKeyName &&
          !!this.form.controls[f.Dependency.DependencyKeyName] &&
          this.form.controls[f.Dependency.DependencyKeyName].value !== 'null' &&
          this.form.controls[f.Dependency.DependencyKeyName].value !== 'No'
      ).length;

      const answered = !dependencies.filter(
        f =>
          f.QuestionKey &&
          !!this.form.controls[f.QuestionKey] &&
          this.form.controls[f.QuestionKey].value &&
          this.form.controls[f.QuestionKey].value !== 'null' &&
          this.form.controls[f.QuestionKey].value !== 'No'
      ).length;

      return ishidden && answered;
    }

    return false;
  }

  ngOnDestroy() {}

  getQuestionByQuestionKey(questionKey: string, questions: any[]): QuestionBase {
    return questions.find(x => x.Questions[0].QuestionKey === questionKey).Questions[0];
  }

  get SmokingExposure() {
    const quantity = this.form.get('Smoker_AverageQuantity')?.value || 0;
    const years = this.form.get('Smoker_YearsDuration')?.value || 0;

    return Math.round((quantity / 20) * years * 10) / 10;
  }
}

function questionGroupOrder(a: QuestionBase, b: QuestionBase) {
  if (a.Order > b.Order) {
    return 1;
  }
  if (a.Order < b.Order) {
    return -1;
  }
  if (a.Order === b.Order) {
    return 0;
  }
}

function questionOrder(a: QuestionBase, b: QuestionBase) {
  if (a.Order > b.Order) {
    return 1;
  }
  if (a.Order < b.Order) {
    return -1;
  }
  if (a.Order === b.Order) {
    return 0;
  }
}
