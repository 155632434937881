import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '@app/core/services/config.service';
import { MerakiClientService } from '@app/core/services/meraki-client.service';
import { ProviderService } from '@app/core/services/provider.service';
import { shareReplayDestroyed } from '@app/core/functions/share-replay-destroyed';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { AuthService, EncounterVo, PatientsService, UiStateService } from '@app/core/services';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GenerateFunctionsService {
  currentTenantId$ = this.authService.currentTenantId$;
  currentPatientId$ = this.uiStateService.currentPatientId$;

  params$ = this.currentPatientId$.pipe(
    withLatestFrom(this.currentTenantId$),
    distinctUntilChanged(),
    // debounceTime(10),
    map(([patientId, tenantId]) => ({ tenantId, patientId })),
    untilDestroyed(this)
  );

  patient$ = this.params$.pipe(
    switchMap(params => this.patientsService.patientDetailsById$(params.tenantId, params.patientId)),
    untilDestroyed(this),
    shareReplayDestroyed(1)
  );
  providerDetails$ = this.providerService.provider$;
  encounterInProgress$: Observable<EncounterVo> = this.params$.pipe(
    switchMap(params => this.clinicalEncounterService.encounterInProgress$(params.tenantId, params.patientId)),
    distinctUntilChanged(_.isEqual),
    untilDestroyed(this),
    shareReplayDestroyed(1)
  );

  showPatientLetterSubject: Subject<boolean> = new Subject<boolean>();
  showPatientLetter$ = this.showPatientLetterSubject.asObservable();
  isPatientLetterLoadingSubject: Subject<boolean> = new Subject<boolean>();
  isPatientLetterLoading$ = this.isPatientLetterLoadingSubject.asObservable();
  isReferralLetterLoadingSubject: Subject<boolean> = new Subject<boolean>();
  isReferralLetterLoading$ = this.isReferralLetterLoadingSubject.asObservable();
  showReferralLetterSubject: Subject<boolean> = new Subject<boolean>();
  showReferralLetter$ = this.showReferralLetterSubject.asObservable();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private merakiClientService: MerakiClientService,
    private providerService: ProviderService,
    private clinicalEncounterService: ClinicalEncounterService,
    private patientsService: PatientsService,
    private authService: AuthService,
    private uiStateService: UiStateService
  ) {}

  // Patient Letter
  generatePatientLetter(letterReason: string, practiceId: string, patientId: string): Observable<any> {
    const cloudFunctionUrl = `${this.configService.config.sandbox.cloudFunctionBaseUrl}patient-letter-ai-generation`;

    return this.http
      .post(
        cloudFunctionUrl,
        {
          reason: letterReason,
          practiceId: practiceId,
          patientId: patientId,
          type: 'visit-summary',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        catchError(error => {
          console.error('Error:', error);
          return throwError(() => new Error('An error occurred while generating the letter. Please try again.'));
        })
      );
  }

  getShowPatientLetterSubject(): Observable<boolean> {
    return this.showPatientLetter$;
  }

  setShowPatientLetterSubject(showPatientLetter: boolean): void {
    this.showPatientLetterSubject.next(showPatientLetter);
  }

  getIsPatientLetterLoadingSubject(): Observable<boolean> {
    return this.isPatientLetterLoading$;
  }

  setIsPatientLetterLoadingSubject(isPatientLetterLoading: boolean): void {
    this.isPatientLetterLoadingSubject.next(isPatientLetterLoading);
  }

  // Referral Letter
  generateReferralLetter(referralReason: string, practiceId: string, patientId: string): Observable<any> {
    const cloudFunctionUrl = `${this.configService.config.sandbox.cloudFunctionBaseUrl}patient-letter-ai-generation`;

    return this.http
      .post(
        cloudFunctionUrl,
        {
          reason: referralReason,
          practiceId: practiceId,
          patientId: patientId,
          type: 'referral-letter',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        catchError(error => {
          console.error('Error:', error);
          return throwError(() => new Error('An error occurred while generating the letter. Please try again.'));
        })
      );
  }

  getIsReferralLetterLoadingSubject(): Observable<boolean> {
    return this.isReferralLetterLoading$;
  }

  setIsReferralLetterLoadingSubject(isReferralLetterLoading: boolean): void {
    this.isReferralLetterLoadingSubject.next(isReferralLetterLoading);
  }

  getShowReferralLetterSubject(): Observable<boolean> {
    return this.showReferralLetter$;
  }

  setShowReferralLetterSubject(showReferralLetter: boolean): void {
    this.showReferralLetterSubject.next(showReferralLetter);
  }

  generatePathologyInsights(userMessage: string, practiceId: string, patientId: string, messageHistory: any[], sessionId: string) {
    // console.log(sessionId);
    // console.log(messageHistory);
    // console.log(userMessage);
    const cloudFunctionUrl = `${this.configService.config.sandbox.cloudFunctionBaseUrl}clinicalai-process-pathology-requests`;

    return this.http
      .post(
        cloudFunctionUrl,
        {
          chatSessionId: sessionId,
          userMessage: userMessage,
          practiceId: practiceId,
          patientId: patientId,
          messages: messageHistory,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        catchError(error => {
          console.error('Error:', error);
          return throwError(() => new Error('An error occurred while processing the pathology request. Please try again.'));
        })
      );
  }

  createSandboxSummary(patientId: string) {
    this.providerDetails$
      .pipe(
        take(1),
        switchMap(providerDetails =>
          this.merakiClientService.createSandboxSummary(
            providerDetails.PracticeNumber,
            providerDetails.PracticeId,
            patientId,
            providerDetails.PracticeTenantId,
            providerDetails.HPCSANumber
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.showPatientLetterSubject.complete();
    this.isPatientLetterLoadingSubject.complete();
    this.isReferralLetterLoadingSubject.complete();
    this.showReferralLetterSubject.complete();
  }
}
