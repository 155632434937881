import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { UserVo } from 'api-clinician-app';

export interface UsersState extends EntityState<UserVo> {}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'users', idKey: 'UserId', resettable: true })
export class UsersStore extends EntityStore<UsersState, UserVo> {
  constructor() {
    super();
  }
}
