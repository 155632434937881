import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Plan {
  planCode: string;
  name: string;
  price: number;
  features: string[];
}

export interface Announcement {
  heading: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class NoraManagementService {
  limits: { [key: string]: number } = { Lite: 180, Premium: 1800 };

  plans: Plan[] = [
    {
      planCode: 'FreeTrial',
      name: 'Free',
      price: 0,
      features: [
        '1-month free access',
        'Unlimited usage',
        'Dictation & Consultation mode',
        'Seamlessly integrated into Healthbridge Clinical',
        'Structured clinical notes',
        'Choose from a selection of note templates',
        'Email support',
      ],
    },
    {
      planCode: 'Lite',
      name: 'Lite',
      price: 499,
      features: [
        '300 consultations per month',
        'Max of 3 mins per consult',
        'Dictation mode',
        'Seamlessly integrated into Healthbridge Clinical',
        'Structured clinical notes',
        'Choose from a selection of note templates',
        'Dedicated customer support manager',
      ],
    },
    {
      planCode: 'Premium',
      name: 'Premium',
      price: 1199,
      features: [
        '300 consultations per month',
        'Max of 30 mins per consult',
        'Dictation & Consultation mode',
        'Seamlessly integrated into Healthbridge Clinical',
        'Structured clinical notes',
        'Choose from a selection of note templates',
        'Dedicated customer support manager',
      ],
    },
    {
      planCode: 'Pro',
      name: 'Pro',
      price: 2999,
      features: [
        'Unlimited access',
        'Unlimited usage',
        'Dictation & Consultation mode',
        'Seamlessly integrated into Healthbridge Clinical',
        'Structured clinical notes',
        'Choose from a selection of note templates',
        'Dedicated customer support manager',
      ],
    },
  ];

  private showWhatIsNora: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showWhatIsNora$: Observable<boolean> = this.showWhatIsNora.asObservable();
  private showGetStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showGetStarted$: Observable<boolean> = this.showGetStarted.asObservable();
  public signUpComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signUpComplete$: Observable<boolean> = this.signUpComplete.asObservable();
  private showSignUp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSignUp$: Observable<boolean> = this.showSignUp.asObservable();
  private subscriptionLevel: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public subscriptionLevel$: Observable<string> = this.subscriptionLevel.asObservable();
  private subscriptionDaysLeft: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public subscriptionDaysLeft$: Observable<number> = this.subscriptionDaysLeft.asObservable();
  public isFreeTrialUsedUp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isFreeTrialUsedUp$: Observable<boolean> = this.isFreeTrialUsedUp.asObservable();
  public monthlyUsageLimitReached: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public monthlyUsageLimitReached$: Observable<boolean> = this.monthlyUsageLimitReached.asObservable();
  public numberOfDaysLeftTillReset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public numberOfDaysLeftTillReset$: Observable<number> = this.numberOfDaysLeftTillReset.asObservable();
  public announcements: BehaviorSubject<Announcement[]> = new BehaviorSubject<Announcement[]>([]);
  public announcements$: Observable<Announcement[]> = this.announcements.asObservable();
  public recordingMode: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public recordingMode$: Observable<string> = this.recordingMode.asObservable();

  constructor(private snackBar: MatSnackBar) {}

  // getStarted
  setShowGetStarted(show: boolean) {
    this.showGetStarted.next(show);
  }

  getShowGetStarted(): Observable<boolean> {
    return this.showGetStarted$;
  }

  // signUp
  setSignUpComplete(complete: boolean) {
    this.signUpComplete.next(complete);
  }

  getSignUpComplete(): Observable<boolean> {
    return this.signUpComplete$;
  }

  // whatIsNora
  setShowWhatIsNora(show: boolean) {
    this.showWhatIsNora.next(show);
  }

  getShowWhatIsNora(): Observable<boolean> {
    return this.showWhatIsNora$;
  }

  // showSignUp
  setShowSignUp(show: boolean) {
    this.showSignUp.next(show);
  }

  getShowSignUp(): Observable<boolean> {
    return this.showSignUp$;
  }

  // subscriptionLevel
  setSubscriptionLevel(level: string) {
    this.subscriptionLevel.next(level);
  }

  getSubscriptionLevel(): Observable<string> {
    return this.subscriptionLevel$;
  }

  // subscriptionDaysLeft
  setSubscriptionDaysLeft(days: number) {
    this.subscriptionDaysLeft.next(days);
  }

  getSubscriptionDaysLeft(): Observable<number> {
    return this.subscriptionDaysLeft$;
  }

  setIsFreeTrialUsedUp(usedUp: boolean) {
    this.isFreeTrialUsedUp.next(usedUp);
  }

  getIsFreeTrialUsedUp(): Observable<boolean> {
    return this.isFreeTrialUsedUp$;
  }

  setMonthlyUsageLimitReached(reached: boolean) {
    this.monthlyUsageLimitReached.next(reached);
  }

  getMonthlyUsageLimitReached(): Observable<boolean> {
    return this.monthlyUsageLimitReached$;
  }

  setNumberOfDaysLeftTillReset(days: number) {
    this.numberOfDaysLeftTillReset.next(days);
  }

  getNumberOfDaysLeftTillReset(): Observable<number> {
    return this.numberOfDaysLeftTillReset$;
  }

  setRecordingMode(mode: string) {
    this.recordingMode.next(mode);
  }

  getRecordingMode(): Observable<string> {
    return this.recordingMode$;
  }

  setAnnouncements(announcements: Announcement[]) {
    this.announcements.next(announcements);
  }

  getAnnouncements(): Observable<Announcement[]> {
    return this.announcements$;
  }
}
