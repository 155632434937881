import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ProviderService } from '../services/provider.service';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ProviderOptionEnabledForHBClinical } from '../services';

@Injectable({
  providedIn: 'root',
})
export class HBClinicalContractGuard implements CanActivate {
  constructor(private router: Router, private providerService: ProviderService, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tenantId = (route.params && route.params.tenantId) || null;

    /* return combineLatest([
      this.authService.superAdmin$,
      this.providerService.provider$.pipe(
        filter(s => !!s),
        map(provider => provider.Options)
      )]).pipe(
        take(1),
        map(
          ([superAdmin, providerOptions]) =>
            this.hasHBClinicalContract(providerOptions) || this.router.parseUrl(`/error/contract/${tenantId}`)
        )
      ); */

    return combineLatest([this.providerService.isLite$, this.providerService.isFull$]).pipe(
      map(([isLite, isFull]) => isLite || isFull || this.router.parseUrl(`/error/contract/${tenantId}`))
    );
  }

  hasHBClinicalContract(options: { [key: string]: string }) {
    return options && options[ProviderOptionEnabledForHBClinical] && options[ProviderOptionEnabledForHBClinical].toLowerCase() === 'true';
  }
}
