import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UserVo } from 'api-clinician-app';
import { UsersState, UsersStore } from './users.store';

@Injectable({
  providedIn: 'root',
})
export class UsersQuery extends QueryEntity<UsersState, UserVo> {
  constructor(protected store: UsersStore) {
    super(store);
  }
}
