import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoraAPIService {
  noraAPIKey = this.configService.config.nora.noraApiToken;
  private feedbackApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/feedback`;
  private consultationsApiUrl = `${this.configService.config.nora.noraApiHostUrlV1}/consults`;
  private uploadAudioApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/upload`;
  private checkUserApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/check-user`;
  private signUpApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/signup`;
  private updateSubscriptionLevelApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/settings/subscription`;
  private getFormatSettingsApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/settings/templates`;
  private getUserSelectedFormatTemplateApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/settings/user-template`;
  private saveNoteFormatApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/settings/user-template`;
  private getConsultationStatusApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/consultationstatus`;
  private regenerateNotesApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/regenerate`;
  private sendNotesFeedbackApiUrl = `${this.configService.config.nora.noraApiHostUrlV2}/notes-feedback`;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  submitFeedback(hbdid: string, message: string): Observable<any> {
    const payload = {
      doctor_id: hbdid,
      feedback: message,
    };
    return this.http.post(this.feedbackApiUrl, payload, { headers: { 'Content-Type': 'application/json', 'x-api-key': this.noraAPIKey } });
  }

  getConsultations(hbdid: string): Observable<any> {
    let params = new HttpParams().set('HBDID', hbdid);
    return this.http.get<any>(this.consultationsApiUrl, { params });
  }

  getPresignedUrl(
    practiceId: string,
    encounterId: string,
    fileName: string,
    contentType: string,
    micUsed: string,
    recordingDuration: number,
    notesType: string
  ): Observable<any> {
    // console.log(practiceId, encounterId, fileName, contentType, micUsed, recordingDuration, notesType);
    const headers = new HttpHeaders({
      'x-api-key': this.noraAPIKey,
      DoctorID: practiceId,
      ConsultationID: encounterId,
      'Content-Type': contentType,
      'Microphone-Selected': micUsed,
      'Audio-Duration': recordingDuration.toString(),
      'Con-Mode': notesType,
    });

    let params = new HttpParams().set('file_name', fileName).set('content_type', contentType);
    return this.http.get<any>(this.uploadAudioApiUrl, { headers, params });
  }

  //User Note Format Endpoints

  async getFormatTemplates() {
    try {
      const response = await fetch(this.getFormatSettingsApiUrl, {
        method: 'GET',
        headers: {
          'x-api-key': this.noraAPIKey,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Make sure templates exist before returning
      if (data) {
        return data;
      } else {
        throw new Error('No templates found in response');
      }
    } catch (error) {
      console.error('Error getting format templates:', error);
      throw error;
    }
  }

  async getUserSelectedFormatTemplate(doctorId: string) {
    // console.log(doctorId);
    try {
      const response = await fetch(this.getUserSelectedFormatTemplateApiUrl, {
        method: 'GET',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Make sure templates exist before returning
      if (data) {
        // console.log('User selected format template:', data);
        return data;
      } else {
        throw new Error('No templates found in response');
      }
    } catch (error) {
      console.error('Error getting format templates:', error);
      throw error;
    }
  }

  async saveNoteFormat(doctorId: string, selectedNoteFormat: string) {
    try {
      const response = await fetch(this.saveNoteFormatApiUrl, {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          template_id: selectedNoteFormat,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to save note format. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Note format saved:', data);
      return data;
    } catch (error) {
      console.error('Error saving note format:', error);
      throw error;
    }
  }

  //Consultation Status Endpoints

  async getConsultationStatus(doctorId: string, encounterId: string) {
    try {
      const response = await fetch(this.getConsultationStatusApiUrl, {
        method: 'GET',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
          ConsultationID: encounterId,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to get consultation status. Status: ${response.status}`);
      }

      const data = response.json();
      return data;
    } catch (error) {
      console.error('Error getting consultation status:', error);
      throw error;
    }
  }

  //Regenerate Endpoints

  async regenerateNotes(doctorId: string, encounterId: string) {
    try {
      const response = await fetch(this.regenerateNotesApiUrl, {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
          ConsultationID: encounterId,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to get consultation status. Status: ${response.status}`);
      }

      const data = response.json();
      return data;
    } catch (error) {
      console.error('Error getting consultation status:', error);
      throw error;
    }
  }

  async sendNotesFeedback(doctorId: string, encounterId: string, rating: boolean, updatedSummary: string) {
    try {
      const response = await fetch(this.sendNotesFeedbackApiUrl, {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
          ConsultationID: encounterId,
        },
        body: JSON.stringify({
          Rating: rating ? 'Good' : 'Bad',
          UpdatedSpeechToNotesSummary: updatedSummary,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send notes feedback. Status: ${response.status}`);
      }

      const data = response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.error('Error sending notes feedback:', error);
      throw error;
    }
  }

  async checkUser(doctorId: string) {
    try {
      const response = await fetch(this.checkUserApiUrl, {
        method: 'GET',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to check user. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.error('Error checking user:', error);
      throw error;
    }
  }

  async signUp(
    doctorId: string,
    subscriptionLevel: string,
    selectedNoteFormatId: string,
    firstLanguage: string,
    whatsappNumber: string,
    speciality: string,
    discountCode: string
  ) {
    // console.log(doctorId, subscriptionLevel, selectedNoteFormatId, firstLanguage, whatsappNumber, speciality);
    try {
      const response = await fetch(this.signUpApiUrl, {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
        },
        body: JSON.stringify({
          subscriptionLevel: subscriptionLevel,
          vendor: 'HBC',
          template_id: selectedNoteFormatId,
          lang: firstLanguage,
          phone_number: whatsappNumber,
          specialty: speciality,
          discount: discountCode,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to create Nora account. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.error('Error creating Nora account:', error);
      throw error;
    }
  }

  async cancelPlan(doctorId: string) {
    try {
      const response = await fetch('', {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to cancel plan. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.error('Error canceling plan:', error);
      throw error;
    }
  }

  async updateSubscriptionLevel(doctorId: string, subscriptionLevel: string) {
    try {
      const response = await fetch(this.updateSubscriptionLevelApiUrl, {
        method: 'POST',
        headers: {
          'x-api-key': this.noraAPIKey,
          DoctorID: doctorId,
        },
        body: JSON.stringify({
          subscriptionLevel: subscriptionLevel,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update subscription level. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.error('Error updating subscription level:', error);
      throw error;
    }
  }
}
