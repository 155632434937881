import { QuestionBase } from 'api-clinician-app';
import { QuestionViewModel } from './question-view-model.model';

export function convertToQuestionViewModels({ questions }: { questions: QuestionBase[] }): QuestionViewModel[] {
  return questions.map(q => convertToQuestionViewModel({ question: q }));
}

export function convertToQuestionViewModel({ question }: { question: QuestionBase }): QuestionViewModel {
  return {
    ...question,
  } as QuestionViewModel;
}

/**
 * QuestionViewModel
 * -- Category
 * -- Order
 * -- HasAnsweredDepQuestions
 *
 * ---- Subcategory (rendered from option selection)
 * ---- Order
 * ---- HasAnsweredDepQuestions
 *
 * ------ Questions
 * -------- Order
 * -------- QuestionKey
 * -------- LongText
 * --------
 * */
