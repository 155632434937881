import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-birthday-icon',
  templateUrl: './birthday-icon.component.html',
  styleUrls: ['./birthday-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirthdayIconComponent implements OnInit, OnChanges {
  showIcon = false;

  @Input() birthDate: moment.Moment;
  @Input() currentDate: moment.Moment;

  constructor() {}
  ngOnInit() {
    this.toggleIcon();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.toggleIcon();
  }

  toggleIcon() {
    this.showIcon = moment(this.currentDate).isSame(this.birthDate, 'day');
  }
}
