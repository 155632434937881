import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  filter,
  map,
  distinctUntilChanged,
  shareReplay,
  take,
  tap,
  switchMap,
  delay,
  withLatestFrom,
  takeUntil,
  exhaustMap,
} from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AuthService, ProviderService, UiStateService } from './core/services';
import { BrowserTypes } from './core/services/state/ui-state/ui-state.store';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from '@app/core/services/zendesk-config';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from './shared/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { MerakiAuthService } from './core/services/meraki-auth.service';
import { BehaviorSubject, combineLatest } from 'rxjs';

declare global {
  interface Window {
    productFruitsUser: any;
    productFruits: any;
    $productFruits: any;
    $crisp: any;
    CRISP_WEBSITE_ID: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  BrowserTypes = BrowserTypes;

  browserType$ = this.uiStateService.browserType$.pipe(distinctUntilChanged(), untilDestroyed(this), shareReplay(1));

  sidebarFlyout$ = this.uiStateService.sidebarFlyout$;

  privacyPolicyDateAccepted$ = this.providerService.PrivacyPolicyDateAccepted$;

  loginToFirebase$ = new BehaviorSubject(true);

  constructor(
    private uiStateService: UiStateService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private authService: AuthService,
    private providerService: ProviderService,
    private dialog: MatDialog,
    private nexusApi: MerakiAuthService
  ) {}

  ngOnInit() {
    // monitor children
    this.router.events
      .pipe(
        filter(routeEvents => routeEvents instanceof NavigationEnd),
        map(() =>
          this.route.firstChild && this.route.firstChild.firstChild
            ? {
                tenantId: this.route.firstChild.firstChild.snapshot.params.tenantId,
                patientId: this.route.firstChild.firstChild.snapshot.params.patientId,
              }
            : { tenantId: this.route.firstChild.snapshot.params.tenantId, patientId: null }
        ),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe({
        next: params => {
          this.uiStateService.changeCurrentPatientId(params.patientId);
        },
      });

    // this.initZendesk();

    this.loginToFirebase$
      .pipe(
        switchMap(() => this.nexusApi.isLoggedIn()),
        filter(loggedIn => this.authService.token && !loggedIn),
        exhaustMap(() => this.nexusApi.signIntoFirebase(this.authService.token)),
        untilDestroyed(this)
      )
      .subscribe();

    this.initProductFruits();
  }

  initProductFruits() {
    combineLatest([this.providerService.provider$, this.authService.auth$])
      .pipe(
        distinctUntilChanged(_.isEqual),
        tap(([provider, user]) => {
          if (!user || !provider) {
            return;
          }

          //set up product fruits user data
          window.productFruitsUser = {
            //username: user.uid,
            username: user.username || '',
            email: provider.EmailAddress,
            firstname: user.fullName || '', // just have full name
            lastname: '',
            //signUpAt: '',//user['email'] ? user['email'] : '',// todo
            role: this.authService.superAdmin ? 'admin' : '', // todo confirm why we care only about admin role
            //role: auth['admin'] ? 'admin' : '',

            props: {
              'Billing Practice Number': provider.PracticeNumber || '',
              'Treating Provider Number': provider.TreatingProviderNumber || '',
              Speciality: provider.SpecialityDescription || '',
            },
          };

          //set up product fruits script, initialise and add it to the head tag
          (function (w, d, u) {
            w.$productFruits = w.$productFruits || [];
            w.productFruits = w.productFruits || {};
            w.productFruits.scrV = '2';
            const a = d.getElementsByTagName('head')[0];
            const r: any = d.createElement('script');
            r.async = 1;
            r.src = u;
            a.appendChild(r);
            w.$productFruits.push(['init', '5zCQ82ryDNVoMqbJ', 'en', w.productFruitsUser]); // hbc key, nothing shown
          })(window, document, 'https://app.productfruits.com/static/script.js');

          //set up crisp script and add it to the head tag
          (function (w, d, s) {
            w.$crisp = w.$crisp || [];
            w.CRISP_WEBSITE_ID = 'bd9eaa37-a026-4808-9b95-a63b6b45a11c';

            const a = d.getElementsByTagName('head')[0];
            const r: any = d.createElement('script');
            r.async = 1;
            r.src = s;
            a.appendChild(r);
            w.$crisp.push(['do', 'chat:hide']);
            //set crisp user data
            w.$crisp.push([
              'set',
              'session:data',
              [
                [
                  ['FullName', user.fullName],
                  ['bpn', provider.PracticeNumber],
                  ['email', ''],
                ],
              ],
            ]);
          })(window, document, 'https://client.crisp.chat/l.js');
        })
      )
      .subscribe();
  }

  initZendesk() {
    this.ngxZendeskWebwidgetService?.initZendesk(new ZendeskConfig());
  }

  showHelp() {
    this.ngxZendeskWebwidgetService?.zE.activate();
  }

  onRouteActivate(e) {
    this.showPrivacyPolicyDialog();
    // TODO: Remove once florence user migration is done.
    this.loginToFirebase$.next(true);
  }

  showPrivacyPolicyDialog() {
    this.providerService.providerOptions$
      .pipe(
        distinctUntilChanged(_.isEqual),
        take(1),
        delay(3500),
        map(options => options?.PrivacyPolicyDateAccepted || ''),
        filter(
          val =>
            val !== this.providerService.PrivacyPolicyLatestDate && this.authService.authenticated && this.dialog.openDialogs.length === 0
        ),
        tap(() => {
          this.dialog.open(PrivacyPolicyDialogComponent, {
            height: '600px',
            width: '500px',
            disableClose: true,
            data: {
              tenantId: this.route.firstChild.firstChild.snapshot.params.tenantId,
            },
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}
}
