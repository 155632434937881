interface String {
  toProperCase(): string;
}

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, text =>
    text
      .split('-')
      .map(t => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase())
      .join(' ')
      .replace(' ', '')
  );
};
