import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limit' })
export class LimitPipe implements PipeTransform {
  transform(value: string, size: number) {
    if (value?.length > 0) {
      return value.slice(0, size);
    } else {
      return value;
    }
  }
}
