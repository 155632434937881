import { NgModule, Optional, SkipSelf, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './nav/menu/menu.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LogoutConfirmationDialogComponent } from './nav/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor, ErrorInterceptor } from './interceptors';
import { NetworkStatusService } from './services/network-status.service';
import { AppInsightServiceErrorHandler } from './services/logging/error-handler.service';
import { WINDOW_PROVIDERS } from './services/window.provider';
import { HeaderComponent } from './nav/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StickyComponent } from './nav/header/sticky.component';
import { StickyHeaderDirective } from './nav/header/sticky-header-directive';
import { VersionService } from './services/version.service';
import { LoggingInterceptor } from './interceptors/logging-interceptor';
import { ResolveHomeComponent } from './nav/resolve-home/resolve-home.component';
import { StateModule } from './services/state/state.module';
import { ProgressBarInterceptor } from './interceptors/progress-bar.interceptor';
import {
  SignalRService,
  // OneSignalService,
  OfflineService,
  AuthService,
  API_BASE_URL,
  CLIENT_ID,
} from './services';
import { SideNavComponent } from './nav/side-nav/side-nav.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInsightsService } from './services/application-insights.service';
import { ConfigService } from './services/config.service';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgAisModule } from 'angular-instantsearch';
import { ProviderDataResolverService } from './resolvers';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { environment } from '@env/environment';
import { MobileLayoutComponent } from './layouts/mobile-layout/mobile-layout.component';
import { WebLayoutComponent } from './layouts/web-layout/web-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from './services/zendesk-config';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '@app/shared/shared.module';
import { GuidedTourComponent } from './components/guided-tour.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  HTTP_INTERCEPTORS_MERAKI,
  HttpClientMeraki,
  MERAKI_CACHE_LB_PROVIDERS,
  MERAKI_NEXUS_PROVIDERS,
} from './services/meraki-nexus.providers';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';

const coreMaterialComponents = [
  MatButtonModule,
  MatChipsModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatSidenavModule,
  MatDividerModule,
  MatListModule,
  MatIconModule,
  MatDialogModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatSnackBarModule,
  NgxMaterialTimepickerModule,
  MatTabsModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [
    StickyHeaderDirective,
    MenuComponent,
    LogoutConfirmationDialogComponent,
    HeaderComponent,
    StickyComponent,
    ResolveHomeComponent,
    SideNavComponent,
    MobileLayoutComponent,
    WebLayoutComponent,
    LoginLayoutComponent,
    GuidedTourComponent,
  ],
  entryComponents: [LogoutConfirmationDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    StateModule,
    SharedModule,
    FlexLayoutModule,
    // NgxCaptchaModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({
      duration: 250,
      scrollOffset: 75,
      interruptible: true,
      // easingLogic
    }),
    NgAisModule.forRoot(),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ...coreMaterialComponents,
  ],
  exports: [
    HttpClientModule,
    MenuComponent,
    HeaderComponent,
    StickyHeaderDirective,
    StickyComponent,
    SideNavComponent,
    NgxPageScrollModule,
    MobileLayoutComponent,
    WebLayoutComponent,
    LoginLayoutComponent,
    NgxPermissionsModule,
    ...coreMaterialComponents,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl,
      multi: true,
    },
    {
      provide: CLIENT_ID,
      useValue: environment.clientId,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS_MERAKI, useClass: ProgressBarInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS_MERAKI, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS_MERAKI, useClass: LoggingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppInsightServiceErrorHandler },
    HttpClientMeraki,
    WINDOW_PROVIDERS,
    NetworkStatusService,
    VersionService,
    AuthService,
    SignalRService,
    // OneSignalService,
    ApplicationInsightsService,
    ProviderDataResolverService,
    MERAKI_NEXUS_PROVIDERS,
    MERAKI_CACHE_LB_PROVIDERS,
    SlicePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  icons = [
    ['male_gender', 'male_gender.svg'],
    ['female_gender', 'female_gender.svg'],
    ['addpatient', 'addpatient.svg'],
    ['addpatient_test', 'addpatient_test.svg'],
    ['billing', 'billing.svg'],
    ['calendar', 'calendar.svg'],
    ['close', 'close.svg'],
    ['diagnosis', 'diagnosis.svg'],
    ['examination', 'examination.svg'],
    ['healthbridge', 'healthbridge.svg'],
    ['home', 'home.svg'],
    ['inbox', 'inbox.svg'],
    ['overview', 'overview.svg'],
    ['plan', 'plan.svg'],
    ['symptoms', 'symptoms.svg'],
    ['tasks', 'tasks.svg'],
    ['pathology', 'pathology.svg'],
    ['pin', 'pin.svg'],
    ['microscope', 'microscope.svg'],
    ['covid', 'covid.svg'],
    ['medicine', 'icn_medicine.svg'],
    ['procedure', 'icn_procedure.svg'],
    ['whiteboard_stylus', 'whiteboard-stylus.svg'],
    ['whiteboard_draw', 'whiteboard_draw.svg'],
    ['line_thickness_medium', 'line_thickness_medium.svg'],
    ['line_thickness_thin', 'line_thickness_thin.svg'],
    ['line_thickness_thick', 'line_thickness_thick.svg'],
    ['resize_bottom_right', 'resize_bottom_right.svg'],
    ['waiting_room', 'waiting_room.svg'],
    ['healthbridge', 'icn_healthbridge.svg'],
    ['icn_capsules', 'icn_capsules1.svg'],
    ['icn_injection', 'icn_injection1.svg'],
    ['icn_spoon', 'icn_spoon1.svg'],
    ['icn_tablets', 'icn_tablets1.svg'],
    ['syringe_vaccine', 'syringe-vaccine.svg'],
    [],
  ];

  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,

    // these services need to be instantiated with the application
    private versionService: VersionService,
    private configService: ConfigService,
    private signalR: SignalRService,
    // private oneSignal: OneSignalService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appInsights: ApplicationInsightsService,
    private offlineService: OfflineService,
    private authService: AuthService
  ) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule.');
    }
    this.initialiseAppInsights();
    this.registerCustomIcons();
  }

  initialiseAppInsights() {
    this.appInsights.config = {
      instrumentationKey: this.configService.config.applicationInsightsKey,
      autoTrackPageVisitTime: true,
    };
    this.appInsights.init();
  }

  registerCustomIcons() {
    this.icons.forEach(svgIcon => {
      this.matIconRegistry.addSvgIcon(svgIcon[0], this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/' + svgIcon[1]));
    });
  }
}
