import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { ProviderConfigurationVo } from 'api-clinician-app';

export interface ProvidersState extends EntityState<ProviderConfigurationVo> {}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'providers', idKey: 'PracticeId', resettable: true })
export class ProvidersStore extends EntityStore<ProvidersState, ProviderConfigurationVo> {
  constructor() {
    super();
  }
}
