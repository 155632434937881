import { Injectable } from '@angular/core';
import { ProviderService, AuthService } from '../services';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { AuthQuery } from '../services/state/auth/auth.query';
import { ProviderQuery } from '../services/state/provider/provider.query';
import { ProviderConfigurationVo } from 'api-clinician-app';
import { map, tap, switchMap, take, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { ProviderStore } from '../services/state/provider/provider.store';
import { AppInsightTags, ApplicationInsightsService } from '../services/application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderDataResolverService {
  constructor(
    private providerService: ProviderService,
    private router: Router,
    private authQuery: AuthQuery,
    private providerQuery: ProviderQuery,
    private authService: AuthService,
    private providerStore: ProviderStore,
    private appInsights: ApplicationInsightsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProviderConfigurationVo> {
    const navTenantId = route.paramMap.get('tenantId');
    if (!navTenantId) {
      return of(null);
    }

    return combineLatest([this.providerQuery.select().pipe(take(1)), this.authQuery.select().pipe(take(1))]).pipe(
      take(1),
      switchMap(([provider, auth]) => {
        const currentTenantId = auth.currentTenantId;
        const currentDate = moment();

        if (currentTenantId !== navTenantId) {
          if (auth.superAdmin) {
            this.appInsights.trackEvent(AppInsightTags.PROVIDER_IMPERSONATION, { targetProviderId: navTenantId });

            this.authService.setCurrentTenantId(navTenantId);
            return this.providerService.getProvider(navTenantId).pipe(
              tap({ next: () => this.providerStore.update({ lastChecked: currentDate.toDate() }) }) // on success only
            );
          } else {
            const err = 'Attempt by non super user to switch contexts';
            console.error(err);
            this.appInsights.trackException(new Error(err), 'ProviderDataResolverService.resolve()', { targetProviderId: navTenantId });

            return of(null);
          }
        } else {
          if (!provider.lastChecked || moment(provider.lastChecked).diff(currentDate, 'minutes') > 5) {
            return this.providerService.getProvider(currentTenantId).pipe(
              tap({ next: () => this.providerStore.update({ lastChecked: currentDate.toDate() }) }) // on success only
            );
          } else {
            return of(provider.details);
          }
        }
      })
    );
  }
}
