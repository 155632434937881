import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { PowerBIReport } from 'api-clinician-app';

export interface ReportsState extends EntityState<PowerBIReport> {}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'reports', idKey: 'reportId', resettable: true })
export class ReportsStore extends EntityStore<ReportsState, PowerBIReport> {
  constructor() {
    super();
  }
}
