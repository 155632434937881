import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { enableAkitaProdMode } from '@datorama/akita';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupStorage, setupAkitaState } from '@app/core/functions/storage-helpers';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

// setupAkitaState();

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

// defineCustomElements(window);

setupStorage().subscribe({
  next: () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));

    defineCustomElements(window);
  },
});
