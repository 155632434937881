import { Injectable } from '@angular/core';
import { DeletedEncounterEntry } from '@app/modules/patient/patient-dashboard/components/timeline/timeline.component';
import { action, applyTransaction, arrayAdd, arrayRemove, arrayUpdate, arrayUpsert, withTransaction } from '@datorama/akita';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import * as guidByString from 'uuid-by-string';
import * as uuidV4 from 'uuid/v4';
import { PatientDashboardHistoryEventDto } from '.';
import {
  AccountDetailsVo,
  AccountInfoVo,
  ClinicalMetricVo,
  ClinicalNewClient,
  ClinicalPatientClient,
  DiagnosisVo2,
  EncounterStatusVo,
  HealthIntegrationClient,
  MedicalCertificate,
  MedicationConditionVo,
  MedicationPrescriptionLine,
  MedicineInteractionVo,
  MyMpsClient,
  PatientAllergiesVo,
  PatientChronicConditionsVo,
  PatientChronicConditionVo,
  PatientClient,
  PatientClinicalDetailsVo,
  PatientClinicalNotesVo,
  PatientConfigurationVo,
  PatientDashboardVo,
  PatientDocumentClient,
  PatientDocumentVo,
  PatientFileGenericInformationVo,
  PatientLifestyleVo,
  PatientSurgicalHistoryVo,
  PatientVo,
  RestApiResultOfBoolean,
  SymptomQuestionAnswer,
  TriggerPatientCommunication,
  EncounterVo,
  ProviderConfigurationVo,
  SendEncounterRequireFollowupPatientSms,
} from './api-client.service';
import { ConfigService } from './config.service';
import { DocumentUploadClient } from './document-upload.client';
import { MerakiClientService, VaccinatedStatusVo } from './meraki-client.service';
import { ProviderService } from './provider.service';
import { IClinicalMetricValue, ReferenceDataService } from './reference-data.service';
import { ClinicalEncountersStore } from './state/clinical-encounter/clinical-encounter.store';
import { AvatarsQuery } from './state/mypatientfiles/avatars.query';
import { PatientsQuery } from './state/patients/patients.query';
import { ConfigurationKey, PatientsStore, PatientStateModel, PatientStateUIModel } from './state/patients/patients.store';
import { PatientFinancialsViewModel } from './view-models/patient-financials-view-model';
import MeiliSearch from 'meilisearch';
import { Account, ACCOUNT_TYPE } from './meraki-models/account.models';
import { AccountMember, IDENTIFICATION_TYPE } from './meraki-models/member.models';
import { PatientAccountIndex } from './meraki-models/index.models';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';
import { AlgoliaRestService } from './algolia-rest.service';
import { MerakiLookupService } from './meraki/meraki-lookup.service';
import { MerakiEncounterService } from './meraki/meraki-encounter.service';
import { MerakiPatientService } from './meraki/meraki-patient.service';

export enum TimelineEventType {
  PrescriptionLineType = 'PrescriptionLine',
  PrescriptionRxNoteType = 'RxNote',
  AdhocPrescriptionLineType = 'AdhocPrescriptionLine',
  MedicalCertificateType = 'MedicalCertificate',
  PathologyTestType = 'PathologyTest',
  PathologyOrdersType = 'PathologyOrders',
  DiagnosisType = 'Diagnosis',
  ClinicalNoteType = 'ClinicalNote',
  TrackingConditionTypeType = 'TrackingConditionType',
  ProviderNoteType = 'ProviderNote',
  ConsultReasonType = 'ConsultReason',
  PatientFileNoteType = 'PatientFileNote',
  CommunicationType = 'Communication',
  SymptomsType = 'Symptoms',
  PlanOutcomeType = 'PlanOutcome',
  FollowUpType = 'FollowUp',
  VisitFollowUpSmsSentType = 'VisitFollowUpSmsSent',
  PatientSmsSentType = 'PatientSmsSent',
  PatientEmailSentType = 'PatientEmailSent',
  PathResultsFollowUpSmsSentType = 'PathResultsFollowUpSmsSent',
  PathResultsNormalSmsSentType = 'PathResultsNormalSmsSent',
  ClinicalMetricType = 'ClinicalMetric',
  PatientFileType = 'PatientFile',
}

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  activePatients$ = this.patientsQuery.selectActive(entity => entity.details);
  activePatientIds$ = this.patientsQuery.selectActive(entity => entity.PatientId);
  capturedMetrics$ = this.patientsQuery.selectActive(entity => entity.historicalMetrics);
  patients$ = this.patientsQuery.selectAll();
  cacheThreshold = 60 * 1000; // 5 minutes = 5 * 60 * 1000
  private readonly meiliSearchAccountClient!: MeiliSearch;

  constructor(
    private referenceDataService: ReferenceDataService,
    private patientClient: PatientClient,
    private clinicalNewClient: ClinicalNewClient,
    private clinicalPatientClient: ClinicalPatientClient,
    private mympsClient: MyMpsClient,
    private documentClient: PatientDocumentClient,
    private documentUploadClient: DocumentUploadClient,
    private healthIntegrationClient: HealthIntegrationClient,

    private patientsStore: PatientsStore,
    private patientsQuery: PatientsQuery,

    private clinicalStore: ClinicalEncountersStore,

    private providerService: ProviderService,
    private configService: ConfigService,
    private avatarQuery: AvatarsQuery,
    private merakiPatientService: MerakiPatientService,
    private merakiClientService: MerakiClientService,
    private merakiLookupService: MerakiLookupService,
    private merakiEncounterService: MerakiEncounterService,
    private slicePipe: SlicePipe,
    private algoliaRestService: AlgoliaRestService
  ) {
    this.meiliSearchAccountClient = new MeiliSearch({
      host: configService.config.meiliSearchAccount.host,
      apiKey: configService.config.meiliSearchAccount.key,
    });
  }

  patientAccountDetailsById$(tenantId: string, patientId: string) {
    return this.patientDetailsById$(tenantId, patientId).pipe(
      withLatestFrom(this.providerService.flattenedMedicalAids$),
      map(([patient, medicalAids]) => {
        const patientAccountDetails = patient?.PatientAccountDetails || {};

        if (
          patientAccountDetails.IsCashAccount || // is cash
          !patientAccountDetails.MedicalAidRoutingCode // no routing code pretty much means cash
        ) {
          return {
            ...patientAccountDetails,
            IsCashAccount: true,
            MedicalAidName: '',
            MedicalAidSchemeCode: '',

            MedicalAidPlan: '',
            MedicalAidPlanCode: '',

            MedicalAidPlanOption: '',
            MedicalAidOptionCode: '',
          } as AccountDetailsVo;
        } else {
          const latestMedAid = medicalAids.find(medAid => medAid.RoutingCode === patientAccountDetails.MedicalAidRoutingCode);

          return !latestMedAid
            ? patientAccountDetails
            : ({
                ...patientAccountDetails,
                MedicalAidName: latestMedAid.SchemeName,
                MedicalAidSchemeCode: latestMedAid.SchemeCode,

                MedicalAidPlan: latestMedAid.PlanName,
                MedicalAidPlanCode: latestMedAid.PlanCode,

                MedicalAidPlanOption: latestMedAid.OptionName,
                MedicalAidOptionCode: latestMedAid.OptionCode,
              } as AccountDetailsVo);
        }
      })
    );
  }

  getVideoChatUrl(practiceNumber: string, treatingNumber: string, visitXref: string) {
    return `${this.configService.config.videoChatUrl}/${practiceNumber}/${treatingNumber}/${visitXref}`;
  }

  revisePatientFileGenericInformation(practiceId: string, patientId: string, genericInformation: PatientFileGenericInformationVo) {
    return (
      this.clinicalNewClient
        .revisePatientFileGenericInformation(practiceId, patientId, genericInformation)
        // todo it's too heavy call to call it everytime when we want to refresh generic info, needs refactoring
        // .pipe(switchMap(result => this.getAllPatientDetailsFromServer(practiceId, patientId).pipe(mapTo(result))));
        .pipe(tap(result => this.updatePatientGenericInfo(patientId, [genericInformation])))
    );
  }

  updatePatientFileWomensHealth(practiceId: string, patientId: string, genericInformation: PatientFileGenericInformationVo) {
    return this.merakiPatientService.updatePatientFileWomensHealth(practiceId, patientId, genericInformation);
  }

  updatePatientGenericInfoStore(tenantId: string, patientId: string, category: string, answer: SymptomQuestionAnswer) {
    this.patientsStore.update(patientId, entity => ({
      patientGenericInformation: arrayUpsert(
        entity.patientGenericInformation || [],
        category,
        {
          ...(entity.patientGenericInformation?.find(f => f.Category === category) || {
            CapturedDate: new Date(),
            PracticeId: tenantId,
            PatientId: patientId,
            Category: category,
          }),
          Answers: arrayUpsert(
            entity.patientGenericInformation?.find(f => f.Category === category)?.Answers || [],
            answer.QuestionKey,
            answer,
            'QuestionKey'
          ),
        },
        'Category'
      ),
    }));
  }

  patientMetricsById(tenantId: string, patientId: string) {
    return combineLatest([
      this.patientsQuery.selectEntity(patientId, entity => entity.dashboard?.PatientDashboardEvents || []),
      this.referenceDataService.clinicalMetrics$,
    ]).pipe(
      map(([dashboardEvents, clinicalMetrics]) => {
        const events = dashboardEvents.filter(event => event.Type.startsWith('ClinicalMetric'));
        const groupedMetrics = _.chain(events)
          .map(metric => {
            const schemaItem = clinicalMetrics
              .find(x => x.groupName === metric.Code)
              ?.items?.find(y => 'ClinicalMetric:' + y.id === metric.Type);
            return {
              ...schemaItem,
              groupName: metric.Code,
              value: metric.Value,
              valueUnit: metric.ValueUnits,
              date: metric.DateTime,
              valueFlag: metric.ValueFlag,
            } as IClinicalMetricValue;
          })
          .groupBy('id')
          .sortBy('type')
          .map(group => {
            const orderedResults = _.chain(group).sortBy('Date').reverse();
            const firstResult = orderedResults.first().value();
            return {
              allResults: orderedResults.value(),
              ...firstResult,
            };
          })
          .value();
        return groupedMetrics;
      })
    );
  }

  getPatientClinicalNotesById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.patientClinicalNotes);
  }

  patientHealthIdClickedToday$(tenantId: string, patientId: string) {
    const healthIdVisitedToday$ = this.patientsQuery
      .patientUiState$(patientId)
      .pipe(map(uiState => !!uiState.healthIdVisited && uiState.healthIdVisited.isSame(moment(), 'day')));
    return healthIdVisitedToday$;
  }

  getPatientSurgicalHistory$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.surgicalHistory);
  }

  patientDetailsById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.details);
  }

  patientById$(patientId: string) {
    return this.patientsQuery.selectEntity(patientId);
  }

  patientDashboardById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.dashboard);
  }

  getPatientWeight$(tenantId: string, patientId: string) {
    return this.patientDashboardById$(tenantId, patientId).pipe(
      map(dashboard => dashboard?.PatientDashboardEvents || []),
      map(events =>
        _.chain(events)
          .filter(x => x.Type === 'ClinicalMetric:Weight')
          .orderBy(x => x.DateTime, 'desc')
          .first()
          .value()
      )
    );
  }

  getPatientHeight$(tenantId: string, patientId: string) {
    return this.patientDashboardById$(tenantId, patientId).pipe(
      map(dashboard => dashboard?.PatientDashboardEvents || []),
      map(events =>
        _.chain(events)
          .filter(x => x.Type === 'ClinicalMetric:Height')
          .orderBy(x => x.DateTime, 'desc')
          .first()
          .value()
      )
    );
  }

  getPatientMetric$(tenantId: string, patientId: string, metricName: string) {
    return this.patientCapturedMetricsById$(tenantId, patientId).pipe(
      map(metrics =>
        _.chain(metrics)
          .filter(x => x.Name === metricName)
          .orderBy(x => x.TestDate, 'desc')
          .first()
          .value()
      )
    );
  }

  patientCapturedMetricsById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.historicalMetrics);
  }

  patientAllergiesById$(tenantId: string, patientId: string) {
    return this.patientsQuery
      .selectEntity(patientId, entity => entity.allergies)
      .pipe(
        tap(allergies => {
          if (!allergies) {
            allergies = {
              NoAllergies: false,
              Other: '',
              PatientAllergies: [],
              PatientAllergiesId: null,
            };
            this.patientsStore.upsert(patientId, { allergies });
          }
        })
      );
  }

  patientFinancialsById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.financials);
  }

  patientConditionsById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.conditions);
  }

  pinnedMedicines$(tenantId, patientId) {
    return this.patientConditionsById$(tenantId, patientId).pipe(
      distinctUntilChanged(),
      map(conditions => {
        const flatListOfPatientMeds = [
          ...(conditions?.OtherMedications || []),
          ...(conditions?.Conditions?.filter(s => s.Condition.Status !== 'Recovered').flatMap(condition => condition.Medications) || []),
        ];

        return flatListOfPatientMeds.filter(s => !!s).filter(s => s.Status !== 'discontinued');
      })
    );
  }

  patientConditionsByIdNoTracking$(tenantId: string, patientId: string) {
    return this.patientsQuery
      .selectEntity(patientId, entity => entity.conditions)
      .pipe(
        map(s => ({
          ...s,
          Conditions: this.filterNoTrackingConditions(s?.Conditions),
        }))
      );
  }

  filterNoTrackingConditions(conditions: PatientChronicConditionVo[]) {
    return (
      conditions
        ?.filter(d => !this.referenceDataService.allTrackingConditionCodes.includes(d.Condition.DiagnosisCode))
        .filter(d => d.Condition.Status !== 'Recovered') || []
    );
  }

  patientDocumentsById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.documents);
  }

  avatarById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.avatar);
  }

  patientFamilyMembersById$(tenantId: string, patientId: string) {
    const patientObservable = this.patientsQuery.selectEntity(patientId);
    const memberObservable = patientObservable.pipe(
      mergeMap(patient => {
        const patients = this.patientsQuery
          .selectAll({
            filterBy: entity =>
              entity &&
              entity.details &&
              entity.details.PatientAccountDetails && // make sure entity hierarchy exists...
              entity.details.PatientAccountDetails.AccountNo === patient.details.PatientAccountDetails.AccountNo &&
              entity.PatientId !== patient.PatientId, // obviously not the same reference person either
          })
          .pipe(map(pmay => pmay.map(entity => entity.details)));
        return patients;
      })
    );
    return memberObservable;
  }

  patientLifestyleById$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.lifestyle);
  }

  patientIsHealthIdById$(tenantId: string, patientId: string) {
    return combineLatest([
      this.patientDetailsById$(tenantId, patientId),
      this.referenceDataService.healthIDSSupportedSchemes$.pipe(take(1)),
    ]).pipe(
      map(([patient, healthIdSchemes]) => {
        return (
          patient &&
          patient.PatientAccountDetails &&
          !patient.PatientAccountDetails.IsCashAccount &&
          healthIdSchemes.includes(patient.PatientAccountDetails.MedicalAidName)
        );
      })
    );
  }

  showHealthIdReminder$(tenantId: string, patientId: string) {
    return combineLatest([
      this.patientIsHealthIdById$(tenantId, patientId),
      this.patientHealthIdClickedToday$(tenantId, patientId),
      this.providerService.isHealthIdPromptEnabled$,
    ]).pipe(
      map(([isHealthId, clickedToday, isHealthIdPromptEnabled]) => {
        return isHealthId && !clickedToday && isHealthIdPromptEnabled;
      })
    );
  }

  medicineInteractions$(tenantId: string, patientId: string) {
    return this.patientsQuery.selectEntity(patientId, entity => entity.medicineInteractions);
  }

  /*
  Temporarily adds to state store, need to explicitly call Update() to persist
  */
  @action('addPatient')
  public addPatient(patient: PatientVo) {
    this.patientsStore.upsert(patient.PatientId, { details: patient, conditions: { Id: uuidV4(), Conditions: [], OtherMedications: [] } });
  }

  public loadPatientInfo(tenantId: string, patientId: string) {
    return this.providerService.isForge$.pipe(
      take(1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([isForge, provider]) =>
        isForge
          ? this.getFullPatientFromForge(provider.PracticeNumber, patientId).pipe(map(s => s?.details))
          : this.patientClient.get(provider.PracticeId, patientId).pipe(map(s => s || {}))
      )
    );
  }
  /*
  Get just the high level patient data from the server (PatientVo)
  */
  @action('getPatient')
  public getPatient(tenantId: string, patientId: string) {
    const request = combineLatest([this.patientClient.get(tenantId, patientId), this.providerService.flattenedMedicalAids$]).pipe(
      map(([patient, medicalAids]) => {
        // ***** enrich patient details with medical aid data
        const routingCode = patient && patient.PatientAccountDetails && patient.PatientAccountDetails.MedicalAidRoutingCode;
        if (!routingCode) {
          return patient;
        } // no routing code, don't even try to find the spo

        const spo = !!routingCode ? medicalAids.find(m => m.RoutingCode === routingCode) : null;
        // no matching spo found, nothing we can do, return back what we have as-is
        if (!spo || !spo.SchemeCode || !spo.PlanCode || !spo.OptionCode) {
          return patient;
        }

        if (
          patient.PatientAccountDetails.MedicalAidSchemeCode === spo.SchemeCode &&
          patient.PatientAccountDetails.MedicalAidPlanCode === spo.PlanCode &&
          patient.PatientAccountDetails.MedicalAidOptionCode === spo.OptionCode
        ) {
          // data is all the same, return normal patient
          return patient;
        }

        // data doesn't match, build new patient object to return
        const newPatient: PatientVo = {
          ..._.cloneDeep(patient),
          PatientAccountDetails: {
            ..._.cloneDeep(patient.PatientAccountDetails),
            MedicalAidSchemeCode: spo.SchemeCode,
            MedicalAidName: spo.SchemeName,
            MedicalAidPlanCode: spo.PlanCode,
            MedicalAidPlan: spo.PlanName,
            MedicalAidOptionCode: spo.OptionCode,
            MedicalAidPlanOption: spo.OptionName,
          },
        };
        // console.warn('getPatient - enriching patient details, new:', newPatient);
        return newPatient;
      })
    );

    return request.pipe(tap(patient => this.patientsStore.upsert(patientId, { PatientId: patientId, details: patient })));
  }

  @action('getPatient')
  public getPatientWithUpdatedSpo(tenantId: string, patientId: string, patient: PatientVo) {
    const request = this.providerService.flattenedMedicalAids$.pipe(map(medicalAids => this.appendMedicalAidInfo(patient, medicalAids)));

    return request.pipe(
      take(1),
      tap(p => this.updatePatientLocal(patientId, p))
    );
  }

  appendMedicalAidInfo(patient: PatientVo, medicalAids): PatientVo {
    // ***** enrich patient details with medical aid data
    const routingCode = patient && patient.PatientAccountDetails && patient.PatientAccountDetails.MedicalAidRoutingCode;
    const optionCode = patient && patient.PatientAccountDetails && patient.PatientAccountDetails.MedicalAidOptionCode;
    if (!routingCode && !optionCode) {
      return patient;
    } // no routing/option code, don't even try to find the spo

    let spo = !!routingCode ? medicalAids.find(m => m.RoutingCode === routingCode) : null;
    spo = spo == null ? medicalAids.find(m => m.OptionCode === optionCode) : spo;

    // no matching spo found, nothing we can do, return back what we have as-is
    if (!spo || !spo.SchemeCode || !spo.PlanCode || !spo.OptionCode) {
      return patient;
    }

    if (
      patient.PatientAccountDetails.MedicalAidSchemeCode === spo.SchemeCode &&
      patient.PatientAccountDetails.MedicalAidPlanCode === spo.PlanCode &&
      patient.PatientAccountDetails.MedicalAidOptionCode === spo.OptionCode
    ) {
      // data is all the same, return normal patient
      return patient;
    }

    // data doesn't match, build new patient object to return
    const newPatient: PatientVo = {
      ..._.cloneDeep(patient),
      PatientAccountDetails: {
        ..._.cloneDeep(patient.PatientAccountDetails),
        MedicalAidSchemeCode: spo.SchemeCode,
        MedicalAidName: spo.SchemeName,
        MedicalAidPlanCode: spo.PlanCode,
        MedicalAidPlan: spo.PlanName,
        MedicalAidOptionCode: spo.OptionCode || null,
        MedicalAidPlanOption: spo.OptionName,
        MedicalAidRoutingCode: patient.PatientAccountDetails.MedicalAidRoutingCode ?? spo.RoutingCode ?? null,
      },
    };
    // console.warn('getPatient - enriching patient details, new:', newPatient);
    return newPatient;
  }

  updatePatientLocal(patientId: string, p: PatientVo): void {
    return this.patientsStore.upsert(patientId, { PatientId: patientId, PatientXRef: p?.PatientXRef, details: p });
  }

  /*
  Get all patient data, including clinical data
  */
  @action('getAllPatientDetailsFromServer')
  private getAllPatientDetailsFromServer(
    tenantId: string,
    patientId: string,
    practiceTenantId: string
  ): Observable<PatientClinicalDetailsVo> {
    return this.providerService.isForge$.pipe(
      take(1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([isForge, provider]) =>
        isForge
          ? this.getFullPatientFromForge(provider.PracticeNumber, patientId).pipe(
              switchMap(s =>
                s
                  ? this.getPatientWithUpdatedSpo(tenantId, patientId, s?.details).pipe(
                      switchMap(updatedSpo =>
                        this.getForgePatientsByAccount(provider.PracticeNumber, updatedSpo.PatientAccountDetails).pipe(
                          map(
                            members =>
                              ({
                                FamilyMembers: members.filter(p => p.PatientId != patientId),
                                MembersXRefs: s.MembersXRefs,
                                Details: {
                                  ...s.details,
                                  PracticeId: provider.PracticeTenantId,
                                },
                              } as PatientClinicalDetailsVo)
                          )
                        )
                      )
                    )
                  : of(null)
              )
            )
          : this.clinicalNewClient.getAllPatientClinicalInfo(tenantId, patientId).pipe(map(data => data.Data))
      ),
      // improved performance by skipping one server call (still looks ugly due to legacy SPO enriching logic which left untouched)
      switchMap(data =>
        combineLatest([
          this.getActiveEncounter(practiceTenantId, tenantId, patientId, data.ActiveEncounter),
          this.getPatientWithUpdatedSpo(tenantId, patientId, data.Details),
          this.getPatientConfigurations(practiceTenantId, patientId),
          this.getPatientWomensHealth(practiceTenantId, patientId, data.Details),
          this.getPatientLifestyles(practiceTenantId, patientId),
          this.getPatientSurgicalHistory(practiceTenantId, patientId),
          this.getPatientAllergies(practiceTenantId, patientId),
          this.getPatientChronicConditions(practiceTenantId, patientId),
          this.merakiPatientService.getAllPatientClinicalMetrics(practiceTenantId, patientId),
        ]).pipe(
          // note: don't change params ordering, below encounter used by index [0]
          withTransaction(
            ([encounter, patient, config, women, lifestyle, surgical, allergies, conditions, metrics]: [
              EncounterStatusVo,
              PatientVo,
              PatientConfigurationVo,
              PatientFileGenericInformationVo,
              PatientLifestyleVo,
              PatientSurgicalHistoryVo,
              PatientAllergiesVo,
              PatientChronicConditionsVo,
              ClinicalMetricVo[]
            ]) => {
              // (AD) do in one place to update store in one transaction and avoid concurrency issue
              // (individual parallel updates conflicts and override each other)
              this.updatePatientLocal(patientId, patient);
              this.updatePatientConfiguration(patientId, config);
              if (women) {
                this.updatePatientGenericInfo(patientId, [women]);
              }
              this.updateLifestyleLocal(patientId, lifestyle);
              this.updateSurgicalHistoryLocal('', patientId, surgical);
              this.updateAllergiesLocal(patientId, allergies);
              this.updateConditionsLocal(patientId, conditions);

              this.updateLastUpdatedLocal(patientId, data.LastUpdated);
              this.updateAvatarLocal(patientId, data.Avatar);
              this.updateDocumentsLocal(patientId, data.Documents);
              this.updateMetricsLocal(patientId, metrics);
            }
          ),
          // use encounter from firestore and from florence as fallback (if nothing found in firestore)
          map(s => ({ ...data, ActiveEncounter: s[0] }))
        )
      ),
      withLatestFrom(this.patients$),
      tap(([s, patientState]) => {
        applyTransaction(() => {
          // this.updateLastUpdatedLocal(patientId, s.LastUpdated);
          // this.updateAllergiesLocal(patientId, s.Allergies);
          // this.updateConditionsLocal(patientId, s.ChronicConditions);
          // this.updateAvatarLocal(patientId, s.Avatar);
          // this.updateDocumentsLocal(patientId, s.Documents);
          // this.updateLifestyleLocal(patientId, s.Lifestyle);
          // this.updateSurgicalHistoryLocal(tenantId, patientId, s.SurgicalHistory);
          this.updateFamilyMembersLocal(patientState, s.FamilyMembers);
          // this.updatePatientGenericInfo(patientId, s.PatientGenericInformation);
          // this.updatePatientConfiguration(patientId, s.PatientConfiguration);
        });
      }),
      map(([allPatientData, patientState]) => allPatientData) // please note: ActiveEncounter & Details field still used outside of method
    );
  }

  loadExternalMympsData(tenantId: string, patientId: string) {
    return this.mympsClient
      .loadEncountersOnTimeline(tenantId, patientId)
      .pipe(switchMap(s => this.getPatientDashboard(tenantId, patientId)));
  }

  loadExternalNovaData(tenantId: string, patientId: string) {
    return this.providerService.provider$.pipe(
      take(1),
      withLatestFrom(this.patientById$(patientId)),
      switchMap(([provider, patient]) => this.merakiPatientService.loadCompletedInvoices(provider.PracticeNumber, patient.PatientXRef)),
      withLatestFrom(this.patientDashboardById$(tenantId, patientId)),
      map(([invoices, dashboard]) => {
        if (!dashboard) {
          return [];
        }

        var billingLineTypes = ['Procedure', 'Medicine', 'Diagnosis', 'Consumable', 'Modifier'];
        var billingLines = dashboard.PatientDashboardEvents.filter(p => billingLineTypes.includes(p.Type));

        var timelineInvoices = _.chain(billingLines)
          .groupBy(p => p.ParentReferenceId)
          .map((value, key) => {
            try {
              var date = moment(value[0].DateTime, false).startOf('day').format('DD-MM-yyyy');
              return { EncounterId: key, Lines: value, DateOfService: date };
            } catch (err) {
              console.error('parsing issue for date', value, key, err);
            }
          })
          .value();

        var uniqueNovaInvoices = invoices
          // ignore prev imported invoices filterm them by Id
          .filter(l => !timelineInvoices.map(s => s.EncounterId).includes(guidByString(l.Id, 5)));

        var diagnosisLines = uniqueNovaInvoices
          .flatMap(invoice =>
            invoice.HeaderDiagnosisCodes?.map(
              d =>
                ({
                  Type: 'Diagnosis',
                  DateTime: invoice.DateOfService,
                  Code: d,
                  Description: '', // todo
                  ParentReferenceId: guidByString(invoice.Id, 5),
                  PatientId: patientId,
                  ParentDescription: invoice.TreatingProvider?.FullName,
                } as PatientDashboardHistoryEventDto)
            )
          )
          .filter(s => !!s);

        var timelineEntries = uniqueNovaInvoices
          .flatMap(invoice =>
            invoice.Lines
              // ctsm_Medicine/ctsm_Consumable/Admin line type will be ignored as we doesn't support them,
              //comment out this line and add new type support on timeline component
              .filter(line => billingLineTypes.includes(line.LineType))
              .map(
                d =>
                  ({
                    Type: d.LineType,
                    DateTime: invoice.DateOfService,
                    Code: d.LineType == 'Procedure' ? d.TariffCode : d.NappiCode,
                    ParentReferenceId: guidByString(invoice.Id, 5),
                    Description: d.Description,
                    PatientId: patientId,
                    Narrative: `${d.TariffCode} ${d.Description}`,
                    ParentLineNum: d.LineNumber,
                    ParentDescription: invoice.TreatingProvider?.FullName,
                  } as PatientDashboardHistoryEventDto)
              )
          )
          .concat(diagnosisLines);

        return timelineEntries.filter(s => !!s);
      }),
      switchMap(async entries => {
        const diagnosisCodes = _.chain(entries.filter(e => e.Type == 'Diagnosis'))
          .uniqBy(s => s.Code)
          .value();
        if (diagnosisCodes.length == 0) {
          return entries;
        }
        var response = await Promise.all(diagnosisCodes.map(e => this.algoliaRestService.getDiagnosis(e.Code)));
        return entries.map(e => ({ ...e, Description: response.find(s => s.ICD10Code == e.Code)?.ICD10CodeDescription }));
      }),
      withLatestFrom(this.providerService.provider$),
      switchMap(
        ([entries, provider]) =>
          (entries.length > 0 && this.clinicalNewClient.addPatientTimelineEntries(provider.PracticeId, patientId, entries)) || of(null)
      ),
      switchMap(s => this.getPatientDashboard(tenantId, patientId))
    );
  }

  getPatientSurgicalHistory(practiceTenantId: string, patientId: string) {
    return this.merakiPatientService
      .getPatientSurgicalHistory(practiceTenantId, patientId)
      .pipe(tap(surgicalHistory => this.updateSurgicalHistoryLocal('', patientId, surgicalHistory)));
  }

  updatePatientSurgicalHistory(practiceTenantId: string, patientId: string, surgicalHistory: PatientSurgicalHistoryVo) {
    return this.merakiPatientService.updatePatientSurgicalHistory(practiceTenantId, patientId, surgicalHistory);
  }

  getPatientConfigurations(practiceTenantId: string, patientId: string) {
    return this.merakiPatientService
      .getPatientConfigurations(practiceTenantId, patientId)
      .pipe(tap(config => this.updatePatientConfiguration(patientId, config)));
  }

  getPatientLifestyles(practiceTenantId: string, patientId: string) {
    return this.merakiPatientService
      .getPatientLifestyle(practiceTenantId, patientId)
      .pipe(tap(lifestyle => this.updateLifestyleLocal(patientId, lifestyle)));
  }

  updatePatientLifestyles(practiceTenantId: string, patientId: string, lifestyle: PatientLifestyleVo) {
    this.updateLifestyleLocal(patientId, lifestyle);
    return this.merakiPatientService.updatePatientLifestyle(practiceTenantId, patientId, lifestyle);
  }

  addPatientTimelineEventsForEncounter(practiceId: string, patientId: string, encounter: EncounterVo) {
    var timelineEntries = this.getPatientEventsFromEncounter(patientId, encounter);
    return this.clinicalNewClient.addPatientTimelineEntries(practiceId, patientId, timelineEntries);
  }

  getPatientEventsFromEncounter(patientId: string, encounter: EncounterVo): PatientDashboardHistoryEventDto[] {
    let encounterParentLineNumber = 0;
    let eventList: PatientDashboardHistoryEventDto[] = [];
    eventList.push(
      ...(encounter.EncounterLineItems.LineItems?.map(l => ({
        Type: l.LineType ?? '',
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Code: l.LineType == 'Procedure' ? l.ChargeCode : l.NappiCode,
        ParentReferenceId: encounter.EncounterId,
        Description: l.ChargeDesc,
        PatientId: patientId,
        Narrative: `${l.ChargeCode} ${this.slicePipe.transform(l.ChargeDesc, 0, 60)}`,
        ParentLineNum: encounterParentLineNumber++,
      })) ?? [])
    );
    eventList.push(
      ...(encounter.EncounterLineItems.EncounterHeader.Diagnosis?.map(d => ({
        Type: TimelineEventType.DiagnosisType,
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Code: d.DiagnosisCode,
        Description: d.DiagnosisDescription,
        ParentReferenceId: encounter.EncounterId,
        PatientId: patientId,
        Narrative: `${d.DiagnosisCode} ${d.DiagnosisDescription}`,
        ParentLineNum: encounterParentLineNumber++,
      })) ?? [])
    );

    if (encounter.ClinicalNote != null) {
      eventList.push({
        Type: TimelineEventType.ClinicalNoteType,
        Description: encounter.ClinicalNote,
        ParentReferenceId: encounter.EncounterId,
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Narrative: 'Clinical note: ' + encounter.ClinicalNote,
        PatientId: patientId,
        ParentLineNum: encounterParentLineNumber++,
      });
    }

    if (encounter.MedicalCertificate != null) {
      //sick note
      eventList.push({
        Type: TimelineEventType.MedicalCertificateType,
        ParentReferenceId: encounter.EncounterId,
        Description: '',
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Narrative:
          `Medical Certificate: ${encounter.MedicalCertificate.Diagnoses.map(d => d.DiagnosisCode).join(', ')} ` +
          `(${encounter.MedicalCertificate.FromDate.getFullYear()}.${
            encounter.MedicalCertificate.FromDate.getMonth() + 1
          }.${encounter.MedicalCertificate.FromDate.getDate()} - ${encounter.MedicalCertificate.ToDate.getFullYear()}.${
            encounter.MedicalCertificate.ToDate.getMonth() + 1
          }.${encounter.MedicalCertificate.ToDate.getDate()})`,
        PatientId: patientId,
        ParentLineNum: encounterParentLineNumber++,
      });
    }

    if (encounter.MedicationsPrescriptions != null) {
      for (let prescription of encounter.MedicationsPrescriptions) {
        //prescription line
        eventList.push(
          ...prescription.MedicationPrescriptionLines.map(l => ({
            Type: TimelineEventType.PrescriptionLineType,
            ParentReferenceId: encounter.EncounterId,
            Code: l.NappiCode,
            Description: prescription.MedicationPrescriptionId.toString(),
            DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
            Narrative: `℞: ${l.Description}`,
            PatientId: patientId,
            ParentLineNum: encounterParentLineNumber++,
          }))
        );
        if (!!prescription.AdditionalNotes) {
          eventList.push({
            Type: TimelineEventType.PrescriptionRxNoteType,
            ParentReferenceId: encounter.EncounterId,
            Code: '',
            Description: prescription.MedicationPrescriptionId.toString(),
            DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
            Narrative: prescription.AdditionalNotes,
            PatientId: patientId,
            ParentLineNum: encounterParentLineNumber++,
          });
        }
      }
    }
    // clinical metrics now added separately on timeline
    /* if (encounter.ClinicalMetrics?.length > 0) {
      eventList.push(...encounter.ClinicalMetrics.sort((a, b) => a.Group.localeCompare(b.Group) || a.Name.localeCompare(b.Name))
        .map((c, index) => this.clinicalMetricToTimelineEntry(c, index, patientId, encounter.Provider)));
    } */

    if (encounter.EncounterFollowUp?.FollowUpReasons?.length > 0) {
      //followup
      eventList.push({
        Type: TimelineEventType.FollowUpType,
        ParentReferenceId: encounter.EncounterId,
        ParentLineNum: encounterParentLineNumber++,
        Description: '',
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Narrative:
          `Follow-up on ${encounter.EncounterFollowUp.DueDateForFollowUp.getFullYear()}-${
            encounter.EncounterFollowUp.DueDateForFollowUp.getMonth() + 1
          }-${encounter.EncounterFollowUp.DueDateForFollowUp.getDate()} ` +
          `for: ${encounter.EncounterFollowUp.FollowUpReasons.join(', ')}`,
        PatientId: patientId,
      });
    }

    if (!!encounter.PatientEventDetails?.ProviderNote) {
      eventList.push({
        Type: TimelineEventType.ProviderNoteType,
        Description: encounter.PatientEventDetails.ProviderNote,
        ParentReferenceId: encounter.PatientEventDetails.PatientEventId,
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        Narrative: 'Note from admin: ' + encounter.PatientEventDetails.ProviderNote,
        PatientId: patientId,
        ParentLineNum: 0,
      });
    }

    if (encounter.Communications?.length > 0) {
      let lineNumber = 0;
      eventList.push(
        ...encounter.Communications.map(c => ({
          Type: TimelineEventType.CommunicationType,
          ParentReferenceId: c.CommunicationId,
          Code: c.Type ?? '',
          Description: '',
          DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
          Narrative: c.Title,
          PatientId: patientId,
          ParentLineNum: lineNumber++,
        }))
      );
    }

    if (encounter.Symptoms?.length > 0) {
      let symptoms = {
        Symptoms: encounter.Symptoms,
        SymptomQuestions: encounter.SymptomQuestions,
        SymptomQuestionAnswers: encounter.SymptomQuestionAnswers,
      };
      eventList.push({
        Type: TimelineEventType.SymptomsType,
        ParentReferenceId: encounter.EncounterId,
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        PatientId: patientId,
        Narrative: JSON.stringify(symptoms),
        Code: '',
      });
    }

    if (encounter.EncounterPlanOutcomes?.length > 0) {
      let lineNumber = 0;
      eventList.push(
        ...encounter.EncounterPlanOutcomes.map(c => ({
          Type: TimelineEventType.PlanOutcomeType,
          DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
          Code: c.Type,
          ParentReferenceId: encounter.EncounterId,
          Description: '',
          Narrative: c.Title,
          PatientId: patientId,
          ParentLineNum: lineNumber++,
        }))
      );
    }

    // for consult reasons
    if (!!encounter.ConsultReason) {
      eventList.push({
        Type: TimelineEventType.ConsultReasonType,
        DateTime: encounter.EncounterLineItems.EncounterHeader.DateOfService,
        ParentReferenceId: encounter.EncounterId,
        PatientId: patientId,
        Description: encounter.EncounterLineItems.EncounterHeader.Ward,
        Narrative: encounter.ConsultReason,
        ValueFlag: encounter.EncounterLineItems.EncounterHeader.PlaceOfService.toString() ?? '',
        ParentLineNum: 0,
      });
    }

    //set treating doctor name
    if (!!encounter.Provider?.TreatingDoctorName) {
      eventList.forEach(a => (a.ParentDescription = encounter.Provider.TreatingDoctorName));
    }

    return eventList;
  }

  clinicalMetricToTimelineEntry(
    c: ClinicalMetricVo,
    index: number,
    patientId: string,
    provider: ProviderConfigurationVo
  ): PatientDashboardHistoryEventDto {
    return {
      Type: `ClinicalMetric:${c.Name}`,
      ParentReferenceId: c.ReferenceId,
      ParentLineNum: index,
      Code: c.Group || '',
      DateTime: c.TestDate,
      PatientId: patientId,
      Value: c.Value,
      ValueUnits: c.Unit || '',
      ValueFlag: c.Type || '',
      Description: this.formatClinicalMetricDescription(c),
      Narrative:
        c.Group != 'General'
          ? `${c.Group} - ${c.Name}: ${this.formatClinicalMetricDescription(c)}`
          : `${c.Name}: ${this.formatClinicalMetricDescription(c)}`,
      ParentDescription: provider.TreatingDoctorName,
    };
  }

  formatClinicalMetricDescription(c: ClinicalMetricVo): string {
    return (c.Value ? `${c.Type || ''} ${c.Value || ''} ${c.Unit || ''}` : `${c.Type || ''} ${c.Interpretation || ''} `).trim();
  }

  getPatientClinicalNotes(tenantId: string, patientId: string): Observable<PatientClinicalNotesVo> {
    const request = this.merakiPatientService.getPatientClinicalNotes(tenantId, patientId);
    return request.pipe(
      tap(patientClinicalNotes => {
        if (patientClinicalNotes === null) {
          patientClinicalNotes = {
            PatientClinicalNotesId: patientId,
            Note: '',
          } as PatientClinicalNotesVo;
        }
        this.patientsStore.update(patientId, { patientClinicalNotes });
      })
    );
  }

  /*
  Get all patient data, including clinical data
  */
  @action('getPatientForOffline')
  public getPatientForOffline(tenantId: string, patientId: string): Observable<PatientVo> {
    const patientFinancialsObs$ = this.providerService.isMymps$.pipe(
      switchMap(isMymps => (!!isMymps ? this.getPatientFinancials(tenantId, patientId) : of(null as AccountInfoVo))),
      take(1)
    );

    const observables = this.getPatient(tenantId, patientId).pipe(
      switchMap(patient =>
        forkJoin([
          this.getPatientDashboard(tenantId, patientId),
          this.getPatientAllergies(tenantId, patientId),
          this.getPatientDocuments(tenantId, patientId),
          this.getPatientLifestyle(tenantId, patientId),
          this.getPatientClinicalNotes(tenantId, patientId),
          patientFinancialsObs$,
        ]).pipe(take(1), mapTo(patient))
      ),
      take(1)
    );

    return observables.pipe(map(obs => obs as PatientVo));
  }

  /*
   * Get all patient data, including clinical data
   */
  @action('getAllPatientDetails')
  public getAllPatientDetails(tenantId: string, patientId: string, practiceTenantId: string) {
    return this.patientLastUpdated$(tenantId, patientId).pipe(
      // withLatestFrom(this.patientLastUpdatedQuery.selectEntity(patientId, d => d.LastUpdated)),
      take(1),
      withLatestFrom(this.providerService.isForge$),
      switchMap(([lastUpdated, isForge]) => {
        if (lastUpdated.HasChanged || isForge) {
          // server is newer!
          return this.getAllPatientDetailsFromServer(tenantId, patientId, practiceTenantId).pipe(
            tap(s => s.ActiveEncounter && this.clinicalStore.addEncounter(patientId, s.ActiveEncounter)),
            map(s => s.Details)
          );
          // .pipe(map(() => ({ fresh: true, cacheDate: new Date() })));
        } else {
          // return cached version instead
          // return of({ fresh: false, cacheDate: lastUpdated.LastUpdated });
          return this.patientDetailsById$(tenantId, patientId);
        }
      })
    );
  }

  @action('getPatientLastUpdated')
  private getPatientLastUpdated(tenantId: string, patientId: string) {
    const request = this.clinicalNewClient.getPatientLastChangeTime(tenantId, patientId);
    return request.pipe(
      map(r => r.Data),
      tap(lastUpd => {
        this.updateLastUpdatedLocal(patientId, lastUpd);
      })
    );
  }

  private updateLastUpdatedLocal(patientId: string, lastUpd: Date) {
    this.patientsStore.update(patientId, { lastChecked: new Date(), lastUpdated: lastUpd });
  }

  @action('getPatientFinancials')
  public getPatientFinancials(tenantId: string, patientId: string) {
    if (!patientId) {
      return of(null);
    }

    return this.providerService.isForge$.pipe(
      take(1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([isForge, provider]) =>
        isForge
          ? this.getAccountFinancialInformation(provider.PracticeNumber, patientId)
          : this.mympsClient.getAccountFinancialInformation(tenantId, patientId).pipe(timeout(5000))
      ),
      catchError((err, caught) => {
        console.error(err);
        return of(null);
      }),
      tap(financials => {
        if (financials) {
          this.patientsStore.update(patientId, { financials: financials || {} });

          if (!financials.LastBcContractData) {
            return;
          }

          let response = JSON.parse(financials.LastBcContractData);

          if (!response.hasOwnProperty('HealthPolicyQueryResponse')) {
            return;
          }

          response = response.HealthPolicyQueryResponse;

          if (!response.hasOwnProperty('PatientResponse')) {
            return;
          }

          const financialsViewModel = { ...financials, ...response.PatientResponse } as PatientFinancialsViewModel;

          this.patientsStore.update(patientId, { financials: financialsViewModel });
        }
      })
    );
  }

  getAccountFinancialInformation(bpn: string, patientId: string): Observable<AccountInfoVo> {
    return this.getForgePatientByUuid(bpn, patientId).pipe(
      switchMap(s => this.merakiPatientService.getAccountDetails(bpn, s.Account.Id).pipe(take(1))),
      map(
        account =>
          ({
            AccountLiable: account?.Balance?.PatientLiable == 0 ? 0 : account?.Balance?.PatientLiable / 100 || null,
            LastBcContractData: JSON.stringify(account?.LastBcContractData),
            LastBenefitCheckStatus: account?.LastBenefitCheckStatus,
            LastBcReferenceNum: account?.LastBcContractData?.ReferenceNum,
            //LastBenefitCheckReportBase64: report,
            LastBenefitCheckDate: account?.LastBenefitCheckDate,
          } as AccountInfoVo) //return this.merakiClientService.loadBenefitCheckReport(bpn, account?.LastBcContractData.ReferenceNum).pipe(
      )
    );
  }

  public getPatientChronicConditions(tenantId: string, patientId: string) {
    // const request = this.clinicalPatientClient.getPatientChronicConditions(tenantId, patientId);
    const request = this.merakiPatientService.getPatientChronicConditions(tenantId, patientId);
    return request.pipe(
      tap(conditions => {
        this.updateConditionsLocal(patientId, conditions);
      })
    );
  }

  public getActiveEncounter(practiceTenantId: string, tenantId: string, patientId: string, activeEncounter: EncounterVo) {
    return this.providerService.waitingRoom$.pipe(
      take(1),
      map(waitingRoom => waitingRoom.find(s => s.PatientEvent.Patient.PatientId == patientId && s.PatientEvent.PracticeId == tenantId)),
      switchMap(visit =>
        this.merakiEncounterService.getActiveEncounterForPatient(practiceTenantId, tenantId, patientId, visit?.PatientEvent)
      ),
      map(encounter => encounter || activeEncounter)
    );
  }

  public updateConditionsLocal(patientId: string, conditions: PatientChronicConditionsVo) {
    if (conditions) {
      this.patientsStore.update(patientId, patient => ({ ...patient, conditions }));
    } else {
      this.patientsStore.update(patientId, { conditions: { Id: patientId, Conditions: [], OtherMedications: [] } });
    }
  }

  public addPatientChronicMedications(patientId: string, medications: MedicationConditionVo[]) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        OtherMedications: arrayAdd(entity.conditions.OtherMedications || [], medications),
      },
    }));
  }

  public getPatientPrescriptionLines(patientId: string): Observable<MedicationPrescriptionLine[]> {
    return this.patientsQuery.selectEntity(patientId, x => x.prescriptionLines).pipe(map(prescrLines => (prescrLines ? prescrLines : [])));
  }

  public addPatientPrescriptionLine(patientId: string, prescriptionLine: MedicationPrescriptionLine) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      prescriptionLines: arrayUpsert(entity.prescriptionLines || [], prescriptionLine.NappiCode, prescriptionLine, 'NappiCode'),
    }));
  }

  public updatePatientPrescriptionLine(patientId: string, prescriptionLine: MedicationPrescriptionLine) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      prescriptionLines: arrayUpdate(entity.prescriptionLines, a => a.NappiCode === prescriptionLine.NappiCode, prescriptionLine),
    }));
  }

  public removePatientPrescriptionLine(patientId: string, nappiCode: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      prescriptionLines: arrayRemove(entity.prescriptionLines || [], prescriptionLine => prescriptionLine.NappiCode === nappiCode),
    }));
  }

  public clearPatientPrescriptionLines(patientId: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      prescriptionLines: [],
    }));
  }

  public addPatientMetric(patientId: string, metric: ClinicalMetricVo) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      activeMetrics: arrayUpsert(entity.activeMetrics || [], metric.Name, metric, 'Name'),
    }));
  }

  public clearPatientMetrics(patientId: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      activeMetrics: [],
    }));
  }

  public removePatientMetric(patientId: string, metricName: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      activeMetrics: arrayRemove(entity.activeMetrics || [], metric => metric.Name === metricName),
    }));
  }

  public getUnsavedPatientMetrics(patientId: string): Observable<ClinicalMetricVo[]> {
    return this.patientsQuery.selectEntity(patientId, x => x.activeMetrics).pipe(map(metrics => (metrics ? metrics : [])));
  }

  public addPatientGynae(patientId: string, genericInfo: PatientFileGenericInformationVo) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      patientGenericInformation: arrayUpsert(
        entity.patientGenericInformation || [],
        genericInfo.PatientFileGenericInformationId,
        genericInfo,
        'PatientFileGenericInformationId'
      ),
    }));
  }

  public getPatientGenericInformationByCategory(patientId: string, category: string): Observable<PatientFileGenericInformationVo> {
    return this.patientsQuery
      .selectEntity(patientId, x => x.patientGenericInformation)
      .pipe(map(patientGenericInformation => patientGenericInformation?.find(x => x.Category === category)));
  }

  public getPatientWomensHealth(practiceId: string, patientId: string, patient: PatientVo): Observable<PatientFileGenericInformationVo> {
    // only load for females to optimize performance and cost
    if (patient.PatientDetails.Gender === 'Female') {
      return this.merakiPatientService
        .getPatientFileWomensHealth(practiceId, patientId)
        .pipe(tap(womensHealth => this.updatePatientGenericInfo(patientId, [womensHealth])));
    }
    return of(null);
  }

  public getPatientInfo(patientId: string): Observable<PatientFileGenericInformationVo[]> {
    return this.patientsQuery.selectEntity(patientId, x => x.patientGenericInformation);
  }

  sendPatientCommunication(practiceId: string, patientId: string, request: TriggerPatientCommunication) {
    return this.clinicalNewClient.sendPatientCommunication(practiceId, patientId, request);
  }

  scheduleVisitFollowup(practiceId: string, patientId: string, request: SendEncounterRequireFollowupPatientSms) {
    return this.clinicalNewClient.sendEncounterRequireFollowupPatientSms(practiceId, patientId, request);
  }

  public patientUIState$(patientId: string) {
    return this.patientsQuery.ui.selectEntity(patientId);
  }

  // todo refactor to use encounter-letter store
  public updateSickNoteUIState(patientId: string, medicalCertificate: MedicalCertificate, htmlBody: string) {
    this.patientsStore.ui.update(patientId, entity => {
      return {
        ...entity,
        prevSickNote: { medicalCertificate, htmlBody },
      };
    });
  }

  public updateVaccinationState(patientId: string, vaccinationState: VaccinatedStatusVo) {
    this.patientsStore.ui.update(patientId, entity => ({
      ...entity,
      vaccinatedStatusSnapshot: vaccinationState,
    }));
  }

  public removeMedicationUIState(patientId: string, conditionId: string, medication: MedicationConditionVo) {
    this.patientsStore.ui.update(patientId, entity => ({
      ...entity,
      medications: arrayRemove(entity.medications, m => m.id === `${conditionId || 'other'}${medication.NappiCode}`),
    }));
  }

  public clearMedicationsUIState(patientId: string) {
    this.patientsStore.ui.update(patientId, entity => ({ ...entity, medications: [] }));
  }

  public addSelectedMedicationsUIState(patientId: string, conditionId: string, medication: MedicationConditionVo, diagnosis: DiagnosisVo2) {
    this.patientsStore.ui.update(patientId, entity => {
      const prescriptionLine = {
        NappiCode: medication.NappiCode,
        Description: medication.Name,
        MedicationDescription: medication.Name,
        DispensingInstructions: medication.Dosage
          ? medication?.Dosage
          : `${medication.Parameters?.AdditionalInstructionSelections || ''}
         ${medication.Parameters?.InformationText || ''}`.trimStart(),
        Quantity: !!medication.Quantity ? medication.Quantity : 1,
        CreatedFrom: 'ConditionsMedication',
        DosageType: medication.DosageType,
        DosageUnits: medication.DosageUnit,
        DurationType: medication.DurationType,
        DurationUnit: medication.DurationUnit,
        FrequencyUnits: medication.FrequencyUnit,
        PeriodType: medication.PeriodType,
        PeriodUnit: medication.PeriodUnit,
        Parameters: medication.Parameters,
        Repeat: medication.Repeat,
        Diagnosis: diagnosis ? [diagnosis] : [],
      } as MedicationPrescriptionLine;

      return {
        ...entity,
        medications: arrayUpsert(
          entity.medications,
          prescriptionLine.NappiCode,
          {
            id: `${conditionId || 'other'}${prescriptionLine.NappiCode}`,
            medication: prescriptionLine,
          },
          'Id'
        ),
      };
    });
  }

  public updatePatientChronicMedications(patientId: string, medication: MedicationConditionVo) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        OtherMedications: arrayUpdate(entity.conditions.OtherMedications, a => a.NappiCode === medication.NappiCode, medication),
      },
    }));
  }

  public updatePatientChronicMedication(patientId: string, condition: PatientChronicConditionVo, medication: MedicationConditionVo) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        Conditions: arrayUpdate(entity.conditions.Conditions, a => a.Condition.Id === condition.Condition.Id, {
          ...condition,
          Medications: arrayUpdate(condition.Medications, a => a.NappiCode === medication.NappiCode, medication),
        }),
      },
    }));
  }

  public removeChronicMedication(patientId: string, NappiCode: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        OtherMedications: arrayRemove(entity.conditions.OtherMedications, a => a.NappiCode === NappiCode),
      },
    }));
  }

  public addPatientChronicConditions(patientId: string, conditions: PatientChronicConditionVo[]) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        Conditions: arrayAdd(entity?.conditions?.Conditions || [], conditions),
      },
    }));
  }

  public updatePatientConditionNote(patientId: string, note: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        Note: note,
      },
    }));
  }

  public updatePatientChronicCondition(patientId: string, condition: PatientChronicConditionVo) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        Conditions: arrayUpdate(entity.conditions.Conditions, a => a.Condition.Id === condition.Condition.Id, condition),
      },
    }));
  }

  removeChronicCondition(patientId: string, DiagnosisCode: string) {
    this.patientsStore.update(patientId, entity => ({
      ...entity,
      conditions: {
        ...entity.conditions,
        Conditions: arrayRemove(entity.conditions.Conditions, a => a.Condition.DiagnosisCode === DiagnosisCode),
      },
    }));
  }

  public getPatientConfigurationByKey(tenantId: string, patientId: string, configKey: string) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        map(config => (config && config?.Configuration[configKey]?.split(',')) || []),
        map(m => m.filter(f => f))
      );
  }

  public getPatientConfiguration(patientId: string) {
    return this.patientsQuery.selectEntity(patientId, s => s.patientConfiguration);
  }

  public updatePatientConfigurationByKey(
    tenantId: string,
    patientId: string,
    configuration: PatientConfigurationVo,
    practiceTenantId: string
  ) {
    return this.merakiPatientService
      .updatePatientConfiguration(practiceTenantId, patientId, configuration)
      .pipe(tap(result => result.Sucess && this.patientsStore.update(patientId, { patientConfiguration: configuration })));
  }

  public deleteEncounterTimelineEntry(tenantId: string, patientId: string, entry: DeletedEncounterEntry, practiceTenantId: string) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const prevValue = config.Configuration[ConfigurationKey.DeletedEncounterEntries];
          const deserializedEncounterEntries: DeletedEncounterEntry[] = prevValue ? JSON.parse(prevValue) : [];
          const resultValue = [...deserializedEncounterEntries, entry];
          const configuration = { ...config.Configuration };
          configuration[ConfigurationKey.DeletedEncounterEntries] = JSON.stringify(resultValue);
          return { ...config, Configuration: configuration };
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public saveVaccinatedStatus(tenantId: string, patientId: string, entry: VaccinatedStatusVo, practiceTenantId: string) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const prevValue = config.Configuration[ConfigurationKey.VaccinatedStatus];
          const deserializedVaccinatedStatus: VaccinatedStatusVo = prevValue ? JSON.parse(prevValue) : {};
          const resultValue = { ...deserializedVaccinatedStatus, ...entry };
          const configuration = { ...config.Configuration };
          configuration[ConfigurationKey.VaccinatedStatus] = JSON.stringify(resultValue);
          return { ...config, Configuration: configuration };
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  saveConfigurationItemByKey(tenantId: string, patientId: string, item: string, configKey: string, practiceTenantId: string) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: item,
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public restoreEncounterTimelineEntry(tenantId: string, patientId: string, referenceIds: string[], practiceTenantId: string) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const prevValue = config.Configuration[ConfigurationKey.DeletedEncounterEntries];
          const deserializedEncounterEntries: DeletedEncounterEntry[] = prevValue ? JSON.parse(prevValue) : [];
          const entryId = deserializedEncounterEntries.find(x => x.ReferenceIds.includes(referenceIds[0]))?.Id;
          if (!entryId) {
            return config;
          }

          const resultValue = [...deserializedEncounterEntries.filter(x => x.Id !== entryId)];
          const configuration = { ...config.Configuration };
          configuration[ConfigurationKey.DeletedEncounterEntries] = JSON.stringify(resultValue);
          return { ...config, Configuration: configuration };
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public addPatientConfigurationItemsByKey(
    tenantId: string,
    patientId: string,
    configKey: string,
    configValue: string[],
    practiceTenantId: string
  ) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq([...(config?.Configuration[configKey]?.split(',') || []), ...configValue]).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public addPatientConfigurationItemByKey(tenantId: string, patientId: string, configKey: string, configValue: string, practiceTenantId) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq([...(config?.Configuration[configKey]?.split(',') || []), configValue]).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public removePatientConfigurationItemFromKey(
    tenantId: string,
    patientId: string,
    configKey: string,
    configValue: string,
    practiceTenantId: string
  ) {
    return this.patientsQuery
      .selectEntity(patientId, s => s.patientConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq((config?.Configuration[configKey]?.split(',') || []).filter(f => f !== configValue)).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updatePatientConfigurationByKey(tenantId, patientId, config, practiceTenantId))
      );
  }

  public getCustomScreeningMetrics(tenantId: string, patientId: string) {
    return this.getPatientConfigurationByKey(tenantId, patientId, ConfigurationKey.PatientScreenings);
  }

  public updateCustomScreeningMetrics(tenantId: string, patientId: string, metrics: string[], practiceTenantId: string) {
    return this.addPatientConfigurationItemsByKey(tenantId, patientId, ConfigurationKey.PatientScreenings, metrics, practiceTenantId);
  }

  @action('getPatientDocuments')
  public getPatientDocuments(tenantId: string, patientId: string) {
    const request = this.documentClient.getPatientDocuments(tenantId, patientId);
    return request.pipe(
      tap(documents => {
        this.updateDocumentsLocal(patientId, documents);
      })
    );
  }

  private updateDocumentsLocal(patientId: string, documents: PatientDocumentVo[]) {
    if (!!documents) {
      this.patientsStore.update(patientId, { documents });
    }
  }
  private updateMetricsLocal(patientId: string, metrics: PatientDocumentVo[]) {
    if (!!metrics) {
      this.patientsStore.update(patientId, { historicalMetrics: metrics });
    }
  }

  @action('getPatientDocument')
  public getPatientDocument(tenantId: string, patientId: string, documentId: string) {
    const request = this.documentClient.getPatientDocument(tenantId, documentId);
    return request.pipe(
      tap(document => {
        if (!!document) {
          this.patientsStore.update(patientId, entity => ({ documents: arrayAdd(entity.documents, document) }));
        }
      })
    );
  }

  @action('GetPatientDashboard')
  getPatientDashboard(tenantId: string, patientId: string) {
    const request = this.clinicalPatientClient.getPatientDashboard(tenantId, patientId);
    return request.pipe(
      map(d => d ?? { PatientDashboardEvents: [] }),
      map(dashboard => {
        // Filter out the events that are not related to this patient (linked accounts for SMS type)
        if (dashboard) {
          dashboard.PatientDashboardEvents = dashboard.PatientDashboardEvents.filter(
            ev =>
              ((ev.Type === 'PathResultsNormalSmsSent' || ev.Type === 'PathResultsFollowUpSmsSent') && ev.PatientId === patientId) ||
              (ev.Type !== 'PathResultsNormalSmsSent' && ev.Type !== 'PathResultsFollowUpSmsSent')
          );
          return dashboard;
        }
        return null;
      }),
      tap(dashboard => {
        this.updateDashboardLocal(patientId, dashboard);
      })
    );
  }

  private updateDashboardLocal(patientId: string, dashboard: PatientDashboardVo) {
    const patient = this.patientsQuery.getEntity(patientId);
    if (!patient) {
      this.patientsStore.upsert(patientId, { PatientId: patientId, dashboard, isIncomplete: true });
    } else {
      this.patientsStore.update(patientId, { dashboard });
    }
  }

  loadPatientCapturedMetrics(patientId: string) {
    // const request = this.clinicalPatientClient.getAllPatientClinicalMetrics(tenantId, patientId);
    return this.providerService.provider$.pipe(
      take(1),
      switchMap(provider => this.merakiPatientService.getAllPatientClinicalMetrics(provider.PracticeTenantId, patientId)),
      tap(metrics => {
        this.updateMetricsLocal(patientId, metrics);
      })
    );
  }

  @action('GetPatientMetrics')
  getLatestPatientCapturedMetrics(patientId: string) {
    return this.loadPatientCapturedMetrics(patientId).pipe(
      map(s =>
        _.chain(s)
          .groupBy(e => e.Name)
          .map(e => _.orderBy(e, k => k.TestDate, 'desc')[0])
          .value()
      )
    );
  }

  @action('GetPatientSurgicalHistory')
  public loadPatientSurgicalHistory(tenantId: string, patientId: string) {
    return this.clinicalPatientClient.getPatientHospitalization(tenantId, patientId).pipe(
      tap(surgicalHistory => {
        this.updateSurgicalHistoryLocal(tenantId, patientId, surgicalHistory);
      })
    );
  }

  private updateSurgicalHistoryLocal(tenantId: string, patientId: string, surgicalHistory: PatientSurgicalHistoryVo) {
    this.updateSurgicalHistory(tenantId, patientId, surgicalHistory || { Hospitalizations: [], NoSurgicalHistory: false });
  }

  public updateSurgicalHistory(tenantId: string, patientId: string, surgicalHistory: PatientSurgicalHistoryVo) {
    this.patientsStore.update(patientId, { surgicalHistory });
  }

  public getAdhocPrescriptionReport(practiceNo: string, prescriptionId: string) {
    // return this.clinicalNewClient.getPatientMedicationPrescriptionBody(tenantId, patientId, prescriptionId);
    return this.merakiClientService.getHtmlBodyFromStorage(practiceNo, 'Prescriptions', prescriptionId);
  }

  @action('GetPatientAllergies')
  public getPatientAllergies(tenantId: string, patientId: string) {
    // const request = this.clinicalPatientClient.getPatientAllergies(tenantId, patientId);
    const request = this.merakiPatientService.getPatientAllergies(tenantId, patientId);
    return request.pipe(
      tap(allergies => {
        this.updateAllergiesLocal(patientId, allergies);
      })
    );
  }

  private updateAllergiesLocal(patientId: string, allergies: PatientAllergiesVo) {
    const patient = this.patientsQuery.getEntity(patientId);
    // only if the patient is not yet in the store (weird) or if the allergies are different
    if (!patient || !_.isEqual(allergies, this.patientsQuery.getEntity(patientId).allergies)) {
      this.patientsStore.update(patientId, { allergies });
    }
  }

  @action('getFamilyMembersForPatient')
  private getFamilyMembersForPatient(tenantId: string, patientId: string) {
    const request = this.patientClient.getAccountMembers(tenantId, patientId);
    return request.pipe(
      tap(patients => {
        //this.updateFamilyMembersLocal(patients);
      })
    );
  }

  private updateFamilyMembersLocal(patientState: PatientStateModel[], patients: PatientVo[]) {
    const patientViewModels = patients.map(p => {
      const storePatient = patientState.find(sp => sp.PatientId === p.PatientId);
      let isIncomplete = true;
      if (storePatient && !storePatient.isIncomplete) {
        isIncomplete = false;
      }
      return { PatientId: p.PatientId, details: p, isIncomplete };
    }) as PatientStateModel[];

    this.patientsStore.upsertMany(patientViewModels);
  }

  private updatePatientGenericInfo(patientId: string, genericInfo: PatientFileGenericInformationVo[]) {
    const patient = this.patientsQuery.getEntity(patientId);
    if (!patient || !_.isEqual(genericInfo, this.patientsQuery.getEntity(patientId).patientGenericInformation)) {
      this.patientsStore.update(patientId, { patientGenericInformation: genericInfo });
    }
  }

  private updatePatientConfiguration(patientId: string, configuration: PatientConfigurationVo) {
    const patient = this.patientsQuery.getEntity(patientId);
    if (!patient || !_.isEqual(configuration, this.patientsQuery.getEntity(patientId).patientConfiguration)) {
      this.patientsStore.update(patientId, { patientConfiguration: configuration });
    }
  }

  public loadPatientAvatar(tenantId: string, patientId: string) {
    const request = this.documentClient.getPatientDocumentAvatar(tenantId, patientId);
    return request.pipe(
      tap(document => {
        this.updateAvatarLocal(patientId, document);
      })
    );
  }

  private updateAvatarLocal(patientId: string, document: PatientDocumentVo) {
    if (!!document) {
      this.patientsStore.upsert(patientId, { avatar: document });
    } else {
      this.patientsStore.upsert(patientId, { avatar: {} });
    }
  }

  @action('searchPatients')
  public searchPatients(tenantId: string, searchString: string) {
    return this.providerService.isForge$.pipe(
      take(1),
      withLatestFrom(this.providerService.provider$),
      switchMap(([forge, provider]) =>
        forge ? this.searchPatientsFromForge(provider.PracticeNumber, searchString) : this.patientClient.getAll(tenantId, searchString)
      )
    );
  }

  private searchPatientsFromForge(bpn: string, searchString: string) {
    const index = this.getPatientIndexName(bpn);
    const filter = ['Patient EXISTS', 'Account EXISTS', 'Patient.AccountMemberStatus = Active', `PracticeId = ${bpn}`];

    const res = index.search<Partial<PatientAccountIndex> & { id: string }>(searchString, {
      filter,
      limit: 10,
    });
    return from(res).pipe(
      map(s => s.hits),
      // build light version of patient (no need to load main member)
      withLatestFrom(this.providerService.flattenedMedicalAids$),
      map(([c, medicalAids]) =>
        c.map(s => {
          const details = toPatient(s.Account, s.Patient, null).details;
          return this.appendMedicalAidInfo(details, medicalAids);
        })
      )
    );
  }

  private getPatientIndexName(bpn: string) {
    let indexName = `patient_${bpn}`;
    if (!this.configService.config.production) {
      indexName = `patient_${bpn.slice(-2)}`;
    }

    return this.meiliSearchAccountClient.index(indexName);
  }

  private getFullPatientFromForge(bpn: string, patientId: string) {
    return this.getForgePatientByUuid(bpn, patientId).pipe(
      switchMap(details =>
        !!details
          ? forkJoin([
              this.merakiPatientService.getAccountDetails(bpn, details.Account.Id),
              this.merakiPatientService.getPatientDetails(bpn, details.Patient.Id),
            ]).pipe(
              switchMap(([account, patient]) => {
                if (account == null || patient == null) {
                  return null;
                }

                if (details.IsMainMember) {
                  return of(toPatient(account, patient, patient));
                }
                return this.merakiPatientService
                  .getPatientDetails(bpn, account.MainMember)
                  .pipe(map(mainInfo => toPatient(account, patient, mainInfo)));
              })
            )
          : of(null)
      )
    );
  }

  private getForgePatientByUuid(bpn: string, patientId: string) {
    const index = this.getPatientIndexName(bpn);
    const filter = [`PracticeId = ${bpn}`, 'Patient EXISTS', 'Account EXISTS', `Patient.UUID = ${patientId}`];

    const res = index.search<Partial<PatientAccountIndex>>('', {
      filter,
      limit: 10,
    });
    return from(res).pipe(
      map(s => s.hits),
      map(patients => (patients?.length > 0 && patients[0]) || null)
    );
  }

  private getForgePatientById(bpn: string, patientId: string) {
    const index = this.getPatientIndexName(bpn);
    const filter = [`PracticeId = ${bpn}`, 'Patient EXISTS', 'Account EXISTS', `Patient.Id = ${patientId}`];

    const res = index.search<Partial<PatientAccountIndex>>('', {
      filter,
      limit: 10,
    });
    return from(res).pipe(
      map(s => s.hits),
      map(patients => (patients?.length > 0 && patients[0]) || null)
    );
  }

  private getForgePatientByAccountNo(bpn: string, accountNo: string) {
    const index = this.getPatientIndexName(bpn);
    const filter = [
      `PracticeId = ${bpn}`,
      'Patient EXISTS',
      'Account EXISTS',
      'Patient.AccountMemberStatus = Active',
      `Account.AccountNo = '${accountNo}'`,
    ];

    const res = index.search<Partial<PatientAccountIndex> & { id: string }>(accountNo, {
      filter,
      limit: 10,
    });
    return from(res).pipe(
      map(s => s.hits),
      withLatestFrom(this.providerService.flattenedMedicalAids$),
      map(([c, medicalAids]) => {
        if (c.length > 0) {
          const mainMemberId = c[0].Account.MainMember;
          const mainMember = c.find(c => c.Patient.Id == mainMemberId);

          return c.map(s => {
            return this.appendMedicalAidInfo(toPatient(s.Account, s.Patient, mainMember.Patient).details, medicalAids);
          });
        }
        return [];
      })
    );
  }

  private getForgePatientsByAccount(bpn: string, accountDetails: AccountDetailsVo) {
    const accountNo = accountDetails.AccountNo;

    const index = this.getPatientIndexName(bpn);
    const filter = [
      'Patient EXISTS',
      'Account EXISTS',
      `PracticeId = ${bpn}`,
      'Patient.AccountMemberStatus = Active',
      `Account.AccountNo = '${accountNo}'`,
    ];
    // Please note: main member is not populated
    const res = index.search<Partial<PatientAccountIndex> & { id: string }>(accountNo, {
      filter,
      limit: 10,
    });
    return from(res).pipe(
      map(s => s.hits),
      // build light version of patient (no need to load main member)
      map(c => c.map(s => toPatient(s.Account, s.Patient, null).details)),
      map(c =>
        c.map(s => ({
          ...s,
          // todo confirm if this valid assumption
          PatientAccountDetails: {
            ...accountDetails,
            MedicalAidDependentCode: s.PatientAccountDetails.MedicalAidDependentCode,
          },
        }))
      )
    );
  }

  public communitySearch(tenantId: string, searchString: string) {
    const request = this.patientClient.searchInNationalDatabase(tenantId, searchString);
    return request.pipe(
      map(restResult => {
        if (restResult && restResult.Sucess) {
          return restResult.Data;
        } else {
          return null;
        }
      })
    );
  }

  getAccountDetails(practiceid: string, accountNo: string): Observable<AccountDetailsVo> {
    return this.providerService.provider$.pipe(
      withLatestFrom(this.providerService.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.getForgePatientByAccountNo(provider.PracticeNumber, accountNo).pipe(
              map(result => result.length > 0 && result[0].PatientAccountDetails)
            )
          : this.patientClient.getAccountDetails(practiceid, accountNo)
      )
    );
  }

  @action('updatePatient')
  public updatePatient(tenantId: string, patient: Partial<PatientVo>) {
    const request = this.loadPatientInfo(tenantId, patient.PatientId).pipe(
      map(fullPatient => this.applyPartialPatient(fullPatient, patient)),
      withLatestFrom(this.providerService.provider$.pipe(take(1)), this.providerService.isForge$),
      switchMap(([updatedPatient, provider, isForge]) =>
        (isForge
          ? this.merakiPatientService.updatePatient(provider.PracticeNumber, updatedPatient)
          : this.patientClient.changePatient(tenantId, patient.PatientId, {
              LooseValidation: false,
              PatientVo: {
                ...updatedPatient,
                PatientDetails: {
                  ...updatedPatient.PatientDetails,
                  IdentityNo: updatedPatient.PatientDetails.PassportNo || updatedPatient.PatientDetails.IdentityNo,
                },
                PatientAccountDetails: {
                  ...updatedPatient.PatientAccountDetails,
                  MedicalAidMainMemberDetails: {
                    ...updatedPatient.PatientAccountDetails.MedicalAidMainMemberDetails,
                    IdentityNo:
                      updatedPatient.PatientAccountDetails.MedicalAidMainMemberDetails.PassportNo ||
                      updatedPatient.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo,
                  },
                },
              },
              UpdateOtherPatientsAccountDetails: false,
            })
        ).pipe(
          tap({
            next: restApiResult => {
              if (restApiResult.Sucess) {
                this.patientsStore.upsert(patient.PatientId, { details: updatedPatient });
              }
            },
          })
        )
      )
    );
    return request.pipe(
      map(restApiResult => {
        if (!restApiResult.Sucess) {
          console.error('Patient not saved ', restApiResult.ResponseMessage);
          return restApiResult;
        }
        return restApiResult;
      })
    );
  }

  private applyPartialPatient(fullPatient: PatientVo, patient: Partial<PatientVo>) {
    return {
      ...fullPatient,
      ...patient,

      PatientAccountDetails: {
        ...fullPatient.PatientAccountDetails,
        ...patient.PatientAccountDetails,
      },

      PatientDetails: {
        ...fullPatient.PatientDetails,
        ...patient.PatientDetails,
      },
    } as PatientVo;
  }

  patientLastUpdated$(tenantId: string, patientId: string) {
    const patientEntity = this.patientsQuery.getEntity(patientId);

    // no patient saved....
    if (!patientEntity) {
      return of({
        LastUpdated: null,
        HasChanged: true,
      });
    } else {
      const lastChecked = new Date((patientEntity.lastUpdated && patientEntity.lastChecked) || '1980/01/01');
      const timeDiff = new Date().getTime() - lastChecked.getTime();

      if (timeDiff >= this.cacheThreshold) {
        // we need to query the server again
        const request = this.getPatientLastUpdated(tenantId, patientId).pipe(
          // should we signal caller to check for newer data?
          map(upd => ({
            LastUpdated: upd,
            HasChanged: !patientEntity.lastChecked || !patientEntity.lastUpdated || patientEntity.lastUpdated < upd,
          }))
          // now change internal data for subsequent lookups
          // tap(upd => this.updatePatientLastUpdated(tenantId, patientId, new Date(), upd.LastUpdated))
        );
        return request;
      } else {
        return of({
          LastUpdated: patientEntity.lastUpdated,
          HasChanged: false,
        });
      }
    }
  }

  @action('revisePatientClinicalNotes')
  revisePatientClinicalNotes(
    tenantId: string,
    patientId: string,
    Note: string,
    IsImportant: boolean = false,
    Type: string = 'text',
    Format: string
  ) {
    return this.patientDetailsById$(tenantId, patientId).pipe(
      take(1),
      switchMap(s =>
        this.merakiPatientService
          .revisePatientClinicalNotes(s.PracticeId, patientId, {
            Note,
            IsImportant,
            Format,
            Type,
            PatientClinicalNotesId: patientId,
            Patient: s,
            CapturedDate: new Date(),
          } as PatientClinicalNotesVo)
          .pipe(
            // map(result => patientClinicalNotes),
            take(1)
            /* tap(result => {
              this.patientsStore.update(patientId, entity => ({
                patientClinicalNotes: { ...entity.patientClinicalNotes, ...patientClinicalNotes },
              }));
            }) */
          )
      )
    );
  }

  getAllChronicConditionsList(tenantId: string) {
    return this.clinicalPatientClient.getChronicConditionsAll(tenantId);
  }

  getAllCommonChronicConditionsList() {
    return this.merakiPatientService.getChronicConditions();
  }

  getChronicConditions(tenantId: string, searchText: string) {
    return this.clinicalPatientClient.getChronicConditions(tenantId, searchText);
  }

  getPatientAllergiesMedications$(tenantId: string, patientId: string) {
    return this.referenceDataService.allergens$.pipe(
      first(),
      map(allergens => allergens.filter(s => s.Category == 'Medication').map(s => s.Code)),
      switchMap(allergens =>
        this.patientAllergiesById$(tenantId, patientId).pipe(
          take(1),
          map(patientAllergies =>
            patientAllergies.PatientAllergies.filter(s => allergens.includes(s.Allergen.Code))
              .map(s => s.Allergen.Description)
              .concat(patientAllergies.Other)
          )
        )
      )
    );
  }

  @action('updatePatientConditions')
  updatePatientConditions(tenantId: string, patientId: string, conditions: PatientChronicConditionsVo) {
    // todo when patients will be in firestore, use triggers function to keep in sync patient details
    // between multiple locations
    return this.patientDetailsById$(tenantId, patientId).pipe(
      take(1),
      map(patient => patient || conditions.Patient), // get latest patient details and update within condition
      switchMap(patient =>
        this.merakiPatientService.revisePatientChronicConditions(conditions?.Patient?.PracticeId || patient.PracticeId, patientId, {
          ...conditions,
          Patient: patient,
        })
      ),
      // update timeline (if covid condition were added or updated)
      switchMap(() => this.patientDashboardById$(tenantId, patientId).pipe(take(1))),
      switchMap(dashboard => (dashboard == null ? this.getPatientDashboard(tenantId, patientId) : of(dashboard))),
      switchMap(dashboard => {
        const covidCodes = this.referenceDataService.covidCodes;
        const covidConditions = conditions.Conditions.filter(s => covidCodes.includes(s.Condition.DiagnosisCode));
        if (covidConditions.length > 0) {
          const trackingCondition = covidConditions[0];

          const timelineEntry = {
            Type: 'TrackingConditionType',
            Narrative: moment(trackingCondition.Condition.TestDate).format('DD MMMM YYYY'),
            Value: trackingCondition.Condition.Status,
            ValueUnits: trackingCondition.Condition.TreatmentStatus,
            ValueFlag: trackingCondition.Condition.RiskLevel,
            Code: trackingCondition.Condition.DiagnosisCode,
            Description: trackingCondition.Condition.Notes,
            PatientId: patientId,
            Thumbnail: trackingCondition.Condition.TestType,
          } as PatientDashboardHistoryEventDto;
          // track only changes
          if (!_.some(dashboard.PatientDashboardEvents, timelineEntry)) {
            return this.clinicalNewClient.addPatientTimelineEntries(tenantId, patientId, [
              {
                ...timelineEntry,
                DateTime: trackingCondition.CapturedDate,
              },
            ]);
          }
        }
        return of({ Sucess: true });
      }),
      tap(() => this.updateConditionsLocal(patientId, conditions)),
      map(() => conditions)
    );
  }

  addPatientTimelineEntries(providerId, patientId, items: PatientDashboardHistoryEventDto[]): Observable<RestApiResultOfBoolean> {
    return this.clinicalNewClient.addPatientTimelineEntries(providerId, patientId, items);
  }

  @action('reviseSurgicalHistory')
  reviseSurgicalHistory(tenantId: string, patientId: string, history: PatientSurgicalHistoryVo) {
    return this.clinicalPatientClient.revisePatientHospitalization(tenantId, patientId, {
      PatientSurgicalHistory: history,
      OriginatedSystem: 'Clinician App',
    });
  }

  @action('updatePatientAllergies')
  updatePatientAllergies(tenantId: string, patientId: string, allergies: PatientAllergiesVo) {
    const existingAllergies = this.patientsQuery.getEntity(patientId).allergies;

    const patientAllergies = {
      ...existingAllergies,
      ...allergies,
    };

    return this.merakiPatientService.revisePatientAllergies(tenantId, patientId, patientAllergies).pipe(
      switchMap(() => this.getPatientAllergies(tenantId, patientId)) // this will refresh with the latest allergies
    );
  }

  @action('uploadPatientFile')
  uploadPatientFile(tenantId: string, patientId: string, documentVo: PatientDocumentVo, file: Blob) {
    const request = this.documentUploadClient.capturePatientDocument(tenantId, patientId, documentVo, file);
    return request.pipe(
      switchMap(response => this.getPatientDocument(tenantId, patientId, response.Data).pipe(map(document => ({ response, document }))))
    );
  }

  @action('getPatientLifestyle')
  public getPatientLifestyle(tenantId: string, patientId: string) {
    const request = this.clinicalPatientClient.getPatientLifestyle(tenantId, patientId);
    return request.pipe(
      tap(lifestyle => {
        this.updateLifestyleLocal(patientId, lifestyle);
      })
    );
  }

  private updateLifestyleLocal(patientId: string, lifestyle: PatientLifestyleVo) {
    this.patientsStore.update(patientId, { lifestyle });
  }

  @action('updatePatientLifestyle')
  public updatePatientLifestyle(tenantId: string, patientId: string, patientLifestyle: PatientLifestyleVo) {
    const revision = {
      Lifestyle: patientLifestyle,
      OriginatedSystem: 'Clinician App',
    };

    return this.clinicalPatientClient
      .revisePatientLifestyle(tenantId, patientId, revision)
      .pipe(switchMap(() => this.getPatientLifestyle(tenantId, patientId)));
  }

  getPatientSpecificHealthIdUrl(tenantId: string, patientId: string) {
    return this.patientById$(patientId).pipe(
      take(1),
      switchMap(patient => this.healthIntegrationClient.getPatientSpecificHealthIdUrlForPatient(tenantId, patientId, patient.details))
    );
  }

  isPatientRoutingCodeValid(tenantId: string, patientId: string) {
    return combineLatest([this.patientDetailsById$(tenantId, patientId), this.providerService.flattenedMedicalAids$]).pipe(
      map(([patient, medicalAids]) => {
        if (patient?.PatientAccountDetails?.MedicalAidRoutingCode) {
          return !!medicalAids.find(a => a.RoutingCode === patient.PatientAccountDetails.MedicalAidRoutingCode);
        }

        return patient?.PatientAccountDetails?.MedicalAidPlanOption
          ? !!medicalAids.find(a => a.OptionCode === patient.PatientAccountDetails.MedicalAidOptionCode)
          : false;
      })
    );
  }

  logHealthIdVisit(tenantId: string, patientId: string) {
    this.updatePatientUiState(tenantId, patientId, { healthIdVisited: moment() });
  }

  private updatePatientUiState(tenantId: string, patientId: string, uiState: Partial<PatientStateUIModel>) {
    this.patientsStore.ui.update(patientId, uiState);
  }

  public getMedicineInteractions(tenantId: string, nappiCodes: string[]): Observable<MedicineInteractionVo[]> {
    return this.clinicalNewClient.getMedicineInteractions(tenantId, nappiCodes);
  }

  public updateMedicineInteractions(patientId: string, medicineInteractions: MedicineInteractionVo[]) {
    this.patientsStore.update(patientId, { medicineInteractions });
  }
}

function toPatient(
  account: Partial<Account>,
  patientInfo: Partial<AccountMember>,
  mainMember?: Partial<AccountMember>
): { details: PatientVo; MembersXRefs?: string[] } {
  const toTitle = (title: string) => {
    if (title == 'Miss') {
      return 'Ms';
    }
    if (title == 'Rev') {
    }
    if (title === 'Hon') {
    }
    return title;
  };

  const patient = {
    // todo switch to guid if not empty
    PatientId: patientInfo.UUID || guidByString(patientInfo.Id.toString(), 5),
    PatientXRef: patientInfo.Id,
    KnownAs: patientInfo.PreferredName,
    FileNo: patientInfo.PatientFileNo,
    PhysicalAddress: patientInfo.Contact?.PhysicalAddress,
    PostalAddress: patientInfo.Contact?.PostalAddress,
    Occupation: patientInfo.Occupation,
    Employer: patientInfo.Employer,
    PatientDetails: {
      FirstName: patientInfo.Name,
      Surname: patientInfo.Surname,
      ContactNo: patientInfo.Contact?.Cellphone,
      Gender: patientInfo.Gender,
      DateOfBirth: patientInfo.DateOfBirth,
      EmailAddress: patientInfo.Contact?.Email,
      IdentityNo: patientInfo.IdentificationType == IDENTIFICATION_TYPE.SAID ? patientInfo.IdentityNo : '',
      //IdentityType: patientInfo.IdentificationType,
      PassportNo: patientInfo.PassportNumber,
      Country: patientInfo.Country,
      Title: toTitle(patientInfo.Title),
    },
    PatientAccountDetails: {
      IsCashAccount: account?.AccountType == ACCOUNT_TYPE.PRIVATE,
      AccountId: account?.Id,
      MainMemberXRef: mainMember?.Id,
      AccountNo: account?.AccountNo,
      MedicalAidOptionCode: account?.Option,
      MedicalAidDependentCode: patientInfo.DependantCode,
      MedicalAidMembershipNumber: account?.MemberNo,
      MedicalAidMainMemberDetails: {
        FirstName: mainMember?.Name,
        Surname: mainMember?.Surname,
        DateOfBirth: mainMember?.DateOfBirth,
        ContactNo: mainMember?.Contact?.Cellphone,
        EmailAddress: mainMember?.Contact?.Email,
        Gender: mainMember?.Gender,
        IdentityNo: mainMember?.IdentificationType == IDENTIFICATION_TYPE.SAID ? mainMember?.IdentityNo : '',
        PassportNo: mainMember?.PassportNumber,
        Country: mainMember?.Country,
        Title: toTitle(mainMember?.Title),
      },
    },
  } as PatientVo;

  return {
    MembersXRefs: account?.Members,
    details: patient,
  };
}
