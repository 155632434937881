import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, delay } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject, Observable, bindNodeCallback } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  Supported = false;
  PushEnabled = false;
  Muted = false;

  supportedBrowser$ = new BehaviorSubject<boolean>(false);
  isRegistered$ = new BehaviorSubject<boolean>(false);
  muted$ = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService, private configService: ConfigService) {
    const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal
    OneSignal.push(() => {
      OneSignal.init({
        appId: this.configService.config.oneSignalAppId,
        notifyButton: {
          enable: false,
        },
        allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
      });

      const supp = OneSignal.isPushNotificationsSupported();
      this.Supported = supp;
      this.supportedBrowser$.next(supp);

      if (supp) {
        // check if already enabled/subscribed
        OneSignal.isPushNotificationsEnabled(isEnabled => {
          this.PushEnabled = isEnabled;
          // console.warn('OneSignal().isPushNotificationsEnabled', 'initial state set as ' + isEnabled);
          this.isRegistered$.next(isEnabled);
        });

        OneSignal.on('subscriptionChange', isSubscribed => {
          this.isRegistered$.next(isSubscribed);
          this.PushEnabled = isSubscribed;
          // console.warn('OneSignal.on.subscriptionChange', 'subscription state changed to ' + isSubscribed);
        });
      } else {
        // console.warn('OneSignal().isPushNotificationsSupported', 'Browser is not compatible');
      }
    });

    this.authService.currentTenantId$.pipe(untilDestroyed(this, 'destroy'), distinctUntilChanged(), delay(1000)).subscribe({
      next: tenantId => {
        if (tenantId && this.PushEnabled) {
          this.connect(tenantId);
        } else {
          this.disconnect();
        }
      },
    });
  }

  register() {
    const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal
    // OneSignal.push(['getNotificationPermission', (permission: 'default' | 'granted' | 'denied') => {
    //   console.log('Site Notification Permission:', permission);
    //   // (Output) Site Notification Permission: default
    // }]);
    OneSignal.push(() => {
      OneSignal.showNativePrompt();
      // console.warn('OneSignal.showNativePrompt', 'Prompt shown');
    });
  }

  connect(providerId: string) {
    // supported and not already connected
    if (this.Supported) {
      const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal

      OneSignal.push(() => {
        OneSignal.setExternalUserId(providerId);
        OneSignal.setDefaultTitle('Clinician, by Healthbridge');

        this.handleNotifications();
        this.isRegistered$.next(true);
      });
    } else {
      console.warn('OneSignal.connect', 'Push not supported');
    }
  }

  handleNotifications() {
    const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal

    OneSignal.push(() => {
      const onNotificationClicked = data => {
        // console.warn('OneSignal Notification', data);
      };

      const handler = data => {
        // call your primary handler
        onNotificationClicked(data);
        // add another handler right away
        OneSignal.addListenerForNotificationOpened(handler);
      };

      // attach your handler for the first time
      OneSignal.addListenerForNotificationOpened(handler);

      // console.warn('OneSignal.handleNotifications()', 'Callback registered');
    });
  }

  disconnect() {
    if (this.PushEnabled) {
      console.log('OneSignal.close', 'Disconnected');
      const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal
      OneSignal.push(() => {
        OneSignal.removeExternalUserId();
      });
    }
  }

  muteSubscription() {
    const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal
    OneSignal.push(['setSubscription', false]);
  }

  unmuteSubscription() {
    const OneSignal = window['OneSignal'] || []; // tslint:disable-line: no-string-literal
    OneSignal.push(['setSubscription', true]);
  }

  destroy() {}
}
