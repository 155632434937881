import { Injectable } from '@angular/core';
import { PowerBIClient } from './api-client.service';
import { ReportsQuery } from './state/reports/reports.query';
import { ReportsStore } from './state/reports/reports.store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  reports$ = this.reportsQuery.selectAll();

  constructor(private powerbiClient: PowerBIClient, private reportsStore: ReportsStore, private reportsQuery: ReportsQuery) {}

  public getReports(providerId: string) {
    const request = this.powerbiClient.getReportList(providerId);
    return request.pipe(
      tap(reports => {
        this.reportsStore.add(reports);
      })
    );
  }

  public getReport(providerId: string, reportId: string) {
    const request = this.powerbiClient.getReport(providerId, reportId);
    return request.pipe(
      tap(report => {
        this.reportsStore.upsert(report.reportId, report);
      })
    );
  }
}
