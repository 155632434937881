import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, mapTo, switchMap, take } from 'rxjs/operators';
import { PatientFileGenericInformationVo, ProviderConfigurationVo } from '..';
import { MerakiAuthService } from '../meraki-auth.service';
import { HttpClientMeraki, MERAKI_API_GATEWAY, MERAKI_CACHE_LB } from '../meraki-nexus.providers';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Timestamp = firebase.firestore.Timestamp;
import {
  PatientConfigurationVo,
  RestApiResultOfBoolean,
  RestApiResultOfGuid,
  PatientClinicalNotesVo,
  CalendarEventVo,
  PatientInWaitingRoom,
  PatientEventVo,
  PatientEventVoStatus,
  RestApiResultOfCheckinPatientVisitResult,
  PatientEventsByDateVo,
  PatientEventView,
  PatientVo,
  PatientAllergiesVo,
  PatientChronicConditionsVo,
  ConditionFilterVo,
  PatientSurgicalHistoryVo,
  PatientLifestyleVo,
  ClinicalMetricVo,
} from '../api-client.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfigService } from '../config.service';
import {
  convertClinicalMetricToFirestore,
  convertConditionToFirestore,
  convertFirestoreToClinicalMetric,
  convertFirestoreToCondition,
  convertPatientToFirestore,
  fromTimestamp,
} from '../meraki-models/meraki-model-util';
import * as uuidV4 from 'uuid/v4';
import * as guidByString from 'uuid-by-string';
import {
  CalendarEvent,
  CALENDAR_EVENT_TYPE,
  CALENDAR_EVENT_STATUS,
  CalendarEventStatusHistory,
  CLINICAL_CALENDAR_EVENT_STATUS,
  VisitInfo,
} from '../meraki-models/calendar.models';
import { VISIT_TYPE } from '../meraki-models/general.models';
import { PatientEventType } from '../state/clinical-encounter/clinical-encounter.model';
import { BaseInvoice, INVOICE_STATUS } from '../meraki-models/invoice.models';
import { Account, ACCOUNT_TYPE } from '../meraki-models/account.models';
import { AccountMember, IDENTIFICATION_TYPE, PatientAccount, PatientAccountResponse } from '../meraki-models/member.models';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';
import { ApplicationInsightsService } from '../application-insights.service';
import { NexResponse } from '../meraki-models/meraki-models';
import { CommonChronicConditionsVo } from '../meraki-client.service';

const PRACTICE_ROOT = 'ClinicalPractice';
const PROVIDER_ROOT = 'ClinicalProvider';
const CHRONIC_CONDITION_ROOT = 'PatientConditions';
const METRICS_THRESHOLD = 700;

@Injectable({
  providedIn: 'root',
})
export class MerakiPatientService {
  private http: HttpClientMeraki;
  private baseUrl: string;
  private loadBalancerUrl: string;

  constructor(
    private httpHandler: HttpBackend, // Bypass Auth interceptor since it overrides with florence auth token.
    @Inject(HttpClientMeraki) http: HttpClientMeraki,
    @Inject(MERAKI_API_GATEWAY) baseUrl: string,
    @Inject(MERAKI_CACHE_LB) cacheLbUrl: string,
    private authService: MerakiAuthService,
    private firestore: AngularFirestore,
    private slicePipe: SlicePipe,
    private configService: ConfigService,
    private appInsightService: ApplicationInsightsService
  ) {
    this.http = http;
    //this.http = new HttpClient(httpHandler);

    this.baseUrl = baseUrl ? baseUrl : '';
    this.loadBalancerUrl = cacheLbUrl;
  }

  dropNullUndefined(d) {
    return Object.entries(d).reduce(
      (acc, [k, v]) =>
        v == null
          ? acc
          : {
              ...acc,
              [k]: _.isPlainObject(v) ? this.dropNullUndefined(v) : _.isArray(v) ? v.map(s => this.dropNullUndefined(s)) : v,
            },
      {}
    );
  }

  loadCompletedInvoices(bpn: string, patientXref: string): Observable<BaseInvoice[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Invoice', r =>
        r
          .where('Patient.Id', '==', patientXref)
          //.where('Source', '!=', INVOICE_SOURCE.CLINICAL) // todo can be improved but need index
          .where('Status', '==', INVOICE_STATUS.OPEN)
      )
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        take(1),
        map(s =>
          s
            .filter(t => t.Source != 'Clinical')
            .map(s => ({
              ...s,
              DateOfService: fromTimestamp(s.DateOfService),
              InvoiceDate: fromTimestamp(s.InvoiceDate),
            }))
        )
      );
  }

  getPracticeCalendarAppointments(bpn: string, providerIds: string[], from: Date, to?: Date): Observable<CalendarEventVo[]> {
    if (providerIds.length == 0) {
      return of([]);
    }
    const ignoreVisitTypes = [CALENDAR_EVENT_STATUS.DELETED, CALENDAR_EVENT_STATUS.CANCELLED, CALENDAR_EVENT_STATUS.NO_SHOW];

    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r =>
        r
          .where('TreatingProvider', 'in', providerIds)
          //.where('Branch', '==', '') // todo get branch? or create other index
          .where('EndTime', '>=', from)
          .where('EndTime', '<=', to)
      )
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        // take(1) // todo confirm if we want to listen
        map(events =>
          events
            .filter(item => !ignoreVisitTypes.includes(item.Status))
            .map(
              (item: CalendarEvent | any) =>
                ({
                  PatientName: item.VisitInfo?.PatientInfo?.Name || '',
                  PatientSurname: item.VisitInfo?.PatientInfo?.Surname || '',
                  AppointmentType: item.VisitInfo?.VisitType, // todo confirm types matching
                  AppointmentReason: item.VisitInfo?.VisitReasonDescription || '',
                  AppointmentTitle: '',
                  // nova save dates in firestore as local time (later each practice will have own timezone configuration)
                  StartDate: moment(item.StartTime?.toDate()).add(-moment().utcOffset(), 'minutes').toDate(),
                  EndDate: moment(item.EndTime?.toDate()).add(-moment().utcOffset(), 'minutes').toDate(),
                  AppointmentStatus: item.Status, // todo confirm statuses
                  BenefitCheckStatus: item.VisitInfo?.BenefitCheckStatus,
                  CalendarEventXRef: item.Id?.toString(), // get id from document ref
                  PatientId: item.VisitInfo?.PatientInfo?.UUID || guidByString(item.VisitInfo?.PatientInfo?.PatientId?.toString() || '', 5), // todo confirm convert
                  PatientXRef: item.VisitInfo?.PatientInfo?.PatientId,
                  Invoiced: item.VisitInfo?.Invoiced,
                  PracticeId: item.TreatingProvider, // set hpcsaNo, on parent level will be changed to PracticeId
                  Type: item.EventType == 'Doctor leave' ? 'Doctor unavailable' : 'Appointment', // map to mymps type
                } as CalendarEventVo)
            )
        )
      );
  }

  checkinInPatient(
    bpn: string,
    patient: PatientVo,
    provider: ProviderConfigurationVo
  ): Observable<RestApiResultOfCheckinPatientVisitResult> {
    const patientVisit = {
      ClinicalStatus: CALENDAR_EVENT_STATUS.CHECKED_IN,
      // AD intentionally don't set Status to keep visit hidden for nova user
      //Status: CALENDAR_EVENT_STATUS.CHECKED_IN,
      TreatingProvider: provider.HPCSANumber,
      Id: uuidV4(),
      StartTime: moment().toDate(),
      // EndTime: visit.CheckInTime ,//todo add 5min time
      //IsWalkIn: true,
      EventType: CALENDAR_EVENT_TYPE.PATIENT_VISIT,
      VisitInfo: this.buildPatientInfo(patient),
    } as CalendarEvent;

    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('CalendarEvent')
        .doc(patientVisit.Id)
        .set(this.dropNullUndefined(patientVisit), { merge: true })
    ).pipe(map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)));
  }

  checkinInProgressPatient(
    bpn: string,
    visit: PatientEventVo,
    provider: ProviderConfigurationVo
  ): Observable<RestApiResultOfCheckinPatientVisitResult> {
    // TODO check if endpoint should be called to create visit
    const patientVisit = {
      ClinicalStatus: CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS,
      // AD intentionally don't set Status to keep visit hidden for nova user
      //Status: CALENDAR_EVENT_STATUS.CHECKED_IN,
      TreatingProvider: provider.HPCSANumber,
      Id: visit.PatientEventXRef,
      StartTime: visit.CheckInTime,
      // EndTime: visit.CheckInTime ,//todo add 5min time
      IsWalkIn: true,
      EventType: CALENDAR_EVENT_TYPE.PATIENT_VISIT,
      VisitInfo: {
        ...this.buildPatientInfo(visit.Patient),
        VisitType: this.toVisitType(visit.Type),
        IsWalkIn: true, //exists on both levels
      },
    } as CalendarEvent;

    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('CalendarEvent')
        .doc(visit.PatientEventXRef)
        .set(this.dropNullUndefined(patientVisit), { merge: true })
    ).pipe(map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)));
  }

  private buildPatientInfo(patient: PatientVo): VisitInfo {
    return {
      //CheckInTime: moment().toDate(),
      PatientInfo: {
        UUID: patient.PatientId,
        Name: patient.PatientDetails.FirstName,
        Surname: patient.PatientDetails.Surname,
        Cellphone: patient.PatientDetails.ContactNo,
        DateOfBirth: patient.PatientDetails.DateOfBirth,
        Email: patient.PatientDetails.EmailAddress,
        FileNumber: patient.FileNo,
        IdentityNo: patient.PatientDetails.IdentityNo,
        PatientId: patient.PatientXRef,
        DependantCode: patient.PatientAccountDetails.MedicalAidDependentCode,
      },
      AccountInfo: {
        AccountId: patient.PatientAccountDetails.AccountId,
        AccountNo: patient.PatientAccountDetails.AccountNo,
        SchemeName: patient.PatientAccountDetails.MedicalAidName,
        PlanName: patient.PatientAccountDetails.MedicalAidPlan,
        //OptionName: visit.Patient.PatientAccountDetails.MedicalAidPlanOption
      },
      CashAccount: patient.PatientAccountDetails.IsCashAccount,
      VisitType: VISIT_TYPE.REGULAR_CONSULTATION,
    };
  }

  toVisitType(type: string): VISIT_TYPE {
    if (type == PatientEventType.MedicalInsuranceType) {
      return VISIT_TYPE.MEDICAL_INSURANCE;
    }
    if (type == PatientEventType.RegularConsultationType) {
      return VISIT_TYPE.REGULAR_CONSULTATION;
    }
    if (type == PatientEventType.NoChargeType) {
      return VISIT_TYPE.NO_CHARGE;
    }
    if (type == PatientEventType.OutOfRoomsType) {
      return VISIT_TYPE.OUT_OF_ROOMS;
    }

    return VISIT_TYPE.CUSTOM;
  }

  markVisitInProgress(bpn: string, visit: PatientEventVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('CalendarEvent')
        .doc(visit.PatientEventXRef)
        .set({ ClinicalStatus: CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS }, { merge: true })
    ).pipe(map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)));
  }

  completeVisit(bpn: string, visit: PatientEventVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('CalendarEvent')
        .doc(visit.PatientEventXRef)
        .set({ ClinicalStatus: CALENDAR_EVENT_STATUS.CHECKED_OUT }, { merge: true })
    ).pipe(map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)));
  }

  cancelPatientVisit(bpn: string, hpcsaNo: string, xref: string): Observable<RestApiResultOfGuid> {
    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('CalendarEvent')
        .doc(xref)
        // todo confirm if we okay to cancel only doctor's visit
        .set({ ClinicalStatus: CALENDAR_EVENT_STATUS.CANCELLED }, { merge: true })
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getPatientVisitHistory(bpn: string, hpcsaNo: string): Observable<PatientEventsByDateVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r =>
        r.where('TreatingProvider', '==', hpcsaNo).where('StartTime', '>', moment().add(-21, 'day').toDate())
      )
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        map(events =>
          events.map(
            (calendar: CalendarEvent | any) =>
              ({
                PatientEvent: this.toPatientEvent(calendar, null),
                BranchName: calendar.Branch,
                EncounterType: 'todo',
              } as PatientEventView)
          )
        ),
        map(list =>
          _.chain(list)
            .filter(s =>
              [PatientEventVoStatus._1, PatientEventVoStatus._2, PatientEventVoStatus._3, PatientEventVoStatus._4].includes(
                s.PatientEvent.Status
              )
            )
            .value()
        ),
        map(list =>
          _.chain(list)
            .groupBy(s => moment(s.PatientEvent.CheckInTime).format('YYYY-MM-DD'))
            .map(values => ({
              Date: values[0].PatientEvent.CheckInTime,
              PatientEvents: _.chain(values)
                .orderBy(it => it?.PatientEvent.CheckInTime, 'desc')
                .value(),
            }))
            .orderBy(s => s.Date, 'desc')
            .value()
        )
      );
  }

  getPatientDetails(bpn: string, id: string) {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Patient')
      .doc(id)
      .get()
      .pipe(
        take(1),
        map(ref => {
          if (!ref.exists) {
            return null;
          }
          const data = ref.data();

          return {
            ...data,
            Id: ref.id,
            DateOfBirth: fromTimestamp(data['DateOfBirth']),
          } as AccountMember;
        })
      );
  }

  getAccountDetails(bpn: string, accountId: string) {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Account')
      .doc(accountId)
      .get()
      .pipe(
        take(1),
        map(ref => {
          if (!ref.exists) {
            return null;
          }
          const data = ref.data();
          return {
            ...data,
            Id: ref.id,
            LastBenefitCheckDate: fromTimestamp(data['LastBenefitCheckDate']),
          } as Account;
        })
      );
  }

  getWaitingRoomsForPractice(bpn: string, providers: ProviderConfigurationVo[]): Observable<PatientInWaitingRoom[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r =>
        r.where('ClinicalStatus', 'in', [CALENDAR_EVENT_STATUS.CHECKED_IN, CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS])
      )
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        //take(1), // todo confirm if we can have active listener
        map(events =>
          events
            // filter out events if they cancelled on nova and there is no active encounter on our side
            .filter(
              (d: CalendarEvent) =>
                d.ClinicalStatus == CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS ||
                d.Status == CALENDAR_EVENT_STATUS.CHECKED_IN ||
                d.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_IN
            )
            .map((d: CalendarEvent | any) => ({
              PatientEvent: this.toPatientEvent(d, providers.find(p => p.HPCSANumber == d.TreatingProvider)?.PracticeId),
            }))
        ),
        map(list =>
          _.chain(list)
            .orderBy(it => it?.PatientEvent?.CheckInTime, 'asc')
            .value()
        )
      );
  }

  private toPatientEvent(calendar: CalendarEvent | any, practiceId): PatientEventVo {
    const visitType = this.toPatientEventType(calendar.VisitInfo?.VisitType);
    const patientVisit = {
      PatientEventId: calendar.Id && guidByString(calendar.Id, 5),
      PracticeId: practiceId,
      BenefitCheckStatus: calendar.VisitInfo?.BenefitCheckStatus,
      BenefitCheckPdfLink: null, // todo no link on nova
      CheckInTime: calendar.StartTime?.toDate(),
      CompletedTime: this.getVisitDateForStatus(calendar.Statuses, CALENDAR_EVENT_STATUS.CHECKED_OUT),
      ScheduledTime: this.getVisitDateForStatus(calendar.Statuses, CALENDAR_EVENT_STATUS.BOOKED),
      PatientEventXRef: calendar.Id,
      VideoChatLink: null, // todo not supported yet
      Status: this.toPatientStatus(calendar.ClinicalStatus),
      Type: visitType,
      Cancelled: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CANCELLED,
      CheckedIn: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_IN,
      Completed: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_OUT,
      ProviderNote: calendar.VisitInfo?.NoteToProvider,
      MedicalInsurance:
        (visitType == PatientEventType.MedicalInsuranceType && {
          Broker: calendar.VisitInfo.PatientInfo.InsuranceBroker,
          MedicalInsurer: {
            Id: calendar.VisitInfo.PatientInfo.Insurer,
            // todo fill
          },
          PolicyNo: calendar.VisitInfo.PatientInfo.InsurancePolicyNo,
          PatientIdentityNo: calendar.VisitInfo.PatientInfo.IdentityNo,
        }) ||
        null,
      BranchName: calendar.Branch,
      BranchXRef: calendar.Branch, // branch name is id in nova
      IsClinicalVisit: !calendar.Status, // if status not provided means we created this visit
      Patient: {
        PatientId: calendar.VisitInfo?.PatientInfo?.UUID || guidByString(calendar.VisitInfo?.PatientInfo?.PatientId || 's', 5) || null,
        PatientXRef: calendar.VisitInfo?.PatientInfo?.PatientId,
        PatientDetails: {
          FirstName: calendar.VisitInfo?.PatientInfo?.Name,
          Surname: calendar.VisitInfo?.PatientInfo?.Surname,
          ContactNo: calendar.VisitInfo?.PatientInfo?.Cellphone,
        },
      },
    } as PatientEventVo;
    return patientVisit;
  }

  getVisitDateForStatus(statuses: CalendarEventStatusHistory[], status: CALENDAR_EVENT_STATUS): Date {
    return (statuses?.find(st => st.Status == status) as any)?.AppliedAt?.toDate();
  }

  toPatientEventType(visitType: VISIT_TYPE): string {
    if (VISIT_TYPE.MEDICAL_INSURANCE == visitType) {
      return PatientEventType.MedicalInsuranceType;
    }
    if (VISIT_TYPE.NO_CHARGE == visitType) {
      return PatientEventType.NoChargeType;
    }
    if (VISIT_TYPE.REGULAR_CONSULTATION == visitType) {
      return PatientEventType.RegularConsultationType;
    }
    if (VISIT_TYPE.OUT_OF_ROOMS == visitType) {
      return PatientEventType.OutOfRoomsType;
    }
    // todo not all types are supported

    return;
  }

  toPatientStatus(status: CALENDAR_EVENT_STATUS | CLINICAL_CALENDAR_EVENT_STATUS): PatientEventVoStatus {
    /** 0 = Scheduled, 1 = CheckedIn, 2 = InProgress, 3 = Completed, 4 = Canceled, 5 = Deleted */
    if (status == CALENDAR_EVENT_STATUS.BOOKED) {
      return PatientEventVoStatus._0;
    }
    if (status == CALENDAR_EVENT_STATUS.CHECKED_IN) {
      return PatientEventVoStatus._1;
    }
    // own status
    if (status == CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS) {
      return PatientEventVoStatus._2;
    }
    if (status == CALENDAR_EVENT_STATUS.CHECKED_OUT) {
      return PatientEventVoStatus._3;
    }
    if (status == CALENDAR_EVENT_STATUS.CANCELLED) {
      return PatientEventVoStatus._4;
    }
    if (status == CALENDAR_EVENT_STATUS.DELETED) {
      return PatientEventVoStatus._5;
    }
  }

  getPatientFileWomensHealth(practiceId: string, patientId: string): Observable<PatientFileGenericInformationVo> {
    const ref = this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceId)
      .collection('PatientFileWomenHealth', r => r.where('PatientId', '==', patientId));

    return ref.valueChanges().pipe(
      map(doc => {
        if (doc.length > 0) {
          const womenHealth = doc.map((data: any) => ({
            ...data,
            CapturedDate: data.CapturedDate?.toDate(),
          }));
          // guarantee we pickup last edited doc
          return _.orderBy(womenHealth, d => d.CapturedDate, 'desc');
        }
        return [{}] as PatientFileGenericInformationVo[];
      }),
      take(1),
      map(womenHealths => womenHealths[0])
    );
  }

  updatePatientFileWomensHealth(
    practiceId: string,
    patientId: string,
    womensHealth: PatientFileGenericInformationVo
  ): Observable<RestApiResultOfGuid> {
    const ref = this.patientWomensHealthRef(practiceId)
      .doc(womensHealth.PatientFileGenericInformationId)
      .set({
        ...womensHealth,
        CapturedDate: womensHealth.CapturedDate && Timestamp.fromDate(womensHealth.CapturedDate),
      });

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  removePatientGenericInformation(practiceId: string, patientId: string, womensHealthId: string): Observable<RestApiResultOfBoolean> {
    const ref = this.patientWomensHealthRef(practiceId).doc(womensHealthId).delete();

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfBoolean)));
  }

  patientWomensHealthRef(practiceId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceId).collection('PatientFileWomenHealth');
  }

  getPatientConfigurations(practiceTenantId: string, patientId: string): Observable<PatientConfigurationVo> {
    return this.patientConfigurationCollectionRef(practiceTenantId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map(data => (data && { ...data, CapturedDate: data.CapturedDate.toDate() }) || null),
        first()
      );
  }

  revisePatientClinicalNotes(practiceTenantId: string, patientId: string, data: PatientClinicalNotesVo) {
    return from(
      this.patientClinicalNoteCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...this.dropNullUndefined(data),
          CapturedDate: data.CapturedDate && Timestamp.fromDate(data.CapturedDate),
        })
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  /**
   * Take a note, the following observable is real-time listener intentionally to propagate change (and update akita)
   */
  getPatientClinicalNotes(practiceId: string, patientId: string): Observable<PatientClinicalNotesVo> {
    return this.patientClinicalNoteCollectionRef(practiceId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map((data: any) => ({ ...data, CapturedDate: data?.CapturedDate?.toDate() })),
        distinctUntilChanged(_.isEqual),
        catchError(() => of(null))
      );
  }

  getPatientAllergies(practiceTenantId: string, patientId: string): Observable<PatientAllergiesVo> {
    return this.patientAllergiesCollectionRef(practiceTenantId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map((data: any) => ({
          ...data,
          Patient: convertPatientToFirestore(data?.Patient),
          PatientAllergies: data?.PatientAllergies?.map(s => ({ ...s, CapturedDate: s?.CapturedDate?.toDate() })),
          NoAllergies: data.NoAllergies || null,
        })),
        distinctUntilChanged(_.isEqual),
        first(),
        catchError(() => of(null))
      );
  }

  revisePatientAllergies(practiceTenantId: string, patientId: string, allergies: PatientAllergiesVo): Observable<RestApiResultOfGuid> {
    return from(
      this.patientAllergiesCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...allergies,
          Patient: this.dropNullUndefined(convertPatientToFirestore(allergies?.Patient)),
          PatientAllergies: allergies.PatientAllergies?.map(s => ({
            ...s,
            CapturedDate: s.CapturedDate && Timestamp.fromDate(s.CapturedDate),
          })),
        })
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  updatePatientConfiguration(practiceTenantId: string, patientId: string, configuration: PatientConfigurationVo) {
    return from(
      this.patientConfigurationCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...configuration,
          CapturedDate: configuration.CapturedDate && Timestamp.fromDate(configuration.CapturedDate),
        })
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  private patientClinicalNoteCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientClinicalNote');
  }
  private patientAllergiesCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientAllergies');
  }

  private patientLifestyleCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientLifestyle');
  }

  private patientConfigurationCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('FlexibleDataStore');
  }

  getChronicConditions(): Observable<CommonChronicConditionsVo[]> {
    const ref: AngularFirestoreCollection<CommonChronicConditionsVo> = this.firestore.collection('ClinicalChronicConditions');
    return ref.valueChanges().pipe(first());
  }

  filterTrackingConditions(practiceTenantId: string, filter: ConditionFilterVo): Observable<PatientChronicConditionsVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(CHRONIC_CONDITION_ROOT, r => r.where('IcdCodes', 'array-contains-any', filter.IcdCodes))
      .valueChanges()
      .pipe(
        first(),
        map((data: any[]) => data.map(item => convertFirestoreToCondition(item))),
        // filter by from / to / gender in memory
        map(data =>
          data
            .filter(s => (filter.FromAge || 0) <= moment().diff(s.Patient.PatientDetails.DateOfBirth, 'years'))
            .filter(s => (filter.ToAge || 150) >= moment().diff(s.Patient.PatientDetails.DateOfBirth, 'years'))
            .filter(s => (filter.Gender && filter?.Gender === s.Patient.PatientDetails.Gender) || !filter?.Gender)
        ),
        // use case insensitive sort, otherwise in some cases order might be not obvious (e.g. iPad user will be last)
        map(data => _.orderBy(data, d => d.Patient?.PatientDetails?.FirstName?.toLocaleLowerCase()))
      );
  }

  revisePatientChronicConditions(
    practiceTenantId: string,
    patientId: string,
    conditions: PatientChronicConditionsVo
  ): Observable<RestApiResultOfGuid> {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection(CHRONIC_CONDITION_ROOT)
        .doc(patientId)
        .set({ ...convertConditionToFirestore(this.dropNullUndefined(conditions)) })
    ).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  removeTrackingConditions(practiceTenantId: string, patientId: string, icdCodes: string[]): Observable<RestApiResultOfGuid> {
    return this.getPatientChronicConditions(practiceTenantId, patientId).pipe(
      map(s => ({
        ...s,
        Conditions: s.Conditions.filter(d => !icdCodes.includes(d.Condition.DiagnosisCode)),
      })),
      switchMap(conditions => this.revisePatientChronicConditions(practiceTenantId, patientId, conditions))
    );
  }

  getPatientChronicConditions(practiceTenantId: string, patientId: string): Observable<PatientChronicConditionsVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(CHRONIC_CONDITION_ROOT)
      .doc(patientId)
      .valueChanges()
      .pipe(
        first(),
        map(data => convertFirestoreToCondition(data))
      );
  }

  updatePatient(bpn: string, patientVo: PatientVo, accountXref?: string, mainMemberXRef?: string): Observable<RestApiResultOfGuid> {
    var patientDetails: AccountMember = {
      Title: patientVo.PatientDetails.Title,
      Name: patientVo.PatientDetails.FirstName,
      Surname: patientVo.PatientDetails.Surname,
      PreferredName: patientVo.KnownAs,
      IdentificationType: patientVo.PatientDetails.IdentityNo ? IDENTIFICATION_TYPE.SAID : IDENTIFICATION_TYPE.PASSPORT,
      IdentityNo: patientVo.PatientDetails.IdentityNo,
      Country: patientVo.PatientDetails.Country,
      PassportNumber: patientVo.PatientDetails.PassportNo,
      Gender: patientVo.PatientDetails.Gender,
      Occupation: patientVo.Occupation,
      Employer: patientVo.Employer,
      Contact: {
        Cellphone: patientVo.PatientDetails.ContactNo,
        Email: patientVo.PatientDetails.EmailAddress,
        PhysicalAddress: patientVo.PhysicalAddress,
        PostalAddress: patientVo.PostalAddress,
      },
      DateOfBirth: patientVo.PatientDetails.DateOfBirth,
      FileNo: patientVo.FileNo,
      DependantCode: patientVo.PatientAccountDetails.MedicalAidDependentCode,
    };

    const accountDetails = {
      Id: accountXref,
      MainMemberDependantCode: patientVo.PatientAccountDetails.MedicalAidDependentCode,
      MemberNo: patientVo.PatientAccountDetails.MedicalAidMembershipNumber,
      AccountNo: patientVo.PatientAccountDetails.AccountNo,

      AccountType: patientVo.PatientAccountDetails.IsCashAccount ? ACCOUNT_TYPE.PRIVATE : ACCOUNT_TYPE.MEDICAL_AID,

      Scheme: patientVo.PatientAccountDetails.MedicalAidSchemeCode,
      Plan: patientVo.PatientAccountDetails.MedicalAidPlanCode,
      Option: patientVo.PatientAccountDetails.MedicalAidOptionCode,
      MainMember: mainMemberXRef,
    };

    var mainMemberDetails: AccountMember = {
      Id: mainMemberXRef,
      Title: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Title,
      Name: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.FirstName,
      Surname: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Surname,
      DateOfBirth: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.DateOfBirth,
      /* IdentificationType: IDENTIFICATION_TYPE.SAID,
      IdentityNo: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo, */
      IdentificationType: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo
        ? IDENTIFICATION_TYPE.SAID
        : IDENTIFICATION_TYPE.PASSPORT,
      IdentityNo: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo,
      PassportNumber: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.PassportNo,
      Country: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Country,

      Gender: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Gender,
      //PreferredName: "todo",
      Contact: {
        Cellphone: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.ContactNo,
        Email: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.EmailAddress,
      },
    };
    /*   if (patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo) {
        mainMemberDetails.IdentityNo = patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo;
        mainMemberDetails.IdentificationType = IDENTIFICATION_TYPE.SAID;
      } */

    // accountXref if not null, means we add new patient or edit
    var request = {} as PatientAccount;

    if (patientVo.PatientXRef != null) {
      // 1. edit patient (means editing account or patient or main member)
      request.Patient = patientDetails;
      request.Patient.Id = patientVo.PatientXRef;
      request.Account = accountDetails;
      request.Account.Id = patientVo.PatientAccountDetails.AccountId;
      request.MainMember = mainMemberDetails;
      request.MainMember.Id = patientVo.PatientAccountDetails.MainMemberXRef;
    } else if (accountXref == null) {
      // 2. new account + main member
      request.Account = accountDetails;
      request.MainMember = patientDetails;
    } else {
      // 3. add new patient to existing account
      request.Patient = patientDetails;
      request.Account = { Id: accountXref };
      request.MainMember = { Id: mainMemberXRef };
      // todo as part of adding patient we can update account info (not obvious way)
    }

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<PatientAccountResponse>>(`${this.baseUrl}/v1/account/patient/${bpn}`, request, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            switchMap(s => {
              if (s.success) {
                return of({ Sucess: true, Data: s.data?.payload.PatientGuid });
              }
              var errorMessage = s.error.message;

              return of({
                Data: '',
                Sucess: false,
                Code: s.error.code,
                ResponseMessage: errorMessage,
              } as RestApiResultOfGuid);
            }),
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return throwError(httpError.error.error.message);
              }
              return throwError(httpError);
            })
          )
      )
    );
  }

  getPatientSurgicalHistory(practiceTenantId: string, patientId: string): Observable<PatientSurgicalHistoryVo> {
    const ref = this.patientSurgicalHistoryRef(practiceTenantId).doc(patientId);

    return ref.valueChanges().pipe(first());
  }

  getAllPatientClinicalMetrics(practiceTenantId: string, patientId: string): Observable<ClinicalMetricVo[]> {
    return this.patientMetricRef(practiceTenantId, patientId)
      .valueChanges()
      .pipe(
        take(1),
        switchMap(s =>
          s?.ClinicalMetrics == null
            ? this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').valueChanges().pipe(take(1))
            : of(s.ClinicalMetrics)
        ),
        map((metrics: ClinicalMetricVo[]) => metrics.map(t => convertFirestoreToClinicalMetric(t)) || [])
      );
  }

  saveClinicalMetrics(
    practiceTenantId: string,
    patientId: string,
    allMetrics,
    addMetrics: ClinicalMetricVo[],
    editScreening: boolean
  ): Observable<ClinicalMetricVo[]> {
    return of(allMetrics).pipe(
      switchMap(existingMetrics => {
        const metricId = uuidV4();
        const metrics = addMetrics.map(s => ({ ...s, ReferenceId: s.ReferenceId || metricId }));

        const updatingMetrics = (
          editScreening
            ? _.unionWith(metrics, existingMetrics, (a, b) => this.getMetricUniqueKey(a) === this.getMetricUniqueKey(b))
            : [...existingMetrics, ...metrics]
        ).map(s => convertClinicalMetricToFirestore(this.dropNullUndefined(s)) as any);

        // put in multiple documents if we reach threshold
        if (updatingMetrics.length >= METRICS_THRESHOLD) {
          // add only new metrics as standalone documents as we reached threshold
          return forkJoin([
            // due to firestore batch limit 500 split into chunks
            ..._.chunk(updatingMetrics, 500).map(group => {
              const batch = this.firestore.firestore.batch();
              group.forEach(item => {
                const id = this.getMetricId(item);
                var ref = this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').doc(id).ref;
                batch.set(ref, item);
              });
              return from(batch.commit());
            }),
            from(
              this.patientMetricRef(practiceTenantId, patientId).set({
                PracticeId: practiceTenantId,
                PatientId: patientId,
                ClinicalMetrics: null,
              })
            ),
          ]).pipe(mapTo(metrics));
        }

        return from(
          this.patientMetricRef(practiceTenantId, patientId).set({
            PracticeId: practiceTenantId,
            PatientId: patientId,
            ClinicalMetrics: updatingMetrics,
          })
        ).pipe(mapTo(metrics));
      }),
      catchError(err => {
        console.error('save metric', err);
        return of(null);
      })
    );
  }

  private getMetricId(item: any) {
    return item.Group + '-' + item.Name + '-' + moment(item.TestDate.toDate()).format('YYYY-MM-DD');
  }

  private getMetricUniqueKey(item: any) {
    return item.Group + '-' + item.Name + '-' + moment(item.TestDate).format('YYYY-MM-DD');
  }

  removePatientClinicalMetrics(
    practiceTenantId: string,
    patientId: string,
    allMetrics: ClinicalMetricVo[],
    referenceId: string,
    allowedTypes: string[]
  ) {
    // todo use key: group + name + referenceId
    const deleteMetrics = allMetrics.filter(s => s.ReferenceId === referenceId && allowedTypes.includes(s.Name));
    const updateMetrics = allMetrics.filter(s => !_.includes(deleteMetrics, s));

    if (allMetrics.length >= METRICS_THRESHOLD) {
      // add only new metrics as standalone documents as we reeched threshold
      return forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(deleteMetrics, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.delete(this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').doc(this.getMetricId(item)).ref);
          });
          return batch.commit();
        }),
      ]).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
    }

    return from(
      this.patientMetricRef(practiceTenantId, patientId).set({
        PracticeId: practiceTenantId,
        PatientId: patientId,
        ClinicalMetrics: updateMetrics,
      })
    ).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  private patientSurgicalHistoryRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientHospitalization');
  }

  private patientMetricRef(practiceTenantId: string, patientId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientClinicalMetric').doc(patientId);
  }

  updatePatientSurgicalHistory(practiceTenantId: string, patientId: string, hospitalizations: PatientSurgicalHistoryVo) {
    const ref = this.patientSurgicalHistoryRef(practiceTenantId).doc(patientId).set(hospitalizations);

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getPatientLifestyle(practiceid: string, patientId: string): Observable<PatientLifestyleVo> {
    const ref = this.patientLifestyleCollectionRef(practiceid).doc(patientId);

    return ref.valueChanges().pipe(first());
  }

  updatePatientLifestyle(practiceid: string, patientId: string, lifestyle: PatientLifestyleVo) {
    const ref = this.patientLifestyleCollectionRef(practiceid).doc(patientId).set(lifestyle);

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }
}
