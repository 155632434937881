import * as moment from 'moment';

export interface PatientAge {
  unit: number;
  unitType: string;
  longAge: string;
}

export function patientAge(dob: Date): PatientAge {
  const momentDob = moment.utc(dob);
  const momentToday = moment();
  const duration = moment.duration(momentToday.diff(momentDob));

  if (duration.as('day') < 1) {
    return {
      unit: Math.floor(duration.asHours()),
      unitType: 'hours',
      longAge: Math.floor(duration.asHours()) + 'h',
    };
  }
  if (duration.as('week') < 1) {
    return {
      unit: Math.floor(duration.asDays()),
      unitType: 'days',
      longAge: Math.floor(duration.asDays()) + 'd',
    };
  }
  if (duration.as('month') < 1) {
    return {
      unit: Math.floor(duration.asWeeks()),
      unitType: 'weeks',
      longAge:
        Math.floor(duration.asWeeks()) +
        'w' +
        ' ' +
        Math.floor(duration.subtract(moment.duration(Math.floor(duration.asWeeks()), 'weeks')).asDays()) +
        'd',
    };
  }
  if (duration.as('years') < 1) {
    return {
      unit: Math.floor(duration.asMonths()),
      unitType: 'months',
      longAge:
        Math.floor(duration.asMonths()) +
        'm' +
        ' ' +
        Math.floor(duration.subtract(moment.duration(Math.floor(duration.asMonths()), 'months')).asWeeks()) +
        'w',
    };
  }
  if (duration.as('years') < 20) {
    return {
      unit: Math.floor(duration.asYears()),
      unitType: 'years',
      longAge:
        Math.floor(duration.asYears()) +
        'y' +
        ' ' +
        Math.floor(duration.subtract(moment.duration(Math.floor(duration.asYears()), 'years')).asMonths()) +
        'm',
    };
  }
  return { unit: Math.floor(duration.as('years')), unitType: 'years', longAge: Math.floor(duration.as('years')).toString() };
}
