import { Injectable } from '@angular/core';
import { ValidatorFn, Validators, FormControl, FormGroup } from '@angular/forms';
import { QuestionBase } from 'api-clinician-app';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  constructor() {}

  addControlsToFormGroup(fg: FormGroup, questions: QuestionBase[]) {
    questions.forEach(question => {
      const validators: ValidatorFn[] = [];

      if (question.Required) {
        validators.push(Validators.required);
      }
      // if (!!question.Validators) {
      //   validators.push(...question.Validators);
      // }

      const updateOn = ['textbox', 'timespan', 'textarea'].includes(question.ControlType) ? 'blur' : 'change';
      let questionAnswer: any = question.Answer || '';
      if (question.ControlType === 'datepicker' && !question.Answer && question.QuestionKey.includes('dob')) {
        questionAnswer = !!question.Answer ? new Date(question.Answer) : new Date();
      }

      const control = new FormControl(questionAnswer, { validators, updateOn });
      fg.addControl(question.QuestionKey, control);

      const notesControl = new FormControl(question.Notes || '', { updateOn: 'blur' });
      fg.addControl(`${question.QuestionKey}-notes`, notesControl);
    });
  }
}
