const emailAddressRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface String {
  isValidEmailAddress(): boolean;
}

String.prototype.isValidEmailAddress = function () {
  return emailAddressRegEx.test(this);
};
