import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { selectPersistStateInit } from '@datorama/akita';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineInitialisedGuard implements CanActivate {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return selectPersistStateInit().pipe(mapTo(true));
  }
}
