import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, SuperAdminOnlyGuard, OfflineInitialisedGuard, HBClinicalContractGuard } from './core/guards';
import { ProviderDataResolverService, PatientDataResolverService } from './core/resolvers';
import { AppCustomPreloader } from './core/app-custom-preloader';
import { ResolveHomeComponent } from './core/nav/resolve-home/resolve-home.component';
import { environment } from '@env/environment';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { FRONT_DESK_PERMISSION } from './core/services/role-manager.service';

const moduleRoutes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
    data: { preload: true },
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [OfflineInitialisedGuard],
    data: { preload: true },
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [OfflineInitialisedGuard, AuthGuard, SuperAdminOnlyGuard],
    data: { preload: false },
  },
  {
    path: ':tenantId/home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [OfflineInitialisedGuard, AuthGuard, HBClinicalContractGuard],
    data: { preload: true },
    resolve: { provider: ProviderDataResolverService },
  },
  {
    path: ':tenantId/patient',
    loadChildren: () => import('./modules/patient/patient.module').then(m => m.PatientModule),
    canActivate: [OfflineInitialisedGuard, AuthGuard, HBClinicalContractGuard, NgxPermissionsGuard],
    data: {
      preload: true,
      permissions: {
        except: FRONT_DESK_PERMISSION.DenyViewPatientFile,
        redirectTo: 'error/unauthorized',
      },
    },
    resolve: { provider: ProviderDataResolverService },
  },
  {
    path: ':tenantId/provider',
    loadChildren: () => import('./modules/provider/provider.module').then(m => m.ProviderModule),
    canActivate: [OfflineInitialisedGuard, AuthGuard],
    data: { preload: true },
    resolve: { provider: ProviderDataResolverService },
  },
  // {
  //   path: ':tenantId/pathology',
  //   loadChildren: () => import('./modules/pathology/pathology.module').then(m => m.PathologyModule),
  //   canActivate: [OfflineInitialisedGuard, AuthGuard, HBClinicalContractGuard, NgxPermissionsGuard],
  //   data: {
  //     preload: true,
  //     permissions: {
  //       except : FRONT_DESK_PERMISSION.DenyViewPathology,
  //       redirectTo: 'error/unauthorized',
  //     }
  //   },
  //   resolve: { provider: ProviderDataResolverService }
  // },
];

const errorCatchRoutes: Routes = [{ path: '**', redirectTo: '/error' }];

const routes: Routes = [
  // FEATURE MODULE ROUTES
  ...moduleRoutes,

  // DEFAULT AND ERROR ROUTES
  {
    path: '',
    pathMatch: 'full',
    canActivate: [OfflineInitialisedGuard, AuthGuard],
    component: ResolveHomeComponent,
  },
  ...(environment.production ? errorCatchRoutes : []),
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppCustomPreloader,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      // anchorScrolling: 'enabled',
      // enableTracing: true
    }),
  ], // , enableTracing: true
  exports: [RouterModule],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule {}
