import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// based loosely on:
// https://netbasal.com/detect-unsaved-changes-in-angular-forms-75fd8f5f1fa6

export interface IDirtyAutoSaveComponent {
  isDirty$: Observable<boolean>;
  save(): Observable<boolean>;
}
/**
 *
 *
 * An example of an isDirty$ implementation
 * isDirty$ = this.allergyForm.valueChanges.pipe(
 *  startWith(false), // Without a starting value, we may never get a dirty value and our router won't work, gross
 *  shareReplay(1), // Share the latest value at subscription, without a value, our router won't work, yuck
 * );
 *
 * NOTE: Ensure you update the patient-routing.module.ts when implementing this guard.
 */
@Injectable({ providedIn: 'root' })
export class DirtyCheckAutoSaveGuard implements CanDeactivate<IDirtyAutoSaveComponent> {
  constructor() {}

  canDeactivate(component: IDirtyAutoSaveComponent) {
    // if, beacuse of a race condition, the component isn't fully set up yet,
    // rather then just let the component deactivate
    if (!component || !component.isDirty$) {
      return of(true);
    }

    return component.isDirty$.pipe(switchMap(dirty => (!dirty ? of(true) : component.save())));
  }
}
