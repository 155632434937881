import { Injectable } from '@angular/core';
import {
  map,
  mapTo,
  tap,
  mergeMap,
  withLatestFrom,
  filter,
  repeatWhen,
  delay,
  switchMap,
  takeUntil,
  skip,
  catchError,
  take,
  distinctUntilChanged,
  shareReplay,
  concatMap,
} from 'rxjs/operators';
import { timer, concat, Observable, combineLatest, of, from, interval } from 'rxjs';
import * as _ from 'lodash';
import { ClinicalNewClient, PatientVo, PatientUpdateTrackingVo } from './api-client.service';
import { PatientsService } from './patients.service';
import { ProviderService } from './provider.service';
import { AuthQuery } from './state/auth/auth.query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { UiStateStore } from './state/ui-state/ui-state.store';
import { ProvidersQuery } from './state/admin/providers.query';
import { PatientsStore } from './state/patients/patients.store';
import * as moment from 'moment';
import { ConfigService } from './config.service';
import { ProviderQuery } from './state/provider/provider.query';
import { applyTransaction } from '@datorama/akita';
import { ApplicationInsightsService } from './application-insights.service';

interface PatientsForUpdate {
  tenantId: string;
  hasUpdate: boolean;
  maxUpdated: Date;
  patients: PatientUpdateTrackingVo[];
}

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  auth$ = this.authQuery.auth$;
  online$ = this.uiQuery.online$;
  currentTenantId$ = this.authQuery.currentTenantId$.pipe(distinctUntilChanged(), shareReplay(1));
  lastPatientUpdated$ = this.uiQuery.lastPatientUpdated$;
  superAdmin$ = this.authQuery.superAdmin$;
  pageVisible$ = this.uiQuery.select(state => state.pageVisible);

  constructor(
    private uiQuery: UiStateQuery,
    private uiStore: UiStateStore,
    private authQuery: AuthQuery,
    private providerQuery: ProviderQuery,
    private patientsService: PatientsService,
    private configService: ConfigService,
    private patientsStore: PatientsStore,
    private providerService: ProviderService,
    private clinicalClient: ClinicalNewClient,
    private appInsightsService: ApplicationInsightsService
  ) {
    // disable patient polling as backend data is not available and won't be used going forward for forge client
    //this.setupPatientPolling();
    this.setupProviderDetailPolling();
    this.logEventForStorageAvailable();
  }

  private logEventForStorageAvailable() {
    if (navigator.storage && 'estimate' in navigator.storage) {
      from(navigator.storage.estimate())
        .pipe(
          switchMap(({ usage, quota }) => {
            if ('persisted' in navigator.storage) {
              return from(navigator.storage.persisted()).pipe(map(persist => ({ usage, quota, persist })));
            } else {
              return of({ usage, quota, persist: false });
            }
          })
        )
        .subscribe(({ usage, quota, persist }) => {
          console.log('[Storage]', `Used ${usage} of ${quota} bytes. Persist: ${persist}`);

          this.appInsightsService.trackMetric('LocalStorageQuota', quota);
          this.appInsightsService.trackMetric('LocalStorageUsed', usage);
          this.appInsightsService.trackMetric('LocalStoragePersisted', persist ? 1 : 0);
        });
    }
  }

  private setupProviderDetailPolling() {
    const {
      enabled,
      delayBeforeStart, // after application start-up, how long should we wait before starting
      delayBetweenPatients, // delay between patients so as not to saturate network/server
      delayBetweenChecks, // between server check-ins for updated data, how long shall we wait
    } = this.configService.config.offlineSync;
    const verboseLogging = !!this.configService.config.settings.verboseLogging;

    setTimeout(() => {
      of('')
        .pipe(
          // arbitrary observable to ensure unique results in "withLatestFrom"
          untilDestroyed(this, 'destroy'),
          repeatWhen(completed => completed.pipe(delay(delayBetweenChecks))), // when finished, delay, then repeat

          // get recent variables in case of changes to impersonation or online status
          withLatestFrom(this.currentTenantId$, this.auth$, this.online$, this.pageVisible$), // get latest info (in case of changes)

          // don't fire unless authenticated, in a provider context, online and page is currently visible
          // (prevents constant query when user has tab open indefinitely)
          filter(([t, tenantId, auth, online, pageVisible]) => !!auth && auth.authenticated && !!tenantId && online && pageVisible),

          // remap to remove
          map(([t, tenantId, auth, online]) => tenantId),
          // todo switch to active listener when we switch to firestore, otherwise active polling would be expensive
          // get provider data from the server
          switchMap(providerId => this.providerService.getProvider(providerId)),

          // catch errors
          catchError(err => {
            console.error('provider polling', err);
            return of(null);
          })
        )
        .subscribe({
          next: () => {
            if (verboseLogging) {
              console.log('[Offline Service]', `Synchronised provider details`);
            }
          },
        });
    }, delayBeforeStart);
  }

  private setupPatientPolling() {
    const {
      enabled,
      delayBeforeStart, // after application start-up, how long should we wait before starting
      delayBetweenPatients, // delay between patients so as not to saturate network/server
      delayBetweenChecks, // between server check-ins for updated data, how long shall we wait
    } = this.configService.config.offlineSync;
    const verboseLogging = !!this.configService.config.settings.verboseLogging;

    let currentProgress = 0;
    let totalRecords = 0;

    if (!enabled) {
      // console.log('[Offline Service]', 'Patient sync is disabled in config');
      return;
    }

    setTimeout(() => {
      of('')
        .pipe(
          // arbitrary observable to ensure unique results in "withLatestFrom"
          untilDestroyed(this, 'destroy'),
          repeatWhen(completed => completed.pipe(delay(delayBetweenChecks))), // when finished, delay, then repeat

          withLatestFrom(this.currentTenantId$, this.auth$, this.online$, this.lastPatientUpdated$, this.superAdmin$, this.pageVisible$),
          tap({ next: () => console.log('[Offline Service]', `Starting patient sync...`) }),

          // don't fire unless authenticated, in a provider context, and online, and not super admin
          filter(
            ([t, tenant, auth, online, lastUpdated, superAdmin, pageVisible]) =>
              !!auth && auth.authenticated && !!tenant && online && !superAdmin
            // && pageVisible
          ),

          // get patients against provided last update date
          switchMap(([t, tenant, auth, online, lastUpdated]) =>
            this.getUpdatedPatients(tenant, lastUpdated).pipe(catchError(err => of(null)))
          ),

          tap({
            next: (t: PatientsForUpdate) => {
              if (!!t && verboseLogging) {
                console.log('[Offline Service]', t.hasUpdate ? `Found ${t.patients.length} patients to update.` : 'No updates');
              }
            },
          }),

          // stop here when no updated patients are found
          filter(upd => !!upd && upd.hasUpdate && !!upd.patients),

          tap({
            next: t => {
              // progress tracking total side effect
              currentProgress = 0;
              totalRecords = t.patients.length;
            },
          }),
          map(upd => upd.patients as PatientUpdateTrackingVo[]), // map to array of patient updates...

          withLatestFrom(this.currentTenantId$),
          // - map to observable that is a concat of all the patient update calls
          // - use concat (not merge) of observables to ensure patients are done in order, and only when previous completes
          // - when these each individually fire they will introduce a delay before emitting

          switchMap(([patients, tenantId]) =>
            from(patients as PatientUpdateTrackingVo[]).pipe(
              concatMap(patient =>
                this.patientsService.getPatientForOffline(tenantId, patient.PatientId).pipe(
                  delay(delayBetweenPatients),
                  mapTo(patient),
                  tap({ error: err => console.error(err) }), // log the error
                  catchError(err => of(null)), // return null for errors (filtered later)
                  tap(p => {
                    if (!!p) {
                      applyTransaction(() => {
                        // in case we caught an error above, suppress the particular patient update
                        // (1) update the patient's last updated with this info
                        this.patientsStore.update(p.PatientId, { lastChecked: new Date(), lastUpdated: p.LastUpdated });

                        // (2) update the global last updated to keep track of progress
                        this.uiStore.update({ lastPatientUpdated: p.LastUpdated });
                      });

                      currentProgress += 1;
                      if (verboseLogging) {
                        console.log('[Offline Service]', `Synchronised patient ${currentProgress} of ${totalRecords}.`);
                      }
                    }
                  })
                )
              ),

              // these conditions on the concat, will ensure that we "break the sync" if any change while busy
              // use skip(1) to ensure that we are only looking for changes, as Store uses BehaviorSubject to replay last value
              takeUntil(this.online$.pipe(distinctUntilChanged(_.isEqual), skip(1))), // stop if online changes
              takeUntil(this.auth$.pipe(distinctUntilChanged(_.isEqual), skip(1))), // stop if auth changes
              takeUntil(this.currentTenantId$.pipe(distinctUntilChanged(_.isEqual), skip(1))) // stop if tenantId changes
            )
          )

          /*
          switchMap(([ patients, tenantId] ) => {
            const concat$ = patients.map(patient =>
              this.patientsService.getPatientForOffline(tenantId, patient.PatientId).pipe(
                delay(delayBetweenPatients),
                mapTo(patient),
                tap({ error: err => console.error(err) }), // log the error
                catchError(err => of(null)), // return null for errors (filtered later)
              ),
            );

            return concat(...concat$).pipe(
              // these conditions on the concat, will ensure that we "break the sync" if any change while busy
              // use skip(1) to ensure that we are only looking for changes, as Store uses BehaviorSubject to replay last value

              takeUntil(this.online$.pipe(distinctUntilChanged(_.isEqual), skip(1))), // stop if online changes
              takeUntil(this.auth$.pipe(distinctUntilChanged(_.isEqual), skip(1))), // stop if auth changes
              takeUntil(this.currentTenantId$.pipe(distinctUntilChanged(_.isEqual), skip(1))) // stop if tenantId changes
            );
          }),
          */

          /*
          tap({
            next: (patient: PatientUpdateTrackingVo) => {
              if (!!patient) {
                // in case we caught an error above, suppress the particular patient update
                // (1) update the patient's last updated with this info
                this.patientsStore.update(patient.PatientId, { lastChecked: new Date(), lastUpdated: patient.LastUpdated });

                // (2) update the global last updated to keep track of progress
                this.uiStore.update({ lastPatientUpdated: patient.LastUpdated });
              }
            },
          })
          */
        )
        .subscribe({
          complete: () => {
            console.log('[Offline Service]', `Ended.`);
          },
          error: err => {
            console.error('[Offline Service]', `Error.`);
          },
        });
    }, delayBeforeStart);
  }

  private getUpdatedPatients(tenantId: string, since: Date): Observable<PatientsForUpdate> {
    const firstDate = moment().subtract(1, 'year').toDate();
    const s = typeof since === 'string' ? new Date(since) : since; // just some house-keeping for weird behaviour

    // if since is null, then start from a year prior to current date (firstDate)
    // if since is earlier than firstDate, then start from firstDate
    const dateFrom = !s || s < firstDate ? firstDate : s;

    const request = this.clinicalClient.getPatientsLastChangeTime(tenantId, dateFrom);
    return request.pipe(
      map(d => d.Data),
      map(patients => {
        const clinicalPatients = patients.filter(patient => !!patient.IsClinical);
        if (clinicalPatients && clinicalPatients.length > 0) {
          const maxUpdated = _.maxBy(clinicalPatients, 'LastUpdated').LastUpdated;
          const patientListSorted = _.sortBy(clinicalPatients, 'LastUpdated');
          return { tenantId, hasUpdate: true, maxUpdated, patients: patientListSorted } as PatientsForUpdate;
        } else {
          return { tenantId, hasUpdate: false, maxUpdated: null, patients: null } as PatientsForUpdate;
        }
      })
    );
  }

  destroy() {}
}
