import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';

export interface AvatarsState extends EntityState<AvatarViewModel> {}

export interface AvatarViewModel {
  PracticeId: string;
  PatientId: string;
  AvatarUrl: string;
  LastUpdated: Date;
}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'mypatientfiles-avatars', idKey: 'PatientId', resettable: true })
export class AvatarsStore extends EntityStore<AvatarsState, AvatarViewModel> {
  constructor() {
    super();
  }
}
