import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services';
import { ApplicationInsightsService } from '../services/application-insights.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(private authService: AuthService, private appInsightsService: ApplicationInsightsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        console.warn(err);

        const errorMessage = (err.error ? err.error.message : null) || err.statusText;
        const errorObj = new Error(`HttpErrorInterceptor ${req.method} ${req.url}, Error: ${errorMessage}`);

        // this.appInsightsService.trackException(errorObj);
        // console.error('HttpErrorInterceptor', errorMessage);

        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      })
    );
  }
}
