import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map, take } from 'rxjs/operators';
import { BrowserTypes } from '@app/core/services/state/ui-state/ui-state.store';
import { UiStateService, AuthService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class MobilePatientDashboardGuard implements CanActivate {
  private currentTenant$ = this.authService.currentTenantId$;
  private browserType$ = this.uiStateService.browserType$;

  constructor(private router: Router, private authService: AuthService, private uiStateService: UiStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.browserType$.pipe(
      take(1),
      map(browserType => {
        if (browserType === BrowserTypes.Mobile) {
          const url = this.router.createUrlTree(['/', route.params.tenantId, 'patient', route.params.patientId, 'mobile-dashboard']);
          return url;
        } else {
          return true;
        }
      }),
      untilDestroyed(this, 'destroy')
      // catchError(() => of(true))
    );
  }

  destroy() {}
}
