import { Injectable, SecurityContext } from '@angular/core';
import { PatientDocumentClient, PatientDocumentVo } from './api-client.service';
import { AvatarsQuery } from './state/mypatientfiles/avatars.query';
import { tap } from 'rxjs/operators';
import { AvatarsStore, AvatarViewModel } from './state/mypatientfiles/avatars.store';
import { action, Order } from '@datorama/akita';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './auth.service';
import { DocumentsQuery } from './state/mypatientfiles/documents.query';
import { DocumentsStore } from './state/mypatientfiles/documents.store';
import { ConfigService } from './config.service';
import { DocumentUploadClient } from './document-upload.client';
import { of, Observable } from 'rxjs';
import * as mime from 'mime';
import { Random } from 'e2e/src/utilities/Random';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MyPatientFilesService {
  imgSrcPDF = '/assets/images/PDF.png';
  imgSrcXLS = '/assets/images/XLS.png';
  imgSrcDOC = '/assets/images/DOC.png';
  imgSrcTXT = '/assets/images/TXT.png';

  constructor(
    private documentClient: PatientDocumentClient,
    private documentUploadClient: DocumentUploadClient,

    private avatarQuery: AvatarsQuery,
    private avatarStore: AvatarsStore,

    private documentsQuery: DocumentsQuery,
    private documentsStore: DocumentsStore,

    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}

  fileUrl(tenantId: string, documentUrl: string): string {
    const accessToken = this.authService.token;
    // tslint:disable-next-line: max-line-length
    const url = `${
      this.configService.config.apiUrl
    }/api/v1/document?url=${documentUrl}&practiceId=${tenantId}&access_token=${accessToken}&r=${Random.randomNum(0, 10000)}.png`;
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }

  downloadFileBlob(url: string) {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getThumbnail$(thumbnailAddress: string, documentUrl: string, params: any): Observable<string> {
    const fileType = this.fileType(documentUrl);
    if (fileType === 'application/xls' || fileType === 'text/csv') {
      return of(this.imgSrcXLS);
    } else if (fileType === 'application/doc') {
      return of(this.imgSrcDOC);
    } else if (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/pdf') {
      return of(this.fileUrl(params.tenantId, thumbnailAddress));
    } else {
      return of(this.imgSrcTXT);
    }
  }

  fileType(filename: string): string {
    return mime.getType(filename);
  }

  patientDocuments$(tenantId: string, patientId: string) {
    return this.documentsQuery.selectAll({
      filterBy: entity => entity.PracticeId === tenantId && entity.PatientId === patientId,
      sortBy: 'DocumentDate',
      sortByOrder: Order.DESC,
    });
  }

  practiceDocuments$(tenantId: string) {
    return this.documentsQuery.selectAll({
      filterBy: entity => entity.PracticeId === tenantId && entity.PatientId === null,
      sortBy: 'DocumentDate',
      sortByOrder: Order.DESC,
    });
  }

  /*
  getPatientDocuments(practiceid: string, patientId: string): Observable<PatientDocumentVo[]>
  getPatientDocumentAvatar(practiceid: string, patientId: string): Observable<PatientDocumentVo>
  getPatientDocument(practiceid: string, documentId: string): Observable<PatientDocumentVo>
  getPatientDocumentHistory(practiceid: string, documentId: string): Observable<PatientDocumentHistoryVo>

  doctor documents => getClinicalDocuments(practiceid: string, filter: string): Observable<PatientDocumentVo[]>
  */

  private avatarViewModel(documentVo: PatientDocumentVo) {
    return {
      PracticeId: documentVo.PracticeId,
      PatientId: documentVo.PatientId,
      AvatarUrl: documentVo.ThumbnailUrl,
      LastUpdated: new Date(),
    } as AvatarViewModel;
  }

  @action('getDocumentsForPatient')
  public getDocumentsForPatient(tenantId: string, patientId: string) {
    const request = this.documentClient.getPatientDocuments(tenantId, patientId);
    return request.pipe(
      tap(document => {
        if (!!document) {
          this.documentsStore.add(document);
        }
      })
    );
  }

  @action('getDocumentsForPractice')
  public getDocumentsForPractice(tenantId: string, filter: string) {
    const request = this.documentClient.getClinicalDocuments(tenantId, filter);
    return request.pipe(
      tap(document => {
        if (!!document) {
          this.documentsStore.add(document);
        }
      })
    );
  }

  @action('uploadDocument')
  uploadDocument(tenantId: string, patientId: string, documentVo: PatientDocumentVo, file: Blob) {
    const request = this.documentUploadClient.capturePatientDocument(tenantId, patientId, documentVo, file, null);
    return request.pipe(
      tap(document => {
        if (!!document) {
          // this.documentsStore.add(document);
        }
      })
    );
  }

  @action('uploadImages')
  uploadImages(tenantId: string, patientId: string, documentVo: PatientDocumentVo, pictures: string[]) {
    const request = this.documentUploadClient.capturePatientDocument(tenantId, patientId, documentVo, null, pictures);
    return request.pipe(
      tap(document => {
        if (!!document) {
          // this.documentsStore.add(document);
        }
      })
    );
  }

  getLastDocumentByCategory(tenantId: string, patientId: string, category: string) {
    const request = this.documentClient.getPatientDocumentByCategory(tenantId, patientId, category);
    return request;
  }

  removeDocument(tenantId: string, documentId: string) {
    const request = this.documentClient.removeDocument(tenantId, documentId);
    return request;
  }
}
