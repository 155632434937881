import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFromString',
})
export class DateFromStringPipe implements PipeTransform {
  transform(value: string, formatTo: string, formatFrom: string): string {
    if (!value) {
      return '';
    }

    var formattedDate = moment(value).format(formatTo);
    if (formatFrom !== '') {
      formattedDate = moment(value, formatFrom).format(formatTo);
    }
    return formattedDate.toString();
  }
}
