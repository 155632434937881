import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { AuthService, ProviderService, UiStateService, PatientsService, NavigationService } from '@app/core/services';
import { map, distinctUntilChanged, debounceTime, tap, shareReplay, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationDialogComponent } from '@app/core/nav/logout-confirmation-dialog/logout-confirmation-dialog.component';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit, OnDestroy {
  authenticated$ = combineLatest([this.authService.authenticated$, this.providerService.provider$]).pipe(
    map(([provider, auth]) => !!provider && !!auth),
    distinctUntilChanged(),
    shareReplay(1),
    untilDestroyed(this)
  );

  currentTenantId$ = this.authService.currentTenantId$;

  params$ = combineLatest([this.authService.currentTenantId$, this.uiStateService.currentPatientId$]).pipe(
    map(([tenantId, patientId]) => ({ tenantId, patientId })),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  patient$ = this.params$.pipe(
    switchMap(params => this.patientsService.patientDetailsById$(params.tenantId, params.patientId)),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  currentArea$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.route.snapshot?.firstChild?.url[1]?.path || ''), // get the path area from activatedroute
    withLatestFrom(this.uiStateService.currentPatientId$),
    map(([area, patientId]) => this.getArea(area, patientId)), // get the path area
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  browserWidth$ = this.uiStateService.browserWidth$;

  constructor(
    private authService: AuthService,
    private providerService: ProviderService,
    private uiStateService: UiStateService,
    private patientsService: PatientsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private navService: NavigationService
  ) {}

  ngOnInit(): void {
    this.setupRouterWatcher();
  }

  getArea(area: string, patientId: string) {
    // need an override for non-contextual patient areas like Search, Add, etc.
    if (area === 'patient') {
      if (!patientId) {
        return 'patient-other';
      }
      return area;
    }
    return area;
  }

  setupRouterWatcher() {}

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.logout();
        this.navService.navigate(['/auth/login']);
      }
    });
  }

  setDesktopOverride() {
    this.uiStateService.setDesktopOverride(true);
  }

  expandConsultation() {
    this.uiStateService.hideConsultTabs = !this.uiStateService.hideConsultTabs;
  }

  ngOnDestroy(): void {}
}
