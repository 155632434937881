import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ProviderConfigurationVo } from 'api-clinician-app';
import { ProvidersState, ProvidersStore } from './providers.store';

@Injectable({
  providedIn: 'root',
})
export class ProvidersQuery extends QueryEntity<ProvidersState, ProviderConfigurationVo> {
  constructor(protected store: ProvidersStore) {
    super(store);
  }
}
