import { Injectable, OnInit, Inject, OnDestroy } from '@angular/core';
import { WINDOW } from './window.provider';
import { UiStateStore } from './state/ui-state/ui-state.store';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { InfrastructureClient } from './api-client.service';
import { interval, Observable, of, timer, merge, fromEvent, scheduled } from 'rxjs';
import {
  switchMap,
  distinctUntilChanged,
  withLatestFrom,
  filter,
  map,
  catchError,
  tap,
  mergeAll,
  debounceTime,
  mapTo,
  timeout,
  take,
} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as _ from 'lodash';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService implements OnInit, OnDestroy {
  private readonly startNetworkCheckingAfter = 3000;
  private readonly checkNetworkEvery = 30000;
  private offlineSnackbar: MatSnackBarRef<SimpleSnackBar>;

  online$ = this.query.online$.pipe(untilDestroyed(this));
  currentTenantId$: Observable<string> = this.authService.currentTenantId$.pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));

  serverHealthCheckerTimer$ = timer(this.startNetworkCheckingAfter, this.checkNetworkEvery).pipe(
    withLatestFrom(this.currentTenantId$),
    filter(([count, tenantId]) => !!tenantId && environment.keepAliveTesting.enabled),
    switchMap(() => this.serverHealthChecker$),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  serverHealthChecker$: Observable<boolean> = this.currentTenantId$.pipe(
    take(1),
    switchMap(tenantId =>
      this.client.getConnectivityState(tenantId).pipe(
        timeout(5000),
        map(response => !!response && !!response.Sucess),
        catchError(() => of(false))
      )
    ),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  constructor(
    private store: UiStateStore,
    private query: UiStateQuery,
    private authService: AuthService,
    private client: InfrastructureClient,
    private snackbar: MatSnackBar,

    @Inject(WINDOW) private window: Window
  ) {
    // do a server check every time the browser indicates it went offline or back online
    merge(fromEvent(this.window, 'online').pipe(mapTo(true)), fromEvent(this.window, 'offline').pipe(mapTo(false)))
      .pipe(
        switchMap(() => this.serverHealthChecker$),
        tap(result => this.setOnline(result)),
        untilDestroyed(this)
      )
      .subscribe();

    // do a server check periodically
    merge(
      of(navigator.onLine), // initial value
      this.serverHealthCheckerTimer$
    )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: online => this.setOnline(online),
      });

    this.online$.subscribe(onlineStatus => {
      if (!onlineStatus) {
        this.offlineSnackbar = this.snackbar.open('We have detected that you may have lost your internet connectivity.', 'Retry', {
          duration: this.checkNetworkEvery,
        });

        this.offlineSnackbar
          .onAction()
          .pipe(
            take(1),
            tap(() => {
              // this.snackbar.open('Retrying...', 'OK', { duration: 5000 });
              console.warn('Retrying...');
            }),
            switchMap(() => this.serverHealthChecker$),
            tap(result => this.setOnline(result))
          )
          .subscribe();
      } else {
        if (this.offlineSnackbar) {
          this.offlineSnackbar.dismiss();
        }
      }
    });
  }

  setOnline(online: boolean) {
    this.store.update({ online });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
