import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MyPatientFilesService, ProviderService } from '@app/core/services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as uuid from 'uuid';

export enum DrawingBackgroundTemplateType {
  SVGRaw = 'raw-svg',
  SVGLink = 'link-svg',
  Image = 'image',
}

export interface DrawTemplateVo {
  Type: DrawingBackgroundTemplateType;
  TemplateSource: string;
  TemplateName: string;
  TemplateId: string;
  IsRestored?: boolean;
}

export interface SelectedTemplatesChangedEvent {
  DrawTemplates: DrawTemplateVo[];
  ItemsRemoved: boolean;
}

@Component({
  selector: 'app-draw-template-selector',
  templateUrl: './draw-template-selector.component.html',
  styleUrls: ['./draw-template-selector.component.scss'],
})
export class DrawTemplateSelectorComponent implements OnInit, OnDestroy {
  @Output() selectedTemplatesChanged = new EventEmitter<SelectedTemplatesChangedEvent>();
  @Output() templateAddedAtIndex = new EventEmitter<Number>();
  @Input() preloadedImages: string[];

  selectedTemplates$: BehaviorSubject<DrawTemplateVo[]> = new BehaviorSubject<DrawTemplateVo[]>(null);
  availableTemplates$: BehaviorSubject<DrawTemplateVo[]> = new BehaviorSubject<DrawTemplateVo[]>(null);
  provider$ = this.providerService.provider$;
  currentTenantId$ = this.provider$.pipe(map(provider => provider.PracticeId));
  isOpen: boolean = false;
  previousSelectedTemplateCount: Number = 0;

  constructor(
    private sanitizer: DomSanitizer,
    private providerService: ProviderService,
    private myPatientFilesService: MyPatientFilesService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.selectedTemplates$
      .pipe(
        tap(items => {
          let itemsRemoved: boolean = this.previousSelectedTemplateCount > (items?.length ?? 0);
          this.previousSelectedTemplateCount = items?.length ?? 0;
          this.selectedTemplatesChanged.emit({ DrawTemplates: items, ItemsRemoved: itemsRemoved });
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.loadDrawingTemplates();
  }

  loadDrawingTemplates() {
    // Create the blank template and add to selected
    const blankTemplate = { TemplateId: uuid(), TemplateSource: '', Type: DrawingBackgroundTemplateType.Image, TemplateName: 'Blank' };
    const assetTemplates: DrawTemplateVo[] = [
      {
        TemplateId: uuid(),
        TemplateSource: '/assets/images/body-charts.png',
        Type: DrawingBackgroundTemplateType.Image,
        TemplateName: 'Body Charts',
      },
      {
        TemplateId: uuid(),
        TemplateSource: '/assets/images/body-front-back.png',
        Type: DrawingBackgroundTemplateType.Image,
        TemplateName: 'Body Front/Back',
      },
      {
        TemplateId: uuid(),
        TemplateSource: '/assets/images/body-side.png',
        Type: DrawingBackgroundTemplateType.Image,
        TemplateName: 'Body Side',
      },
      {
        TemplateId: uuid(),
        TemplateSource: '/assets/images/foot-top-side.png',
        Type: DrawingBackgroundTemplateType.Image,
        TemplateName: 'Foot',
      },
      {
        TemplateId: uuid(),
        TemplateSource: '/assets/images/hand-top-bottom.png',
        Type: DrawingBackgroundTemplateType.Image,
        TemplateName: 'Hand',
      },
    ];

    // Load the previous templates
    let reloadPreviousTemplates: DrawTemplateVo[] = [];
    if (!!this.preloadedImages && this.preloadedImages.length !== 0) {
      this.currentTenantId$
        .pipe(
          tap(tenantId => {
            this.preloadedImages.map((imageData, index) => {
              const fileUrl = this.myPatientFilesService.fileUrl(tenantId, imageData);
              reloadPreviousTemplates.push({
                TemplateId: uuid(),
                TemplateSource: fileUrl,
                Type: DrawingBackgroundTemplateType.Image,
                TemplateName: `Drawing ${index + 1}`,
                IsRestored: true,
              });
            });
          })
        )
        .subscribe();
    }

    let templates: DrawTemplateVo[] = new Array<DrawTemplateVo>(blankTemplate, ...assetTemplates);
    this.availableTemplates$.next(templates);
    if (reloadPreviousTemplates.length === 0) {
      this.selectedTemplates$.next(new Array<DrawTemplateVo>({ ...blankTemplate, TemplateId: uuid() }));
    } else {
      this.selectedTemplates$.next(new Array<DrawTemplateVo>(...reloadPreviousTemplates));
    }

    // Load Custom templates
    this.providerService
      .getPracticeDrawingTemplates()
      .pipe(
        withLatestFrom(this.currentTenantId$),
        switchMap(([customTemplates, tenantId]) => {
          if (customTemplates?.length > 0) {
            const requests = customTemplates.map(t => {
              const fileUrl = this.myPatientFilesService.fileUrl(tenantId, t.DocumentUrl);
              return this.myPatientFilesService.downloadFileBlob(fileUrl).pipe(
                switchMap(blob => this.blobToBase64$(blob)),
                catchError(err => {
                  console.log(`Failed to get custom template blob. ${err}`);
                  return of(null);
                })
              );
            });
            return forkJoin(requests).pipe(
              map(req => {
                const templates = req
                  .map((t, idx) => {
                    if (t) {
                      return {
                        TemplateId: uuid(),
                        TemplateSource: t,
                        Type: DrawingBackgroundTemplateType.Image,
                        TemplateName: customTemplates[idx].Description,
                      };
                    }
                    return t;
                  })
                  .filter(t => !!t);

                return templates;
              })
            );
          }
          return [];
        }),
        tap(templates => {
          const res = [...(this.availableTemplates$.value || []), ...templates];
          this.availableTemplates$.next(res);
        })
      )
      .subscribe();
  }

  isTemplateSelected(template: DrawTemplateVo): boolean {
    return (this.selectedTemplates$.value ?? []).findIndex(t => t.TemplateId == template.TemplateId) !== -1;
  }

  selectTemplate(template: DrawTemplateVo) {
    if (template === null) {
      return;
    }

    const templateIndex = this.selectedTemplates$.value?.findIndex(t => t.TemplateId === template.TemplateId) ?? -1;
    if (templateIndex === -1) {
      const currentTemplates = [...(this.selectedTemplates$.value ?? [])];
      const insertTemplate = { ...template, TemplateId: uuid() };
      currentTemplates.push(insertTemplate);
      this.selectedTemplates$.next(currentTemplates);
      this.templateAddedAtIndex.emit(currentTemplates.length - 1);
    }
  }

  removeTemplateFromSelectedTemplates(templateId) {
    const currentTemplates = [...(this.selectedTemplates$.value ?? [])];
    const templateIndex = this.selectedTemplates$.value?.findIndex(t => t.TemplateId === templateId) ?? -1;
    if (templateIndex !== -1) {
      currentTemplates.splice(templateIndex, 1);
      this.selectedTemplates$.next(currentTemplates);
    }
  }

  transformSafeHtml(html) {
    // This allows for the SVG elements to be output as raw html. This is not safe
    // and another way to do this should be investigated
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }

  toggleDisplay() {
    this.isOpen = !this.isOpen;
  }

  blobToBase64$(blob: Blob): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = event => {
          observer.next((event.target as any).result);
          observer.complete();
          // Set reader to null to ensure it's cleaned up
          reader = null;
        };
      }
    });
  }
}
