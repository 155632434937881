import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderAvatar',
})
export class GenderAvatarPipe implements PipeTransform {
  transform(value: any, gender: string): any {
    if (!value) {
      console.warn('NotYetImplemented', 'showing gender avatar for ' + gender + ' instead');
      return value;
    } else {
      return value;
    }
  }
}
