import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { HttpClientMeraki, MERAKI_API_GATEWAY, MERAKI_CACHE_LB } from '../meraki-nexus.providers';
import { MerakiAuthService } from '../meraki-auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';
import { ConfigService } from '../config.service';
import { ApplicationInsightsService } from '../application-insights.service';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { NexResponse, PriceResponse, SinglePriceRequest, TariffPriceRequest } from '../meraki-models/meraki-models';
import { catchError, first, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import {
  DiagnosisVo2,
  EncounterHeaderVoPlaceOfService,
  EncounterLineItemVo,
  EncounterStatusVo,
  EncounterTemplateVo,
  EncounterVo,
  PatientEventVo,
  ProviderConfigurationVo,
  RestApiResultOfBoolean,
  RestApiResultOfCompleteEncounterResult,
  RestApiResultOfEncounterVo,
  RestApiResultOfGuid,
  RestApiResultOfString,
  TemplateSummaryVo,
} from '../api-client.service';
import { InvoiceTemplate, TemplateLine } from '../meraki-models/invoice-template.models';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  BaseInvoice,
  INVOICE_SUBTYPE,
  INVOICE_TYPE,
  InvoiceLine,
  MEDICINE_TYPE,
  PLACE_OF_SERVICE,
  SaveInvoiceRequest,
} from '../meraki-models/invoice.models';
import { EncounterType } from '../state/clinical-encounter/clinical-encounter.model';
import { ERROR_CODE } from '../meraki-models/error-models';
import { convertEncounterToFirestore, convertFirestoreToEncounter, fromTimestamp } from '../meraki-models/meraki-model-util';

const PRACTICE_ROOT = 'ClinicalPractice';
const PROVIDER_ROOT = 'ClinicalProvider';
const ENCOUNTER_ROOT = 'Encounter';

@Injectable({
  providedIn: 'root',
})
export class MerakiEncounterService {
  private http: HttpClientMeraki;
  private baseUrl: string;
  private loadBalancerUrl: string;

  constructor(
    private httpHandler: HttpBackend, // Bypass Auth interceptor since it overrides with florence auth token.
    @Inject(HttpClientMeraki) http: HttpClientMeraki,
    @Inject(MERAKI_API_GATEWAY) baseUrl: string,
    @Inject(MERAKI_CACHE_LB) cacheLbUrl: string,
    private authService: MerakiAuthService,
    private firestore: AngularFirestore,
    private slicePipe: SlicePipe,
    private configService: ConfigService,
    private appInsightService: ApplicationInsightsService
  ) {
    this.http = http;
    //this.http = new HttpClient(httpHandler);

    this.baseUrl = baseUrl ? baseUrl : '';
    this.loadBalancerUrl = cacheLbUrl;
  }

  dropNullUndefined(d) {
    return Object.entries(d).reduce(
      (acc, [k, v]) =>
        v == null
          ? acc
          : {
              ...acc,
              [k]: _.isPlainObject(v) ? this.dropNullUndefined(v) : _.isArray(v) ? v.map(s => this.dropNullUndefined(s)) : v,
            },
      {}
    );
  }

  searchProcedure(
    query: string = '',
    year: number = new Date().getFullYear(),
    type: string = '10',
    page: number = 0,
    pageLimit: number = 10
  ): Observable<NexResponse<any>> {
    // todo speciality code is missing
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<any>>(
            `${this.baseUrl}/v1/medicine/search/searchProcedure`,
            {
              query,
              year,
              field: 'Code',
              type,
              page,
              pageLimit,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            })
          )
      )
    );
  }

  createTemplateGroup(bpn: string, hpcsaNo: string, templateType: string, request: TemplateSummaryVo) {
    return from(
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('Provider')
        .doc(hpcsaNo)
        .collection('InvoiceTemplate')
        .doc()
        .set({
          Caption: request.Caption,
          Description: request.Description,
          IsGroup: true,
          IsActive: true,
          SortOrder: 0,
          Type: templateType == 'Template' ? 'Invoice' : templateType,
        } as InvoiceTemplate)
    );
  }

  deleteTemplate(bpn: string, hpcsaNo: string, xref: string): Observable<RestApiResultOfBoolean> {
    const templateRef = this.firestore.collection('Practice').doc(bpn).collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate');

    return from(templateRef.doc(xref).set({ IsActive: false }, { merge: true })).pipe(
      mapTo({
        Sucess: true,
        Data: true,
      })
    );
  }

  deleteTemplateFromGroup(bpn: string, hpcsaNo: string, groupXRef: string, caption: string): Observable<RestApiResultOfBoolean> {
    const templateRef = this.firestore.collection('Practice').doc(bpn).collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate');

    return templateRef
      .doc(groupXRef)
      .valueChanges()
      .pipe(
        take(1),
        switchMap(group =>
          from(
            templateRef.doc(groupXRef).set(
              {
                ...group,
                Templates: [...(group?.Templates || []).filter(s => s.Caption != caption)],
              },
              { merge: true }
            )
          )
        ),
        catchError(err => {
          console.error('saving error', err);
          return of(null);
        }),
        mapTo({ Sucess: true, Data: true })
      );
  }

  updateTemplate(
    bpn: string,
    hpcsaNo: string,
    template: EncounterTemplateVo,
    existingTemplates: TemplateSummaryVo[]
  ): Observable<RestApiResultOfString> {
    const request = this.dropNullUndefined(this.fromEncounterTemplate(template));

    const templateRef = this.firestore.collection('Practice').doc(bpn).collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate');
    // adding to group if caption contains '|'
    // (following old drapp communication principle to not refactor other components)

    if (template.Caption.includes('|')) {
      const groupRef = template.Caption.split('|')[0];
      const caption = template.Caption.split('|')[1];

      if (
        !template.TemplateXref &&
        existingTemplates.filter(s => s.Caption == caption && s.GroupInvoiceTemplateXRef == groupRef).length > 0
      ) {
        return of({
          Data: '',
          Sucess: false,
          ResponseMessage: 'Name of favorite is DUPLICATE',
        } as RestApiResultOfString);
      }

      return templateRef
        .doc(groupRef)
        .valueChanges()
        .pipe(
          take(1),
          switchMap(group =>
            from(
              templateRef.doc(groupRef).set(
                {
                  ...group,
                  Templates: [
                    ...(group?.Templates || []),
                    {
                      ...request,
                      Caption: template.Caption.split('|')[1],
                    },
                  ],
                },
                { merge: true }
              )
            )
          ),
          mapTo({ Sucess: true }),
          catchError(err => {
            console.error('saving error', err);
            return of(null);
          })
        );
    }

    if (
      !template.TemplateXref &&
      existingTemplates.filter(s => s.Caption == template.Caption && s.GroupInvoiceTemplateXRef == null).length > 0
    ) {
      return of({ Data: '', Sucess: false, ResponseMessage: 'Name of favorite is DUPLICATE' } as RestApiResultOfString);
    }

    return (
      template.TemplateXref
        ? from(templateRef.doc(template.TemplateXref).set(request, { merge: true }))
        : from(templateRef.doc().set(request))
    ).pipe(mapTo({ Sucess: true }));
  }

  getInvoiceTemplateGroup(bpn: string, hpcsaNo: string, templateType: string): Observable<TemplateSummaryVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Provider')
      .doc(hpcsaNo)
      .collection('InvoiceTemplate', r => r.where('Type', '==', templateType).where('IsGroup', '==', true).where('IsActive', '==', true))
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        //take(1), // todo confirm if easy to make real time
        map(templates =>
          templates.map(
            (t: InvoiceTemplate) =>
              ({
                Caption: t.Caption,
                Code: t.Description, // confirm
                Description: t.Description,
                SortOrder: t.SortOrder,
                TemplateType: templateType,
                TemplateXRef: t.Id || t.Caption,
                GroupInvoiceTemplateXRef: t.Id,
              } as TemplateSummaryVo)
          )
        ),
        map(list => _.chain(list).orderBy('Caption', 'asc').value())
      );
  }

  getInvoiceTemplateGroupContent(bpn: string, hpcsaNo: string, templateType: string, groupXref: string): Observable<TemplateSummaryVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Provider')
      .doc(hpcsaNo)
      .collection('InvoiceTemplate')
      .doc(groupXref)
      .get()
      .pipe(
        take(1),
        map(s => s.data() as InvoiceTemplate),
        map(s =>
          s.Templates.map(
            t =>
              ({
                Caption: t.Caption,
                Code: t.Description, // confirm
                Description: t.Description,
                SortOrder: t.SortOrder,
                TemplateType: templateType,
                TemplateXRef: t.Id || t.Caption,
                GroupInvoiceTemplateXRef: groupXref,
                OriginalTemplate: t,
              } as TemplateSummaryVo)
          )
        )
      );
  }

  getInvoiceTemplates(
    bpn: string,
    hpcsaNo,
    templateType: string,
    pageSize: number,
    pageNo: number,
    search: string
  ): Observable<EncounterTemplateVo[]> {
    // adjust type to not overcomplicate tweaking logic on higher level
    let type = templateType;
    if (templateType == 'Template') {
      type = 'Invoice';
    }
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Provider')
      .doc(hpcsaNo)
      .collection('InvoiceTemplate', r => r.where('Type', '==', type).where('IsActive', '==', true))
      .valueChanges<string>({ idField: 'Id' })
      .pipe(
        //take(1), // todo we can't set otherwise only load local cache version and no server call
        map((templates: InvoiceTemplate[]) =>
          templates.flatMap(template =>
            template.IsGroup
              ? [...(template.Templates?.map(t => ({ ...this.toEncounterTemplate(t, templateType, template.Id) })) || [])]
              : [this.toEncounterTemplate(template, templateType, null)]
          )
        ),
        map(templates => (search && templates.filter(t => t.Caption?.toLocaleLowerCase().includes(search.toLowerCase()))) || templates),
        map(templates => templates.slice(pageNo * pageSize, pageNo * pageSize + pageSize)),
        map(list => _.chain(list).orderBy('Caption', 'asc').value())
      );
  }

  private fromEncounterTemplate(template: EncounterTemplateVo): InvoiceTemplate {
    return {
      Caption: template.Caption,
      Description: template.Description,
      IsGroup: false,
      IsActive: true,
      SortOrder: 0,
      Type: template.TemplateType == 'MyTemplate' ? 'Invoice' : template.TemplateType,
      Diagnosis: template.EncounterLineItems.EncounterHeader.Diagnosis.map((s, index) => ({
        Code: s.DiagnosisCode,
        Description: s.DiagnosisDescription,
        OrderNo: index,
      })),
      Lines: [
        ...template.EncounterLineItems.LineItems.map(
          s =>
            ({
              Diagnoses: s.Diagnosis.map((d, index) => ({
                Code: d.DiagnosisCode,
                Description: d.DiagnosisDescription,
                OrderNo: index,
              })),
              ChargeCode: s.ChargeCode,
              Description: s.ChargeDesc,
              Quantity: s.ChargeQuan,
              LineType: s.LineType,
              LineNumber: s.LineNum,
              NappiCode: s.NappiCode,
              DosageType: s.DosageType,
              FrequencyType: s.FrequencyType,
              DurationType: s.DurationType,
              DurationUnit: s.DurationUnit,
              DosageUnit: s.DosageUnit,
              PeriodType: s.PeriodType,
              PeriodUnit: s.PeriodUnit,
              Repeat: s.Repeat,
              ChronicIndicator: s.ChronicIndicator,
              Route: s.Parameters?.RouteOfAdministration,
              FrequencyUnit: s.FrequencyUnit,
              AdditionalInstructions: s.Parameters?.InformationText,
              Instructions: s.Parameters?.AdditionalInstructionSelections?.split(', '),
              // new field in model, forge doesn't know about it
              DosageDescription: s.Parameters?.DosageDescription,
              Dispense: s.LineType == 'Medicine',
            } as TemplateLine)
        ),
        ...template.MedicationPrescriptionLines.map(
          s =>
            ({
              Diagnoses: s.Diagnosis.map((d, index) => ({
                Code: d.DiagnosisCode,
                Description: d.DiagnosisDescription,
                OrderNo: index,
              })),
              LineType: 'Medicine',
              Description: s.MedicationDescription,
              MedicationDescription: s.MedicationDescription,
              Dispense: false,
              Quantity: s.Quantity,
              LineNumber: s.LineNum,
              NappiCode: s.NappiCode,
              DosageType: s.DosageType,
              FrequencyUnit: s.FrequencyUnits,
              DurationType: s.DurationType,
              DurationUnit: s.DurationUnit,
              DosageUnit: s.DosageUnits,
              PeriodType: s.PeriodType,
              PeriodUnit: s.PeriodUnit,
              Repeat: s.Repeat,
              ChronicIndicator: s.ChronicIndicator,
              DispensingInstructions: s.DispensingInstructions,
              AdditionalInstructions: s.Parameters?.InformationText,
              Instructions: s.Parameters?.AdditionalInstructionSelections?.split(', '),
              // new field in model, forge doesn't know about it
              DosageDescription: s.Parameters?.DosageDescription,
            } as TemplateLine)
        ),
      ],
      // (AD) prescriptions model of template service NOT used here same as via mymps integration
      // instead used Dispense flag to diff between dispense/prescribe so then we could quickly prescribe dispensed med and vice versa
      /* Prescriptions: template.MedicationPrescriptionLines.map(s => ({
          Description: s.Description,
          Quantity: s.Quantity,
          LineNumber: s.LineNum,
          NappiCode: s.NappiCode,
          DosageType: s.DosageType,
          FrequencyUnit: s.FrequencyUnits,
          DurationType: s.DurationType,
          DurationUnit: s.DurationUnit,
          DosageUnit: s.DosageUnits,
          PeriodType: s.PeriodType,
          PeriodUnit: s.PeriodUnit,
          Repeat: s.Repeat,
          ChronicIndicator: s.ChronicIndicator,
          DispensingInstructions: s.DispensingInstructions,
          MedicationDescription: s.MedicationDescription,
        } as InvoicePrescription)) */
    } as InvoiceTemplate;
  }

  toEncounterTemplate(t: InvoiceTemplate, templateType: string, groupId?: string): EncounterTemplateVo {
    return {
      Caption: t.Caption,
      Code: t.Description,
      Description: t.Description,
      SortOrder: t.SortOrder,
      TemplateType: templateType,
      TemplateXref: t.Id || t.Caption,
      TemplateId: t.Id || t.Caption, // todo templates in group doesn't have id
      GroupInvoiceTemplateXRef: groupId,
      EncounterLineItems: {
        EncounterHeader: {
          Diagnosis:
            t.Diagnosis?.map(
              d =>
                ({
                  DiagnosisCode: d.Code,
                  DiagnosisDescription: d.Description,
                } as DiagnosisVo2)
            ) || [],
        },
        LineItems: t.Lines.map(
          (s: TemplateLine | any) =>
            ({
              ChargeCode: s.ChargeCode,
              ChargeDesc: s.Description,
              ChargeQuan: s.Quantity,
              LineType: s.LineType,
              LineNum: s.LineNumber,
              NappiCode: s.NappiCode,
              DosageType: s.DosageType || s.UserDefinedDosageType,
              FrequencyType: null, //todo
              DurationType: s.DurationType,
              DurationUnit: s.DurationUnit,
              DosageUnit: s.DosageUnit,
              PeriodType: s.PeriodType,
              PeriodUnit: s.PeriodUnit,
              Repeat: s.Repeat,
              ChronicIndicator: s.ChronicIndicator,
              FrequencyUnit: s.FrequencyUnit,
              Parameters:
                (s.LineType == 'Medicine' && {
                  RouteOfAdministration: s.Route,
                  AdditionalInstructionSelections: s.AdditionalInstructionSelections || '',
                  InformationText: s.AdditionalInstructions,
                  DosageDescription: s.DosageDescription,
                  MedicineType: s.Dispense ? 'Dispense' : 'Prescribe',
                }) ||
                null,
            } as EncounterLineItemVo)
        ),
      },
    } as EncounterTemplateVo;
  }

  priceEncounter(
    practiceid: string,
    encounterId: string,
    encounterVo: EncounterVo,
    provider: ProviderConfigurationVo
  ): Observable<RestApiResultOfEncounterVo> {
    // todo private rate is not part of
    /** 0 = Uknown, 2 = Telehealth, 11 = ConsultingRooms, 21 = InPatientHospital, 24 = DayClinic */
    const placeOfServiceCode = encounterVo.EncounterLineItems.EncounterHeader.PlaceOfService;
    let placeOfService = 'OH';
    if (placeOfServiceCode == EncounterHeaderVoPlaceOfService._21) {
      placeOfService = 'IH';
    } else if (placeOfServiceCode == EncounterHeaderVoPlaceOfService._24) {
      placeOfService = 'DC';
    }
    return forkJoin([
      ...encounterVo.EncounterLineItems.LineItems.map(line => {
        const year = moment(encounterVo.EncounterLineItems.EncounterHeader.DateOfService).year().toString();
        const optionCode = encounterVo.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidOptionCode;

        if (line.LineType === 'Procedure') {
          const request = {
            type: 'Procedure',
            placeOfService: placeOfService,
            date: encounterVo.EncounterLineItems.EncounterHeader.DateOfService,
            disciplineCode: provider.SpecialityCode,
            planOptionCode: optionCode,
            priceCode: line.ChargeCode,
            practiceId: provider.PracticeNumber,
            providerId: provider.HPCSANumber,
            quantity: line.ChargeQuan,
            isMedicalAid: !encounterVo.PatientEventDetails.Patient.PatientAccountDetails.IsCashAccount,
          } as SinglePriceRequest;
          return this.getSinglePrice(request).pipe(
            map(s => this.addPricingFields(line, s)),
            catchError(err => throwError(`Unable to price ${line.LineType} line item: ${line.ChargeCode}, ${line.ChargeDesc} `))
          );
        }
        if (line.LineType === 'Medicine' || line.LineType === 'Consumable') {
          const request = {
            type: line.LineType,
            placeOfService: placeOfService,
            date: encounterVo.EncounterLineItems.EncounterHeader.DateOfService,
            disciplineCode: provider.SpecialityCode,
            planOptionCode: optionCode,
            priceCode: line.NappiCode,
            practiceId: provider.PracticeNumber,
            providerId: provider.HPCSANumber,
            quantity: line.ChargeQuan,
            isMedicalAid: !encounterVo.PatientEventDetails.Patient.PatientAccountDetails.IsCashAccount,
          } as SinglePriceRequest;
          return this.getSinglePrice(request).pipe(
            map(s => this.addPricingFields(line, s)),
            catchError(err =>
              throwError(
                `Unable to price ${line.LineType} line item: ${line.NappiCode}, s ${this.slicePipe.transform(line.ChargeDesc, 0, 70)} `
              )
            )
          );
        }
        return of(line);
      }),
    ]).pipe(
      map(lines => ({
        Sucess: true,
        Data: {
          ...encounterVo,
          EncounterLineItems: {
            ...encounterVo.EncounterLineItems,
            LineItems: lines,
          },
        },
      })),
      catchError(err => {
        return of({
          Sucess: false,
          ResponseCode: 400,
          ResponseMessage: err,
        });
      })
    );
  }

  addPricingFields(line: EncounterLineItemVo, s: NexResponse<PriceResponse>): EncounterLineItemVo {
    const totalPrice = parseFloat(Number(s.data.payload.result.price).toFixed(2));
    return {
      ...line,
      Amount: totalPrice,
      TotalIncVat: totalPrice,
      //UnitPrice: s.data.payload.result.price
    };
  }

  getSinglePrice(priceRequest: SinglePriceRequest): Observable<NexResponse<PriceResponse>> {
    const params = {
      type: priceRequest.type,
      placeOfService: priceRequest.placeOfService,
      disciplineCode: priceRequest.disciplineCode,
      planOptionCode: priceRequest.planOptionCode || null,
      priceCode: priceRequest.priceCode,
      date: priceRequest.date.toISOString(),
      isMedicalAid: String(priceRequest.isMedicalAid),
      quantity: priceRequest.quantity,
      /*isMultiBranch: String(priceRequest.isMultiBranch) */
    };
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<PriceResponse>>(
          `${this.loadBalancerUrl}/v1/pricing/single/${priceRequest.practiceId}/${priceRequest.providerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              rejectUnauthorized: 'false',
            },
            params: {
              ...this.dropNullUndefined(params),
            },
          }
        )
      )
    );
  }

  getTariffPrice(priceRequest: TariffPriceRequest): Observable<NexResponse<any>> {
    // todo extract load balancer (responsible for caching) to env config
    // please note atm private rates not returned by endpont

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/tariff/${priceRequest.practiceId}/${priceRequest.providerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            rejectUnauthorized: 'false',
          },
          params: {
            year: priceRequest.year,
            tariffCode: priceRequest.tariffCode,
            disciplineCode: priceRequest.disciplineCode,
            placeOfService: priceRequest.placeOfService,
            planOptionCode: priceRequest.planOptionCode,
          },
        })
      )
    );
  }

  getMedicinePrice(bpn: string, nappiCode: string, planOptionCode, quantity, year): Observable<NexResponse<any>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/${bpn}/medicine`, {
          headers: {
            Authorization: `Bearer ${token}`,
            rejectUnauthorized: 'false',
          },
          params: {
            nappiCode: nappiCode,
            planOptionCode: planOptionCode,
            quantity: quantity,
            year: year,
          },
        })
      )
    );
  }

  getConsumablePrice(bpn: string, nappiCode: string, planOptionCode, quantity, year): Observable<NexResponse<any>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/${bpn}/consumable`, {
          headers: {
            Authorization: `Bearer ${token}`,
            rejectUnauthorized: 'false',
          },
          params: {
            nappiCode: nappiCode,
            planOptionCode: planOptionCode,
            quantity: quantity,
            year: year,
          },
        })
      )
    );
  }

  isBillingInvoiceRequired(encounter: EncounterVo) {
    return encounter.EncounterLineItems.LineItems.length > 0;
  }

  getInvoiceType(type: string): INVOICE_TYPE {
    if (type == EncounterType.MedicalAidInvoice) {
      return INVOICE_TYPE.MEDICAL_AID;
    } else if (type == EncounterType.CashInvoice) {
      return INVOICE_TYPE.CASH;
    } else if (type == EncounterType.MedicalInsurance) {
      return INVOICE_TYPE.MEDICAL_AID;
    } else if (type == EncounterType.ZeroInvoice) {
      return INVOICE_TYPE.MEDICAL_AID;
    }
    //return type;
  }

  getInvoiceSubtype(type: string): INVOICE_SUBTYPE {
    if (type == EncounterType.ZeroInvoice) {
      return INVOICE_SUBTYPE.NO_CHARGE;
    } else if (type == EncounterType.MedicalInsurance) {
      return INVOICE_SUBTYPE.MEDICAL_INSURER;
    }
    return INVOICE_SUBTYPE.NONE;
  }

  getPlaceOfService(placeOfService: EncounterHeaderVoPlaceOfService): PLACE_OF_SERVICE {
    /** 0 = Uknown, 2 = Telehealth, 11 = ConsultingRooms, 21 = InPatientHospital, 24 = DayClinic */
    if (placeOfService == EncounterHeaderVoPlaceOfService._2) {
      return PLACE_OF_SERVICE.TELEMEDICINE;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._11) {
      return PLACE_OF_SERVICE.CONSULTING_ROOM;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._21) {
      return PLACE_OF_SERVICE.INPATIENT_HOSPITAL;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._24) {
      return PLACE_OF_SERVICE.DAY_CLINIC_HOSPITAL;
    }
    return undefined;
  }

  updateEncounterStatus(
    practiceTenantId: string,
    encounterId: string,
    status: 'Created' | 'Updated' | 'Saved' | 'Completed' | 'Cancelled'
  ): Observable<any> {
    return from(this.encounterRef(practiceTenantId, encounterId).set({ Status: status }, { merge: true }));
  }

  completeEncounter(
    bpn: string,
    encounter: EncounterVo,
    requireManualProcessing: string[]
  ): Observable<RestApiResultOfCompleteEncounterResult> {
    if (!this.isBillingInvoiceRequired(encounter)) {
      return of({ Sucess: true, Data: { EncounterId: encounter.EncounterId } });
    }

    const dosSameAsVisit =
      moment(encounter.PatientEventDetails.CheckInTime).format('YYYY-MM-DD') ==
      moment(encounter.EncounterLineItems.EncounterHeader.DateOfService).format('YYYY-MM-DD');
    const invoice: BaseInvoice = {
      //Id: uuidV4(),
      SourceInvoiceId: encounter.EncounterId,
      Type: this.getInvoiceType(encounter.EncounterType),
      Subtype: this.getInvoiceSubtype(encounter.EncounterType),
      PlaceOfService: this.getPlaceOfService(encounter.EncounterLineItems.EncounterHeader.PlaceOfService),
      DateOfService: encounter.EncounterLineItems.EncounterHeader.DateOfService,
      AdmissionDate: encounter.EncounterLineItems.EncounterHeader.AdmissionDateTime,
      DischargeDate: encounter.EncounterLineItems.EncounterHeader.DischargeDateTime,
      AuthorizationNo: encounter.EncounterLineItems.EncounterHeader.ClaimAuthorizationNumber,
      ReferringProvider: encounter.ReferringDoctorPracticeNumber && {
        TreatingPracticeNumber: encounter.ReferringDoctorPracticeNumber,
        FullName: encounter.ReferringDoctorName,
      },
      AssistingProvider: encounter.AssistingDoctorPracticeNumber && {
        TreatingPracticeNumber: encounter.AssistingDoctorPracticeNumber,
        FullName: encounter.AssistingDoctorName,
      },
      InvoiceDate: moment().toDate(),
      IdentityNo: encounter.PatientEventDetails.Patient.PatientDetails.IdentityNo,
      Branch: encounter.BranchXRef,
      HeaderDiagnosisCodes: encounter.EncounterLineItems.EncounterHeader.Diagnosis.map(s => s.DiagnosisCode),
      // don't set linked appointment if it's visit created by us
      LinkedAppointment: encounter.PatientEventDetails.IsClinicalVisit /* || !dosSameAsVisit  */
        ? null
        : encounter.PatientEventDetails.PatientEventXRef,
      TreatingProvider: {
        TreatingPracticeNumber: encounter.Provider.TreatingDoctorPracticeNumber,
        BillingPracticeNumer: encounter.Provider.PracticeNumber,
        PracticeName: encounter.Provider.PracticeName,
        HPCSANumber: encounter.Provider.HPCSANumber,
      },
      PolicyNo: encounter.MedicalInsurance?.PolicyNo,
      Broker: encounter.MedicalInsurance?.Broker,
      MedicalInsurer: encounter.MedicalInsurance && {
        Name: encounter.MedicalInsurance.MedicalInsurer.Name,
        //Code: encounter.MedicalInsurance.MedicalInsurer.
        Phone: encounter.MedicalInsurance.MedicalInsurer.Phone1,
        Email: encounter.MedicalInsurance.MedicalInsurer.Email,
        Id: encounter.MedicalInsurance.MedicalInsurer.Id,
        //Url:
      },
      Account: {
        Id: encounter.PatientEventDetails.Patient.PatientAccountDetails.AccountId,
        AccountNo: encounter.PatientEventDetails.Patient.PatientAccountDetails.AccountNo,
        MemberNo: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidMembershipNumber,
        SchemeName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidName,
        SchemeCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidSchemeCode,
        PlanCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlanCode,
        PlanName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlan,
        OptionName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlanOption,
        OptionCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidOptionCode,
      },
      MainMember: {
        Id: encounter.PatientEventDetails.Patient.PatientAccountDetails.MainMemberXRef,
      },
      Patient: {
        Id: encounter.PatientEventDetails.Patient.PatientXRef,
        Name: encounter.PatientEventDetails.Patient.PatientDetails.FirstName,
        Surname: encounter.PatientEventDetails.Patient.PatientDetails.Surname,
        DateOfBirth: encounter.PatientEventDetails.Patient.PatientDetails.DateOfBirth,
        IdentityNo: encounter.PatientEventDetails.Patient.PatientDetails.IdentityNo,
        FileNo: encounter.PatientEventDetails.Patient.FileNo,
        DependantCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidDependentCode,
        Contact: {
          Cellphone: encounter.PatientEventDetails.Patient.PatientDetails.ContactNo,
        },
      },

      //todo add other fields
      Lines: encounter.EncounterLineItems.LineItems.map(
        s =>
          ({
            Description: s.ChargeDesc,
            LineType: s.LineType,
            LineNumber: s.LineNum,
            NappiCode: s.NappiCode,
            TariffCode: s.ChargeCode,
            Quantity: s.ChargeQuan,
            DiagnosisCodes: s.Diagnosis.map(t => t.DiagnosisCode),
            AmountBilled: encounter.EncounterType == EncounterType.ZeroInvoice ? 0 : Math.round(s.Amount * 100),
            PriceOverride: s.PriceOverride,
            //Balance
            MedicineAdditionalInfo: {
              // todo map types
              DosageType: s.DosageType,
              DosageUnit: s.DosageUnit,
              DurationType: s.DurationType,
              DurationUnit: s.DurationUnit,
              FrequencyUnit: s.FrequencyUnit,
              MedicineType: s.ChronicIndicator ? MEDICINE_TYPE.CHRONIC : MEDICINE_TYPE.ACUTE,
              PeriodType: s.PeriodType,
              PeriodUnit: s.PeriodUnit,
              Repeats: s.Repeat,
            },
          } as InvoiceLine)
      ),
    };

    var claimNote =
      requireManualProcessing.length > 0
        ? `${encounter.Note ?? ''} [Note: Invoice deferred for manual processing due to ${requireManualProcessing.join(', ')}]`
        : encounter.Note;

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<{ invoiceId: string }>>(
            `${this.baseUrl}/v1/invoice/claim/saveInvoice`,
            {
              BillingPracticeNumber: bpn,
              Note: claimNote,
              Source: 'Clinical',
              Invoice: invoice,
              ProcessingContext: {
                Completed: requireManualProcessing.length == 0,
                IgnoreLinkedAppointmentOnDosMismatch: true,
              },
            } as SaveInvoiceRequest,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .pipe(
            switchMap(s => {
              if (s.success) {
                return of({ Sucess: true, Data: { EncounterId: s.data.payload.invoiceId } });
              }
              var errorMessage =
                s.error.code == ERROR_CODE.DUPLICATE_REQUEST
                  ? 'Consultation already completed. Use cancel option to close consultation.'
                  : s.error.message;

              return throwError({
                Data: '',
                Sucess: false,
                Code: s.error.code,
                ResponseMessage: errorMessage,
              } as RestApiResultOfCompleteEncounterResult);
            }), //todo ucomment
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return throwError(httpError.error);
              }
              return throwError(httpError);
            })
          )
      )
    );
  }

  saveEncounter(providerId: string, patientId: string, encounter: EncounterStatusVo): Observable<RestApiResultOfGuid> {
    // threshold limits: 120: SymptomQuestions, 240: SymptomQuestionAnswers, 50: EncounterLineItems.LineItems,
    let questions$ = of(null);
    let encounterForUpdate = { ...this.dropNullUndefined(encounter), Status: 'Saved' } as EncounterStatusVo;

    encounterForUpdate = convertEncounterToFirestore(encounterForUpdate);

    const encounterRootRef = this.encounterRef(encounterForUpdate.PracticeId, encounterForUpdate.EncounterId);

    if (encounterForUpdate.SymptomQuestions.length >= 120) {
      // symptom questions is more informational data, no need to merge
      questions$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.SymptomQuestions, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(encounterRootRef.collection('SymptomQuestions').doc(item.QuestionKey.replace('/', '_')).ref, item);
          });
          return from(batch.commit());
        }),
      ]);
      encounterForUpdate = { ...encounterForUpdate, SymptomQuestions: null };
    }

    let answers$ = of(null);
    if (encounterForUpdate.SymptomQuestionAnswers.length >= 240) {
      answers$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.SymptomQuestionAnswers, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(encounterRootRef.collection('SymptomQuestionAnswers').doc(item.QuestionKey.replace('/', '_')).ref, item);
          });
          return from(batch.commit());
        }),
      ]);
      encounterForUpdate = { ...encounterForUpdate, SymptomQuestionAnswers: null };
    }

    let prescriptions$ = of(null);
    if (encounterForUpdate.MedicationsPrescriptions?.length > 0) {
      const batch = this.firestore.firestore.batch();
      encounterForUpdate.MedicationsPrescriptions.forEach(item => {
        batch.set(encounterRootRef.collection('MedicationsPrescriptions').doc(item.MedicationPrescriptionId).ref, item);
      });
      prescriptions$ = from(batch.commit()).pipe(catchError(s => of(null)));
      encounterForUpdate = { ...encounterForUpdate, MedicationsPrescriptions: null };
    }

    let lines$ = of(null);
    if (encounterForUpdate.EncounterLineItems?.LineItems.length >= 50) {
      lines$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.EncounterLineItems.LineItems, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(
              encounterRootRef
                .collection('EncounterLineItems.LineItems')
                .doc(`${item.LineType}-${item.NappiCode || '00'}-${item.ChargeCode}`).ref,
              item
            );
          });
          return from(batch.commit());
        }),
      ]);

      encounterForUpdate = {
        ...encounterForUpdate,
        EncounterLineItems: { ...encounterForUpdate.EncounterLineItems, LineItems: [] },
        LastUpdated: moment().toDate(),
      };
    }

    return forkJoin([lines$, questions$, answers$, prescriptions$, from(encounterRootRef.set(encounterForUpdate))]).pipe(
      map(() => ({ Sucess: true } as RestApiResultOfGuid)),
      catchError(s => {
        console.warn(s);
        this.appInsightService.trackException(s, 'save-encounter', { encounterVo: JSON.stringify(encounter) });
        return of({ Sucess: false, ResponseMessage: s.message });
      })
    );
  }

  private encounterRef(practiceid: string, encounterId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceid).collection(ENCOUNTER_ROOT).doc(encounterId);
  }

  getActiveEncounterForPatient(
    practiceTenantId: string,
    providerId: string,
    patientId: string,
    visit?: PatientEventVo
  ): Observable<EncounterVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r =>
        r.where('Status', 'in', ['Created', 'Updated', 'Saved']).where('PatientEventDetails.Patient.PatientId', '==', patientId)
      )
      .valueChanges()
      .pipe(
        take(1),
        map(encounters => encounters?.filter(e => e.PatientEventDetails.PracticeId === providerId) || []),
        map(encounters =>
          _.orderBy(encounters, [s => fromTimestamp(s.PatientEventDetails?.CheckInTime), s => s.EncounterId], ['desc', 'asc'])
        ),
        map(encounters =>
          visit != null
            ? encounters.filter(
                e =>
                  e.PatientEventDetails.PatientEventId === visit.PatientEventId ||
                  e.PatientEventDetails.PatientEventXRef === visit.PatientEventXRef
              )
            : encounters
        ),
        map(encounters => (encounters.length > 0 && encounters[0]) || null),
        // load full encounter (in case if contains sub-collections)
        switchMap((encounter: EncounterStatusVo) => this.loadFullEncounter(encounter, practiceTenantId)),
        map(encounter => (encounter && convertFirestoreToEncounter(encounter)) || null)
      );
  }

  checkIfEncounterStillActive(practiceTenantId: string, patientId: string, encounterId: string) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r =>
        r.where('Status', 'in', ['Created', 'Updated', 'Saved']).where('PatientEventDetails.Patient.PatientId', '==', patientId)
      )
      .valueChanges()
      .pipe(
        first(),
        map(encounters => encounters?.filter(e => e.EncounterId === encounterId) || []),
        map(encounters => encounters.length > 0)
      );
  }

  loadFullEncounter(encounter: EncounterStatusVo, practiceTenantId: string): any {
    return (
      (encounter &&
        this.loadEncounterSubCollections(encounter, practiceTenantId).pipe(
          map(([symptomQuestions, symptomQuestionAnswers, lineItems, prescriptions]) => ({
            ...encounter,
            SymptomQuestions: symptomQuestions || encounter.SymptomQuestions,
            SymptomQuestionAnswers: symptomQuestionAnswers || encounter.SymptomQuestionAnswers,
            EncounterLineItems: {
              ...encounter.EncounterLineItems,
              LineItems: lineItems || encounter.EncounterLineItems.LineItems,
            },
            MedicationsPrescriptions: prescriptions || encounter.MedicationsPrescriptions,
          }))
        )) ||
      of(null)
    );
  }

  startCapturingEncounter(practiceId: string, patientId: string, encounter: EncounterStatusVo) {
    return this.saveEncounter(practiceId, patientId, encounter).pipe(mapTo(encounter));
  }

  loadEncounterSubCollections(encounter: EncounterStatusVo, practiceTenantId: string): any {
    // threshold limits: 120: SymptomQuestions, 240: SymptomQuestionAnswers, 50: EncounterLineItems.LineItems,
    return forkJoin([
      of(encounter).pipe(
        switchMap(s => {
          if (s.SymptomQuestions == null || s?.SymptomQuestions?.length >= 120) {
            return this.encounterRef(practiceTenantId, s.EncounterId).collection('SymptomQuestions').valueChanges().pipe(first());
          }
          return of(null);
        })
      ),
      of(encounter).pipe(
        switchMap(s => {
          if (s.SymptomQuestionAnswers == null || s.SymptomQuestionAnswers?.length >= 240) {
            return this.encounterRef(practiceTenantId, s.EncounterId).collection('SymptomQuestionAnswers').valueChanges().pipe(first());
          }
          return of(null);
        })
      ),
      of(encounter).pipe(
        switchMap(s => {
          if (s.EncounterLineItems.LineItems == null || s.EncounterLineItems.LineItems.length >= 50) {
            return this.encounterRef(practiceTenantId, s.EncounterId)
              .collection('EncounterLineItems.LineItems')
              .valueChanges()
              .pipe(first());
          }
          return of(null);
        })
      ),
      of(encounter).pipe(
        switchMap(s => {
          if (s.MedicationsPrescriptions === null) {
            return this.encounterRef(practiceTenantId, s.EncounterId).collection('MedicationsPrescriptions').valueChanges().pipe(first());
          }
          return of(null);
        })
      ),
    ]);
  }
  getEncountersRxHistory(
    practiceTenantId: string,
    patientId: string,
    type?: 'dispensed' | 'prescribed' | 'all' | null
  ): Observable<EncounterStatusVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r =>
        r.where('Status', 'in', ['Completed', 'Finalised', 'Corrected']).where('PatientEventDetails.Patient.PatientId', '==', patientId)
      )
      .valueChanges()
      .pipe(
        first(),
        switchMap((encounters: EncounterStatusVo[]) =>
          encounters.length > 0 ? forkJoin([...encounters.map(encounter => this.loadFullEncounter(encounter, practiceTenantId))]) : []
        ),
        map(encounters => encounters.map(encounter => convertFirestoreToEncounter(encounter))),
        map(encounters =>
          encounters.filter(
            (encounter: EncounterStatusVo) =>
              encounter.EncounterLineItems.LineItems.find(s => s.LineType == 'Medicine' || s.LineType == 'Consumable') != null ||
              encounter.MedicationsPrescriptions?.find(s => s.MedicationPrescriptionLines.length > 0 != null)
          )
        ),
        map(encounters => {
          if (type === 'dispensed') {
            return encounters.filter((encounter: EncounterStatusVo) =>
              encounter.EncounterLineItems.LineItems.find(s => s.LineType == 'Medicine' || s.LineType == 'Consumable')
            );
          } else if (type === 'prescribed') {
            return encounters.filter((encounter: EncounterStatusVo) =>
              encounter.MedicationsPrescriptions?.find(s => s.MedicationPrescriptionLines.length > 0 != null)
            );
          } else {
            return encounters;
          }
        })
      );
  }
}
