import { Directive, HostListener, OnInit, Input, OnDestroy, HostBinding } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { MatDialogRef, MatDialogContainer } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { takeUntil, tap } from 'rxjs/operators';

// from: https://stackoverflow.com/questions/47510888/how-can-i-make-a-matdialog-draggable-angular-material
@Directive({
  selector: '[sharedDialogDraggableTitle]',
})
export class DialogDraggableTitleDirective implements OnInit, OnDestroy {
  @Input() sharedDialogDraggableTitle: boolean;
  @HostBinding('style.cursor') cursor: string;

  private _subscription: Subscription;
  mouseStart: Position;
  mouseDelta: Position;
  offset: Position;

  constructor(private matDialogRef: MatDialogRef<any>, private container: MatDialogContainer) {}

  ngOnInit() {
    this.offset = this._getOffset();
  }

  ngOnDestroy(): void {
    this.cursor = '';
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.mouseStart = { x: event.pageX, y: event.pageY };

    if (this.sharedDialogDraggableTitle) {
      const mouseup$ = fromEvent(document, 'mouseup').pipe(untilDestroyed(this));
      this._subscription = mouseup$.subscribe(() => this.onMouseup());

      const mousemove$ = fromEvent(document, 'mousemove')
        .pipe(takeUntil(mouseup$), untilDestroyed(this))
        .subscribe((e: MouseEvent) => this.onMouseMove(e));

      this._subscription.add(mousemove$);
    }
  }

  onMouseMove(event: MouseEvent) {
    this.mouseDelta = { x: event.pageX - this.mouseStart.x, y: event.pageY - this.mouseStart.y };

    this._updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
  }

  onMouseup() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }

    if (this.mouseDelta) {
      this.offset.x += this.mouseDelta.x;
      this.offset.y += this.mouseDelta.y;
    }

    this.cursor = '';
  }

  private _updatePosition(top: number, left: number) {
    this.matDialogRef.updatePosition({
      top: top + 'px',
      left: left + 'px',
    });
  }

  private _getOffset(): Position {
    const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
    return {
      x: box.left + pageXOffset,
      y: box.top + pageYOffset,
    };
  }
}

export interface Position {
  x: number;
  y: number;
}
