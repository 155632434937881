<form [formGroup]="form">
  <h2 mat-dialog-title>Send email <mat-icon mat-dialog-close>clear</mat-icon>
  </h2>
  <!-- <mat-card class="delay-banner"><span>
      Email communications are currently delayed. We recommend printing urgent sick notes, referral letters &
      prescriptions.</span></mat-card> -->
  <mat-dialog-content>
    <mat-form-field class="field-input" floatLabel="never">
      <!-- <mat-label>To</mat-label> -->
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let recipient of recipients" selectable="true" removable="true" (removed)="remove(recipient)">
          {{ recipient }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input matInput #recipientInput formControlName="recipients" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
          (matChipInputTokenEnd)="add($event)" />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption="true">
        <mat-option *ngFor="let recipient of filteredAddresses$ | async" [value]="recipient.EmailAddress">
          <span class="auto-com-opt name">{{ recipient.Name }} {{ recipient.Surname }}</span>
          <span class="auto-com-opt email">{{ recipient.EmailAddress }}</span>
        </mat-option>
      </mat-autocomplete>
      <span matPrefix>To &nbsp;&nbsp;</span>
      <div matSuffix>
        <div *ngIf="!!(provider$ | async)?.EmailAddress" style="position: relative">
            <mat-checkbox formControlName="sendBlindCopyToTenant" class="send-copy-checkbox"
              *ngIf="provider$ | async as provider">Send me a blind carbon copy
            </mat-checkbox>
            <mat-icon class="send-copy-icon">person</mat-icon>
          </div>
        <div *ngIf="!!(provider$ | async)?.EmailAddress" style="position: relative">
          <mat-checkbox formControlName="sendCopyToTenant" class="send-copy-checkbox"
            *ngIf="provider$ | async as provider" (change)="toggleRecipient(provider.EmailAddress)">Send me a copy
          </mat-checkbox>
          <mat-icon class="send-copy-icon">person</mat-icon>
        </div>
        <div *ngIf="patient$ | async as patient" style="position: relative">
          <div class="column">
            <div class="row">
              <mat-checkbox formControlName="sendCopyToPatient" class="send-copy-checkbox"
                [disabled]="!patient?.PatientDetails?.EmailAddress"
                (change)="toggleRecipient(patient.PatientDetails.EmailAddress)">Send patient a copy
              </mat-checkbox>
              <mat-icon class="send-copy-icon">person</mat-icon>
            </div>
            <label class="error-mess" *ngIf="!patient?.PatientDetails?.EmailAddress">No email address captured for this
              patient.</label>
          </div>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field class="field-input" floatLabel="never">
      <!-- <mat-label>Subject</mat-label> -->
      <input matInput type="text" formControlName="subject" placeholder="Subject" />
    </mat-form-field>
    <div *ngIf="data.showFiles" class="attachments">
      <mat-chip-list *ngIf="this.form.value.attachments as files">
        <mat-chip *ngFor="let file of files" class="chip-file">
          {{ file.FileName }}
          <mat-icon (click)="removeAttachment(file)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>

    <div class="editor-section">
      <editor formControlName="letterText" [init]="editorConfig" class="editor"></editor>
      <div class="files" *ngIf="data.attachFiles">
        <app-form-file-selection formControlName="files">Add files</app-form-file-selection>
        <mat-chip-list *ngIf="files$ | async as files">
          <mat-chip *ngFor="let file of files" class="chip-file">
            <!-- <mat-icon mat-list-icon>image</mat-icon> -->
            {{ file.size | fileSize }}
            <!-- <h4>{{ file.name | slice:0:15 }}</h4> -->
            <mat-icon (click)="removeFile(file)">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>

    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button cdkFocusInitial color="primary" [disabled]="form.invalid || sendBusy" #sendButton
      (click)="send(form, sendButton)">
      <mat-spinner class="busy-spinner" *ngIf="sendBusy" diameter="20" color="primary"></mat-spinner>Send
    </button>
  </mat-dialog-actions>
</form>
