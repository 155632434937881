import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noNegativeValue' })
export class NoNegativeValuePipe implements PipeTransform {
  transform(value: number): number {
    if (value < 0) {
      value *= -1;
      return value;
    }
    return value;
  }
}
