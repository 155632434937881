import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';

@Directive({
  selector: '[sharedFileDrop]',
})
export class FileDropDirective {
  // reference: https://scotch.io/@minrock/how-to-create-a-drag-and-drop-file-directive-in-angular2-with-angular-cli-part-2

  @HostBinding('style.background') private background = '';

  constructor() {}

  @Input() fileDropAllowedExtensions: Array<string> = [];
  @Input() fileDropChangeBackground = true;
  @Input() fileDropHoverBackground = '#999';

  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter();
  @Output() invalidFilesDropped: EventEmitter<File[]> = new EventEmitter();

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.fileDropChangeBackground) {
      this.background = this.fileDropHoverBackground;
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.fileDropChangeBackground) {
      this.background = '';
    }
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.fileDropChangeBackground) {
      this.background = '';
    }

    const files: FileList = evt.dataTransfer.files;
    let validFiles: Array<File> = [];
    let invalidFiles: Array<File> = [];

    if (files.length > 0) {
      validFiles = Array.from(files).filter(file => {
        const ext = file.name.split('.')[file.name.split('.').length - 1];
        return (
          !this.fileDropAllowedExtensions || // if null [allowedExts] then assume valid
          this.fileDropAllowedExtensions.length === 0 ||
          this.fileDropAllowedExtensions.lastIndexOf(ext) >= 0
        );
      });
      invalidFiles = Array.from(files).filter(file => {
        const ext = file.name.split('.')[file.name.split('.').length - 1];
        return (
          this.fileDropAllowedExtensions && // only if
          this.fileDropAllowedExtensions.length >= 0 &&
          this.fileDropAllowedExtensions.lastIndexOf(ext) === 0
        );
      });

      if (validFiles && validFiles.length > 0) {
        this.filesDropped.emit(validFiles);
      }
      if (invalidFiles && invalidFiles.length > 0) {
        this.invalidFilesDropped.emit(invalidFiles);
      }
    }
  }
}
