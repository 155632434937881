import { Injectable } from '@angular/core';
import {
  ChangeProviderConfigurationOptions,
  BillingClient,
  SetProviderConfigurationOptions,
  ClinicalNewClient,
  ClinicalClient,
  MyMpsClient,
  AddressBookEntryVo,
  WaitingRoomClient,
  ProviderConfigurationVo,
  PathologyReportDeliveryVo,
  PathologyReportWorkflowVo,
  PatientDocumentVo,
  PatientDocumentClient,
  PatientChronicConditionsVo,
  AllergenVo,
  PatientInWaitingRoom,
  EncounterTemplateVo,
  PatientEventClient,
  PatientEventsByDateVo,
  AccountClient,
  ChangeUserPassword,
  TemplateSummaryVo,
  SortingOrder,
  SortingOrder2,
  ConditionFilterVo,
  CommunicationTemplateVo,
  CommunicationTemplateClient,
  ReminderClient,
  ReminderVo,
  NotificationVo,
  NotificationClient,
  PatientConfigurationVo,
  PatientClient,
  UserFeedbackVo,
  ProviderBranchConfigurationVo,
  RestApiResultOfString,
  OptionVo,
  PlanVo,
  MedicalAidVo,
  PatientVo,
} from './api-client.service';
import {
  timeout,
  tap,
  map,
  switchMap,
  take,
  mapTo,
  withLatestFrom,
  distinctUntilChanged,
  shareReplay,
  catchError,
  startWith,
  filter,
} from 'rxjs/operators';
import { AuthQuery } from './state/auth/auth.query';
import {
  ProviderStore,
  PatientInWaitingRoomViewModel,
  ChatRoomStatusVo,
  PathologyReportViewModel,
  PathCentreFilter,
} from './state/provider/provider.store';
import { ProviderQuery } from './state/provider/provider.query';
import { action, arrayAdd, arrayRemove, arrayUpsert, withTransaction } from '@datorama/akita';
import { UpdateAddressBookEntry, CalendarEventVo } from './api-client.service';
import { Observable, of, combineLatest, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { DocumentUploadClient } from './document-upload.client';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { CalendarEventViewModel } from './view-models/calendarEvent-view-model.utils';
import { ProviderConfigurationViewModel } from './view-models/providerConfiguration-view-model.utils';
import { CommunicationTemplateType, ReferenceDataService } from './reference-data.service';
import * as _ from 'lodash';
import { PracticeProviderStateUI, UiStateStore } from './state/ui-state/ui-state.store';
import { MerakiClientService } from './meraki-client.service';
import { PathologyReportFirestoreVo } from './meraki-models/meraki-models';
import { Scheme } from './meraki-models/scheme.models';
import { MerakiLookupService } from './meraki/meraki-lookup.service';
import { MerakiEncounterService } from './meraki/meraki-encounter.service';
import { MerakiPatientService } from './meraki/meraki-patient.service';

export enum ProviderOptionsEnum {
  HealthbridgeClinicalLiteContract = 'IsHealthbridgeClinicalLiteEnabled',
  HealthbridgeClinicalContract = 'IsHealthbridgeClinicalEnabled',
  DisplayPrices = 'IsEnabledForDisplayingPrices',
  DisableFinancialInformation = 'IsDisabledForDisplayingPatientFinancialInformation',
  ConsultReasonForVisitEnabled = 'IsConsultReasonForVisitEnabled',
  EncounterSectionsCollapsed = 'IsEncounterSectionCollapsed',
  IsEnabledForHealthIdPrompt = 'IsEnabledForHealthIdPrompt',
  IsEnabledForAdvancedSymptoms = 'IsEnabledForAdvancedSymptoms',
  IsEnabledForDispensing = 'IsEnabledForDispensing',
  IsEnabledForPpeCodes = 'IsEnabledForPpeCodes',
  IncludeQrCodeInReport = 'IncludeQrCodeInReport',
  IncludesDigitalSignature = 'IncludesDigitalSignature', // used only for scripts
  IsEnabledForSymptom = 'IsEnabledForSymptom',
  IsEnabledForExamination = 'IsEnabledForExamination',
  IsEnabledForPlan = 'IsEnabledForPlan',
  IsConsultNotesDefault = 'IsConsultNotesDefault',
  IsEnabledForConsultTypeOptions = 'IsEnabledForConsultTypeOptions',
  IsEnabledForMedicines = 'IsEnabledForMedicines',
  IsRequireToShowConsultNavigationTips = 'IsRequireToShowConsultNavigationTips',
  CheckForMedicineInteractionsOnOverview = 'CheckForMedicineInteractionsOnOverview',
  CheckForMedicineInteractionsOnEncounter = 'CheckForMedicineInteractionsOnEncounter',
  IsEnabledForSmartGenericMedicines = 'IsEnabledForSmartGenericMedicines', // ignored, disabled used below
  IsDisabledForSmartGenericMedicines = 'IsDisabledForSmartGenericMedicines',
  IsEnabledToHideDialogForMedicineFavorite = 'IsEnabledToHideDialogForMedicineFavorite',
  IsEnabledToExcludeDefaultProcedureCode = 'IsEnabledToExcludeDefaultProcedureCode',
  IsEnabledConsultationNotesTab = 'IsEnabledConsultationNotesTab',
  IsEnabledForSmartGenericAds = 'IsEnabledForSmartGenericAds',
  ExcludeSignatureFromMedicalCertificate = 'ExcludeSignatureFromMedicalCertificate',
  ExcludeSignatureFromPrescriptions = 'ExcludeSignatureFromPrescriptions',
  ExcludeSignatureFromReferralLetter = 'ExcludeSignatureFromReferralLetter',
}

export enum ProviderConfigurationKey {
  SendClaimToAdminByPlaceOfService = 'SendClaimToAdminByPlaceOfService',
  SendClaimToAdminByScheme = 'SendClaimToAdminByScheme',
  DisabledReasonForVisit = 'DisabledReasonForVisit',
  GuidedTourConsultNavigation = 'GuidedTourConsultNavigation',
  PrivacyPolicyDateAccepted = 'PrivacyPolicyDateAccepted',
  AppointmentTypeConfig = 'AppointmentTypeConfig',
  DisabledPlanOutcomes = 'DisabledPlanOutcomes',
  CommonConditionsConfig = 'CommonConditionsConfig',
  CommonSurgeriesConfig = 'CommonSurgeriesConfig',
}

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private currentTenantId$ = this.authQuery.currentTenantId$;

  provider$ = this.query.provider$;
  providerOptions$ = this.query.provider$.pipe(map(provider => (!!provider ? provider.Options : {})));
  addressbook$ = this.query.addressbook$;
  appointments$ = this.query.calendar$;
  waitingRoom$ = this.query.waitingRoom$;
  specialityRule$ = this.query.specialityRule$;
  undeliveredReports$ = this.query.undeliveredReports$;
  patientReports$ = this.query.patientReports$;
  followupReports$ = this.query.followupReports$;
  urgentReports$ = this.query.urgentReports$;
  weekReports$ = this.query.weekReports$;
  pathologyReports$ = this.query.pathologyReports$;
  pathCentreReports$ = this.query.pathReports$;
  todayReports$ = this.query.todayReports$;
  trackingConditions$ = this.query.trackingConditions$;
  tasks$ = this.query.tasks$;
  allergens$ = this.query.allergens$;
  practiceProviders$ = this.query.practiceProviders$;
  practiceProvidersStateUI$ = this.uiQuery.practiceProviders$;
  branches$ = this.query.branches$;
  templates$ = this.query.templates$;
  invoiceTemplates$ = this.query.invoiceTemplates$;
  templateGroups$ = this.query.templateGroups$;
  visitHistory$ = this.query.visitHistory$;
  communicationTemplates$ = this.query.communicationTemplates$;
  notifications$ = this.query.notifications$;
  providerConfigurations$ = this.query.providerConfiguration$;
  medicalInsurers$ = this.query.medicalInsurers$;

  waitingRoomUI$ = combineLatest([this.waitingRoom$, this.query.chatStatuses$]).pipe(
    map(([waitingRoom, ui]) =>
      waitingRoom?.map(s => ({
        patientEvent: s.PatientEvent,
        status: ui?.find(d => d.ChatRoomId === s.PatientEvent.PatientEventXRef),
      }))
    )
  );

  defaultProviderOptions = {
    IsConsultReasonForVisitEnabled: true,
    IsHealthbridgeClinicalEnabled: false,
    IsHealthbridgeClinicalLiteEnabled: false,
    IsEnabledForSymptom: true,
    IsConsultNotesDefault: false,
    IsEnabledForExamination: true,
    IsEnabledForPlan: true,
    IncludesDigitalSignature: true,
    IsEnabledForAdvancedSymptoms: true,
    IsEncounterSectionCollapsed: false,
    IsEnabledForHealthIdPrompt: true,
    IsEnabledForDispensing: false,
    IsEnabledForMedicines: true,
    IsEnabledForPpeCodes: false,
    IsEnabledForConsultTypeOptions: true,
    IsRequireToShowConsultNavigationTips: false,
    CheckForMedicineInteractionsOnOverview: false,
    CheckForMedicineInteractionsOnEncounter: false,
    // IsEnabledForSmartGenericMedicines: false,
    IsDisabledForSmartGenericMedicines: false,
    IsEnabledForSmartGenericAds: false,
    ExcludeSignatureFromMedicalCertificate: false,
    ExcludeSignatureFromPrescriptions: false,
    ExcludeSignatureFromReferralLetter: false,
  };
  DefaultStartWorkingHours = '06:00';
  DefaultEndWorkingHours = '20:00';

  PrivacyPolicyLatestDate = '2021/08/18';

  IsConsultReasonForVisitEnabled$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.ConsultReasonForVisitEnabled, options)),
    shareReplay(1)
  );

  IsEnabledForHealthIdPrompt$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForHealthIdPrompt, options)),
    shareReplay(1)
  );

  IsEnabledForDispensing$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForDispensing, options)),
    shareReplay(1)
  );

  ExcludeSignatureFromMedicalCertificate$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.ExcludeSignatureFromMedicalCertificate, options)),
    shareReplay(1)
  );
  ExcludeSignatureFromPrescriptions$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.ExcludeSignatureFromPrescriptions, options)),
    shareReplay(1)
  );
  ExcludeSignatureFromReferralLetter$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.ExcludeSignatureFromReferralLetter, options)),
    shareReplay(1)
  );

  IsEnabledForPpeCodes$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForPpeCodes, options))
  );

  IncludeQrCodeInReport$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IncludeQrCodeInReport, options))
  );

  IsEnabledToHideDialogForMedicineFavorite$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledToHideDialogForMedicineFavorite, options))
  );

  IsEnabledToExcludeDefaultProcedureCode$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getExcludeProcedureCode(options))
  );

  IsEnabledConsultationNotesTab$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledConsultationNotesTab, options))
  );

  IsEnabledForSymptom$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForSymptom, options)),
    shareReplay(1)
  );

  IsConsultNotesDefault$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsConsultNotesDefault, options)),
    shareReplay(1)
  );

  IsEnabledForExamination$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForExamination, options)),
    shareReplay(1)
  );

  IsEnabledForPlan$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForPlan, options)),
    shareReplay(1)
  );

  // 'Use ExcludeSignatureFromPrescriptions$ instead'
  IncludesDigitalSignature$ = this.providerOptions$.pipe(
    map(options => this.getOptionValue(ProviderOptionsEnum.IncludesDigitalSignature, options)),
    shareReplay(1)
  );

  IsEnabledForAdvancedSymptoms$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForAdvancedSymptoms, options)),
    shareReplay(1)
  );

  IsEncounterSectionCollapsed$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.EncounterSectionsCollapsed, options)),
    shareReplay(1)
  );

  CheckForMedicineInteractionsOnOverview$ = this.providerOptions$.pipe(
    startWith({}),
    switchMap(options => this.getAzMedicineInteractionAddOnConfig$().pipe(map(trial => ({ options, trial })))),
    map(({ options, trial }) => trial || this.getOptionValue(ProviderOptionsEnum.CheckForMedicineInteractionsOnOverview, options)),
    shareReplay(1)
  );

  CheckForMedicineInteractionsOnEncounter$ = this.providerOptions$.pipe(
    startWith({}),
    switchMap(options => this.getAzMedicineInteractionAddOnConfig$().pipe(map(trial => ({ options, trial })))),
    map(({ options, trial }) => trial || this.getOptionValue(ProviderOptionsEnum.CheckForMedicineInteractionsOnEncounter, options)),
    shareReplay(1)
  );

  WorkingHoursStart$: Observable<string> = this.providerOptions$.pipe(
    startWith({}),
    map((options: any) => options?.WorkingHoursStart),
    shareReplay(1)
  );

  WorkingHoursEnd$: Observable<string> = this.providerOptions$.pipe(
    startWith({}),
    map((options: any) => options?.WorkingHoursEnd),
    shareReplay(1)
  );

  PrivacyPolicyDateAccepted$: Observable<string> = this.providerOptions$.pipe(
    startWith({}),
    map((options: any) => options?.PrivacyPolicyDateAccepted),
    shareReplay(1)
  );

  calendarView$ = this.query.select(store => store.calendarView);

  isFull$ = this.provider$.pipe(
    map(s => s?.Options),
    filter(s => s != null),
    map(options => this.getOptionValue(ProviderOptionsEnum.HealthbridgeClinicalContract, options))
  );

  isLite$ = this.provider$.pipe(
    map(s => s?.Options),
    filter(s => s != null),
    map(options => this.getOptionValue(ProviderOptionsEnum.HealthbridgeClinicalLiteContract, options))
  );

  isMymps$ = this.provider$.pipe(
    map(
      provider =>
        !!provider &&
        !!provider.PracticeXRef &&
        provider.PracticeXRef.length > 0 &&
        !provider.PracticeXRef.startsWith('iHealth') &&
        !provider.PracticeXRef.startsWith('nova')
    ),
    distinctUntilChanged()
  );

  isForge$ = this.provider$.pipe(
    map(provider => provider?.PracticeXRef && provider.PracticeXRef?.startsWith('nova')),
    distinctUntilChanged()
  );

  isIHealth$ = this.provider$.pipe(map(provider => provider.PracticeXRef && provider.PracticeXRef.startsWith('iHealth')));

  isHealthIdPromptEnabled$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForHealthIdPrompt, options)),
    shareReplay(1)
  );

  IsEnabledForMedicines$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForMedicines, options)),
    shareReplay(1)
  );

  IsEnabledForConsultTypeOptions$ = this.providerOptions$.pipe(
    startWith({}),
    withLatestFrom(this.provider$),
    map(([options, provider]) => {
      const option = options[ProviderOptionsEnum.IsEnabledForConsultTypeOptions];
      if (option === null || option === undefined) {
        if (this.referenceDataService.SpecialityCodesForEnhancedHospitalConsultReasons.includes(provider?.SpecialityCode)) {
          return true;
        } else {
          return false;
        }
      }
      return option === 'true';
    }),
    shareReplay(1)
  );

  IsRequireToShowConsultNavigationTips$ = this.providerOptions$.pipe(
    map(options => {
      const option = options[ProviderOptionsEnum.IsRequireToShowConsultNavigationTips];

      if (option === null || option === undefined) {
        return true;
      }

      return option === 'true';
    }),
    shareReplay(1)
  );

  IsDisabledForSmartGenericMedicines$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsDisabledForSmartGenericMedicines, options)),
    shareReplay(1)
  );

  IsEnabledForSmartGenericAds$ = this.providerOptions$.pipe(
    startWith({}),
    map(options => this.getOptionValue(ProviderOptionsEnum.IsEnabledForSmartGenericAds, options)),
    shareReplay(1)
  );

  overdueTasks$ = this.tasks$.pipe(
    distinctUntilChanged(),
    map(tasks => tasks.filter(_ => !_.Completed)),
    map(tasks =>
      tasks.filter(
        _ =>
          moment().utc(true).format('YYYY-MM-DD') !== moment(_.DueDate).format('YYYY-MM-DD') &&
          moment().utc(true).toDate().getTime() >= moment(_.DueDate).toDate().getTime()
      )
    ),
    map(tasks => tasks.sort((a, b) => new Date(a.DueDate).getTime() - new Date(b.DueDate).getTime()))
  );

  readonly emptyGuid: string = '00000000-0000-0000-0000-000000000000'; // upload file with patientId empty Guid

  medicalAids$ = this.isForge$.pipe(
    switchMap(isForge =>
      !isForge
        ? this.referenceDataService.medicalAids$
        : this.merakiLookupService.getAllMedicalAids().pipe(
            map((aids: Scheme[]) =>
              aids
                .map(
                  aid =>
                    ({
                      Name: aid.Name,
                      SchemeCode: aid.Code,
                      Plans:
                        aid.Plans?.map(
                          p =>
                            ({
                              Name: p.Plan,
                              PlanCode: p.Code,
                              Options:
                                p.Options?.map(
                                  o =>
                                    ({
                                      Name: o.Option,
                                      OptionCode: o.Code,
                                      RouteCode: o.RouteCode,
                                    } as OptionVo)
                                ) || [],
                            } as PlanVo)
                        ) || [],
                    } as MedicalAidVo)
                )
                .filter(s => s.Name && s.Plans.length > 0)
            ),
            map(aids =>
              _.chain(aids)
                .orderBy(s => s.Name)
                .value()
            )
            //catchError((err) => { console.error('error aid', err); return of(([] as MedicalAidVo[])); }),
            //distinctUntilChanged(_.isEqual)
          )
    ),
    shareReplay(1)
  );

  flattenedMedicalAids$ = this.medicalAids$.pipe(
    map(medicalAids =>
      medicalAids.flatMap(scheme =>
        scheme.Plans.flatMap(plan =>
          plan.Options.flatMap(option => ({
            SchemeName: scheme.Name,
            SchemeCode: scheme.SchemeCode,

            PlanName: plan.Name,
            PlanCode: plan.PlanCode,

            OptionName: option.Name,
            OptionCode: option.OptionCode,
            RoutingCode: option.RoutingCode,
            PVEnabled: option.RoutingCode,
          }))
        )
      )
    )
  );

  patientSummaryEnabled$ = this.provider$.pipe(
    switchMap(provider => this.merakiClientService.isPracticeEnabledForPatientAI(provider?.PracticeNumber)),
    distinctUntilChanged()
  );

  speechToNotesEnabled$ = this.provider$.pipe(
    switchMap(provider => this.merakiClientService.isPracticeEnabledForSpeechToNotes(provider?.PracticeNumber, provider?.HPCSANumber)),
    distinctUntilChanged()
  );

  sandboxEnabled$ = this.provider$.pipe(
    switchMap(provider => this.merakiClientService.isPracticeEnabledForSandboxProjects(provider?.PracticeNumber, provider?.HPCSANumber)),
    distinctUntilChanged()
  );

  constructor(
    private store: ProviderStore,
    private query: ProviderQuery,

    private authQuery: AuthQuery,

    private waitingRoomClient: WaitingRoomClient,
    private bilingClient: BillingClient,
    private clinicalClient: ClinicalClient,
    private clinicalNew: ClinicalNewClient,
    private mympsClient: MyMpsClient,
    private patientEventClient: PatientEventClient,
    private accountClient: AccountClient,
    private reminderClient: ReminderClient,
    private communicationTemplateClient: CommunicationTemplateClient,

    private documentUploadClient: DocumentUploadClient,
    private documentClient: PatientDocumentClient,
    private uiQuery: UiStateQuery,
    private uiStore: UiStateStore,
    private referenceDataService: ReferenceDataService,
    private notificationClient: NotificationClient,
    private patientClient: PatientClient,
    private merakiLookupService: MerakiLookupService,
    private merakiEncounterService: MerakiEncounterService,
    private merakiPatientService: MerakiPatientService,
    private merakiClientService: MerakiClientService
  ) {}

  private getOptionValue(enamValue: ProviderOptionsEnum, options): boolean {
    const optionName = enamValue;
    const option = options[optionName];
    if (!option) {
      return this.defaultProviderOptions[enamValue];
    }
    return option.toLowerCase() === 'true';
  }

  public getAzMedicineInteractionAddOnConfig$() {
    return this.merakiLookupService
      .getFeatureAddOnConfig('A-Z-Trial')
      .pipe(
        map(
          trial =>
            trial.Enabled &&
            moment(trial.DateNow.toDateString()).isAfter(moment(trial.BeginDate.toDateString())) &&
            moment(trial.DateNow.toDateString()).isBefore(moment(trial.EndDate.toDateString()))
        )
      );
  }

  public isPpeEnabled(options: any) {
    return this.getOptionValue(ProviderOptionsEnum.IsEnabledForPpeCodes, options);
  }

  public getExcludeProcedureCode(options: any) {
    return this.getOptionValue(ProviderOptionsEnum.IsEnabledToExcludeDefaultProcedureCode, options);
  }

  @action('addPatientToWaitingRoom')
  public addPatientToWaitingRoom(providerId: string, patient: PatientVo) {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiPatientService.checkinInPatient(provider.PracticeNumber, patient, provider)
          : this.waitingRoomClient.addPatientToWaitingRoom(providerId, patient.PatientId)
      ),
      switchMap(ret => this.getWaitingList()),
      map(waitingRoom => waitingRoom.find(w => w.PatientEvent.Patient.PatientId === patient.PatientId)),
      take(1)
    );
  }

  removeUndeliveredReport(pathologyReportId: string) {
    this.store.update(entity => ({
      undeliveredReports: arrayRemove(entity.undeliveredReports, d => d.PathologyReportId === pathologyReportId),
    }));
  }

  addChatStatus(status: ChatRoomStatusVo) {
    this.store.update(e => {
      let statuses = arrayRemove(e.chatStatuses, d => d.ChatRoomId === status.ChatRoomId);
      if (status.Status === 'joined') {
        statuses = arrayAdd(e.chatStatuses, status);
      }
      return { chatStatuses: statuses };
    });
  }

  updateUndeliveredReports(undeliveredReports: PathologyReportDeliveryVo[]) {
    this.store.update({ undeliveredReports });
  }

  updatePatientReports(patientReports: PathologyReportWorkflowVo[]) {
    this.store.update({ patientReports });
  }

  updatePathReports(pathReports: any[]) {
    this.store.update({ pathReports });
  }

  updateTrackingConditions(conditions: PatientChronicConditionsVo[]) {
    this.store.update({ trackingConditions: conditions });
  }

  updateFollowupReports(followupReports: PathologyReportWorkflowVo[]) {
    this.store.update({ followupReports });
  }

  updateUrgentReports(urgentReports: PathologyReportWorkflowVo[]) {
    this.store.update({ urgentReports });
  }

  updateTodayReports(todayReports: PathologyReportFirestoreVo[]) {
    this.store.update({ todayReports });
  }

  updateWeekReports(weekReports: PathologyReportWorkflowVo[]) {
    this.store.update({ weekReports });
  }

  updatePathFilters(pathFilters: PathCentreFilter) {
    this.store.ui.update({ pathFilters: pathFilters });
  }

  getPathFilter$(): Observable<PathCentreFilter> {
    return this.query.providerUiState$().pipe(distinctUntilChanged());
  }

  updatePathologyReport(pathologyReports: PathologyReportViewModel[]) {
    this.store.update({ pathologyReports });
  }

  updateCommunicationTemplates(communicationTemplates: CommunicationTemplateVo[]) {
    this.store.update({ communicationTemplates });
  }

  createTemplateCategory(practiceId: string, templateType: string, request: TemplateSummaryVo) {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiEncounterService
              .createTemplateGroup(provider.PracticeNumber, provider.HPCSANumber, templateType, request)
              .pipe(map(s => ({ Data: '', Sucess: true } as RestApiResultOfString)))
          : this.mympsClient.createTemplateGroup(practiceId, templateType, request)
      )
    );
  }

  saveTemplate(template: EncounterTemplateVo, existingTemplates: TemplateSummaryVo[]) {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiEncounterService
              .updateTemplate(provider.PracticeNumber, provider.HPCSANumber, template, existingTemplates)
              .pipe(catchError(s => of({ Data: '', Sucess: false, ResponseMessage: '' } as RestApiResultOfString)))
          : this.mympsClient.updateTemplate(template.PracticeId, template)
      )
    );
  }

  updateVisitHistory(visitHistory: PatientEventsByDateVo[]) {
    this.store.update({ visitHistory });
  }

  removeVisitFromWaitingRoom(patientVisitId: string) {
    this.store.update(entity => ({ waitingRoom: entity.waitingRoom.filter(s => s.PatientEvent.PatientEventId !== patientVisitId) }));
  }

  removeVisitFromWaitingRoomByPatientId(tenantId: string, patientId: string) {
    this.store.update(entity => ({
      waitingRoom: arrayRemove(
        entity.waitingRoom,
        s => s.PatientEvent.PracticeId === tenantId && s.PatientEvent.Patient.PatientId === patientId
      ),
    }));
  }

  updateAddressbookEntry(providerId: string, entry: AddressBookEntryVo) {
    const addressBookEntries: UpdateAddressBookEntry = {
      AddressBookEntry: entry,
      OriginatedSystem: 'ClinicianApp',
    };

    const request = this.merakiClientService.updateAddressBook(providerId, entry.AddressBookEntryId, addressBookEntries);
    return request;
  }

  deleteAddressbookEntry(providerId: string, addressBookEntryId: string) {
    const request = this.merakiClientService.removeAddressBook(providerId, addressBookEntryId).pipe(
      switchMap(t => this.merakiClientService.getAddressBookAll(providerId)),
      tap({
        next: addressbook => {
          const patchedAddressBook = [...addressbook.filter(a => a.AddressBookEntryId === addressBookEntryId)] as AddressBookEntryVo[];
          this.store.update({ addressbook });
        },
      })
    );

    return request;
  }

  loadCovidConditions(providerId: string, codes: string[]) {
    return this.merakiPatientService.filterTrackingConditions(providerId, { IcdCodes: codes });
  }

  getTrackingConditions(providerId: string, codes: string[]) {
    return this.merakiPatientService
      .filterTrackingConditions(providerId, { IcdCodes: codes })
      .pipe(tap(result => this.updateTrackingConditions(result)));
  }

  sendUserFeedback(practiceId: string, userFeedback: UserFeedbackVo) {
    return this.clinicalNew.sendUserFeedback(practiceId, userFeedback);
  }

  updateTask(provierId: string, task: ReminderVo) {
    // return this.reminderClient.updateReminder(provierId, task.ReminderId, task);
    return this.merakiClientService.updateReminder(provierId, task.ReminderId, task);
  }

  completeTask(provierId: string, task: ReminderVo) {
    // return this.reminderClient.completeReminder(provierId, task.ReminderId, task);
    return this.merakiClientService.updateReminder(provierId, task.ReminderId, task);
  }

  deleteReminder(provierId: string, taskId: string) {
    // return this.reminderClient.deleteReminder(provierId, taskId);
    return this.merakiClientService.deleteReminder(provierId, taskId);
  }

  getTasks(provierId: string) {
    // return this.reminderClient.getReminders(provierId).pipe(
    return this.merakiClientService.getReminders(provierId).pipe(tap(result => this.updateTasks(result)));
  }

  updateTasks(tasks: ReminderVo[]) {
    this.store.update({ tasks });
  }

  filterTrackingConditions(providerId: string, filterVo: ConditionFilterVo): Observable<PatientChronicConditionsVo[]> {
    //return this.clinicalNew.filterTrackingConditions(providerId, filterVo)
    return this.merakiPatientService
      .filterTrackingConditions(providerId, filterVo)
      .pipe(tap(result => this.updateTrackingConditions(result)));
  }

  getAllergens(practiceId: string) {
    return this.clinicalNew.getAllergens(practiceId).pipe(tap(result => result.Sucess && this.updateAllergens(result.Data)));
  }

  updateCommunicationTemplate(template: CommunicationTemplateVo) {
    return this.authQuery.auth$.pipe(
      take(1),
      switchMap(auth => this.merakiClientService.updateCommunicationTemplate({ ...template, LastUpdatedBy: auth.fullName || null }))
    );
  }

  deleteCommunicationTemplate(practiceTenantId: string, templateId: string) {
    return this.merakiClientService.removeCommunicationTemplate(practiceTenantId, templateId);
    // return this.communicationTemplateClient.remove(practiceId, templateId);
  }

  loadCommunicationTemplates(practiceId: string) {
    const templateTypes = Object.keys(CommunicationTemplateType).map(m => CommunicationTemplateType[m]);
    return (
      forkJoin([
        this.merakiClientService.getCommunicationTemplates(practiceId, templateTypes).pipe(catchError(() => of([]))),
        this.merakiClientService.getBasicCommunicationTemplates().pipe(catchError(() => of([]))),
      ])
        // this.communicationTemplateClient.getTemplatesForPracticeByTypes(practiceId, templateTypes)
        // return this.communicationTemplateClient.getTemplatesForDoctorByType(practiceId)
        .pipe(
          map(([doctorsTemplate, commonTemplates]) => (commonTemplates || []).concat(doctorsTemplate || [])),
          tap(templates => this.updateCommunicationTemplates(templates))
        )
    );
  }

  updateAllergens(allergens: AllergenVo[]) {
    this.store.update({ allergens });
  }

  loadMedicalInsurers$() {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        (isForge
          ? this.merakiLookupService.getAllMedicalInsurers()
          : this.patientEventClient.getAllMedicalInsurer(provider.PracticeId)
        ).pipe(tap(medicalInsurers => this.store.update({ medicalInsurers })))
      )
    );
  }

  getTemplateGroups(practiceId: string, templateType: string): Observable<TemplateSummaryVo[]> {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiEncounterService.getInvoiceTemplateGroup(provider.PracticeNumber, provider.HPCSANumber, templateType)
          : this.mympsClient.getTemplateGroups(practiceId, templateType)
      ),
      withTransaction(groups =>
        this.store.update(e => {
          let templates = arrayRemove(e.templateGroups, 'TemplateType', templateType);
          for (const template of groups) {
            templates = arrayUpsert(templates, template.TemplateXRef, template, 'TemplateXRef');
          }

          return { templateGroups: templates };
        })
      ),
      catchError(() => of([]))
    );
  }

  getTemplateDetails(providerId: string, xref: string, isFromGroup: boolean = false) {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? isFromGroup
            ? this.templates$.pipe(
                take(1),
                map(items => items.find(s => s.TemplateXRef === xref)),
                map(template => ({ ...this.merakiEncounterService.toEncounterTemplate(template.OriginalTemplate, template.TemplateType) }))
              )
            : this.invoiceTemplates$.pipe(
                take(1),
                map(items => items.find(s => s.TemplateXref === xref))
              )
          : this.mympsClient.getTemplateDetails(providerId, xref)
      )
    );
  }

  getTemplateGroupContent(providerId: string, groupXref: string, templateType: string) {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiEncounterService
              .getInvoiceTemplateGroupContent(provider.PracticeNumber, provider.HPCSANumber, templateType, groupXref)
              .pipe(take(1))
          : this.mympsClient.getTemplateGroup(providerId, groupXref, SortingOrder2._0)
      ),
      tap(templates => {
        this.store.update(entity => ({
          templates: arrayAdd(
            arrayRemove(entity.templates, item => item.GroupInvoiceTemplateXRef === groupXref),
            templates
          ),
        }));
      }),
      catchError(() => of([]))
    );
  }

  deleteTemplate(providerId: string, xref: string, groupXRef?: string) {
    return this.isForge$.pipe(
      take(1),
      withLatestFrom(this.provider$),
      switchMap(([isForge, provider]) =>
        isForge
          ? !groupXRef
            ? this.merakiEncounterService.deleteTemplate(provider.PracticeNumber, provider.HPCSANumber, xref)
            : this.merakiEncounterService.deleteTemplateFromGroup(provider.PracticeNumber, provider.HPCSANumber, groupXRef, xref)
          : this.mympsClient.deleteTemplate(providerId, xref)
      )
    );
  }

  clearTemplateType(templateType: string) {
    this.store.update(entity => ({ templates: arrayRemove(entity.templates, temp => temp.TemplateType === templateType) }));
  }

  clearInvoiceTemplateType(templateType: string) {
    this.store.update(entity => ({ invoiceTemplates: arrayRemove(entity.invoiceTemplates, temp => temp.TemplateType === templateType) }));
  }

  getTemplatePage(
    providerId: string,
    templateType: string,
    pageSize: number,
    pageNo: number,
    search?: string
  ): Observable<TemplateSummaryVo[]> {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiEncounterService
              .getInvoiceTemplates(provider.PracticeNumber, provider.HPCSANumber, templateType, pageSize, pageNo, search)
              .pipe(
                tap(templates => {
                  if (pageNo === 0) {
                    this.clearInvoiceTemplateType(templateType);
                  }
                  this.store.update(entity => ({ invoiceTemplates: arrayAdd(entity.invoiceTemplates, templates) }));
                }),
                map(templates => templates.map(t => ({ ...t, TemplateXRef: t.TemplateXref } as TemplateSummaryVo))),
                map(templates => templates.filter(t => !t.GroupInvoiceTemplateXRef))
              )
          : this.mympsClient.getTemplatePage(providerId, templateType, pageSize, pageNo, SortingOrder._0, search)
      ),
      tap(templates => {
        if (pageNo === 0) {
          this.clearTemplateType(templateType);
        }
        this.store.update(entity => ({ templates: arrayAdd(entity.templates, templates) }));
      }),
      catchError(err => {
        console.error('loading error', err);
        return of([]);
      })
    );
  }

  getVisitHistory(tenantId: string): Observable<PatientEventsByDateVo[]> {
    return this.provider$.pipe(
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        (isForge
          ? this.merakiPatientService.getPatientVisitHistory(provider.PracticeNumber, provider.HPCSANumber)
          : this.patientEventClient.getAll(tenantId)
        ).pipe(tap(s => this.updateVisitHistory(s)))
      )
    );
  }

  removeTrackingConditions(tenantId: string, patientId: any, codes: string[]): Observable<any> {
    // return this.clinicalNew.removeTrackingConditions(tenantId, patientId, { IcdCodes: codes })
    return this.merakiPatientService.removeTrackingConditions(tenantId, patientId, codes);
  }

  // todo review this logic, can be simplified, not wise to load all address books just to append new entry
  addAddressbookEntry(providerId: string, entry: AddressBookEntryVo) {
    this.merakiClientService.getAddressBookAll(providerId).pipe(
      tap({
        next: addressbook => {
          const patchedAddressBook = [
            ...addressbook.filter(a => a.AddressBookEntryId !== entry.AddressBookEntryId),
            entry,
          ] as AddressBookEntryVo[];

          this.store.update({ addressbook });
        },
      })
    );
  }

  updateUserPassword(userid: string, message: ChangeUserPassword) {
    return this.accountClient.updateUserPassword(userid, message);
  }

  getAddressbook(providerId: string) {
    return this.merakiClientService.getAddressBookAll(providerId).pipe(
      tap({
        next: addressbook => {
          // add to state management
          this.store.update({ addressbook });
        },
      })
    );
  }

  searchForProviders(search: string): Observable<ProviderConfigurationVo[]> {
    throw this.bilingClient.getProvidersWithFilterAsQueryable(search);
  }

  getAddressbookById(providerId: string, addressBookEntryId: string) {
    return this.merakiClientService.getAddressBookEntryById(providerId, addressBookEntryId).pipe(map(x => x));
  }

  loadProviderDetails(providerId: string) {
    return this.bilingClient.getProvider(providerId).pipe(tap(provider => this.store.update({ details: provider })));
  }

  getProvider(providerId: string) {
    return this.bilingClient.getProvider(providerId).pipe(
      switchMap(provider =>
        forkJoin([
          this.merakiLookupService.getSpecialityRule(provider.PracticeId, provider.SpecialityCode),
          this.getPracticeProviders(providerId),
          this.getPracticeBranchesForProvider(provider),
        ]).pipe(map(([specialityRule, practiceProviders, branches]) => ({ provider, specialityRule, practiceProviders, branches })))
      ),
      withLatestFrom(this.referenceDataService.randomColors$),
      tap({
        next: ([{ provider, specialityRule, practiceProviders, branches }, colors]) =>
          this.store.update({
            details: provider,
            providerId: provider.PracticeId,
            // Adding the loaded provider in here if florence didn't return any to prevent issues down the line.
            practiceProviders:
              practiceProviders.length > 0 ? practiceProviders : [{ Color: colors[0], ...provider } as ProviderConfigurationViewModel],
            specialityRule,
            lastChecked: new Date(),
            branches,
          }),
      }),
      map(([{ provider }]) => provider),
      switchMap(provider =>
        forkJoin([
          this.getWaitingList().pipe(take(1), mapTo(provider)),
          this.getNotifications(providerId).pipe(mapTo(provider)),
          this.getTasks(providerId).pipe(take(1), mapTo(provider)),
          this.getProviderConfiguration(providerId).pipe(mapTo(provider)),
          this.getAppointmentTypeConfiguration(providerId).pipe(mapTo(provider)),
        ])
      )
    );
  }

  getPracticeBranches(providerId: string): Observable<ProviderBranchConfigurationVo[]> {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, forge]) =>
        forge ? this.merakiLookupService.getPracticeBranches(provider.PracticeNumber) : this.bilingClient.getPracticeBranches(providerId)
      )
    );
  }

  getPracticeBranchesForProvider(provider: ProviderConfigurationVo): Observable<ProviderBranchConfigurationVo[]> {
    if (provider?.PracticeXRef && provider.PracticeXRef?.startsWith('nova')) {
      return this.merakiLookupService.getPracticeBranches(provider.PracticeNumber).pipe();
    }
    return this.bilingClient.getPracticeBranches(provider.PracticeId);
  }

  getNotifications(providerId: string) {
    return this.merakiClientService.getTopNotifications(providerId).pipe(tap(result => this.updateNotifications(result)));
  }

  viewedNotification(providerId: string, notificationId: string) {
    return this.merakiClientService.viewNotification(providerId, notificationId).pipe(switchMap(() => this.getNotifications(providerId)));
  }

  private updateNotifications(notifications: NotificationVo[]) {
    this.store.update({ notifications });
  }

  private getPracticeProviders(providerId: string): Observable<ProviderConfigurationViewModel[]> {
    return this.bilingClient.getPracticeProviderList(providerId).pipe(
      withLatestFrom(this.referenceDataService.randomColors$),
      map(([providers, colors]) =>
        _.chain(providers)
          .sortBy(s => s.TreatingDoctorName)
          .sortBy(s => (s.PracticeId === providerId ? 0 : 1))
          .map(
            (provider, index) =>
              ({
                ...provider,
                Color: colors[index < colors.length ? index : Math.floor(Math.random() * (colors.length - 1)) + 1],
              } as ProviderConfigurationViewModel)
          )
          .value()
      ),
      catchError(() => {
        console.error(`Error retrieving other providers for practice [${providerId}]`);
        return of([] as ProviderConfigurationViewModel[]);
      })
    );
  }

  @action('getCalendarAppointments')
  getCalendarAppointments(newLocal: Date, days: number = 1): Observable<CalendarEventVo[]> {
    const periodStart = moment(newLocal).startOf('day');
    const periodEnd = periodStart.clone();
    periodEnd.add(days, 'd');

    return this.currentTenantId$.pipe(
      take(1),
      switchMap(providerId => this.mympsClient.getCalendarAppointments(providerId, newLocal, days).pipe(timeout(10000))),
      withLatestFrom(this.query.select(e => e.calendar)),
      tap({
        next: ([serverApps, storeAppointments]) => {
          const currentApps = storeAppointments.filter(a => moment(a.StartDate) >= periodStart && moment(a.StartDate) < periodEnd);

          // remove all current apps within the time period
          this.store.update(entity => ({
            calendar: arrayRemove(entity.calendar, a => currentApps.some(b => b.CalendarEventXRef === a.CalendarEventXRef)),
          }));

          // add all from server
          this.store.update(entity => ({
            calendar: arrayAdd(entity.calendar, serverApps),
          }));
        },
      }),
      map(([serverApps, currentApps]) => serverApps),
      catchError((err, caught) => {
        console.error(err);
        return of([] as CalendarEventVo[]);
      })
    );
  }

  @action('getPracticeCalendarAppointments')
  getPracticeCalendarAppointments(
    newLocal: Date,
    days: number = 1,
    selectedProviders: PracticeProviderStateUI[]
  ): Observable<CalendarEventViewModel[]> {
    const periodStart = moment(newLocal).startOf('day');
    const periodEnd = periodStart.clone();
    periodEnd.add(days, 'd');

    return this.currentTenantId$.pipe(
      take(1),
      withLatestFrom(of(selectedProviders), this.provider$, this.isForge$),
      switchMap(([providerId, providers, provider, isForge]) =>
        isForge
          ? this.merakiPatientService
              .getPracticeCalendarAppointments(
                provider.PracticeNumber,
                providers.map(s => s.hpcsaNo).filter(s => !!s),
                periodStart.toDate(),
                periodEnd.endOf('day').toDate()
              )
              .pipe(
                map(s => s.map(p => ({ ...p, PracticeId: providers.find(d => d.hpcsaNo === p.PracticeId)?.providerId || p.PracticeId })))
              )
          : this.mympsClient
              .getPracticeCalendarAppointments(providerId, providers.map(m => m.providerId).join(';'), newLocal, days)
              .pipe(timeout(30000))
      ),
      withLatestFrom(
        this.query.select(e => e.calendar),
        this.practiceProviders$
      ),
      withTransaction(([serverApps, storeAppointments, providers]) => {
        const currentApps = storeAppointments.filter(a => moment(a.StartDate) >= periodStart && moment(a.StartDate) < periodEnd);

        this.store.update(entity => {
          // remove all current apps within the time period
          let calendar = arrayRemove(entity.calendar, a => currentApps.some(b => b.CalendarEventXRef === a.CalendarEventXRef));
          // add all from server
          calendar = arrayAdd(
            calendar,
            serverApps.map(
              c =>
                ({
                  ...c,
                  Color: providers.find(p => p.PracticeId === c.PracticeId)?.Color || {},
                } as CalendarEventViewModel)
            )
          );
          return { calendar };
        });
      }),
      map(([serverApps, currentApps]) => serverApps.map(m => ({ ...m } as CalendarEventViewModel))),
      catchError((err, caught) => {
        console.error(err);
        return of([] as CalendarEventViewModel[]);
      })
    );
  }

  getAppointmentDetails(practiceId: string, appointmentXRef: string) {
    return this.mympsClient.getAppointmentDetails(practiceId, appointmentXRef);
  }

  async findNewItems(newList: PatientInWaitingRoom[], oldList: PatientInWaitingRoomViewModel[]) {
    return newList.filter(newItem => {
      const oldItem = oldList.find(
        oldItem =>
          oldItem.PatientId === newItem.PatientEvent.Patient.PatientId &&
          oldItem.PatientEvent.PracticeId === newItem.PatientEvent.PracticeId
      );
      return newItem.PatientEvent.Status <= 1 && (!oldItem || oldItem.PatientEvent.Status > 1);
    });
  }

  @action('getWaitingList')
  public getWaitingList(): Observable<PatientInWaitingRoomViewModel[]> {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$, this.practiceProviders$),
      switchMap(([provider, isForge, providers]) =>
        isForge
          ? this.merakiPatientService.getWaitingRoomsForPractice(provider.PracticeNumber, providers)
          : this.waitingRoomClient.getWaitingRoomsForPractice(provider.PracticeId).pipe(
              withLatestFrom(this.waitingRoom$),
              tap(
                async ([newList, oldList]) =>
                  // throwaway code  to allow mymps providers participate in the AI prototype and giving feedback
                  await this.processPatientSummary(newList, oldList, provider)
              ),
              map(([newList, oldList]) => newList)
            )
      ),
      map(
        waitingRoomList =>
          waitingRoomList?.map(
            patient =>
              ({
                ...patient,
                PatientId: patient.PatientEvent.Patient.PatientId,
              } as PatientInWaitingRoomViewModel)
          ) || []
      ),
      tap(waitingRoom => this.store.update({ waitingRoom }))
    );
  }

  async processPatientSummary(
    newList: PatientInWaitingRoom[],
    oldList: PatientInWaitingRoomViewModel[],
    provider: ProviderConfigurationVo
  ): Promise<any> {
    const practiceEnabled = await this.merakiClientService.isPracticeEnabledForPatientAI(provider.PracticeNumber).toPromise();
    if (practiceEnabled) {
      const newItems = await this.findNewItems(newList, oldList);
      if (newItems.length > 0) {
        for (const item of newItems) {
          // only trigger from owner of the provider
          if (provider.PracticeId == item.PatientEvent.PracticeId) {
            await this.merakiClientService.requestPatientSummary(provider, item.PatientEvent);
            console.warn('Requested patient summary for', item.PatientEvent.PracticeId, item.PatientEvent.PatientEventId);
          }
        }
        // } else {
        //   console.warn('No new patients in waiting room');
      }
      // } else {
      //   console.warn('Patient history summary is disabled');
    }
  }

  @action('updateOptions')
  updateOptions(options: { [key: string]: string }) {
    return this.currentTenantId$.pipe(
      take(1),
      switchMap(providerId => {
        const configurationOptions: SetProviderConfigurationOptions = {
          ProviderId: providerId,
          Options: options,
          OriginatedSystem: 'ClinicianApp',
        };
        return this.bilingClient.setProviderConfigurationOptions(providerId, configurationOptions);
      }),
      tap({
        next: restApiResult => {
          if (restApiResult.Sucess) {
            const details = {
              ...this.query.getValue().details,
              Options: options,
            };
            this.store.update({ details });
          }
        },
      })
    );
  }

  getSupportingProviders() {
    return this.provider$.pipe(
      take(1),
      withLatestFrom(this.isForge$),
      switchMap(([provider, isForge]) =>
        isForge
          ? this.merakiLookupService.getSupportingProviders(provider.PracticeNumber)
          : this.bilingClient.getSupportingProviders(provider.PracticeId)
      ),
      shareReplay(1)
    );
  }

  getPracticeProviderList(practiceId: string) {
    return this.bilingClient.getPracticeProviderList(practiceId);
  }

  updateOption(option: string, value: string) {
    return this.currentTenantId$.pipe(
      take(1),
      switchMap(providerId => {
        const providerConfigOptionChange: ChangeProviderConfigurationOptions = {
          Options: {},
          OriginatedSystem: 'ClinicianApp',
        };
        providerConfigOptionChange.Options[option] = value;

        const request = this.clinicalNew.updateProviderPreferences(providerId, providerConfigOptionChange);
        return request;
      }),
      tap({
        next: restApiResult => {
          if (restApiResult.Sucess) {
            const details = {
              ...this.query.getValue().details,
              Options: updateMapWithValue(this.query.getValue().details.Options, option, value),
            };

            this.store.update({ details });
          }
        },
      })
    );
  }

  updateMultiplePreferences(options: { key: string; value: string }[]) {
    return this.currentTenantId$.pipe(
      take(1),
      switchMap(providerId => {
        const providerConfigOptionChange: ChangeProviderConfigurationOptions = {
          Options: {},
          OriginatedSystem: 'ClinicianApp',
        };
        options.forEach(option => {
          providerConfigOptionChange.Options[option.key] = option.value;
        });

        const request = this.clinicalNew.updateProviderPreferences(providerId, providerConfigOptionChange);
        return request;
      }),
      tap({
        next: restApiResult => {
          if (restApiResult.Sucess) {
            const details = {
              ...this.query.getValue().details,
              Options: {
                ...this.query.getValue().details.Options,
                ...options.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {}),
              },
            };

            this.store.update({ details });
          }
        },
      })
    );
  }

  /******************* ALL DOCS *******************/

  @action('getDocument')
  public getDocument(documentId: string) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentClient.getPatientDocument(provider.PracticeId, documentId))
    );
  }

  /******************* PRACTICE DOCUMENTS (null patientId ) *******************/

  @action('uploadPracticeDocument')
  uploadPracticeDocument(documentVo: PatientDocumentVo, file: Blob) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentUploadClient.capturePatientDocument(provider.PracticeId, this.emptyGuid, documentVo, file))
    );
  }

  @action('getLastPracticeDocumentByCategory')
  public getLastPracticeDocumentByCategory(category: string) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentClient.getPatientDocumentByCategory(provider.PracticeId, this.emptyGuid, category))
    );
  }

  @action('getPracticeDocumentsByCategory')
  public getPracticeDocumentsByCategory(category: string) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentClient.getPatientDocuments(provider.PracticeId, this.emptyGuid)),
      map(documents => documents.filter(d => d.Category === category))
    );
  }

  @action('getPracticeDrawingTemplates')
  public getPracticeDrawingTemplates() {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentClient.getDrawingTemplates(provider.PracticeId, this.emptyGuid))
    );
  }

  /******************* PROVIDER DOCUMENTS (patientId = practiceTenantId) *******************/

  @action('uploadProviderDocument')
  uploadProviderDocument(documentVo: PatientDocumentVo, file: Blob) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentUploadClient.capturePatientDocument(provider.PracticeId, provider.PracticeId, documentVo, file))
    );
  }

  @action('getLastProviderDocumentByCategory')
  public getLastProviderDocumentByCategory(category: string) {
    return this.provider$.pipe(
      take(1),
      switchMap(provider => this.documentClient.getPatientDocumentByCategory(provider.PracticeId, provider.PracticeId, category))
    );
  }

  changeCalendarView(calendarView: 'day' | 'week') {
    this.store.update({ calendarView });
  }

  private getProviderConfiguration(providerId: string) {
    return this.bilingClient
      .getProviderConfiguration(providerId)
      .pipe(tap(providerConfiguration => this.store.update({ providerConfiguration })));
  }

  private getAppointmentTypeConfiguration(providerId: string) {
    return this.merakiLookupService.getDefaultConfigurationByType().pipe(
      map(data => data.Type),
      tap(appointmentTypes => this.store.update({ appointmentTypes }))
    );
  }

  public getProviderConfigurationByKey(configKey: string) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        map(config => (config && config?.Configuration[configKey]?.split(',')) || []),
        map(m => m.filter(f => f))
      );
  }

  private updateProviderConfigurationByKey(tenantId: string, providerConfiguration: PatientConfigurationVo) {
    return this.patientClient.revisePatientConfiguration(tenantId, tenantId, providerConfiguration).pipe(
      take(1),
      tap(result => result.Sucess && this.store.update({ providerConfiguration }))
    );
  }

  public addProviderConfigurationItemsByKey(tenantId: string, configKey: string, configValue: string[]) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: tenantId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq([...(config?.Configuration[configKey]?.split(',') || []), ...configValue]).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updateProviderConfigurationByKey(tenantId, config))
      );
  }

  public addProviderConfigurationItemByKey(tenantId: string, configKey: string, configValue: string) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: tenantId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq([...(config?.Configuration[configKey]?.split(',') || []), configValue]).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updateProviderConfigurationByKey(tenantId, config))
      );
  }

  public updateProviderConfigurationItemsByKey(tenantId: string, configKey: string, configValue: string[]) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: tenantId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq([...configValue]).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updateProviderConfigurationByKey(tenantId, config))
      );
  }

  public updateProviderConfigurationValueByKey(tenantId: string, configKey: string, configValue: any[]) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: tenantId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: JSON.stringify(configValue),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updateProviderConfigurationByKey(tenantId, config))
      );
  }

  public removeProviderConfigurationItemFromKey(tenantId: string, patientId: string, configKey: string, configValue: string) {
    return this.query
      .select(s => s.providerConfiguration)
      .pipe(
        take(1),
        map(
          config =>
            config ||
            ({
              PracticeId: tenantId,
              PatientId: patientId,
              CapturedDate: new Date(),
              Configuration: {},
            } as PatientConfigurationVo)
        ),
        map(config => {
          const newConfigValue = {
            [configKey]: _.uniq((config?.Configuration[configKey]?.split(',') || []).filter(f => f !== configValue)).join(','),
          };

          return {
            ...config,
            Configuration: { ...config?.Configuration, ...newConfigValue },
          } as PatientConfigurationVo;
        }),
        switchMap(config => this.updateProviderConfigurationByKey(tenantId, config))
      );
  }

  public acceptLatestPrivacyPolicy() {
    return this.updateOption(ProviderConfigurationKey.PrivacyPolicyDateAccepted, this.PrivacyPolicyLatestDate);
  }

  destroy() {}
}

function updateMapWithValue(objectMap: { [key: string]: string }, key: string, value: any) {
  if (!objectMap == null || key == null || value == null) {
    return objectMap;
  }

  const newMap = { ...objectMap };
  newMap[key] = value;
  return newMap;
}
