export enum ConsultReason {
  NormalConsult = 'Normal consult',
  AdhocSickNote = 'Adhoc sick note',
  AdhocPrescription = 'Adhoc prescription',
  TelehealthConsult = 'Telehealth consult',
  NewHospitalEvent = 'New hospital event',
  FollowUpHospitalEvent = 'Follow-up hospital event',
  VitalityAssessment = 'Vitality assessment',
  ConditionsMedicationsDispense = 'Conditions medications dispense',
  MedicalInsurance = 'Medical insurance',
  TelephonicConsult = 'TelephonicConsult',
  VideoConsult = 'VideoConsult',
}

export const VirtualVisitTypes = [ConsultReason.TelephonicConsult.toString(), ConsultReason.TelephonicConsult.toString()];
export const HospitalReasons = [ConsultReason.NewHospitalEvent.toString(), ConsultReason.FollowUpHospitalEvent.toString()];
export const AllowedPpeConsultReasons = [
  ConsultReason.NewHospitalEvent.toString(),
  ConsultReason.FollowUpHospitalEvent.toString(),
  ConsultReason.NormalConsult.toString(),
];

export enum PatientEventType {
  RegularConsultationType = 'RegularConsultation',
  NoChargeType = 'NoCharge',
  OutOfRoomsType = 'OutOfRooms',
  MedicalInsuranceType = 'MedicalInsurance',
  ChronicVisitType = 'ChronicVisit',
  MedicalCheckUp = 'MedicalCheckUp',
  AntenatalCheckup = 'AntenatalCheckup',
}

export enum EncounterType {
  CashInvoice = 'CashInvoice',
  ZeroInvoice = 'NoChargeInvoice',
  MedicalAidInvoice = 'MedicalAidInvoice',
  MedicalInsurance = 'MedicalInsurance',
}
