import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { TenantVo } from 'api-clinician-app';

export interface TenantsState extends EntityState<TenantVo> {}

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'tenants', idKey: 'TenantId', resettable: true })
export class TenantsStore extends EntityStore<TenantsState, TenantVo> {
  constructor() {
    super();
  }
}
