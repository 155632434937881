import { Injectable, InjectionToken, Injector, ValueProvider } from '@angular/core';
import { environment } from '@env/environment';
import { MyHandlerService } from '../interceptors/http-interceptor-handler';
import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';

export const HTTP_INTERCEPTORS_MERAKI = new InjectionToken<HttpInterceptor[]>('HTTP_INTERCEPTORS_MERAKI');

@Injectable()
export class HttpClientMeraki extends HttpClient {
  constructor(backend: HttpBackend, private injector: Injector) {
    super(new MyHandlerService(backend, injector, HTTP_INTERCEPTORS_MERAKI));
  }
}

export const MERAKI_API_GATEWAY = new InjectionToken<string>('MERAKI_API_GATEWAY');

const apiGatewayProvider: ValueProvider = {
  provide: MERAKI_API_GATEWAY,
  useValue: environment.merakiApiGateway,
};

export const MERAKI_NEXUS_PROVIDERS = [apiGatewayProvider];

export const MERAKI_CACHE_LB = new InjectionToken<string>('MERAKI_CACHE_LB');

const cacheLbProvider: ValueProvider = {
  provide: MERAKI_CACHE_LB,
  useValue: environment.merakiCacheLoadBalancer,
};

export const MERAKI_CACHE_LB_PROVIDERS = [cacheLbProvider];
